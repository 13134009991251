import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../shared/components/DashboardLayout";
import DashboardNavbar from "./../../shared/components/DashboardNavbar";
import Loading from './../../shared/Loading';
import Card from "@mui/material/Card";
import MDButton from "./../../shared/components/MDButton";
import MDBox from "./../../shared/components/MDBox";
import MDTypography from "./../../shared/components/MDTypography";
import SubscriptionTable from "./component/subscription-table";
import SubscriptionDialog from "./component/subscription-model-dialog";

const topHead = {
    fontWeight: "500",
    fontSize: "24px",
    color: "#09032B"
}
const mdbutton = {
    marginLeft: "auto",
    background: "linear-gradient(#09032B, #312B56)",
    color: "#FFFFFF",
    fontWeight: "700",
    fontSize: "12px",
    padding: "12px 24px"
}

const emptySubscription = {
    name: "",
    description: "",
    price: "",
    priceId: "",
    timeFrame: "",
    timeInterval: "",
    platformAccess: 1,
    userAccess: 1,
    themeAcess: 0,
    industryAccess: 0,
    publishStatus: 0,
    featured: 0
}

export default function SubscriptionPage(props) {
    const { isLoading, fetchSubscriptions, addSubscription, updateSubscription, subscriptions, isSaved } = props;
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(emptySubscription);

    useEffect(() => {
        fetchSubscriptions();
    }, []);

    useEffect(() => {
        if(isSaved) {
            fetchSubscriptions();
            onClose();
        }
    }, [isSaved]);

    function onClose() {
        setSelected(emptySubscription);
        setOpen(false);
    }

  return (
    <DashboardLayout>
        <DashboardNavbar />
        <Loading loading={isLoading} />
        <SubscriptionDialog
            open={open}
            onClose={onClose}
            selected={selected}
            setSelected={setSelected}
            addSubscription={addSubscription}
            updateSubscription={updateSubscription}
        />
        <MDBox style={{display: "flex", marginTop: "41px"}}>
            <MDTypography style={topHead}>Subscription Plans</MDTypography>
            <MDButton style={mdbutton}
                onClick={() => setOpen(true)} >
                Create Subscription
            </MDButton>
        </MDBox>
        <Card style={{ marginTop: "27px" }}>
            <SubscriptionTable subscriptions={subscriptions} selected={selected} setSelected={setSelected} setOpen={setOpen} />
        </Card>
    </DashboardLayout>
  );
}
