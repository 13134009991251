import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MDBox from "../../MDBox";
import MDTypography from "../../MDTypography";
import { useTimeline } from "../context";
import timelineItem from "./styles";

function TimelineItem({ color, icon, title, description, lastItem }) {
  const isDark = useTimeline();

  return (
    <MDBox position="relative" mb={3} sx={(theme) => timelineItem(theme, { lastItem, isDark })}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor="#B5B3BF"
        color="white"
        width="13px"
        height="13px"
        borderRadius="50%"
        position="absolute"
        top="8%"
        zIndex={2}
        sx={{ fontSize: ({ typography: { size } }) => size.sm }}
      >
        <Icon fontSize="inherit">{icon}</Icon>
      </MDBox>
      <MDBox ml={5.75} pt={description ? 0 : 0.5} lineHeight={0} maxWidth="30rem">
        <MDTypography
          variant="button"
          style={{ color: "#221C40", fontWeight: "600", fontSize: "16px" }}
        >
          {title}
        </MDTypography>
        <MDBox mt={0.5}>
          {description ? (
            <MDTypography
              variant="button"
              style={{ color: "#7B809A", fontWeight: "400", fontSize: "14px" }}
            >
              {description}
            </MDTypography>
          ) : null}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

TimelineItem.defaultProps = {
  color: "#E9EAED",
  lastItem: false,
  description: "",
};

TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  lastItem: PropTypes.bool,
};

export default TimelineItem;
