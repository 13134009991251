import React from "react";
import Dialog from "@mui/material/Dialog";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../shared/components/MDBox";
import MDTypography from "../../shared/components/MDTypography";
import FormField from "./../../shared/components/FormField";
import ClearIcon from "@mui/icons-material/Clear";
import MDButton from "../../shared/components/MDButton";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const topHead = {
  fontWeight: "700",
  fontSize: "20px",
  color: "#221C40",
};
const button = {
  background: "linear-gradient(180.67deg, #09032B 2.43%, #312B56 101%)",
  color: "#FFFFFF",
  borderRadius: "8px",
  width: "189px",
};
const boxText = {
  borderRadius: "12px",
  border: "1px solid #DEE1E6",
  width: "128px",
  height: "120px",
};
const buttonBox = {
  display: "flex",
  justifyContent: "center",
  marginTop: "52px",
};
const mdbox = {
  background: "#E6E8EB",
  height: "40px",
  width: "40px",
  borderRadius: "100px",
};
const mdText1 = {
  color: "red",
  marginTop: "-0px",
  marginBottom: "14px",
  fontSize: "12px",
  fontWeight: 400,
};

const X = 5;
const Y = 8;
const A = -4;
const B = -6;

export function SaveDialog(props) {
  const { open, selected, setSelected, onClose, saveLayout, updateLayout } = props;
  const [error, setError] = React.useState(false);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox style={{ maxWidth: "274px" }}>
                <MDTypography style={topHead}>
                  Update Layout
                </MDTypography>
              </MDBox>
              <MDBox style={mdbox}>
                <ClearIcon
                  onClick={() => onClose()}
                  style={{ margin: "12px 12px", cursor: "pointer" }}
                />
              </MDBox>
            </MDBox>
            <MDBox mb={2} style={{ marginTop: "24px" }}>
              <FormField
                label="Name"
                name="name"
                placeholder="Theme Name"
                type="text"
                value={selected.name}
                onChange={e => {
                  setSelected({ ...selected, name: e.target.value });
                  setError(false);
                }}
              />
              {error != "" && (
                <MDTypography style={mdText1}>Name is required.</MDTypography>
              )}
            </MDBox>

            <MDBox>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={selected.facebook === 1} onChange={() => setSelected({ ...selected, facebook: selected.facebook === 1 ? 0 : 1 })} />} label="Facebook?" />
                <FormControlLabel control={<Checkbox checked={selected.linkedin === 1} onChange={() => setSelected({ ...selected, linkedin: selected.linkedin === 1 ? 0 : 1 })} />} label="Linkedin?" />
                <FormControlLabel control={<Checkbox checked={selected.insta === 1} onChange={() => setSelected({ ...selected, insta: selected.insta === 1 ? 0 : 1 })} />} label="Instagram?" />
                <FormControlLabel control={<Checkbox checked={selected.gmb === 1} onChange={() => setSelected({ ...selected, gmb: selected.gmb === 1 ? 0 : 1 })} />} label="GMB?" />
              </FormGroup>
            </MDBox>

            <MDBox sx={{ mt: 1 }}>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  variant="standard"
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selected.status}
                  label="Status"
                  onChange={e => setSelected({ ...selected, status: e.target.value })}
                >
                  <MenuItem value={1}>Publish</MenuItem>
                  <MenuItem value={2}>Draft</MenuItem>
                  <MenuItem value={3}>Disabled</MenuItem>
                </Select>
            </MDBox>

            <MDBox style={buttonBox}>
              <MDButton
                style={button}
                fullWidth
                onClick={() => {
                  if (selected.name == "") {
                    setError(true);
                    return;
                  } else {
                    setError(false);
                    if (selected.id) {
                      updateLayout(selected);
                    } else {
                      saveLayout(selected);
                    }
                  }
                }}
              >
                SAVE
              </MDButton>
            </MDBox>
            </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
