import * as actionTypes from "./industryActionType";
import axios from 'axios';
import { checkError } from './../error';
const baseUrl = process.env.REACT_APP_API_URL;

export const createIndustry = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(createIndustryInit());
        axios.post(`${baseUrl}/industry/store`, data, config)
        .then((response)=>{
            dispatch(createIndustrySuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createIndustryFailure());
        })
    };
};

export const createIndustryInit = () => ({
    type: actionTypes.CREATE_INDUSTRY
});

export const createIndustryFailure = data => ({
    type: actionTypes.CREATE_INDUSTRY_FAILURE
});

export const createIndustrySuccess = data => ({
    type: actionTypes.CREATE_INDUSTRY_SUCCESS,
    payload: { data }
});

export const updateIndustry = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(updateIndustryInit());
        axios.put(`${baseUrl}/industry/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateIndustrySuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateIndustryFailure());
        })
    };
};

export const updateIndustryInit = () => ({
    type: actionTypes.UPDATE_INDUSTRY
});

export const updateIndustryFailure = data => ({
    type: actionTypes.UPDATE_INDUSTRY_FAILURE
});

export const updateIndustrySuccess = data => ({
    type: actionTypes.UPDATE_INDUSTRY_SUCCESS,
    payload: { data }
});

export const deleteIndustry = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(deleteIndustryInit());
        axios.delete(`${baseUrl}/industry/${id}`, config)
        .then((response)=>{
            dispatch(deleteIndustrySuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteIndustryFailure());
        })
    };
};

export const deleteIndustryInit = () => ({
    type: actionTypes.DELETE_INDUSTRY
});

export const deleteIndustryFailure = data => ({
    type: actionTypes.DELETE_INDUSTRY_FAILURE
});

export const deleteIndustrySuccess = data => ({
    type: actionTypes.DELETE_INDUSTRY_SUCCESS,
    payload: { data }
});

export const fetchIndustry = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(fetchIndustryInit());
        axios.get(`${baseUrl}/industry`, config)
        .then((response)=>{
            dispatch(fetchIndustrySuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchIndustryFailure());
        })
    };
};

export const fetchIndustryInit = () => ({
    type: actionTypes.FETCH_INDUSTRY
});

export const fetchIndustryFailure = data => ({
    type: actionTypes.FETCH_INDUSTRY_FAILURE
});

export const fetchIndustrySuccess = data => ({
    type: actionTypes.FETCH_INDUSTRY_SUCCESS,
    payload: { data }
});

export const fetchIndustryForAutoComplete = () => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(fetchIndustryForAutoCompleteInit());
        axios.get(`${baseUrl}/industry/options`, config)
        .then((response)=>{
            dispatch(fetchIndustryForAutoCompleteSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchIndustryForAutoCompleteFailure());
        })
    };
};

export const fetchIndustryForAutoCompleteInit = () => ({
    type: actionTypes.FETCH_INDUSTRY_FOR_AUTOCOMPLETE
});

export const fetchIndustryForAutoCompleteFailure = data => ({
    type: actionTypes.FETCH_INDUSTRY_FOR_AUTOCOMPLETE_FAILURE
});

export const fetchIndustryForAutoCompleteSuccess = data => ({
    type: actionTypes.FETCH_INDUSTRY_FOR_AUTOCOMPLETE_SUCCESS,
    payload: { data }
});