import { toast } from 'react-toastify';

export function checkError(err) {
    if (err.response.status === 401) {
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("user");
        window.location.reload();
    }
    toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_RIGHT
    });
}