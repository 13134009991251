import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Loading from './../../shared/Loading';
import MDAvatar from "./../../shared/components/MDAvatar";
import MDTypography from "./../../shared/components/MDTypography";
import MDButton from "./../../shared/components/MDButton";
import MDBox from "./../../shared/components/MDBox";
import logo from "./../../assets/images/logo-ct-dark.png";
import FormField from "./../../shared/components/FormField";
import googleLogo from "./../../assets/images/googleLogo.png";
import { Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SignupPage(props) {
  console.warn("signup props:- ", props);

  console.warn("signup props emailVerified:- ", props.user.emailVerified);
  const navigate = useNavigate();
  const { user, isLoading, isLoggedIn } = props;
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const x = 0;
  const y = -11;
  const ortext = {
    transform: `translate(${x}px, ${y}px)`,
    backgroundColor: "white",
    width: "33px",
    margin: "0px auto",
    borderRadius: "25px",
    color: "#344767",
    fontSize: "14px",
  };
  const orborder = {
    borderTop: "1px dashed #E4E4E4",
    textAlign: "center",
    marginTop: "24px",
  };
  const mdText = {
    color: "#E71D36",
    marginTop: "-14px",
    marginBottom: "14px",
    fontSize: "12px",
    fontWeight: 600,
  };

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    retypepassword: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setErrors(validate(values));
  };

  const validate = (values) => {
    let errorss = {};
    const regex = /\S+@\S+\.\S+/;
    if (!values.firstName) {
      errorss.firstName = "First name is required";
    }
    if (!values.lastName) {
      errorss.lastName = "Last name is required";
    }
    if (!values.email) {
      errorss.email = "Email is required";
    } else if (!regex.test(values.email)) {
      errorss.email = "This is not a valid email format";
    }
    if (!values.password) {
      errorss.password = "password is required";
    }
    if (values.password !== values.retypepassword) {
      errorss.retypepassword = "Password and Retype Password does not match.";
    }
    if (!values.retypepassword) {
      errorss.retypepassword = "Retype Password is required";
    }
    // userRegister(values);
    return errorss;
  };

  console.warn("email:-", props.user.emailVerified);
  if (isLoggedIn && user.emailVerified == 0) {
    navigate("/email-verify");
  }
  // useEffect(() => {
  //   console.warn("email:-", user.emailConfirmed)
  //   if (isLoggedIn && user.emailConfirmed == 0) {
  //       navigate('/email-verify');
  //       return;
  //     // props.history.push('/login');
  //   }
  // }, [isLoggedIn]);

  return (
    <>
    <Loading loading={isLoading} />
    <Grid style={{ backgroundColor: "#101030f0", minHeight: "100vh" }}>
      <Grid
        item
        style={{ display: "flex", paddingLeft: "45px", paddingTop: "32px" }}
      >
        <MDAvatar
          src={logo}
          variant="square"
          style={{ width: "13px", height: "22px", marginTop: "5px" }}
        />
        &nbsp;&nbsp;
        <MDTypography style={{ color: "white" }}>Social Broker</MDTypography>
      </Grid>
      <Grid item style={{ display: "flex", justifyContent: "center" }}>
        <MDBox
          style={{
            backgroundColor: "white",
            width: "469px",
            height: "778px",
            padding: "0px 25px",
            borderRadius: "12px",
            marginTop: "21px",
          }}
        >
          <MDBox style={{ textAlign: "center" }}>
            <MDTypography
              style={{
                color: "#2A3342",
                fontSize: "25px",
                fontWeight: "700",
                marginTop: "40px",
              }}
            >
              Create your account
            </MDTypography>
            <MDTypography
              style={{
                color: "#556987",
                fontSize: "16px",
                fontWeight: "700",
                marginTop: "5px",
              }}
            >
              Let’s get social
            </MDTypography>
          </MDBox>
          <form>
            <MDBox mb={2} style={{ marginTop: "24px" }}>
              <FormField
                  label="First name"
                  name="firstName"
                  placeholder="John"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                />
            </MDBox>
            {errors.firstName && (
              <MDTypography style={mdText}>{errors.firstName}</MDTypography>
            )}
            <MDBox mb={2} style={{ marginTop: "24px" }}>
            <FormField
                  label="Last name"
                  name="lastName"
                  placeholder="Doe"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                />
            </MDBox>
            {errors.lastName && (
              <MDTypography style={mdText}>{errors.lastName}</MDTypography>
            )}
            <MDBox mb={2} style={{ marginTop: "24px" }}>
            <FormField
                  label="Email"
                  name="email"
                  placeholder="john@email.com"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                />
            </MDBox>
            {errors.email && (
              <MDTypography style={mdText}>{errors.email}</MDTypography>
            )}
            <MDBox mb={2} style={{ marginTop: "24px" }}>
            <FormField
                  label="Password"
                  name="password"
                  placeholder="****************"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                />
            </MDBox>
            {errors.password && (
              <MDTypography style={mdText}>{errors.password}</MDTypography>
            )}
            <MDBox mb={2} style={{ marginTop: "24px" }}>
            <FormField
                  label="Retype password"
                  name="retypepassword"
                  placeholder="****************"
                  type="password"
                  value={values.retypepassword}
                  onChange={handleChange}
                />
            </MDBox>
            {errors.retypepassword && (
              <MDTypography style={mdText}>{errors.retypepassword}</MDTypography>
            )}
            <MDBox
              display="block"
              alignItems="center"
              ml={0}
              style={{ marginTop: "24px" }}
            >
              <Grid>
                <Grid item>
                  <Checkbox />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={handleSetRememberMe}
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Remember me
                  </MDTypography>
                </Grid>
                <Grid item>
                  <MDTypography
                    sx={{
                      color: "#f4233d",
                      fontSize: "14px",
                      float: "right",
                      marginTop: "-28px",
                    }}
                  >
                    Forget Password?
                  </MDTypography>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                onClick={handleFormSubmit}
                type="submit"
                style={{ backgroundColor: "#09032B", color: "white" }}
                size="large"
                fullWidth
              >
                sign up now
              </MDButton>
            </MDBox>
          </form>
          <MDBox mt={3} textAlign="center">
            <MDTypography variant="button" color="text">
              Don&apos;t have an account?{" "}
              <MDTypography
                component={Link}
                to="/"
                variant="button"
                color="secondary"
                fontWeight="medium"
                textGradient
              >
                Signin
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDBox style={orborder}>
            <MDTypography style={ortext}>OR</MDTypography>
          </MDBox>
          <MDButton
            style={{
              backgroundColor: "#F1F1F1",
              color: "#1B172E",
              marginBottom: "40px",
            }}
            size="large"
            fullWidth
          >
            <MDAvatar
              style={{ width: "25px", height: "25px" }}
              src={googleLogo}
            />{" "}
            &nbsp; sign in with Google
          </MDButton>
        </MDBox>
      </Grid>
      <ToastContainer style={{ fontSize: "14px", width: "auto" }} />
    </Grid>
    </>
  );
}
