import MasterLayoutPage from './master-layouts.component';
import { connect } from 'react-redux';
import { createMasterLayout, updateMasterLayout, deleteMasterLayout, fetchMasterLayouts } from '../../api/master-layout/masterLayoutAction';

const mapDispatchToProps = {
    createMasterLayout, 
    updateMasterLayout,
    deleteMasterLayout,
    fetchMasterLayouts
}

const mapStateToProps = state => ({
    isLoading: state.masterLayoutPage.isLoading,
    isSaved: state.masterLayoutPage.isSaved,
    masterLayouts: state.masterLayoutPage.masterLayouts
});

export const MasterLayoutPageContainer = connect(mapStateToProps, mapDispatchToProps)(MasterLayoutPage);