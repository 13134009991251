import SignupPage from './signup.component';
import { connect } from 'react-redux';
// import { userRegister } from './../../api/user/userAction';

const mapDispatchToProps = {
    // userRegister
}

const mapStateToProps = state => ({
    isLoggedIn: state.userPage.isLoggedIn,
    user: state.userPage.loggedUser,
    isLoading: state.userPage.isLoading,
});

export const SignupPageContainer = connect(mapStateToProps, mapDispatchToProps)(SignupPage);