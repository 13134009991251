/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Countries flags
import { Stack } from "@mui/material";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import MDProgress from "./../../../shared/components/MDProgress";
import US from "./../../../assets/images/icons/flags/US.png";

const salesTableData = [
  {
    country: [US, "united state"],
    sales: "2.5M",
    bounce: (
      <MDBox>
        <MDBox width="8rem">
          <MDProgress variant="gradient" value={80} color="success" />
        </MDBox>
        <Stack direction="row">
          <MDTypography
            style={{ color: "#50B153", fontSize: "12px", fontWeight: "300" }}
          >
            +55%
          </MDTypography>
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "300" }}
          >
            engagement
          </MDTypography>
        </Stack>
      </MDBox>
    ),
  },
  {
    country: [US, "united state"],
    sales: "2.5M",
    bounce: (
      <MDBox>
        <MDBox width="8rem">
          <MDProgress variant="gradient" value={80} color="success" />
        </MDBox>
        <Stack direction="row">
          <MDTypography
            style={{ color: "#50B153", fontSize: "12px", fontWeight: "300" }}
          >
            +55%
          </MDTypography>
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "300" }}
          >
            engagement
          </MDTypography>
        </Stack>
      </MDBox>
    ),
  },
  {
    country: [US, "united state"],
    sales: "2.5M",
    bounce: (
      <MDBox>
        <MDBox width="8rem">
          <MDProgress variant="gradient" value={80} color="success" />
        </MDBox>
        <Stack direction="row">
          <MDTypography
            style={{ color: "#50B153", fontSize: "12px", fontWeight: "300" }}
          >
            +55%
          </MDTypography>
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "300" }}
          >
            engagement
          </MDTypography>
        </Stack>
      </MDBox>
    ),
  },
  {
    country: [US, "united state"],
    sales: "2.5M",
    bounce: (
      <MDBox>
        <MDBox width="8rem">
          <MDProgress variant="gradient" value={80} color="success" />
        </MDBox>
        <Stack direction="row">
          <MDTypography
            style={{ color: "#50B153", fontSize: "12px", fontWeight: "300" }}
          >
            +55%
          </MDTypography>
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "300" }}
          >
            engagement
          </MDTypography>
        </Stack>
      </MDBox>
    ),
  },
  // { country: [BR, "brasil"], sales: 562, bounce: "32.14%" },
  // { country: [AU, "australia"], sales: 400, bounce: "56.83%" },
];

export default salesTableData;
