export const blocks = [
    {
        "id": "circle",
        "label": "Cicle",
        "logo": "circle.png",
        "data-name": "Circle",
        "html": "<div class='circleItem innerItem' style='width: 300px; height: 300px;border-radius: 50%; border: 2px solid #5a5a5a'></div>",
        "scrollable": true,
        "resizeable": true
    },
    {
        "id": "rectangle",
        "label": "Rectangle",
        "data-name": "Rectangle",
        "logo": "rectangle.png",
        "html": "<div class='ractangleItem innerItem' style='width: 300px; height: 300px; border-radius: 20px; border: 2px solid #5a5a5a'></div>",
        "scrollable": true,
        "resizeable": true
    },
    {
        "id": "image",
        "label": "Image",
        "logo": "image.png",
        "data-name": "Image",
        "html": "<div style='width: 200px; height: 200px' class='imageItem innerItem'></div>",
        "scrollable": true,
        "resizeable": true
    },
    {
        "id": "text",
        "label": "Text",
        "data-name": "Text",
        "logo": "text.png",
        "html": "<div class='textItem innerItem' style='display: flex; align-items: center; justify-content: center; overflow: hidden; padding: 5px'></div>",
        "scrollable": true,
        "resizeable": true
    },
]