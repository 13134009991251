import * as actionTypes from "./themeActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;
export const uploadBG = (data) => {
    const params = new FormData();
    Object.keys(data).forEach(key => {
        const value = data[key];
        params.append(key, value);
    });
    return (dispatch) => {
        dispatch(uploadBGInit());
        axios.post(`${baseUrl}/theme/upload-image`, params)
        .then((response)=>{
            dispatch(uploadBGSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(uploadBGFailure());
        })
    };
};

export const uploadBGInit = () => ({
    type: actionTypes.UPLOAD_BG
});

export const uploadBGFailure = data => ({
    type: actionTypes.UPLOAD_BG_FAILURE
});

export const uploadBGSuccess = data => ({
    type: actionTypes.UPLOAD_BG_SUCCESS,
    payload: { data }
});

export const saveLayout = (data) => {
    return (dispatch) => {
        dispatch(saveLayoutInit());
        axios.post(`${baseUrl}/layout/store`, data)
        .then((response)=>{
            dispatch(saveLayoutSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(saveLayoutFailure());
        })
    };
};

export const saveLayoutInit = () => ({
    type: actionTypes.SAVE_LAYOUT
});

export const saveLayoutFailure = data => ({
    type: actionTypes.SAVE_LAYOUT_FAILURE
});

export const saveLayoutSuccess = data => ({
    type: actionTypes.SAVE_LAYOUT_SUCCESS,
    payload: { data }
});

export const createTheme = (data) => {
    const params = new FormData();
    Object.keys(data).forEach(key => {
        const value = data[key];
        params.append(key, value);
    });
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(createThemeInit());
        axios.post(`${baseUrl}/theme/store`, params, config)
        .then((response)=>{
            dispatch(createThemeSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createThemeFailure());
        })
    };
};

export const createThemeInit = () => ({
    type: actionTypes.CREATE_THEME
});

export const createThemeFailure = data => ({
    type: actionTypes.CREATE_THEME_FAILURE
});

export const createThemeSuccess = data => ({
    type: actionTypes.CREATE_THEME_SUCCESS,
    payload: { data }
});

export const updateTheme = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(updateThemeInit());
        axios.put(`${baseUrl}/theme/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateThemeSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateThemeFailure());
        })
    };
};

export const updateThemeInit = () => ({
    type: actionTypes.UPDATE_THEME
});

export const updateThemeFailure = data => ({
    type: actionTypes.UPDATE_THEME_FAILURE
});

export const updateThemeSuccess = data => ({
    type: actionTypes.UPDATE_THEME_SUCCESS,
    payload: { data }
});

export const deleteTheme = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(deleteThemeInit());
        axios.delete(`${baseUrl}/theme/${id}`, config)
        .then((response)=>{
            dispatch(deleteThemeSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteThemeFailure());
        })
    };
};

export const deleteThemeInit = () => ({
    type: actionTypes.DELETE_THEME
});

export const deleteThemeFailure = data => ({
    type: actionTypes.DELETE_THEME_FAILURE
});

export const deleteThemeSuccess = data => ({
    type: actionTypes.DELETE_THEME_SUCCESS,
    payload: { data }
});

export const fetchTheme = () => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(fetchThemeInit());
        axios.get(`${baseUrl}/theme`, config)
        .then((response)=>{
            dispatch(fetchThemeSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchThemeFailure());
        })
    };
};
export const fetchCompleteTheme = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }, params: data};
    return (dispatch) => {
        dispatch(fetchCompleteThemeInit());
        axios.get(`${baseUrl}/theme/get-complete-theme`, config)
        .then((response)=>{
            dispatch(fetchCompleteThemeSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchCompleteThemeFailure());
        })
    };
};

export const fetchCompleteThemeInit = () => ({
    type: actionTypes.FETCH_COMPLETE_THEME
});

export const fetchCompleteThemeFailure = data => ({
    type: actionTypes.FETCH_COMPLETE_THEME_FAILURE
});

export const fetchCompleteThemeSuccess = data => ({
    type: actionTypes.FETCH_COMPLETE_THEME_SUCCESS,
    payload: { data }
});

export const fetchThemeInit = () => ({
    type: actionTypes.FETCH_THEME
});

export const fetchThemeFailure = data => ({
    type: actionTypes.FETCH_THEME_FAILURE
});

export const fetchThemeSuccess = data => ({
    type: actionTypes.FETCH_THEME_SUCCESS,
    payload: { data }
});

export const fetchThemeForAutoComplete = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(fetchThemeForAutoCompleteInit());
        axios.get(`${baseUrl}/theme/options`, config)
        .then((response)=>{
            dispatch(fetchThemeForAutoCompleteSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchThemeForAutoCompleteFailure());
        })
    };
};

export const fetchThemeForAutoCompleteInit = () => ({
    type: actionTypes.FETCH_THEME_FOR_AUTOCOMPLETE
});

export const fetchThemeForAutoCompleteFailure = data => ({
    type: actionTypes.FETCH_THEME_FOR_AUTOCOMPLETE_FAILURE
});

export const fetchThemeForAutoCompleteSuccess = data => ({
    type: actionTypes.FETCH_THEME_FOR_AUTOCOMPLETE_SUCCESS,
    payload: { data }
});

export const getLayout = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(getLayoutInit());
        axios.get(`${baseUrl}/layout/layout/${id}`, config)
        .then((response)=>{
            dispatch(getLayoutSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getLayoutFailure());
        })
    };
};

export const getLayoutInit = () => ({
    type: actionTypes.GET_LAYOUT
});

export const getLayoutFailure = data => ({
    type: actionTypes.GET_LAYOUT_FAILURE
});

export const getLayoutSuccess = data => ({
    type: actionTypes.GET_LAYOUT_SUCCESS,
    payload: { data }
});