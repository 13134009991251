import * as actionTypes from "./whiteActionType";
import axios from 'axios';
import { checkError } from './../error';
const baseUrl = process.env.REACT_APP_API_URL;

export const createWhite = (data) => {
    const params = new FormData();
    Object.keys(data).forEach(key => {
        const value = data[key];
        params.append(key, value);
    });
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(createWhiteInit());
        axios.post(`${baseUrl}/admin/store`, params, config)
        .then((response)=>{
            dispatch(createWhiteSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createWhiteFailure());
        })
    };
};

export const createWhiteInit = () => ({
    type: actionTypes.CREATE_WHITE
});

export const createWhiteFailure = data => ({
    type: actionTypes.CREATE_WHITE_FAILURE
});

export const createWhiteSuccess = data => ({
    type: actionTypes.CREATE_WHITE_SUCCESS,
    payload: { data }
});

export const updateWhite = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(updateWhiteInit());
        axios.put(`${baseUrl}/admin/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateWhiteSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateWhiteFailure());
        })
    };
};

export const updateWhiteInit = () => ({
    type: actionTypes.UPDATE_WHITE
});

export const updateWhiteFailure = data => ({
    type: actionTypes.UPDATE_WHITE_FAILURE
});

export const updateWhiteSuccess = data => ({
    type: actionTypes.UPDATE_WHITE_SUCCESS,
    payload: { data }
});

export const deleteWhite = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(deleteWhiteInit());
        axios.delete(`${baseUrl}/admin/${id}`, config)
        .then((response)=>{
            dispatch(deleteWhiteSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteWhiteFailure());
        })
    };
};

export const deleteWhiteInit = () => ({
    type: actionTypes.DELETE_WHITE
});

export const deleteWhiteFailure = data => ({
    type: actionTypes.DELETE_WHITE_FAILURE
});

export const deleteWhiteSuccess = data => ({
    type: actionTypes.DELETE_WHITE_SUCCESS,
    payload: { data }
});

export const fetchWhite = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(fetchWhiteInit());
        axios.get(`${baseUrl}/admin`, config)
        .then((response)=>{
            dispatch(fetchWhiteSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchWhiteFailure());
        })
    };
};

export const fetchWhiteInit = () => ({
    type: actionTypes.FETCH_WHITE
});

export const fetchWhiteFailure = data => ({
    type: actionTypes.FETCH_WHITE_FAILURE
});

export const fetchWhiteSuccess = data => ({
    type: actionTypes.FETCH_WHITE_SUCCESS,
    payload: { data }
});
