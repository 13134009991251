import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import { RoutePath } from "./../../routes";
import Sidenav from "./../../layout/Sidenav/index";
import routes from "./routes";
import { useLocation } from "react-router-dom";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function AppRoot(props) {
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });
  const [open, setOpen] = React.useState(false);

  const location = useLocation();

  const routesWithoutSidenav = [
    "login",
    "business-details",
    "business-account",
    "content",
    "user-details",
    "content-table",
    "contentTable",
    "content-list",
    "facebook",
    "overview",
    "curated",
    "user-account",
    "industry",
    "topic",
    "themes",
    "master-layout",
    "media",
    "user-content",
    "subscription",
    "calendar",
    "white-label"
  ];

  React.useEffect(() => {
    const loc = location.pathname.split("/");
    (loc.length > 1 && routesWithoutSidenav.indexOf(loc[1]) > 0) || (loc.length > 2 && routesWithoutSidenav.indexOf(loc[2]) > 0)  ? setOpen(true) : setOpen(false);    
  }, [location]);

  return (
    <Fragment>
      {open ? (
        <Sidenav brandName="Social Broker" routes={routes} />
       ) : null}
       <LocalizationProvider dateAdapter={AdapterLuxon}>
        <RoutePath />
       </LocalizationProvider>
    </Fragment>
  );
}
