export const CREATE_INDUSTRY = "CREATE_INDUSTRY";
export const CREATE_INDUSTRY_FAILURE = "CREATE_INDUSTRY_FAILURE";
export const CREATE_INDUSTRY_SUCCESS = "CREATE_INDUSTRY_SUCCESS";

export const UPDATE_INDUSTRY = "UPDATE_INDUSTRY";
export const UPDATE_INDUSTRY_FAILURE = "UPDATE_INDUSTRY_FAILURE";
export const UPDATE_INDUSTRY_SUCCESS = "UPDATE_INDUSTRY_SUCCESS";

export const FETCH_INDUSTRY = "FETCH_INDUSTRY";
export const FETCH_INDUSTRY_FAILURE = "FETCH_INDUSTRY_FAILURE";
export const FETCH_INDUSTRY_SUCCESS = "FETCH_INDUSTRY_SUCCESS";

export const FETCH_INDUSTRY_FOR_AUTOCOMPLETE = "FETCH_INDUSTRY_FOR_AUTOCOMPLETE";
export const FETCH_INDUSTRY_FOR_AUTOCOMPLETE_FAILURE = "FETCH_INDUSTRY_FOR_AUTOCOMPLETE_FAILURE";
export const FETCH_INDUSTRY_FOR_AUTOCOMPLETE_SUCCESS = "FETCH_INDUSTRY_FOR_AUTOCOMPLETE_SUCCESS";

export const DELETE_INDUSTRY = "DELETE_INDUSTRY";
export const DELETE_INDUSTRY_FAILURE = "DELETE_INDUSTRY_FAILURE";
export const DELETE_INDUSTRY_SUCCESS = "DELETE_INDUSTRY_SUCCESS";