const defaultLineChartData = {
  labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  datasets: [
    {
      label: "Instagram",
      color: "error",
      data: [50, 100, 200, 190, 400, 350, 500, 450, 700],
    },
    {
      label: "Facebook",
      color: "info",
      data: [10, 30, 40, 120, 150, 220, 280, 250, 280],
    },
  ],
};

export default defaultLineChartData;
