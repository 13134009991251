import React from "react";
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";
import Loading from './../../shared/Loading';
// MUI
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MDAvatar from "../../shared/components/MDAvatar";
import MDButton from "../../shared/components/MDButton";
import HelpIcon from '@mui/icons-material/Help';
import MDBox from "../../shared/components/MDBox";
import MDTypography from "../../shared/components/MDTypography";
import DefaultStatisticsCard from "./../../shared/components/Cards/DefaultStatisticsCard";
import drake from "./../../assets/images/drake.jpg";
import burceMars from "./../../assets/images/alec.png";
import DefaultLineChart from "./../../shared/components/DefaultLineChart";
import defaultLineChartData from "./data/defaultLineChartData";
import MDBadgeDot from "./../../shared/components/MDBadgeDot";

const topHead = {
    fontWeight: "500",
    fontSize: "24px",
    color: "#09032B"
}
const imageText = {
    width: "20px",
    height: "20px",
    marginRight: "8px",
}
const text = {
    fontWeight: "400",
    fontSize: "12px",
    color: "#7B809A",
}

export default function FacebookPage() {
    const [salesDropdownValue, setSalesDropdownValue] = React.useState("6 May - 7 May");
    const [salesDropdown, setSalesDropdown] = React.useState(null);
    const openSalesDropdown = ({ currentTarget }) => setSalesDropdown(currentTarget);
    const closeSalesDropdown = ({ currentTarget }) => {
      setSalesDropdown(null);
      setSalesDropdownValue(currentTarget.innerText || salesDropdownValue);
    };
    const renderMenu = (state, close) => (
        <Menu
          anchorEl={state}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={Boolean(state)}
          onClose={close}
          keepMounted
          disableAutoFocusItem
        >
          <MenuItem onClick={close}>Last 7 days</MenuItem>
          <MenuItem onClick={close}>Last week</MenuItem>
          <MenuItem onClick={close}>Last 30 days</MenuItem>
        </Menu>
      );

  return (
    <DashboardLayout>
        <DashboardNavbar />
        <MDBox style={{display: "flex", marginTop: "41px"}}>
            <MDBox style={{ display: "flex", border: "1px solid #dadada", borderRadius: "13px", padding: "12px" }}> 
                <MDAvatar
                    src={drake}
                    variant="square"
                    style={{ width: "40px", height: "40px", borderRadius: "8px", marginRight: "12px" }}
                />
                <MDBox>
                    <MDTypography style={{fontWeight: "700", fontSize: "20px", color: "#344767"}} >
                        1 Account
                    </MDTypography>
                    <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#344767"}} >
                        Connected
                    </MDTypography>
                </MDBox>
            </MDBox>
            <MDBox style={{marginLeft: "auto", display: "flex", alignItems: "center"}}>
                <MDButton variant="outlined" style={{textTransform:"capitalize", padding: "11px 16px", fontSize: "14px", fontWeight: "400",
                    background: "#FFFFFF", borderRadius: "8px", border: "1px solid #CECDD5", color: "#7B809A", marginRight: "20px"}} 
                    size="small">
                    <CalendarTodayIcon style={{marginRight: "17px", color: "#323232"}} />
                    Last 30 Days
                </MDButton>
                <MDButton variant="outlined" style={{textTransform:"capitalize", padding: "11px 16px", fontSize: "12px", fontWeight: "700",
                    background: "linear-gradient(180.67deg, #09032B 2.43%, #312B56 101%)", borderRadius: "8px", color: "#FFFFFF"}} 
                    size="small">
                    Export <SystemUpdateAltIcon style={{marginLeft: "17px"}} />
                </MDButton>
            </MDBox>
        </MDBox>
        <MDBox style={{marginTop: "41px", marginBottom: "24px"}}>
            <MDTypography style={topHead}>Overview</MDTypography>
        </MDBox>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={2}>
                <DefaultStatisticsCard
                    title="Total Post"
                    count="224"
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <DefaultStatisticsCard
                    title="Post like"
                    count="2.4 M"
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <DefaultStatisticsCard
                    title="Total Post"
                    count="3.2 M"
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <DefaultStatisticsCard
                    title="Total Post"
                    count="3.2 M"
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <DefaultStatisticsCard
                    title="Total Post"
                    count="3.2 M"
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <DefaultStatisticsCard
                    title="Total Post"
                    count="3.2 M"
                />
            </Grid>
        </Grid>
        <Card style={{marginTop: "32px"}}>
            <MDBox style={{margin: "32px 32px 0"}}>
                <MDBox style={{display: "flex"}}>
                    <MDTypography style={{fontWeight: "700", fontSize: "20px", color: "#09032B", marginRight: "17px"}}>Posts</MDTypography>
                    <HelpIcon style={{marginTop: "5px"}} />
                </MDBox>
            </MDBox>
            <Divider style={{ background: "#E9EAED", opacity: "0.7" }} />
            <Grid container style={{padding: "8px 20px"}}>
                <Grid item xs={12} md={6}>
                    <MDBox display="flex" alignItems="center">
                        <MDAvatar
                            src={drake}
                            variant="square"
                            style={{ width: "104px", height: "72px", borderRadius: "6px", border: "1px solid #E8E8E8", marginRight: "16px" }}
                        />
                        <MDBox>
                            <MDBox style={{ display: "flex" }}>
                                <MDAvatar style={imageText}
                                    src={burceMars}
                                    alt="profile-image"
                                    size="sm"
                                />
                                <MDTypography style={text} >
                                    &nbsp;Posted by
                                </MDTypography>
                                <MDTypography style={{ fontSize: "12px", fontWeight: "400" }}
                                    variant="body1"
                                >
                                    &nbsp;&quot;Name here&quot;
                                </MDTypography>
                            </MDBox>
                            <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#7B809A", marginTop: "8px"}}>
                                Published at 31 Jan 12.30 am
                            </MDTypography>
                            <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#534F6B", marginTop: "8px"}}>
                                Reference site about Lorem Ipsum, giving inform on its origins, Lipsum genera...
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MDBox style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <MDBox>
                            <MDTypography
                                style={{
                                    fontWeight: "700",
                                    fontSize: "17px",
                                    color: "#344767",
                                }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Post Share
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                            <MDTypography
                            style={{
                                fontWeight: "700",
                                fontSize: "17px",
                                color: "#344767",
                            }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Likes
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                            <MDTypography
                            style={{
                                fontWeight: "700",
                                fontSize: "17px",
                                color: "#344767",
                            }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Comments
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                        <MDButton variant="outlined" style={{textTransform:"capitalize", color: "#1B172E", border: "1px solid #AEAEAE"}} 
                            size="small">
                            View Details
                        </MDButton>
                        </MDBox>
                    </MDBox>
                </Grid>
            </Grid>
            <Divider style={{ background: "#E9EAED", opacity: "0.7" }} />
            <Grid container style={{padding: "8px 20px"}}>
                <Grid item xs={12} md={6}>
                    <MDBox display="flex" alignItems="center">
                        <MDAvatar
                            src={drake}
                            variant="square"
                            style={{ width: "104px", height: "72px", borderRadius: "6px", border: "1px solid #E8E8E8", marginRight: "16px" }}
                        />
                        <MDBox>
                            <MDBox style={{ display: "flex" }}>
                                <MDAvatar style={imageText}
                                    src={burceMars}
                                    alt="profile-image"
                                    size="sm"
                                />
                                <MDTypography style={text} >
                                    &nbsp;Posted by
                                </MDTypography>
                                <MDTypography style={{ fontSize: "12px", fontWeight: "400" }}
                                    variant="body1"
                                >
                                    &nbsp;&quot;Name here&quot;
                                </MDTypography>
                            </MDBox>
                            <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#7B809A", marginTop: "8px"}}>
                                Published at 31 Jan 12.30 am
                            </MDTypography>
                            <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#534F6B", marginTop: "8px"}}>
                                Reference site about Lorem Ipsum, giving inform on its origins, Lipsum genera...
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MDBox style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <MDBox>
                            <MDTypography
                                style={{
                                    fontWeight: "700",
                                    fontSize: "17px",
                                    color: "#344767",
                                }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Post Share
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                            <MDTypography
                            style={{
                                fontWeight: "700",
                                fontSize: "17px",
                                color: "#344767",
                            }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Likes
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                            <MDTypography
                            style={{
                                fontWeight: "700",
                                fontSize: "17px",
                                color: "#344767",
                            }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Comments
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                        <MDButton variant="outlined" style={{textTransform:"capitalize", color: "#1B172E", border: "1px solid #AEAEAE"}} 
                            size="small">
                            View Details
                        </MDButton>
                        </MDBox>
                    </MDBox>
                </Grid>
            </Grid>
            <Divider style={{ background: "#E9EAED", opacity: "0.7" }} />
            <Grid container style={{padding: "8px 20px"}}>
                <Grid item xs={12} md={6}>
                    <MDBox display="flex" alignItems="center">
                        <MDAvatar
                            src={drake}
                            variant="square"
                            style={{ width: "104px", height: "72px", borderRadius: "6px", border: "1px solid #E8E8E8", marginRight: "16px" }}
                        />
                        <MDBox>
                            <MDBox style={{ display: "flex" }}>
                                <MDAvatar style={imageText}
                                    src={burceMars}
                                    alt="profile-image"
                                    size="sm"
                                />
                                <MDTypography style={text} >
                                    &nbsp;Posted by
                                </MDTypography>
                                <MDTypography style={{ fontSize: "12px", fontWeight: "400" }}
                                    variant="body1"
                                >
                                    &nbsp;&quot;Name here&quot;
                                </MDTypography>
                            </MDBox>
                            <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#7B809A", marginTop: "8px"}}>
                                Published at 31 Jan 12.30 am
                            </MDTypography>
                            <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#534F6B", marginTop: "8px"}}>
                                Reference site about Lorem Ipsum, giving inform on its origins, Lipsum genera...
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MDBox style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <MDBox>
                            <MDTypography
                                style={{
                                    fontWeight: "700",
                                    fontSize: "17px",
                                    color: "#344767",
                                }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Post Share
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                            <MDTypography
                            style={{
                                fontWeight: "700",
                                fontSize: "17px",
                                color: "#344767",
                            }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Likes
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                            <MDTypography
                            style={{
                                fontWeight: "700",
                                fontSize: "17px",
                                color: "#344767",
                            }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Comments
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                        <MDButton variant="outlined" style={{textTransform:"capitalize", color: "#1B172E", border: "1px solid #AEAEAE"}} 
                            size="small">
                            View Details
                        </MDButton>
                        </MDBox>
                    </MDBox>
                </Grid>
            </Grid>
            <Divider style={{ background: "#E9EAED", opacity: "0.7" }} />
            <Grid container style={{padding: "8px 20px"}}>
                <Grid item xs={12} md={6}>
                    <MDBox display="flex" alignItems="center">
                        <MDAvatar
                            src={drake}
                            variant="square"
                            style={{ width: "104px", height: "72px", borderRadius: "6px", border: "1px solid #E8E8E8", marginRight: "16px" }}
                        />
                        <MDBox>
                            <MDBox style={{ display: "flex" }}>
                                <MDAvatar style={imageText}
                                    src={burceMars}
                                    alt="profile-image"
                                    size="sm"
                                />
                                <MDTypography style={text} >
                                    &nbsp;Posted by
                                </MDTypography>
                                <MDTypography style={{ fontSize: "12px", fontWeight: "400" }}
                                    variant="body1"
                                >
                                    &nbsp;&quot;Name here&quot;
                                </MDTypography>
                            </MDBox>
                            <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#7B809A", marginTop: "8px"}}>
                                Published at 31 Jan 12.30 am
                            </MDTypography>
                            <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#534F6B", marginTop: "8px"}}>
                                Reference site about Lorem Ipsum, giving inform on its origins, Lipsum genera...
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MDBox style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <MDBox>
                            <MDTypography
                                style={{
                                    fontWeight: "700",
                                    fontSize: "17px",
                                    color: "#344767",
                                }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Post Share
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                            <MDTypography
                            style={{
                                fontWeight: "700",
                                fontSize: "17px",
                                color: "#344767",
                            }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Likes
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                            <MDTypography
                            style={{
                                fontWeight: "700",
                                fontSize: "17px",
                                color: "#344767",
                            }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Comments
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                        <MDButton variant="outlined" style={{textTransform:"capitalize", color: "#1B172E", border: "1px solid #AEAEAE"}} 
                            size="small">
                            View Details
                        </MDButton>
                        </MDBox>
                    </MDBox>
                </Grid>
            </Grid>
            <Divider style={{ background: "#E9EAED", opacity: "0.7" }} />
        </Card>
        <Grid container spacing={3} style={{marginTop: "32px"}}>
            <Grid item xs={12} md={6}>
            <DefaultLineChart
                title={
                    <MDBox style={{display: "flex"}}>
                        <MDTypography style={{fontSize: "20px", fontWeight: "700", color: "#09032B", marginRight: "17px"}}>
                            My Post Activity – Daily
                        </MDTypography>
                        <HelpIcon style={{marginTop: "7px", width: "20px", height: "20px"}} />
                        <MDBadgeDot style={{ background: "#EEEEF1", borderRadius: "4px"}}
                            color="info"
                            size="sm"
                            badgeContent="Facebook"
                        />
                    </MDBox>
                }
                // description="Product insights"
                chart={defaultLineChartData}
            />
            </Grid>
            <Grid item xs={12} md={6}>
            <DefaultLineChart
                title={
                    <MDBox style={{display: "flex"}}>
                        <MDTypography style={{fontSize: "20px", fontWeight: "700", color: "#09032B", marginRight: "17px"}}>
                            Engagement by time
                        </MDTypography>
                        <HelpIcon style={{marginTop: "7px", width: "20px", height: "20px"}} />
                        <MDBadgeDot style={{ background: "#EEEEF1", borderRadius: "4px"}}
                            color="info"
                            size="sm"
                            badgeContent="Facebook"
                        />
                    </MDBox>
                }
                // description="Visits from devices"
                chart={defaultLineChartData}
            />
            </Grid>
        </Grid>
    </DashboardLayout>
  );
}
