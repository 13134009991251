import LoginPage from './login.component';
import { connect } from 'react-redux';
import { adminLogin } from './../../api/admin/adminAction';

const mapDispatchToProps = {
    adminLogin
}

const mapStateToProps = state => ({
    isLoggedIn: state.adminPage.isLoggedIn,
    admin: state.adminPage.loggedAdmin,
    isLoading: state.adminPage.isLoading,
});

export const LoginPageContainer = connect(mapStateToProps, mapDispatchToProps)(LoginPage);