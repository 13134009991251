import React from "react";
import DashboardLayout from "./../../shared/components/DashboardLayout";
import DashboardNavbar from "./../../shared/components/DashboardNavbar";
import Loading from './../../shared/Loading';
// MUI
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import CardMedia from "@mui/material/CardMedia";
// import MDTypography from "./../../shared/components/MDTypography";
import MDButton from "./../../shared/components/MDButton";
import MDInput from "./../../shared/components/MDInput";
import MDBox from "./../../shared/components/MDBox";
import MDTypography from "./../../shared/components/MDTypography";
import homeDecor1 from "./../../assets/images/home-decor-1.jpg";
// Icons
import WindowIcon from "@mui/icons-material/Window";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const button = {
  textTransform: "capitalize",
  background: "#FFFFFF",
  border: "1px solid #CECDD5",
  color: "#7B809A",
  borderRadius: "8px",
};
const headText = {
  fontWeight: "400",
  fontSize: "12px",
  color: "#7B809A",
};
const mdBox = {
  background: "#FFFFFF",
  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
  borderRadius: "12px",
  width: "auto",
  padding: "20px",
};
const iconText = {
  color: "#000",
  width: "15px",
  height: "15px",
  margin: "2px 6px",
};
const socialPost = {
  color: "#4CAF50",
  fontWeight: "700",
  fontSize: "12px",
  background: "rgba(76, 175, 80, 0.2)",
  borderRadius: "8px",
  padding: "4px 8px",
  width: "93px",
  height: "25px",
  textTransform: "uppercase",
};
const cicleIcon = {
  width: "20px",
  height: "20px",
  color: "#41B853",
  marginRight: "6px",
};
const mdText = {
  fontWeight: "500",
  fontSize: "10px",
  color: "#7B809A",
  textTransform: "uppercase",
};
const cardText = {
  width: "100%",
  margin: 0,
  boxShadow: ({ boxShadows: { md } }) => md,
  objectFit: "cover",
  objectPosition: "center",
  height: "82px",
};
const topText = {
  fontWeight: "400",
  fontSize: "14px",
  color: "#344767",
  marginTop: "8px",
};
const box = {
  backgroundColor: "#F0F2F5",
  height: "28px",
  width: "28px",
  borderRadius: "6px",
};
const socialIcon = {
  width: "20px",
  height: "20px",
  color: "#6B6880",
};

export default function ContentListPage() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  // toggleButton
  const [alignment, setAlignment] = React.useState("right");

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  // toggleButton

  // menu
  const [menu, setMenu] = React.useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const [menu1, setMenu1] = React.useState(null);

  const openMenu1 = (event) => setMenu1(event.currentTarget);
  const closeMenu1 = () => setMenu1(null);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>
        All Topics <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={closeMenu}>
        Social Posts <Checkbox defaultChecked sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={closeMenu}>
        RSS Feed <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={closeMenu}>
        Blog posts <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
    </Menu>
  );
  const renderMenu1 = (
    <Menu
      anchorEl={menu1}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu1)}
      onClose={closeMenu1}
      keepMounted
    >
      <MenuItem onClick={closeMenu1}>
        Select All <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={closeMenu1}>
        Scheduled <Checkbox defaultChecked sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={closeMenu1}>
        Published <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={closeMenu1}>
        Failed <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={closeMenu1}>
        Draft <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
    </Menu>
  );
  // menu

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <Loading loading={isLoading} /> */}
      <Grid container style={{ display: "flex", marginTop: "24px" }}>
        <Grid item xs={3}>
          <MDInput
            style={{
              borderRadius: "8px",
            }}
            fullWidth
            label="Search"
            inputProps={{ type: "text", autoComplete: "" }}
          />
          <SearchIcon
            style={{
              float: "right",
              marginTop: "-30px",
              marginRight: "10px",
            }}
          />
        </Grid>
        <Grid item style={{ marginLeft: "auto" }}>
          <MDButton
            sx={{
              background: "linear-gradient(#09032B, #312B56)",
              color: "#fff",
            }}
          >
            CREATE CONTENT
          </MDButton>
        </Grid>
      </Grid>
      <Grid style={{ display: "flex", marginTop: "27px" }}>
        <Grid item>
          <MDButton
            style={button}
            variant={menu ? "contained" : "outlined"}
            onClick={openMenu}
          >
            Topics&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu}
        </Grid>
        <Grid style={{ marginLeft: "auto" }}>
          <MDButton
            style={button}
            sx={{ marginLeft: "16px" }}
            variant={menu1 ? "contained" : "outlined"}
            onClick={openMenu1}
          >
            All Posts&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu1}
          <MDButton
            style={button}
            sx={{ marginLeft: "16px" }}
            variant={menu1 ? "contained" : "outlined"}
            onClick={openMenu1}
          >
            Social Posts&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu1}
          <MDButton
            style={button}
            sx={{ marginLeft: "16px" }}
            variant={menu1 ? "contained" : "outlined"}
            onClick={openMenu1}
          >
            RSS Feed&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu1}
          <MDButton
            style={button}
            sx={{ marginLeft: "16px", marginRight: "16px" }}
            variant={menu1 ? "contained" : "outlined"}
            onClick={openMenu1}
          >
            Blog Posts&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu1}
          <RefreshIcon
            style={{
              backgroundColor: "#E6E6EA",
              height: "30px",
              width: "30px",
              borderRadius: "8px",
              color: "#000",
              padding: "6px 6px",
              marginBottom: "-8px",
            }}
          />
          <ToggleButtonGroup
            sx={{
              marginLeft: "16px",
              background: "#E6E6EA",
              borderRadius: "12px",
              padding: "6px 8px",
              "& .css-1c48ueu-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                {
                  color: "#fff !important",
                  backgroundColor: "#09032B !important",
                  
                },
              "& .css-1c48ueu-MuiButtonBase-root-MuiToggleButton-root": {
                border: "none",
              },
            }}
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <ToggleButton
              style={{
                borderRadius: "8px",
                width: "30px",
                height: "30px",
                marginRight: "8px",
              }} value="left" aria-label="left aligned">
              <WindowIcon />
            </ToggleButton>
            <ToggleButton
              style={{
                borderRadius: "8px",
                width: "30px",
                height: "30px",
              }} value="right" aria-label="right aligned">
              <FormatListBulletedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: "24px" }}>
        <Grid item xs={12} md={3}>
          <MDBox style={mdBox}>
            <MDBox>
              <MDBox sx={{ display: "flex", alignItems: "center" }}>
                <MDTypography style={headText} sx={{ marginRight: "17px" }}>
                  May 22, 08:00 AM
                </MDTypography>
                <Stack direction="row" spacing="1">
                  <CheckCircleOutlineIcon style={cicleIcon} />
                  <MDTypography style={headText}>Approved</MDTypography>
                </Stack>
                <Checkbox style={{ marginLeft: "auto" }} {...label} />
              </MDBox>
              <MDTypography style={mdText} sx={{ marginTop: "10px" }}>
                CAPTION
              </MDTypography>
              <MDTypography style={topText}>
                Lorem ipsum dolor sit am Business owners! Tell us, can you
                imagine ever working for someone else, now that you've been in
                business for yourself?
              </MDTypography>
              <MDBox style={{ display: "flex", marginTop: "20px" }}>
                <MDBox style={{ marginRight: "41px" }}>
                  <MDTypography style={mdText}>Platforms</MDTypography>
                  <Stack
                    direction="row"
                    spacing="1"
                    style={{ marginTop: "8px" }}
                  >
                    <FacebookRoundedIcon
                      style={socialIcon}
                      sx={{ marginRight: "11px" }}
                    />
                    <InstagramIcon style={socialIcon} />
                  </Stack>
                </MDBox>
                <MDBox>
                  <MDTypography style={mdText}>Topics</MDTypography>
                  <MDTypography style={headText}
                    sx={{ marginTop: "8px" }} >
                    Health
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDTypography style={mdText} sx={{ marginTop: "23px" }}>
                MEDIA
              </MDTypography>
              <MDBox
                position="relative"
                shadow="xl"
                borderRadius="xl"
                style={{ marginTop: "8px" }}
              >
                <CardMedia style={cardText} src={homeDecor1} component="img" />
              </MDBox>
              <MDBox style={{ display: "flex", marginTop: "19px" }}>
                <MDTypography style={socialPost}>Social Post</MDTypography>
                <MDBox
                  style={box}
                  sx={{ marginRight: "5px", marginLeft: "auto" }}
                >
                  <ModeEditOutlinedIcon style={iconText} />
                </MDBox>
                <MDBox style={box}>
                  <DeleteOutlineIcon style={iconText} />
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={3}>
          <MDBox style={mdBox}>
            <MDBox>
              <MDBox sx={{ display: "flex", alignItems: "center" }}>
                <MDTypography style={headText} sx={{ marginRight: "17px" }}>
                  May 22, 08:00 AM
                </MDTypography>
                <Stack direction="row" spacing="1">
                  <CheckCircleOutlineIcon style={cicleIcon} />
                  <MDTypography style={headText}>Approved</MDTypography>
                </Stack>
                <Checkbox style={{ marginLeft: "auto" }} {...label} />
              </MDBox>
              <MDTypography style={mdText} sx={{ marginTop: "10px" }}>
                CAPTION
              </MDTypography>
              <MDTypography style={topText}>
                Lorem ipsum dolor sit am Business owners! Tell us, can you
                imagine ever working for someone else, now that you've been in
                business for yourself?
              </MDTypography>
              <MDBox style={{ display: "flex", marginTop: "20px" }}>
                <MDBox style={{ marginRight: "41px" }}>
                  <MDTypography style={mdText}>Platforms</MDTypography>
                  <Stack
                    direction="row"
                    spacing="1"
                    style={{ marginTop: "8px" }}
                  >
                    <FacebookRoundedIcon
                      style={socialIcon}
                      sx={{ marginRight: "11px" }}
                    />
                    <InstagramIcon style={socialIcon} />
                  </Stack>
                </MDBox>
                <MDBox>
                  <MDTypography style={mdText}>Topics</MDTypography>
                  <MDTypography style={headText} sx={{ marginTop: "8px" }}>
                    Health
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDTypography style={mdText} sx={{ marginTop: "23px" }}>
                MEDIA
              </MDTypography>
              <MDBox
                position="relative"
                shadow="xl"
                borderRadius="xl"
                style={{ marginTop: "8px" }}
              >
                <CardMedia style={cardText} src={homeDecor1} component="img" />
              </MDBox>
              <MDBox style={{ display: "flex", marginTop: "19px" }}>
                <MDTypography style={socialPost}>Social Post</MDTypography>
                <MDBox
                  style={box}
                  sx={{ marginRight: "5px", marginLeft: "auto" }}
                >
                  <ModeEditOutlinedIcon style={iconText} />
                </MDBox>
                <MDBox style={box}>
                  <DeleteOutlineIcon style={iconText} />
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={3}>
          <MDBox style={mdBox}>
            <MDBox>
              <MDBox sx={{ display: "flex", alignItems: "center" }}>
                <MDTypography style={headText} sx={{ marginRight: "17px" }}>
                  May 22, 08:00 AM
                </MDTypography>
                <Stack direction="row" spacing="1">
                  <CheckCircleOutlineIcon style={cicleIcon} />
                  <MDTypography style={headText}>Approved</MDTypography>
                </Stack>
                <Checkbox style={{ marginLeft: "auto" }} {...label} />
              </MDBox>
              <MDTypography style={mdText} sx={{ marginTop: "10px" }}>
                CAPTION
              </MDTypography>
              <MDTypography style={topText}>
                Lorem ipsum dolor sit am Business owners! Tell us, can you
                imagine ever working for someone else, now that you've been in
                business for yourself?
              </MDTypography>
              <MDBox style={{ display: "flex", marginTop: "20px" }}>
                <MDBox style={{ marginRight: "41px" }}>
                  <MDTypography style={mdText}>Platforms</MDTypography>
                  <Stack
                    direction="row"
                    spacing="1"
                    style={{ marginTop: "8px" }}
                  >
                    <FacebookRoundedIcon
                      style={socialIcon}
                      sx={{ marginRight: "11px" }}
                    />
                    <InstagramIcon style={socialIcon} />
                  </Stack>
                </MDBox>
                <MDBox>
                  <MDTypography style={mdText}>Topics</MDTypography>
                  <MDTypography style={headText} sx={{ marginTop: "8px" }}>
                    Health
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDTypography style={mdText} sx={{ marginTop: "23px" }}>
                MEDIA
              </MDTypography>
              <MDBox
                position="relative"
                shadow="xl"
                borderRadius="xl"
                style={{ marginTop: "8px" }}
              >
                <CardMedia style={cardText} src={homeDecor1} component="img" />
              </MDBox>
              <MDBox style={{ display: "flex", marginTop: "19px" }}>
                <MDTypography style={socialPost}>Social Post</MDTypography>
                <MDBox
                  style={box}
                  sx={{ marginRight: "5px", marginLeft: "auto" }}
                >
                  <ModeEditOutlinedIcon style={iconText} />
                </MDBox>
                <MDBox style={box}>
                  <DeleteOutlineIcon style={iconText} />
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={3}>
          <MDBox style={mdBox}>
            <MDBox>
              <MDBox sx={{ display: "flex", alignItems: "center" }}>
                <MDTypography style={headText} sx={{ marginRight: "17px" }}>
                  May 22, 08:00 AM
                </MDTypography>
                <Stack direction="row" spacing="1">
                  <CheckCircleOutlineIcon style={cicleIcon} />
                  <MDTypography style={headText}>Approved</MDTypography>
                </Stack>
                <Checkbox style={{ marginLeft: "auto" }} {...label} />
              </MDBox>
              <MDTypography style={mdText} sx={{ marginTop: "10px" }}>
                CAPTION
              </MDTypography>
              <MDTypography style={topText}>
                Lorem ipsum dolor sit am Business owners! Tell us, can you
                imagine ever working for someone else, now that you've been in
                business for yourself?
              </MDTypography>
              <MDBox style={{ display: "flex", marginTop: "20px" }}>
                <MDBox style={{ marginRight: "41px" }}>
                  <MDTypography style={mdText}>Platforms</MDTypography>
                  <Stack direction="row" spacing="1"
                    style={{ marginTop: "8px" }}
                  >
                    <FacebookRoundedIcon style={socialIcon}
                      sx={{ marginRight: "11px" }}
                    />
                    <InstagramIcon style={socialIcon} />
                  </Stack>
                </MDBox>
                <MDBox>
                  <MDTypography style={mdText}>Topics</MDTypography>
                  <MDTypography style={headText} sx={{ marginTop: "8px" }}>
                    Health
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDTypography style={mdText} sx={{ marginTop: "23px" }}>
                MEDIA
              </MDTypography>
              <MDBox
                position="relative"
                shadow="xl"
                borderRadius="xl"
                style={{ marginTop: "8px" }}
              >
                <CardMedia style={cardText} src={homeDecor1} component="img" />
              </MDBox>
              <MDBox style={{ display: "flex", marginTop: "19px" }}>
                <MDTypography style={socialPost}>Social Post</MDTypography>
                <MDBox style={box}
                  sx={{ marginRight: "5px", marginLeft: "auto" }}
                >
                  <ModeEditOutlinedIcon style={iconText} />
                </MDBox>
                <MDBox style={box}>
                  <DeleteOutlineIcon style={iconText} />
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={3}>
          <MDBox style={mdBox}>
            <MDBox>
              <MDBox sx={{ display: "flex", alignItems: "center" }}>
                <MDTypography style={headText} sx={{ marginRight: "17px" }}>
                  May 22, 08:00 AM
                </MDTypography>
                <Stack direction="row" spacing="1">
                  <CheckCircleOutlineIcon style={cicleIcon} />
                  <MDTypography style={headText}>Approved</MDTypography>
                </Stack>
                <Checkbox style={{ marginLeft: "auto" }} {...label} />
              </MDBox>
              <MDTypography style={mdText} sx={{ marginTop: "10px" }}>
                CAPTION
              </MDTypography>
              <MDTypography style={topText}>
                Lorem ipsum dolor sit am Business owners! Tell us, can you
                imagine ever working for someone else, now that you've been in
                business for yourself?
              </MDTypography>
              <MDBox style={{ display: "flex", marginTop: "20px" }}>
                <MDBox style={{ marginRight: "41px" }}>
                  <MDTypography style={mdText}>Platforms</MDTypography>
                  <Stack direction="row" spacing="1"
                    style={{ marginTop: "8px" }}
                  >
                    <FacebookRoundedIcon style={socialIcon}
                      sx={{ marginRight: "11px" }}
                    />
                    <InstagramIcon style={socialIcon} />
                  </Stack>
                </MDBox>
                <MDBox>
                  <MDTypography style={mdText}>Topics</MDTypography>
                  <MDTypography style={headText} sx={{ marginTop: "8px" }}>
                    Health
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDTypography style={mdText} sx={{ marginTop: "23px" }}>
                MEDIA
              </MDTypography>
              <MDBox
                position="relative"
                shadow="xl"
                borderRadius="xl"
                style={{ marginTop: "8px" }}
              >
                <CardMedia style={cardText} src={homeDecor1} component="img" />
              </MDBox>
              <MDBox style={{ display: "flex", marginTop: "19px" }}>
                <MDTypography style={socialPost}>Social Post</MDTypography>
                <MDBox style={box}
                  sx={{ marginRight: "5px", marginLeft: "auto" }}
                >
                  <ModeEditOutlinedIcon style={iconText} />
                </MDBox>
                <MDBox style={box}>
                  <DeleteOutlineIcon style={iconText} />
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={3}>
          <MDBox style={mdBox}>
            <MDBox>
              <MDBox sx={{ display: "flex", alignItems: "center" }}>
                <MDTypography style={headText} sx={{ marginRight: "17px" }}>
                  May 22, 08:00 AM
                </MDTypography>
                <Stack direction="row" spacing="1">
                  <CheckCircleOutlineIcon style={cicleIcon} />
                  <MDTypography style={headText}>Approved</MDTypography>
                </Stack>
                <Checkbox style={{ marginLeft: "auto" }} {...label} />
              </MDBox>
              <MDTypography style={mdText} sx={{ marginTop: "10px" }}>
                CAPTION
              </MDTypography>
              <MDTypography style={topText}>
                Lorem ipsum dolor sit am Business owners! Tell us, can you
                imagine ever working for someone else, now that you've been in
                business for yourself?
              </MDTypography>
              <MDBox style={{ display: "flex", marginTop: "20px" }}>
                <MDBox style={{ marginRight: "41px" }}>
                  <MDTypography style={mdText}>Platforms</MDTypography>
                  <Stack direction="row" spacing="1"
                    style={{ marginTop: "8px" }}
                  >
                    <FacebookRoundedIcon style={socialIcon}
                      sx={{ marginRight: "11px" }}
                    />
                    <InstagramIcon style={socialIcon} />
                  </Stack>
                </MDBox>
                <MDBox>
                  <MDTypography style={mdText}>Topics</MDTypography>
                  <MDTypography style={headText} sx={{ marginTop: "8px" }}>
                    Health
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDTypography style={mdText} sx={{ marginTop: "23px" }}>
                MEDIA
              </MDTypography>
              <MDBox
                position="relative"
                shadow="xl"
                borderRadius="xl"
                style={{ marginTop: "8px" }}
              >
                <CardMedia style={cardText} src={homeDecor1} component="img" />
              </MDBox>
              <MDBox style={{ display: "flex", marginTop: "19px" }}>
                <MDTypography style={socialPost}>Social Post</MDTypography>
                <MDBox style={box}
                  sx={{ marginRight: "5px", marginLeft: "auto" }}
                >
                  <ModeEditOutlinedIcon style={iconText} />
                </MDBox>
                <MDBox style={box}>
                  <DeleteOutlineIcon style={iconText} />
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={3}>
          <MDBox style={mdBox}>
            <MDBox>
              <MDBox sx={{ display: "flex", alignItems: "center" }}>
                <MDTypography style={headText} sx={{ marginRight: "17px" }}>
                  May 22, 08:00 AM
                </MDTypography>
                <Stack direction="row" spacing="1">
                  <CheckCircleOutlineIcon style={cicleIcon} />
                  <MDTypography style={headText}>Approved</MDTypography>
                </Stack>
                <Checkbox style={{ marginLeft: "auto" }} {...label} />
              </MDBox>
              <MDTypography style={mdText} sx={{ marginTop: "10px" }}>
                CAPTION
              </MDTypography>
              <MDTypography style={topText}>
                Lorem ipsum dolor sit am Business owners! Tell us, can you
                imagine ever working for someone else, now that you've been in
                business for yourself?
              </MDTypography>
              <MDBox style={{ display: "flex", marginTop: "20px" }}>
                <MDBox style={{ marginRight: "41px" }}>
                  <MDTypography style={mdText}>Platforms</MDTypography>
                  <Stack direction="row" spacing="1"
                    style={{ marginTop: "8px" }}
                  >
                    <FacebookRoundedIcon style={socialIcon}
                      sx={{ marginRight: "11px" }}
                    />
                    <InstagramIcon style={socialIcon} />
                  </Stack>
                </MDBox>
                <MDBox>
                  <MDTypography style={mdText}>Topics</MDTypography>
                  <MDTypography style={headText} sx={{ marginTop: "8px" }}>
                    Health
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDTypography style={mdText} sx={{ marginTop: "23px" }}>
                MEDIA
              </MDTypography>
              <MDBox
                position="relative"
                shadow="xl"
                borderRadius="xl"
                style={{ marginTop: "8px" }}
              >
                <CardMedia style={cardText} src={homeDecor1} component="img" />
              </MDBox>
              <MDBox style={{ display: "flex", marginTop: "19px" }}>
                <MDTypography style={socialPost}>Social Post</MDTypography>
                <MDBox style={box}
                  sx={{ marginRight: "5px", marginLeft: "auto" }}
                >
                  <ModeEditOutlinedIcon style={iconText} />
                </MDBox>
                <MDBox style={box}>
                  <DeleteOutlineIcon style={iconText} />
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={3}>
          <MDBox style={mdBox}>
            <MDBox>
              <MDBox sx={{ display: "flex", alignItems: "center" }}>
                <MDTypography style={headText} sx={{ marginRight: "17px" }}>
                  May 22, 08:00 AM
                </MDTypography>
                <Stack direction="row" spacing="1">
                  <CheckCircleOutlineIcon style={cicleIcon} />
                  <MDTypography style={headText}>Approved</MDTypography>
                </Stack>
                <Checkbox style={{ marginLeft: "auto" }} {...label} />
              </MDBox>
              <MDTypography style={mdText} sx={{ marginTop: "10px" }}>
                CAPTION
              </MDTypography>
              <MDTypography style={topText}>
                Lorem ipsum dolor sit am Business owners! Tell us, can you
                imagine ever working for someone else, now that you've been in
                business for yourself?
              </MDTypography>
              <MDBox style={{ display: "flex", marginTop: "20px" }}>
                <MDBox style={{ marginRight: "41px" }}>
                  <MDTypography style={mdText}>Platforms</MDTypography>
                  <Stack direction="row" spacing="1"
                    style={{ marginTop: "8px" }}
                  >
                    <FacebookRoundedIcon style={socialIcon}
                      sx={{ marginRight: "11px" }}
                    />
                    <InstagramIcon style={socialIcon} />
                  </Stack>
                </MDBox>
                <MDBox>
                  <MDTypography style={mdText}>Topics</MDTypography>
                  <MDTypography style={headText} sx={{ marginTop: "8px" }}>
                    Health
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDTypography style={mdText} sx={{ marginTop: "23px" }}>
                MEDIA
              </MDTypography>
              <MDBox
                position="relative"
                shadow="xl"
                borderRadius="xl"
                style={{ marginTop: "8px" }}
              >
                <CardMedia style={cardText} src={homeDecor1} component="img" />
              </MDBox>
              <MDBox style={{ display: "flex", marginTop: "19px" }}>
                <MDTypography style={socialPost}>Social Post</MDTypography>
                <MDBox style={box}
                  sx={{ marginRight: "5px", marginLeft: "auto" }}
                >
                  <ModeEditOutlinedIcon style={iconText} />
                </MDBox>
                <MDBox style={box}>
                  <DeleteOutlineIcon style={iconText} />
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}
