import { useState, useEffect } from "react";
// import { Chart } from 'node_modules/chart.js';
import Chart from "chart.js/auto";
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";
// import { Datepicker } from '@mobiscroll/react';

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";

// icon
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import HelpIcon from "@mui/icons-material/Help";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

// Material Dashboard 2 PRO React components
import DefaultStatisticsCard from "./../../shared/components/Cards/DefaultStatisticsCard";
import defaultLineChartData from "./data/defaultLineChartData";
import DataTable from "./data/index";
import dataTableData from "./data/dataTableData";
import SalesTable from "./../../shared/components/SalesTable";
import salesTableData from "./data/salesTableData";
import DefaultLineChart from "./../../shared/components/DefaultLineChart";
import MDBox from "./../../shared/components/MDBox";
import MDAvatar from "./../../shared/components/MDAvatar";
import MDButton from "./../../shared/components/MDButton";
import MDBadgeDot from "./../../shared/components/MDBadgeDot";
import MDTypography from "./../../shared/components/MDTypography";
import drake from "./../../assets/images/drake.jpg";
import burceMars from "./../../assets/images/alec.png";
import breakpoints from "./../../assets/theme/base/breakpoints";
// @react-jvectormap components
import { VectorMap } from "@react-jvectormap/core";
import { worldMerc } from "@react-jvectormap/world";

const imageText = {
    width: "20px",
    height: "20px",
    marginRight: "8px",
}
const topHead = {
    fontWeight: "500",
    fontSize: "24px",
    color: "#09032B"
}
const text = {
    fontWeight: "400",
    fontSize: "12px",
    color: "#7B809A",
}
const socialIcon = {
    marginRight: "8px",
    color: "#AEAEAE",
    fontSize: "17px",
}
  const twitterIcon = {
    marginRight: "18px",
    color: "#AEAEAE",
    fontSize: "17px",
}
const paraText = {
    fontSize: "11px",
    color: "#7B809A",
    fontWeight: "400",
    marginTop: "3px",
}

export default function OverviewPage() {
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(0);
    useEffect(() => {
        function handleTabsOrientation() {
          return window.innerWidth < breakpoints.values.sm
            ? setTabsOrientation("vertical")
            : setTabsOrientation("horizontal");
        }
        window.addEventListener("resize", handleTabsOrientation);

        handleTabsOrientation();

        return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  // DefaultStatisticsCard state for the dropdown value
  const [salesDropdownValue, setSalesDropdownValue] = useState("6 May - 7 May");
  const [customersDropdownValue, setCustomersDropdownValue] =
    useState("6 May - 7 May");
  const [revenueDropdownValue, setRevenueDropdownValue] =
    useState("6 May - 7 May");

  // DefaultStatisticsCard state for the dropdown action
  const [salesDropdown, setSalesDropdown] = useState(null);
  const [customersDropdown, setCustomersDropdown] = useState(null);
  const [revenueDropdown, setRevenueDropdown] = useState(null);

  // DefaultStatisticsCard handler for the dropdown action
  const openSalesDropdown = ({ currentTarget }) =>
    setSalesDropdown(currentTarget);
  const closeSalesDropdown = ({ currentTarget }) => {
    setSalesDropdown(null);
    setSalesDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openCustomersDropdown = ({ currentTarget }) =>
    setCustomersDropdown(currentTarget);
  const closeCustomersDropdown = ({ currentTarget }) => {
    setCustomersDropdown(null);
    setCustomersDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openRevenueDropdown = ({ currentTarget }) =>
    setRevenueDropdown(currentTarget);
  const closeRevenueDropdown = ({ currentTarget }) => {
    setRevenueDropdown(null);
    setRevenueDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Last 7 days</MenuItem>
      <MenuItem onClick={close}>Last week</MenuItem>
      <MenuItem onClick={close}>Last 30 days</MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
        <DashboardNavbar />
        <MDBox style={{display: "flex", marginTop: "41px"}}>
            <MDBox style={{ display: "flex", border: "1px solid #dadada", borderRadius: "13px", padding: "12px" }}> 
                <MDAvatar
                    src={drake}
                    variant="square"
                    style={{ width: "40px", height: "40px", borderRadius: "8px", marginRight: "12px" }}
                />
                <MDBox>
                    <MDTypography style={{fontWeight: "700", fontSize: "20px", color: "#344767"}} >
                        1 Account
                    </MDTypography>
                    <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#344767"}} >
                        Connected
                    </MDTypography>
                </MDBox>
            </MDBox>
            <MDBox style={{marginLeft: "auto", display: "flex", alignItems: "center"}}>
                <MDButton variant="outlined" style={{textTransform:"capitalize", padding: "11px 16px", fontSize: "14px", fontWeight: "400",
                    background: "#FFFFFF", borderRadius: "8px", border: "1px solid #CECDD5", color: "#7B809A", marginRight: "20px"}} 
                    size="small">
                    <CalendarTodayIcon style={{marginRight: "17px", color: "#323232"}} />
                    Last 30 Days
                </MDButton>
                <MDButton variant="outlined" style={{textTransform:"capitalize", padding: "11px 16px", fontSize: "12px", fontWeight: "700",
                    background: "linear-gradient(180.67deg, #09032B 2.43%, #312B56 101%)", borderRadius: "8px", color: "#FFFFFF"}} 
                    size="small">
                    Export <SystemUpdateAltIcon style={{marginLeft: "17px"}} />
                </MDButton>
            </MDBox>
        </MDBox>
        <MDBox style={{marginTop: "41px", marginBottom: "24px"}}>
            <MDTypography style={topHead}>Overview</MDTypography>
        </MDBox>
        
        <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <DefaultStatisticsCard
                title="Total Post"
                count="224"
                dropdown={{
                  action: openSalesDropdown,
                  menu: renderMenu(salesDropdown, closeSalesDropdown),
                  value: salesDropdownValue,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <DefaultStatisticsCard
                title="Post like"
                count="2.4 M"
                dropdown={{
                  action: openCustomersDropdown,
                  menu: renderMenu(customersDropdown, closeCustomersDropdown),
                  value: customersDropdownValue,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <DefaultStatisticsCard
                title="Post Comments"
                count="3.2 M"
                dropdown={{
                  action: openRevenueDropdown,
                  menu: renderMenu(revenueDropdown, closeRevenueDropdown),
                  value: revenueDropdownValue,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <DefaultStatisticsCard
                title="Post Comments"
                count="3.2 M"
                dropdown={{
                  action: openRevenueDropdown,
                  menu: renderMenu(revenueDropdown, closeRevenueDropdown),
                  value: revenueDropdownValue,
                }}
              />
            </Grid>
        </Grid>
        <Card style={{marginTop: "32px"}}>
            <MDBox style={{margin: "32px 32px 0"}}>
                <MDBox style={{display: "flex"}}>
                    <MDTypography style={{fontWeight: "700", fontSize: "20px", color: "#09032B", marginRight: "17px", marginBottom: "16px"}}>
                        Posts
                    </MDTypography>
                    <HelpIcon style={{marginTop: "5px"}} />
                </MDBox>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <AppBar position="static">
                            <Tabs
                                orientation={tabsOrientation}
                                value={tabValue}
                                onChange={handleSetTabValue}
                            >
                                <Tab label="All Post" />
                                <Tab label="Facebook" />
                                <Tab label="Instagram" />
                                <Tab label="Twitter" />
                                <Tab label="Linkedin" />
                            </Tabs>
                        </AppBar>
                    </Grid>
                </Grid>
            </MDBox>
            <Divider style={{ background: "#E9EAED", opacity: "0.7" }} />
            <Grid container style={{padding: "8px 20px"}}>
                <Grid item xs={12} md={6}>
                    <MDBox display="flex" alignItems="center">
                        <MDAvatar
                            src={drake}
                            variant="square"
                            style={{ width: "126px", height: "100px", borderRadius: "6px", border: "1px solid #E8E8E8", marginRight: "16px" }}
                        />
                        <MDBox>
                            <MDBox style={{ display: "flex" }}>
                                <MDBox style={{color: "#AEAEAE", fontSize: "17px"}} >
                                    <FacebookRoundedIcon style={socialIcon} />
                                    <InstagramIcon style={socialIcon} />
                                    <TwitterIcon style={twitterIcon} />
                                </MDBox>
                                <MDBox>
                                    <MDTypography style={paraText} >
                                        31 Jan 12.30 AM
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                            <MDBox style={{display: "flex"}}>
                                <MDTypography style={{background: "#F0F2F5", borderRadius: "36px", width: "123px", height: "22px", padding: "4px 7px 3px",
                                    fontWeight: "500", fontSize: "11px", color: "#09032B", marginRight: "17px"}}>
                                    &quot;Waiting for Approval
                                </MDTypography>
                                <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#7B809A"}}>
                                    Scheduled at 31 Jan 12.30 am
                                </MDTypography>
                            </MDBox>
                            <MDBox style={{ display: "flex", marginTop: "13px" }}>
                                <MDAvatar style={imageText}
                                    src={burceMars}
                                    alt="profile-image"
                                    size="sm"
                                />
                                <MDTypography style={text} >
                                    &nbsp;Posted by
                                </MDTypography>
                                <MDTypography style={{ fontSize: "12px", fontWeight: "400" }}
                                    variant="body1"
                                >
                                    &nbsp;&quot;Name here&quot;
                                </MDTypography>
                            </MDBox>
                            <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#534F6B", marginTop: "8px"}}>
                                Reference site about Lorem Ipsum, giving inform on its origins, Lipsum genera...
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MDBox style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <MDBox>
                            <MDTypography
                                style={{
                                    fontWeight: "700",
                                    fontSize: "17px",
                                    color: "#344767",
                                }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Post Share
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                            <MDTypography
                            style={{
                                fontWeight: "700",
                                fontSize: "17px",
                                color: "#344767",
                            }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Likes
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                            <MDTypography
                            style={{
                                fontWeight: "700",
                                fontSize: "17px",
                                color: "#344767",
                            }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Comments
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                        <MDButton variant="outlined" style={{textTransform:"capitalize", color: "#1B172E", border: "1px solid #AEAEAE"}} 
                            size="small">
                            View Details
                        </MDButton>
                        </MDBox>
                    </MDBox>
                </Grid>
            </Grid>
            <Divider style={{ background: "#E9EAED", opacity: "0.7" }} />
            <Grid container style={{padding: "8px 20px"}}>
                <Grid item xs={12} md={6}>
                    <MDBox display="flex" alignItems="center">
                        <MDAvatar
                            src={drake}
                            variant="square"
                            style={{ width: "126px", height: "100px", borderRadius: "6px", border: "1px solid #E8E8E8", marginRight: "16px" }}
                        />
                        <MDBox>
                            <MDBox style={{ display: "flex" }}>
                                <MDBox style={{color: "#AEAEAE", fontSize: "17px"}} >
                                    <FacebookRoundedIcon style={socialIcon} />
                                    <InstagramIcon style={socialIcon} />
                                    <TwitterIcon style={twitterIcon} />
                                </MDBox>
                                <MDBox>
                                    <MDTypography style={paraText} >
                                        31 Jan 12.30 AM
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                            <MDBox style={{display: "flex"}}>
                                <MDTypography style={{background: "#F0F2F5", borderRadius: "36px", width: "123px", height: "22px", padding: "4px 7px 3px",
                                    fontWeight: "500", fontSize: "11px", color: "#09032B", marginRight: "17px"}}>
                                    &quot;Waiting for Approval
                                </MDTypography>
                                <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#7B809A"}}>
                                    Scheduled at 31 Jan 12.30 am
                                </MDTypography>
                            </MDBox>
                            <MDBox style={{ display: "flex", marginTop: "13px" }}>
                                <MDAvatar style={imageText}
                                    src={burceMars}
                                    alt="profile-image"
                                    size="sm"
                                />
                                <MDTypography style={text} >
                                    &nbsp;Posted by
                                </MDTypography>
                                <MDTypography style={{ fontSize: "12px", fontWeight: "400" }}
                                    variant="body1"
                                >
                                    &nbsp;&quot;Name here&quot;
                                </MDTypography>
                            </MDBox>
                            <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#534F6B", marginTop: "8px"}}>
                                Reference site about Lorem Ipsum, giving inform on its origins, Lipsum genera...
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MDBox style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <MDBox>
                            <MDTypography
                                style={{
                                    fontWeight: "700",
                                    fontSize: "17px",
                                    color: "#344767",
                                }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Post Share
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                            <MDTypography
                            style={{
                                fontWeight: "700",
                                fontSize: "17px",
                                color: "#344767",
                            }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Likes
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                            <MDTypography
                            style={{
                                fontWeight: "700",
                                fontSize: "17px",
                                color: "#344767",
                            }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Comments
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                        <MDButton variant="outlined" style={{textTransform:"capitalize", color: "#1B172E", border: "1px solid #AEAEAE"}} 
                            size="small">
                            View Details
                        </MDButton>
                        </MDBox>
                    </MDBox>
                </Grid>
            </Grid>
            <Divider style={{ background: "#E9EAED", opacity: "0.7" }} />
            <Grid container style={{padding: "8px 20px"}}>
                <Grid item xs={12} md={6}>
                    <MDBox display="flex" alignItems="center">
                        <MDAvatar
                            src={drake}
                            variant="square"
                            style={{ width: "126px", height: "100px", borderRadius: "6px", border: "1px solid #E8E8E8", marginRight: "16px" }}
                        />
                        <MDBox>
                            <MDBox style={{ display: "flex" }}>
                                <MDBox style={{color: "#AEAEAE", fontSize: "17px"}} >
                                    <FacebookRoundedIcon style={socialIcon} />
                                    <InstagramIcon style={socialIcon} />
                                    <TwitterIcon style={twitterIcon} />
                                </MDBox>
                                <MDBox>
                                    <MDTypography style={paraText} >
                                        31 Jan 12.30 AM
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                            <MDBox style={{display: "flex"}}>
                                <MDTypography style={{background: "#F0F2F5", borderRadius: "36px", width: "123px", height: "22px", padding: "4px 7px 3px",
                                    fontWeight: "500", fontSize: "11px", color: "#09032B", marginRight: "17px"}}>
                                    &quot;Waiting for Approval
                                </MDTypography>
                                <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#7B809A"}}>
                                    Scheduled at 31 Jan 12.30 am
                                </MDTypography>
                            </MDBox>
                            <MDBox style={{ display: "flex", marginTop: "13px" }}>
                                <MDAvatar style={imageText}
                                    src={burceMars}
                                    alt="profile-image"
                                    size="sm"
                                />
                                <MDTypography style={text} >
                                    &nbsp;Posted by
                                </MDTypography>
                                <MDTypography style={{ fontSize: "12px", fontWeight: "400" }}
                                    variant="body1"
                                >
                                    &nbsp;&quot;Name here&quot;
                                </MDTypography>
                            </MDBox>
                            <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#534F6B", marginTop: "8px"}}>
                                Reference site about Lorem Ipsum, giving inform on its origins, Lipsum genera...
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MDBox style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <MDBox>
                            <MDTypography
                                style={{
                                    fontWeight: "700",
                                    fontSize: "17px",
                                    color: "#344767",
                                }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Post Share
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                            <MDTypography
                            style={{
                                fontWeight: "700",
                                fontSize: "17px",
                                color: "#344767",
                            }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Likes
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                            <MDTypography
                            style={{
                                fontWeight: "700",
                                fontSize: "17px",
                                color: "#344767",
                            }}
                            >
                                2.4k
                            </MDTypography>
                            <MDTypography
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#AEAEAE",
                            }}
                            >
                                Comments
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                        <MDButton variant="outlined" style={{textTransform:"capitalize", color: "#1B172E", border: "1px solid #AEAEAE"}} 
                            size="small">
                            View Details
                        </MDButton>
                        </MDBox>
                    </MDBox>
                </Grid>
            </Grid>
            <Divider style={{ background: "#E9EAED", opacity: "0.7" }} />
        </Card>
        <Card style={{ background: "#fff", marginTop: "32px" }}>
          <Grid container>
            <Grid item sm={12} md={7} lg={6} style={{ overflow: "auto" }}>
              <DefaultLineChart
                title="My Social Overview"
                chart={defaultLineChartData}
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1}>
                      <MDBadgeDot
                        color="info"
                        size="sm"
                        badgeContent="Facebook"
                      />
                      <MDBadgeDot
                        color="error"
                        size="sm"
                        badgeContent="Instagram"
                      />
                      <MDBadgeDot
                        color="info"
                        size="sm"
                        badgeContent="Linkedin"
                      />
                      <MDBadgeDot
                        color="info"
                        size="sm"
                        badgeContent="Twitter"
                      />
                      <MDBadgeDot
                        color="warning"
                        size="sm"
                        badgeContent="RSS Feed"
                      />
                    </MDBox>
                  </MDBox>
                }
              />
            </Grid>
            <Grid item sm={12} md={5} lg={6}
              sx={{
                mt: { xs: 5, lg: 5 },
              }}
            >
              <VectorMap
                map={worldMerc}
                zoomOnScroll={false}
                zoomButtons={false}
                markersSelectable
                backgroundColor="transparent"
                selectedMarkers={[1, 3]}
                markers={[
                  {
                    name: "USA",
                    latLng: [40.71296415909766, -74.00437720027804],
                  },
                  {
                    name: "Germany",
                    latLng: [51.17661451970939, 10.97947735117339],
                  },
                  {
                    name: "Brazil",
                    latLng: [-7.596735421549542, -54.781694323779185],
                  },
                  {
                    name: "Russia",
                    latLng: [62.318222797104276, 89.81564777631716],
                  },
                  {
                    name: "China",
                    latLng: [22.320178999475512, 114.17161225541399],
                  },
                ]}
                regionStyle={{
                  initial: {
                    fill: "#dee2e7",
                    "fill-opacity": 1,
                    stroke: "none",
                    "stroke-width": 0,
                    "stroke-opacity": 0,
                  },
                }}
                markerStyle={{
                  initial: {
                    fill: "#e91e63",
                    stroke: "#ffffff",
                    "stroke-width": 5,
                    "stroke-opacity": 0.5,
                    r: 7,
                  },
                  hover: {
                    fill: "E91E63",
                    stroke: "#ffffff",
                    "stroke-width": 5,
                    "stroke-opacity": 0.5,
                  },
                  selected: {
                    fill: "E91E63",
                    stroke: "#ffffff",
                    "stroke-width": 5,
                    "stroke-opacity": 0.5,
                  },
                }}
                style={{
                  marginTop: "-1.5rem",
                }}
                onRegionTipShow={() => false}
                onMarkerTipShow={() => false}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={12} md={7} lg={6} style={{ overflow: "auto" }}>
              <MDBox mt={2} mb={2}>
                <MDBox mb={1} ml={2}>
                  <MDTypography variant="h5" fontWeight="medium">
                    Upcoming Scheduled Posts
                  </MDTypography>
                </MDBox>
                <DataTable
                  table={dataTableData}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isSorted={false}
                />
              </MDBox>
            </Grid>
            <Grid item sm={12} md={5} lg={6} style={{ overflow: "auto" }}>
              <MDBox mt={4} mb={2}>
                <SalesTable title="Sales by Country" rows={salesTableData} />
              </MDBox>
            </Grid>
          </Grid>
        </Card>
    </DashboardLayout>
  );
}
