const timelineData = [
    {
      title: "Post title here",
      description: "Posted by User Name at 12.30 AM 12 dec",
      badges: ["design"],
    },
    {
      title: "Post title here",
      description: "Posted by User Name at 12.30 AM 12 dec",
      badges: ["order", "#1832412"],
    },
    {
      title: "Post title here",
      description: "Posted by User Name at 12.30 AM 12 dec",
      badges: ["server", "payments"],
    },
    {
      title: "Post title here",
      description: "Posted by User Name at 12.30 AM 12 dec",
      badges: ["card", "#4395133", "priority"],
    },
  ];
  
  export default timelineData;
  