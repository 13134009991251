import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import MDBox from "../../../shared/components/MDBox";
import EditIcon from '@mui/icons-material/Edit';

const tableHeading = {
  fontWeight: 700,
  fontSize: "11px",
  color: "#7B809A"
}

const tablecell = {
  fontWeight: 700,
  fontSize: "14px",
  color: "#09032B"
}

function SubscriptionTable(props) {
  const { subscriptions, selected, setSelected, setOpen } = props
  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <Table>
        <MDBox component="thead">
            <TableRow>
                <TableCell style={tableHeading}>Package Name</TableCell>
                <TableCell style={tableHeading}>Interval</TableCell>
                <TableCell style={tableHeading}>Price</TableCell>
                <TableCell style={tableHeading}>Platforms</TableCell>
                <TableCell style={tableHeading}>Content / Month</TableCell>
                <TableCell></TableCell>
            </TableRow>
        </MDBox>
        <TableBody>
          {subscriptions && subscriptions.map(x => {
             return (
              <TableRow>
                <TableCell style={tablecell}>{x.name}</TableCell>
                <TableCell style={tablecell}>{`${x.timeInterval} ${x.timeFrame}`}</TableCell>
                <TableCell style={tablecell}>{x.price}</TableCell>
                <TableCell style={tablecell}>{x.platformAccess}</TableCell>
                <TableCell style={tablecell}>{x.contentNumber}</TableCell>
                <TableCell style={tablecell}>
                  <IconButton
                    onClick={() => {
                      setSelected(x);
                      setOpen(true);
                    }}>
                      <EditIcon />
                    </IconButton>
                </TableCell>
              </TableRow>
             ) 
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SubscriptionTable;
