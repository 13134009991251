import { combineReducers } from 'redux';
import { userReducer as userPage } from './../api/user/userReducer';
import { whiteReducer as whitePage } from './../api/white/whiteReducer';
import { themeReducer as themePage } from './../api/theme/themeReducer';
import { masterLayoutReducer as masterLayoutPage } from './../api/master-layout/masterLayoutReducer';
import { adminReducer as adminPage } from './../api/admin/adminReducer';
import { topicReducer as topicPage } from './../api/topic/topicReducer';
import { profileReducer as profilePage } from './../api/profile/profileReducer';
import { industryReducer as industryPage } from './../api/industry/industryReducer';
import { mediaReducer as mediaPage } from './../api/media/mediaReducer';
import { masterContentReducer as masterContentPage } from './../api/master-content/masterContentReducer';
import { contentReducer as contentPage } from './../api/content/contentReducer';
import { subscriptionReducer as subscriptionPage } from './../api/subscription/subscriptionReducer';
import { scheduleReducer as schedulePage } from './../api/schedule/scheduleReducer';

const appReducer = combineReducers({
    userPage,
    themePage,
    adminPage,
    topicPage,
    industryPage,
    mediaPage,
    profilePage,
    masterLayoutPage,
    masterContentPage,
    contentPage,
    subscriptionPage,
    schedulePage,
    whitePage
});

export default appReducer;