import * as actionType from "./masterLayoutActionType";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    masterLayouts: []
}

export const masterLayoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.FETCH_MASTER_LAYOUTS:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.FETCH_MASTER_LAYOUTS_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.FETCH_MASTER_LAYOUTS_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                masterLayouts: action.payload ? action.payload.data : [],
            };
        case actionType.CREATE_MASTER_LAYOUT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_MASTER_LAYOUT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false
            };
        case actionType.CREATE_MASTER_LAYOUT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.UPDATE_MASTER_LAYOUT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.UPDATE_MASTER_LAYOUT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false
            };
        case actionType.UPDATE_MASTER_LAYOUT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.DELETE_MASTER_LAYOUT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.DELETE_MASTER_LAYOUT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false
            };
        case actionType.DELETE_MASTER_LAYOUT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        default: 
            return state
    }
}