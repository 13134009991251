import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from '@mui/styles';
import MDButton from "../../../shared/components/MDButton";
import ClearIcon from "@mui/icons-material/Clear";
import MDAvatar from "../../../shared/components/MDAvatar";
import FormField from "../../../shared/components/FormField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import List from "@mui/material/List";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
    listText: {
      '& .css-knlq9b-MuiFormControlLabel-root .MuiFormControlLabel-label ':
        {
          color: "#344767",
          fontWeight: "500",
          fontSize: "12px",
        },
    },
  });

const topHead = {
    fontWeight: "700",
    fontSize: "20px",
    color: "#344767",
    }
const topText = {
    fontWeight: "500",
    fontSize: "14px",
    marginTop: "3px",
    color: "#7B809A",
}
const mdbox = {
    background: "#E6E8EB",
    height: "40px",
    width: "40px",
    borderRadius: "100px",
}
const box = {
    marginTop: "49px",
}

const button = {
    background: "#09032B",
    width: "192px",
    height: "50px",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
    color: "#FFFFFF",
    borderRadius: "8px",
    textTransform: "inherit",
    fontSize: "12px",
    fontWeight: "700"
}

export default function GooglePagesDialog(props) {
  const classes = useStyles();
  const { open, setOpen } = props;
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox>
                <MDTypography style={topHead} >
                 Google My Business
                </MDTypography>
                <MDTypography style={topText} >
                  You can add your google my businessaccount
                </MDTypography>
              </MDBox>
              <MDBox>
                <ClearIcon  onClick={() => setOpen(false)}
                  style={{ width: "32px", height: "32px" }} 
                />
              </MDBox>
            </MDBox>
            <Grid container>
              <Grid item xs={12} style={{marginTop: "24px"}}>
                <Autocomplete
                  multiple
                  options={[]}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label="Account"
                      name="account"
                      placeholder="Select account"
                      InputLabelProps={{ shrink: true }}
                      multiple
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} style={{marginTop: "24px"}}>
                <Autocomplete
                  multiple
                  options={[]}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label="Business Location"
                      name="business location"
                      placeholder="1901 Thornrldge Cir. Shiloh, Hawaii 81063..."
                      InputLabelProps={{ shrink: true }}
                      multiple
                    />
                  )}
                />
              </Grid>
            </Grid> 
            <MDBox style={box} >
              <MDButton style={button} variant="contained" >
                  Save and Continue
              </MDButton>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
