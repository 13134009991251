import * as actionTypes from "./masterContentActionType";
import axios from 'axios';
import { checkError } from './../error';
const baseUrl = process.env.REACT_APP_API_URL;

export const createMasterContent = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(createMasterContentInit());
        axios.post(`${baseUrl}/master-content/store`, data, config)
        .then((response)=>{
            dispatch(createMasterContentSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createMasterContentFailure());
        })
    };
};

export const createMasterContentInit = () => ({
    type: actionTypes.CREATE_MASTER_CONTENT
});

export const createMasterContentFailure = data => ({
    type: actionTypes.CREATE_MASTER_CONTENT_FAILURE
});

export const createMasterContentSuccess = data => ({
    type: actionTypes.CREATE_MASTER_CONTENT_SUCCESS,
    payload: { data }
});

export const updateMasterContent = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(updateMasterContentInit());
        axios.put(`${baseUrl}/master-content/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateMasterContentSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateMasterContentFailure());
        })
    };
};

export const updateMasterContentInit = () => ({
    type: actionTypes.UPDATE_MASTER_CONTENT
});

export const updateMasterContentFailure = data => ({
    type: actionTypes.UPDATE_MASTER_CONTENT_FAILURE
});

export const updateMasterContentSuccess = data => ({
    type: actionTypes.UPDATE_MASTER_CONTENT_SUCCESS,
    payload: { data }
});

export const deleteMasterContent = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(deleteMasterContentInit());
        axios.delete(`${baseUrl}/master-content/${id}`, config)
        .then((response)=>{
            dispatch(deleteMasterContentSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteMasterContentFailure());
        })
    };
};

export const deleteMasterContentInit = () => ({
    type: actionTypes.DELETE_MASTER_CONTENT
});

export const deleteMasterContentFailure = data => ({
    type: actionTypes.DELETE_MASTER_CONTENT_FAILURE
});

export const deleteMasterContentSuccess = data => ({
    type: actionTypes.DELETE_MASTER_CONTENT_SUCCESS,
    payload: { data }
});

export const fetchMasterContents = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }, params: data};
    return (dispatch) => {
        dispatch(fetchMasterContentsInit());
        axios.get(`${baseUrl}/master-content`, config)
        .then((response)=>{
            dispatch(fetchMasterContentsSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchMasterContentsFailure());
        })
    };
};

export const fetchMasterContentsInit = () => ({
    type: actionTypes.FETCH_MASTER_CONTENTS
});

export const fetchMasterContentsFailure = data => ({
    type: actionTypes.FETCH_MASTER_CONTENTS_FAILURE
});

export const fetchMasterContentsSuccess = data => ({
    type: actionTypes.FETCH_MASTER_CONTENTS_SUCCESS,
    payload: { data }
});

