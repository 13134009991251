export const FETCH_ALL_USERS = "FETCH_ALL_USERS";
export const FETCH_ALL_USERS_FAILURE = "FETCH_ALL_USERS_FAILURE";
export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";

export const FETCH_TEAM = "FETCH_TEAM";
export const FETCH_TEAM_FAILURE = "FETCH_TEAM_FAILURE";
export const FETCH_TEAM_SUCCESS = "FETCH_TEAM_SUCCESS";

export const FETCH_USER_BY_ID = "FETCH_USER_BY_ID";
export const FETCH_USER_BY_ID_FAILURE = "FETCH_USER_BY_ID_FAILURE";
export const FETCH_USER_BY_ID_SUCCESS = "FETCH_USER_BY_ID_SUCCESS";

export const DELETE_TEAM = "DELETE_TEAM";
export const DELETE_TEAM_FAILURE = "DELETE_TEAM_FAILURE";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";

export const UPDATE_USER_ACCOUNT = "UPDATE_USER_ACCOUNT";
export const UPDATE_USER_ACCOUNT_FAILURE = "UPDATE_USER_ACCOUNT_FAILURE";
export const UPDATE_USER_ACCOUNT_SUCCESS = "UPDATE_USER_ACCOUNT_SUCCESS";