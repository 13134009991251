import * as actionTypes from "./profileActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const addProfile = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(addProfileInit());
        axios.post(`${baseUrl}/profile/admin/store`, data, config)
        .then((response)=>{
            dispatch(addProfileSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(addProfileFailure());
        })
    };
};

export const updateProfile = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(updateProfileInit());
        axios.put(`${baseUrl}/profile/admin/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateProfileSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateProfileFailure());
        })
    };
};

export const deleteProfile = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(deleteProfileInit());
        axios.delete(`${baseUrl}/profile/admin/${id}`, config)
        .then((response)=>{
            dispatch(deleteProfileSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteProfileFailure());
        })
    };
};

export const addProfileInit = () => ({
    type: actionTypes.ADD_PROFILE
});

export const addProfileFailure = data => ({
    type: actionTypes.ADD_PROFILE_FAILURE
});

export const addProfileSuccess = data => ({
    type: actionTypes.ADD_PROFILE_SUCCESS,
    payload: { data }
});

export const updateProfileInit = () => ({
    type: actionTypes.UPDATE_PROFILE
});

export const updateProfileFailure = data => ({
    type: actionTypes.UPDATE_PROFILE_FAILURE
});

export const updateProfileSuccess = data => ({
    type: actionTypes.UPDATE_PROFILE_SUCCESS,
    payload: { data }
});

export const deleteProfileInit = () => ({
    type: actionTypes.DELETE_PROFILE
});

export const deleteProfileFailure = data => ({
    type: actionTypes.DELETE_PROFILE_FAILURE
});

export const deleteProfileSuccess = data => ({
    type: actionTypes.DELETE_PROFILE_SUCCESS,
    payload: { data }
});