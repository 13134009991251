import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import MDButton from "../../../shared/components/MDButton";
import FormField from "./../../../shared/components/FormField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const useStyles = makeStyles({
  closeBtn: {
    position: "relative",
    cursor: "pointer",
    fontSize: "24px",
    color: "#AEAEAE",
  },
  selct: {
    '& .css-rjulde-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select': {
        padding: "0px!important",
    },
    '& .css-ynad2y-MuiSvgIcon-root-MuiSelect-icon': {
        display: "block",
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      minWidth: "255px",
      marginLeft: "122px",

    },
  },
};

const names = ["Administrator", "Contributor", "Author", "Editor"];

export default function TeamMatesDialog(props) {
  const classes = useStyles();
  const { open, setOpen } = props;
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox>
                <MDTypography
                  sx={{
                    fontWeight: "700",
                    fontSize: "20px",
                    color: "#344767",
                    marginBottom: "8px",
                  }}
                >
                  Add New member
                </MDTypography>
                <MDTypography
                  sx={{ fontWeight: "400", fontSize: "14px", color: "#7B809A" }}
                >
                  Create a new brand member and add them to this site.
                </MDTypography>
              </MDBox>
              <MDBox>
                <span
                  className={classes.closeBtn}
                  onClick={() => setOpen(false)}
                >
                  x
                </span>
              </MDBox>
            </MDBox>
            <MDBox component="form" pb={3} mt={2} pt={2}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormField
                    label="Member Name"
                    name="member name"
                    placeholder="Add user name..."
                    type="text"
                    // value={values.businessName}
                    // onChange={handleChange}
                  />
                  {/* {errors.businessName && (
                            <MDTypography style={mdText1}>{errors.businessName}</MDTypography>
                            )} */}
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    label="Email"
                    name="email"
                    placeholder="Add email here..."
                    type="email"
                    // value={values.businessName}
                    // onChange={handleChange}
                  />
                  {/* {errors.businessName && (
                            <MDTypography style={mdText1}>{errors.businessName}</MDTypography>
                            )} */}
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    label="First Name"
                    name="first name"
                    placeholder="First name..."
                    type="text"
                    // value={values.businessName}
                    // onChange={handleChange}
                  />
                  {/* {errors.businessName && (
                            <MDTypography style={mdText1}>{errors.businessName}</MDTypography>
                            )} */}
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    label="Last Name"
                    name="last name"
                    placeholder="Last name..."
                    type="text"
                    // value={values.businessName}
                    // onChange={handleChange}
                  />
                  {/* {errors.businessName && (
                            <MDTypography style={mdText1}>{errors.businessName}</MDTypography>
                            )} */}
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="standard" 
                        style={{paddingTop: "22px"}}
                        fullWidth
                    >
                        <InputLabel id="demo-simple-select-standard-label">
                            Member Role
                        </InputLabel>
                        <Select
                            className={classes.selct}
                            multiple
                            displayEmpty
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={personName}
                            onChange={handleChange}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                return <em style={{color: "#B5B3BF"}}>Select member role</em>;
                                }

                                return selected.join(", ");
                            }}
                            MenuProps={MenuProps}
                        >   
                            {names.map((name) => (
                                <MenuItem key={name} value={name}>
                                <ListItemText primary={name} />
                                <Checkbox checked={personName.indexOf(name) > -1} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
              </Grid>
              <MDBox sx={{ display: "flex", marginTop: "33px" }}>
                <MDTypography
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#534F6B",
                    marginRight: "45px",
                  }}
                >
                  Send User Notification
                </MDTypography>
                <FormControlLabel
                  sx={{ marginTop: "-8px" }}
                  control={<Switch />}
                />
              </MDBox>
              <MDButton
                style={{
                  background: "#09032B",
                  color: "#FFFFFF",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                  borderRadius: "8px",
                  width: "150px",
                  fontWeight: "700",
                  fontSize: "12px",
                  marginTop: "64px",
                }}
                fullWidth
              >
                Add User
              </MDButton>
              <MDButton
                variant="text"
                style={{
                  color: "#6B6880",
                  fontWeight: "700",
                  fontSize: "12px",
                  marginTop: "64px",
                }}
              >
                Cancel
              </MDButton>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
