import React, { useEffect, useState } from "react";
import DashboardLayout from "./../../shared/components/DashboardLayout";
import DashboardNavbar from "./../../shared/components/DashboardNavbar";
import Loading from './../../shared/Loading';
// MUI
import Grid from "@mui/material/Grid";
import MDButton from "./../../shared/components/MDButton";
import MDInput from "./../../shared/components/MDInput";
import { IndustryDialog } from './component/industry-dialog';
import { IndustryTable } from './component/industry-table';

export default function IndustryPage(props) {
  const { 
    createIndustry, 
    updateIndustry,
    deleteIndustry,
    fetchIndustry,
    industries,
    isSaved,
    isLoading
  } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState({name: "", status: 1});

  useEffect(() => {
    fetchIndustry();
  }, []);

  useEffect(() => {
    if (isSaved) {
      fetchIndustry();
      setSelected({name: "", status: 1});
      setOpen(false);
    }
  }, [isSaved]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loading loading={isLoading} />
      <Grid container style={{display: "flex", marginTop: "24px"}}>
        <Grid item style={{marginLeft: "auto"}}>
            <MDButton
              onClick={() => {
                setSelected({name: "", status: 1});
                setOpen(true)
              }}
                sx={{
                  background: "linear-gradient(#09032B, #312B56)",
                  color: "#fff",
                  marginLeft: "auto"
                }}
            >
                CREATE INDUSTRY
            </MDButton>
        </Grid>
      </Grid>
      <IndustryTable
        selected={selected}
        setSelected={setSelected}
        rows={industries}
        open={open}
        setOpen={setOpen}
        updateIndustry={updateIndustry}
      />
      <IndustryDialog
        open={open}
        setOpen={setOpen}
        updateIndustry={updateIndustry}
        createIndustry={createIndustry}
        onClose={() => {
          setSelected({});
          setOpen(false);
        }}
        selected={selected}
        setSelected={setSelected}
      />
    </DashboardLayout>
  );
}
