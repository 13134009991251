import React from "react";
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";
import Loading from '../../shared/Loading';
// MUI
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDButton from "../../shared/components/MDButton";
import MDBox from "../../shared/components/MDBox";
import MDTypography from "../../shared/components/MDTypography";
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import MDAvatar from "../../shared/components/MDAvatar";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import burceMars from "../../assets/images/bruce-mars.jpg";

const headText = {
    fontWeight: "500", 
    fontSize: "14px", 
    color: "#344767", 
    maxWidth: "172px"
}
const topText = {
    fontWeight: "400", 
    fontSize: "14px", 
    color: "#7B809A"
}
const text = {
    fontWeight: "400", 
    fontSize: "12px", 
    color: "#7B809A", 
    marginLeft: "auto"
}
const button = {
    background: "linear-gradient(180.67deg, #09032B 2.43%, #312B56 101%)",
    boxShadow: "0px 2px 6px rgba(41, 43, 63, 0.36)",
    borderRadius: "8px",
    color: "#FFFFFF",
}
const mdButton = {
    color: "#344767",
    marginRight: "10px",
    borderColor: "#344767"
}

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 8,
      marginTop: theme.spacing(1),
      minWidth: 240,
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
    },
  }));

export default function CuratedPage() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

  return (
    <DashboardLayout>
        <DashboardNavbar />
        <Grid container>
            <Grid item>
                <MDButton style={{color: "#344767", borderColor: "#344767"}}
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="outlined"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    Sort
                </MDButton>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem
                        style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "rgba(0, 0, 0, 0.6)"
                        }} onClick={handleClose} disableRipple>
                        Most Popular
                    </MenuItem>
                    <Divider sx={{ background: "rgba(0, 0, 0, 0.12)" }} />
                    <MenuItem
                        style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "rgba(0, 0, 0, 0.6)"
                        }} onClick={handleClose} disableRipple>
                        Latest
                    </MenuItem>
                    <Divider sx={{ background: "rgba(0, 0, 0, 0.12)" }} />
                    <MenuItem
                        style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "rgba(0, 0, 0, 0.6)"
                        }} onClick={handleClose} disableRipple>
                        Oldest
                    </MenuItem>
                </StyledMenu>
            </Grid>
            <Grid item style={{marginLeft: "auto"}}>
                <MDButton variant="outlined" style={mdButton} >
                    RSS AUTO POSTER
                </MDButton>
                <MDButton style={button} >
                    RADD BLOG FEED
                </MDButton>
            </Grid>
        </Grid>
        <Divider sx={{ border: "1px solid #C7CCD0" }} />
        <Grid container>
            <Grid item xs={12} sm={4}>
                <MDBox>
                    <MDBox style={{display: "flex"}}>
                        <MDAvatar variant="rounded" src={burceMars} alt="profile-image" size="xl" shadow="sm"
                            style={{marginRight: "16px"}}
                        />
                        <MDBox>
                            <MDTypography style={headText} >
                                HTML and CSS in Emails: What Works in 2022?
                            </MDTypography>
                            <MDTypography style={topText} >
                                Lorem ipsum dolor sit am Business...
                            </MDTypography>
                        </MDBox>
                        <MDTypography style={text} >
                            May 22
                        </MDTypography>
                    </MDBox> 
                </MDBox>
                <Divider sx={{ border: "1px solid #C7CCD0" }} />
                <MDBox>
                    <MDBox style={{display: "flex"}}>
                        <MDAvatar variant="rounded" src={burceMars} alt="profile-image" size="xl" shadow="sm"
                            style={{marginRight: "16px"}}
                        />
                        <MDBox>
                            <MDTypography style={headText} >
                                HTML and CSS in Emails: What Works in 2022?
                            </MDTypography>
                            <MDTypography style={topText} >
                                Lorem ipsum dolor sit am Business...
                            </MDTypography>
                        </MDBox>
                        <MDTypography style={text} >
                            May 22
                        </MDTypography>
                    </MDBox> 
                </MDBox>
                <Divider sx={{ border: "1px solid #C7CCD0" }} />
                <MDBox>
                    <MDBox style={{display: "flex"}}>
                        <MDAvatar variant="rounded" src={burceMars} alt="profile-image" size="xl" shadow="sm"
                            style={{marginRight: "16px"}}
                        />
                        <MDBox>
                            <MDTypography style={headText} >
                                HTML and CSS in Emails: What Works in 2022?
                            </MDTypography>
                            <MDTypography style={topText} >
                                Lorem ipsum dolor sit am Business...
                            </MDTypography>
                        </MDBox>
                        <MDTypography style={text} >
                            May 22
                        </MDTypography>
                    </MDBox> 
                </MDBox>
                <Divider sx={{ border: "1px solid #C7CCD0" }} />
                <MDBox>
                    <MDBox style={{display: "flex"}}>
                        <MDAvatar variant="rounded" src={burceMars} alt="profile-image" size="xl" shadow="sm"
                            style={{marginRight: "16px"}}
                        />
                        <MDBox>
                            <MDTypography style={headText} >
                                HTML and CSS in Emails: What Works in 2022?
                            </MDTypography>
                            <MDTypography style={topText} >
                                Lorem ipsum dolor sit am Business...
                            </MDTypography>
                        </MDBox>
                        <MDTypography style={text} >
                            May 22
                        </MDTypography>
                    </MDBox> 
                </MDBox>
                <Divider sx={{ border: "1px solid #C7CCD0" }} />
                <MDBox>
                    <MDBox style={{display: "flex"}}>
                        <MDAvatar variant="rounded" src={burceMars} alt="profile-image" size="xl" shadow="sm"
                            style={{marginRight: "16px"}}
                        />
                        <MDBox>
                            <MDTypography style={headText} >
                                HTML and CSS in Emails: What Works in 2022?
                            </MDTypography>
                            <MDTypography style={topText} >
                                Lorem ipsum dolor sit am Business...
                            </MDTypography>
                        </MDBox>
                        <MDTypography style={text} >
                            May 22
                        </MDTypography>
                    </MDBox> 
                </MDBox>
                <Divider sx={{ border: "1px solid #C7CCD0" }} />
                <MDBox>
                    <MDBox style={{display: "flex"}}>
                        <MDAvatar variant="rounded" src={burceMars} alt="profile-image" size="xl" shadow="sm"
                            style={{marginRight: "16px"}}
                        />
                        <MDBox>
                            <MDTypography style={headText} >
                                HTML and CSS in Emails: What Works in 2022?
                            </MDTypography>
                            <MDTypography style={topText} >
                                Lorem ipsum dolor sit am Business...
                            </MDTypography>
                        </MDBox>
                        <MDTypography style={text} >
                            May 22
                        </MDTypography>
                    </MDBox> 
                </MDBox>
                <Divider sx={{ border: "1px solid #C7CCD0" }} />
                <MDBox>
                    <MDBox style={{display: "flex"}}>
                        <MDAvatar variant="rounded" src={burceMars} alt="profile-image" size="xl" shadow="sm"
                            style={{marginRight: "16px"}}
                        />
                        <MDBox>
                            <MDTypography style={headText} >
                                HTML and CSS in Emails: What Works in 2022?
                            </MDTypography>
                            <MDTypography style={topText} >
                                Lorem ipsum dolor sit am Business...
                            </MDTypography>
                        </MDBox>
                        <MDTypography style={text} >
                            May 22
                        </MDTypography>
                    </MDBox> 
                </MDBox>
            </Grid>
        </Grid>
    </DashboardLayout>
  );
}
