import React, { useState, useEffect } from "react";
import MDBox from "../../shared/components/MDBox";
import FormField from "../../shared/components/FormField";
import Grid from "@mui/material/Grid";
import MDTypography from "../../shared/components/MDTypography";
import socialImage from "../../assets/images/social-image.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { makeStyles } from "@mui/styles";
import MDButton from "./../../shared/components/MDButton";
import ButtonGroup from '@mui/material/ButtonGroup';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { blocks } from './block';
import { ColorPicker, createColor } from 'material-ui-color';
import { useNavigate, useParams } from 'react-router-dom';
import { createApi } from 'unsplash-js';
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import ClearIcon from "@mui/icons-material/Clear";
import { CollectionPage } from "./collection-page";
import { RightBar } from "./right-bar";
import EditIcon from '@mui/icons-material/Edit';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from 'axios';
import Loading from "../../shared/Loading";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import CircularProgress from '@mui/material/CircularProgress';
import { SaveDialog } from "./save-dialog";
const baseUrl = process.env.REACT_APP_API_URL;

const appUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles({
    
    radioButton: {
        "& .css-z8dsix-MuiButtonBase-root-MuiRadio-root.Mui-checked .MuiSvgIcon-root": {
            borderColor: "#1B1C29",
        },
        "& .css-z8dsix-MuiButtonBase-root-MuiRadio-root:after": {
            backgroundImage: "linear-gradient(195deg, #1B1C29, #1B1C29)"
        },
    },
  });

export function LayoutPage(props) {
    let { themeId, layoutId } = useParams();
    const navigate = useNavigate();
    const { fetchTheme, uploadBG, bgImg, isLoading, themes, getLayout, layout, masterLayouts, fetchMasterLayouts } = props;
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState({});
    const [openS, setOpenS] = useState(false);
    const [preset, setPreset] = useState("facebook_sqaure");
    const [platform, setPlatform] = useState("facebook");
    const [zoom, setZoom] = useState(.5);
    const [width, setWidth] = useState(1200);
    const [height, setHeight] = useState(1200);
    const [search,setSearch] = useState("Abstract");
    const [dynamicField,setDynamicField] = useState("");
    const [styled, setStyled] = useState({});
    const [imageOpen, setImageOpen] = useState(false);
    const [collections, setCollections] = useState([]);
    const [images, setImages] = useState([]);
    const [theme, setTheme] = useState({});
    const [color, setColor] = useState(createColor("transparent"));
    const [bgcolor, setBgcolor] = useState(createColor("transparent"));
    const [unsplashImg, setUnsplashImg] = useState(null);
    const [layers, setLayers] = useState([]);
    const [name, setName] = useState("");
    const [h, setH] = useState([]);
    const [master, setMaster] = useState("");
    const [menuFb, setMenuFb] = React.useState(null);
    const [menuGMB, setMenuGMB] = React.useState(null);
    const [menuInsta, setMenuInsta] = React.useState(null);
    const [menuLinkedin, setMenuLinkedin] = React.useState(null);
    const [layerLoading, setLayerLoading] = React.useState(false);

    const openMenuFb = (event) => setMenuFb(event.currentTarget);
    const closeMenuFb = () => setMenuFb(null);

    const openMenuGMB = (event) => setMenuGMB(event.currentTarget);
    const closeMenuGMB = () => setMenuGMB(null);

    const openMenuInsta = (event) => setMenuInsta(event.currentTarget);
    const closeMenuInsta = () => setMenuInsta(null);

    const openMenuLinkedin = (event) => setMenuLinkedin(event.currentTarget);
    const closeMenuLinkedin = () => setMenuLinkedin(null);

    document.body.addEventListener('dragstart', handleDragStart);
    useEffect(() => {
        document.body.addEventListener('dragend', handleDrop);
        return () => {
            document.body.removeEventListener('dragend', handleDrop);
        }
    }, []);

    useEffect(() => {
        window.addEventListener("message", receiveMessage, false);
        return () => {
            window.removeEventListener("message", receiveMessage, false);
        }
    }, []);

    function receiveMessage (ev) {
        if (["react-devtools-inject-backend", "react-devtools-bridge", "react-devtools-content-script"].indexOf(ev.data.source) === -1 && ["webpackClose", "webpackWarnings", "webpackInvalid"].indexOf(ev.data.type) === -1) {
            console.log(ev.data);
            const data = ev.data && JSON.parse(ev.data) || {};
            if (data.type === "style_elements") {
                setStyled(data.data);
            }
        }
    }

    const api = createApi({
    accessKey: "r-_TgsNnHJJMn0xnfdCD0ZO1vqQM79WpS6_bq-D1ZzQ"
    });
      
    function getCollectionById (id) {
        api.collections.getPhotos({ collectionId: id, page: 1, perPage: 24 })
        .then(res => {
          setImages(res);
          setImageOpen(true);
        })
        .catch(() => {
          console.log("Something went wrong");
        })
      }
    
    function unsplashSearch (x) {
    api.search
        .getCollections({ query: x, page: 1, perPage: 15})
        .then(result => {
        setCollections(result);
        })
        .catch(() => {
        console.log("something went wrong!");
        });
    }

    function handleDragStart(ev) {
        let obj = ev.target;
        if (!obj.closest('.draggable')) return;
        if(obj.classList.contains('draggable')){
        obj = obj.firstElementChild;
        }

        const data = {
        type: "drag",
        data: {}
        }
        document.getElementById("dropframe").contentWindow.postMessage(JSON.stringify(data), appUrl);
    }
  function handleDrop(ev) {
      var $body = document.getElementsByTagName("body");
      var offsetLeft = ev.pageX - $body[0].scrollLeft;
      var offsetTop = ev.pageY - $body[0].scrollTop;

        const data = {
            type: "drop",
            data: {
              mouse:{
                  x: 20,
                  y: 20,
              },
              htmlPayload: ev.target.id,
              width,
              height,
              timestamp: "fsk_" + Date.now() + "_" + Math.floor((Math.random() * 10000) + 1)
            }
        }
    document.getElementById("dropframe").contentWindow.postMessage(JSON.stringify(data), appUrl);
    setTimeout(() => getNodes(), 1000);
  }

  function setStyle(type = "innerStyle"){
    const data = {
      type,
      data: styled
    }
    document.getElementById("dropframe").contentWindow.postMessage(JSON.stringify(data), appUrl);
  }

  useEffect(() => {
    if(styled){
        setStyle();
    }
  }, [styled]);

function setDynamicStyle(x, type = "dynamic_css"){
    const data = {
        type,
        data: x
    }
    document.getElementById("dropframe").contentWindow.postMessage(JSON.stringify(data), appUrl);
    
}

// function get_html(){
//     if (master == "") {
//         alert("Please select Master Layout.");
//         return;
//     }
//     if(name == "") {
//         alert("Layout Name field is required.");
//         return;
//     }
//     const data = {
//       type: "get_html",
//       data: { id: theme.id, layoutId, MasterLayoutId: master, name: name }
//     }
//     document.getElementById("dropframe").contentWindow.postMessage(JSON.stringify(data), appUrl);
// }

useEffect(() => {
    if (bgImg) {
        const data = { 
            type: "background_image",
            data: { image: bgImg }
        }
        document.getElementById("dropframe").contentWindow.postMessage(JSON.stringify(data), appUrl);
    }
}, [bgImg])

useEffect(() => {
    if (unsplashImg) {
        setOpen(false);
        const data = { 
            type: "background_image",
            data: { image: unsplashImg }
        }
        document.getElementById("dropframe").contentWindow.postMessage(JSON.stringify(data), appUrl);
    }
}, [unsplashImg])

  const setBackgroundImage = (event) => {
    if (
        event &&
        event.target &&
        event.target.files &&
        event.target.files.length > 0
        ) {
            uploadBG({ image: event.target.files[0] });
        }
  }

  useEffect(() => {
    if (Array.isArray(themes)){
        let y = themes.filter(x => x.id == themeId);
        setTheme(y[0]);
    }
  }, [themes]);

  useEffect(() => {
    fetchTheme();
    fetchMasterLayouts();
    setMaster("");
    setName("");
    setH([]);
    setWidth(1200);
    setHeight(1200);
    setZoom(.5);
    setPreset("facebook_square");
    setPlatform("facebook");
    if (layoutId) {
        getLayout(layoutId);
    }
    setTimeout(() => getNodes(), 2000);
  }, []);

  useEffect(() => {
    if (layout.id) {
        setLoading(true);
        const od = JSON.parse(layout.data);
        setMaster(layout.MasterLayoutId);
        setName(layout.name);
        setH(od);
        setWidth(od[0].width);
        setHeight(od[0].height);
        setZoom(.4);
        setPreset(od[0].preset);
        setPlatform(od[0].platform);

        setTimeout(() => console.log("iDoocument: ", document.getElementById("dropframe").contentWindow.document.getElementById("canvas").innerHTML = od[0].html), 1000);
        // setTimeout(() => iDocument.getElementById("canvas").innerHTML = od[0].html, 5000);
        setTimeout(() => getNodes(), 2000);
        setTimeout(() => setLoading(false), 2500);
    }
  }, [layout]);

  useEffect(async () => {
    if (master != "") {
        const data1 = {
            type: "remove_all",
            data: {}
        }
        document.getElementById("dropframe").contentWindow.postMessage(JSON.stringify(data1), appUrl);
        let payload = "";
        let a = "";
        const y = masterLayouts.filter(x => x.id == master);
        if (y.length > 0) {
            let componentsToBePopulate = JSON.parse(y[0].fields);
            await Promise.all(componentsToBePopulate.map((z, i) => {
                a = z.split(" ");
                    const data = {
                        type: "drop",
                        data: {
                            mouse:{
                                x: 20 * (i + 2),
                                y: 20 * (i + 2),
                            },
                            htmlPayload: a[0].toLowerCase(),
                            name: z,
                            timestamp: Date.now()
                        }
                    }
                    document.getElementById("dropframe").contentWindow.postMessage(JSON.stringify(data), appUrl);
            }));
        }
        setTimeout(() => getNodes(), 1000);
    }
  }, [master]);

  async function getNodes() {
    const iWindow = document.getElementById("dropframe").contentWindow;
    const iDocument = iWindow.document;
    const nodeList = iDocument.getElementById("canvas").childNodes;

    const len = nodeList.length;
    let nodes = [];
    for (let i=0; i<len; i++) {
        if(nodeList[i].getAttribute("data-name") != ""){
            nodeList[i].style.zIndex = (len - i);
            nodes = nodes.concat([nodeList[i].getAttribute("data-name")]);
        }
    }
    setLayers(nodes);
  }

  function updateIndex(ele1, ele2) {
    setLayerLoading(true);
    const data1 = {
        type: "update_layers",
        data: {ele1, ele2}
    }
    document.getElementById("dropframe").contentWindow.postMessage(JSON.stringify(data1), appUrl);
    setTimeout(() => getNodes(), 1000);
    setTimeout(() => setLayerLoading(false), 2000);
  }

  function addAttribute(attr) {
    const data = {
      type: "set_attr",
      data: attr
    }
    document.getElementById("dropframe").contentWindow.postMessage(JSON.stringify(data), appUrl);
  }

  function changePreset(data) {
    const iWindow = document.getElementById("dropframe").contentWindow;
    const iDocument = iWindow.document;
    console.log("iDocument: ", iDocument);
    const html = iDocument.getElementById("canvas").innerHTML;
    const prePreset = preset;
    const prewidth = width;
    const preHeight = height;
    const prePlatform = platform;
    let obj = {
        preset: prePreset,
        width: prewidth,
        height: preHeight,
        platform: prePlatform,
        html: html
    }
    let pfs = h.filter(x => x.platform != prePlatform);
    let newPlatformData = h.filter(y => y.platform == data.platform);

    setH(pfs.concat([obj]));
    setWidth(data.width);
    setHeight(data.height);
    setZoom(data.zoom);
    setPreset(data.preset);
    setPlatform(data.platform);
    if(newPlatformData.length > 0 && iDocument) {
        iDocument.getElementById("canvas").innerHTML = newPlatformData[0].html;
    }
  }

  function get_html() {
    if (master == "") {
        alert("Please select Master Layout.");
        return;
    }
    const iWindow = document.getElementById("dropframe").contentWindow;
    const iDocument = iWindow.document;
    const html = iDocument.getElementById("canvas").innerHTML;
    const prePreset = preset;
    const prewidth = width;
    const preHeight = height;
    const prePlatform = platform;
    let obj = {
        preset: prePreset,
        width: prewidth,
        height: preHeight,
        platform: prePlatform,
        html: html
    }
    let pfs = h.filter(x => x.platform != prePlatform);

    setH(pfs.concat([obj]));
    
    if (layoutId) {
      setSelected({ id: layoutId, data: JSON.stringify(pfs.concat([obj])), name: name, MasterLayoutId: master, status: 1, facebook: 1, insta: 1, linkedin: 1, gmb: 1 });
      setOpenS(true);
    } else {
      setSelected({ ThemeId: theme.id, data: JSON.stringify(pfs.concat([obj])), name: name, MasterLayoutId: master, status: 1, facebook: 1, insta: 1, linkedin: 1, gmb: 1 });
      setOpenS(true);
    }
  }

  function updateLayout (data) {
    setLoading(true);
    axios.put(`${baseUrl}/layout/${data.id}`, data)
      .then((response)=>{
        setLoading(false);
        setOpenS(false);
        alert("Layout updated.");
      })
      .catch(function (error) {
        setLoading(false);
        setOpenS(false);
        alert("Some Error occure");
      })
  }

  function saveLayout (data) {
    setLoading(true);
    axios.post(`${baseUrl}/layout/store`, data)
      .then((response)=>{
        setLoading(false);
        setOpenS(false);
        alert("Layout Saved.");
      })
      .catch(function (error) {
        setLoading(false);
        setOpenS(false);
        alert("Some Error occure");
      })
  }

  useEffect(() => {
    if(dynamicField) {
        const data1 = {
            type: "update_dynamic_Field",
            data: dynamicField
        }
        document.getElementById("dropframe").contentWindow.postMessage(JSON.stringify(data1), appUrl);     
    }
  }, [dynamicField]);

  const classes = useStyles();
    const ariaLabel = { 'aria-label': 'description' };

    const facebookType = (
        <Menu
          anchorEl={menuFb}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          open={Boolean(menuFb)}
          onClose={closeMenuFb}
          keepMounted
        >
          <MenuItem onClick={() => {
              changePreset({ width: 1200, height: 1200, zoom: .5, platform: "facebook", preset: "facebook_square" });
              closeMenuFb();
          }}>
            Facebook Square (1200 x 1200)
          </MenuItem>
          <MenuItem onClick={() => {
              changePreset({ width: 1200, height: 630, zoom: .5, platform: "facebook", preset: "facebook_landscape" });
            closeMenuFb();
          }}>
            Facebook Landscape (1200 x 630)
          </MenuItem>
          <MenuItem onClick={() => {
              changePreset({ width: 1080, height: 1920, zoom: .5, platform: "facebook", preset: "facebook_story" });
            closeMenuFb();
          }}>
            Facebook Story (1080 x 1920)
          </MenuItem>
        </Menu>
      );
    
      const instaType = (
        <Menu
          anchorEl={menuInsta}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          open={Boolean(menuInsta)}
          onClose={closeMenuInsta}
          keepMounted
        >
          <MenuItem onClick={() => {
            changePreset({ width: 1080, height: 1080, zoom: .5, platform: "insta", preset: "insta_square" });
            closeMenuInsta();
          }}>
            Insta square (1080 x 1080)
          </MenuItem>
          <MenuItem onClick={() => {
              changePreset({ width: 1080, height: 1350, zoom: .5, platform: "insta", preset: "insta_portrait" });
            closeMenuInsta();
          }}>
            Insta Portrait (1080 x 1350)
          </MenuItem>
          <MenuItem onClick={() => {
              changePreset({ width: 1080, height: 566, zoom: .5, platform: "insta", preset: "insta_landscape" });
            closeMenuInsta();
          }}>
            Insta Landscape (1080 x 566)
          </MenuItem>
          <MenuItem onClick={() => {
              changePreset({ width: 1080, height: 1920, zoom: .5, platform: "insta", preset: "insta_story" });
            closeMenuInsta();
          }}>
            Insta Story (1080 x 1920)
          </MenuItem>
          <MenuItem onClick={() => {
            changePreset({ width: 1080, height: 1920, zoom: .5, platform: "insta", preset: "insta_reel" });
            closeMenuInsta();
          }}>
            Insta Reel (1080 x 1920)
          </MenuItem>
        </Menu>
      );

      const linkedinType = (
        <Menu
          anchorEl={menuLinkedin}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          open={Boolean(menuLinkedin)}
          onClose={closeMenuLinkedin}
          keepMounted
        >
          <MenuItem onClick={() => {
              changePreset({ width: 1200, height: 628, zoom: .4, platform: "linkedin", preset: "linkedin_feed" });
              closeMenuLinkedin();
          }}>
            Linkedin Feed (1200 x 628)
          </MenuItem>
        </Menu>
      );

      const gmbType = (
        <Menu
          anchorEl={menuGMB}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          open={Boolean(menuGMB)}
          onClose={closeMenuGMB}
          keepMounted
        >
          <MenuItem onClick={() => {
              changePreset({ width: 1200, height: 900, zoom: .4, platform: "gmb", preset: "gmb_feed" });
              closeMenuGMB();
          }}>
            GMB Feed (1200 x 900)
          </MenuItem>
        </Menu>
      );
    
  return (
    <>
    {/* <MediaLibraryPage
      mediaLoading={mediaLoading}
      open={mediaOpen}
      onClose={() => setMediaOpen(false)}
      medias={medias}
      accountId={selectedAccount.id}
      addMedias={addMedias}
      onSelect={setDynamics}
      getMedias={getMedias}
      deleteMedia={deleteMedia}
    /> */}
    <Loading loading={isLoading || loading} />
    <SaveDialog
      open={openS}
      selected={selected}
      setSelected={setSelected}
      onClose={() => {
        setSelected({});
        setOpenS(false);
      }}
      saveLayout={saveLayout}
      updateLayout={updateLayout}
    />
        <CollectionPage
            images={images}
            open={imageOpen}
            onClose={() => {
                setImages([]);
                setImageOpen(false);
            }}
            setUnsplashImg={setUnsplashImg}
        />
        <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            aria-labelledby="responsive-dialog-title"
        >
            <MDBox
            style={{
                display: "grid",
                textAlign: "left",
                padding: "32px 12px",
            }}
            >
            <Grid container>
                <Grid item xs={10}>
                <DialogTitle id="responsive-dialog-title">
                    See and save our vast image library
                </DialogTitle>
                </Grid>
                <Grid item xs={2}>
                <MDBox
                    onClick={() => setOpen(false)}
                    style={{
                    background: "#E6E8EB",
                    height: "40px",
                    width: "40px",
                    borderRadius: "100px",
                    float: "right",
                    marginTop: "24px",
                    marginRight: "24px",
                    }}
                >
                    <ClearIcon style={{ margin: "10px 10px" }} />
                </MDBox>
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "5px" }}>
                <Grid item xs={8}>
                    <FormField
                        label="Search Image"
                        placeholder="search Image"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <MDButton
                        style={{
                            background: "linear-gradient(180deg, #3E3D45 0%, #202020 100%)",
                            color: "#fff",
                            minWidth: "295px",
                        }}
                        variant="contained"
                        onClick={() => unsplashSearch(search)}
                    >
                        Search
                    </MDButton>
                </Grid>
                {collections && !collections.errors && collections.response && collections.response.results.map(x => {
                return (
                    <Grid
                        item
                        xs={12}
                        md={4}
                    >
                    <img
                        src={ x.cover_photo.urls.small }
                        style={{ width: "100%", height: "300px", objectFit:"cover" }}
                        width="100%"
                    />
                    <Grid item>
                        <MDBox style={{ display: "flex" }}>
                        <MDTypography
                            style={{
                            fontWeight: "600",
                            fontSize: "15px",
                            color: " #534F6B",
                            }}
                        >
                            {x.title}
                        </MDTypography>
                        <MDBox sx={{ ml: "auto", display: 'flex', cursor: "pointer" }} onClick={() => getCollectionById(x.id)}>
                            <p style={{ fontSize: '12px', fontWeight: 700 }}>View All</p>
                            <ArrowForwardIcon style={{color: "#09032B", marginBottom: "-2px"}} />
                        </MDBox>
                        </MDBox>
                    </Grid>
                    </Grid>
                )
                })}
            </Grid>
            </MDBox>
        </Dialog>
        <Grid container spacing={2}>
            <Grid item xs={12} style={{display: "flex", background: "#FFFFFF", height: "73px", alignItems: "center", justifyContent: "space-between", PaddingLeft: "24px", paddingRight: "24px"}}>
                <MDBox style={{display: "flex", minWidth: "200px"}}>
                    <ArrowBackIosOutlinedIcon style={{marginRight: "16px"}} onClick={() => navigate("/themes")} />
                    <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#534F6B"}}>Layout:&nbsp;
                        <span style={{fontWeight: "500", fontSize: "12px", color: "#534F6B"}}>{name.length > 10 ? `${name.substring(0,10)}...` : name}</span>
                    </MDTypography>
                    <HelpOutlineOutlinedIcon style={{color: "#CECDD5", marginLeft: "4px"}} />
                </MDBox>
                <MDBox sx={{ display: "flex", background: "#E6E6EA", alignItems: "center", borderRadius: "12px", padding: "5px" }}>
                    <MDBox
                        onClick={openMenuFb}
                        sx={platform == "facebook" ? { display: "flex", padding: "2px 10px", alignItems: "center", marginRight: "15px", background: "#FFFFFF", boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)", borderRadius: "8px", cursor: "pointer" } : { display: "flex", padding: "2px 10px", alignItems: "center", marginRight: "15px", cursor: "pointer" }}>
                        <FacebookIcon /><p style={{ fontSize: "12px" }}>&nbsp;Facebook</p>
                    </MDBox>
                    {facebookType}
                    <MDBox
                        onClick={openMenuInsta}
                        sx={platform == "insta" ? { display: "flex", padding: "2px 10px", alignItems: "center", marginRight: "15px", background: "#FFFFFF", boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)", borderRadius: "8px", cursor: "pointer" } : { display: "flex", padding: "2px 10px", alignItems: "center", marginRight: "15px", cursor: "pointer" }}>
                        <InstagramIcon /><p style={{ fontSize: "12px" }}>&nbsp;Instagram</p>
                    </MDBox>
                    {instaType}
                    <MDBox
                        onClick={openMenuLinkedin}
                        sx={platform == "linkedin" ? { display: "flex", padding: "2px 10px", alignItems: "center", marginRight: "15px", background: "#FFFFFF", boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)", borderRadius: "8px", cursor: "pointer" } : { display: "flex", padding: "2px 10px", alignItems: "center", marginRight: "15px", cursor: "pointer" }}>
                        <LinkedInIcon /><p style={{ fontSize: "12px" }}>&nbsp;Linkedin</p>
                    </MDBox>
                    {linkedinType}
                    <MDBox
                        onClick={openMenuGMB}
                        sx={platform == "gmb" ? { display: "flex", padding: "2px 10px", alignItems: "center", marginRight: "15px", background: "#FFFFFF", boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)", borderRadius: "8px", cursor: "pointer" } : { display: "flex", padding: "2px 10px", alignItems: "center", marginRight: "15px", cursor: "pointer" }}>
                        <StorefrontRoundedIcon /><p style={{ fontSize: "12px" }}>&nbsp;Google Business</p>
                    </MDBox>
                    {gmbType}
                </MDBox>
                <MDBox style={{display: "flex"}}>
                    <MDBox style={{marginRight: "32px"}}>
                        <ButtonGroup variant="outlined" size="small" aria-label="outlined button group" sx={{ float: "right" }}>
                            <Button variant="contained" style={{ color: "#fff", background: "#000" }} onClick={() => setZoom(zoom - .1)}>-</Button>
                            <Button style={{ color: "#000" }}>{Math.round(zoom * 100)}%</Button>
                            <Button variant="contained" style={{ color: "#fff", background: "#000" }} onClick={() => setZoom(zoom + .1)}>+</Button>
                        </ButtonGroup>
                    </MDBox>
                    <MDButton
                        style={{
                        background: "linear-gradient(180.67deg, #09032B 2.43%, #312B56 101%)",
                        boxShadow: "0px 2px 6px rgba(41, 43, 63, 0.36)",
                        color: "#FFFFFF",
                        fontWeight: 700,
                        fontSize: "12px",
                        textTransform: "inherit",
                        }}
                        onClick={() => get_html()}
                    >
                    Save and Publish
                    </MDButton>
                </MDBox>
            </Grid>
        </Grid>
        <Grid container spacing={2} style={{padding: "24px"}}>
            <Grid item xs={12} md={3}>
                <MDBox style={{ width: 'auto', height: 903, padding: '16px', background: "#FFFFFF", borderRadius: "12px", boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)"}} >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                        {Array.isArray(blocks) && blocks.map((x) => {
                            return (
                                <div key={x.id} className="draggable" style={{marginTop: "32px"}} draggable >
                                    <img src={`/images/${x.logo}`} style={{ margin: "auto 12px" }} id={x.id} />
                                    <p style={{fontWeight: "400", fontSize: "12px", color: '#6B6880', margin: "auto 12px"}}>{x.label}</p>
                                </div>
                            )
                        })}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <MDTypography style={{fontWeight: "500", fontSize: "16px", color: "#221C40"}}>
                                Components
                            </MDTypography>
                            {layers && Array.isArray(layers) && layers.map((y, i) => {
                                return (
                                    <MDBox key={i} style={{display: "flex", marginTop: "20px", cursor: "pointer", justifyContent: "space-between"}}>
                                        <div style={{ display: "flex" }}>
                                            <img src={y && `/images/${y.split(" ")[0].toLowerCase()}.png`} style={{ margin: "auto 12px" }} width="30px" />
                                            <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#221C40"}}>{y && y}</MDTypography>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <IconButton sx={{ p: 0, m:0 }}>
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                            {(i  !== 0) && (
                                                <IconButton sx={{ p: 0, m:0 }} disabled={layerLoading} onClick={() => updateIndex(i, i-1)}>
                                                    {layerLoading ? <CircularProgress size={15} /> : <NorthIcon fontSize="small" />}
                                                </IconButton>
                                            )}
                                            {(i + 1 !== layers.length) && (
                                                <IconButton sx={{ p: 0, m:0 }} disabled={layerLoading} onClick={() => updateIndex(i+1, i)}>
                                                    {layerLoading ? <CircularProgress size={15} /> : <SouthIcon fontSize="small" />}
                                                </IconButton>
                                            )}
                                        </div>
                                    </MDBox>
                                )
                            })}
                        </Grid>
                    </Grid>
                </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
                <div style={{ margin: "auto", width: `${width * zoom}px` }}>
                    <MDBox style={{ display: "flex", marginTop: "50px" }}>
                        <MDTypography style={{fontWeight: "700", fontSize: "16px", color: "#1B1C29"}}>
                            Canvas1
                        </MDTypography>
                        {/* <MDBox sx={{ ml: "auto" }}>
                            KeyboardArrowUpIcon style={{marginRight: "24px", color: "#B5B3BF"}} />
                            <KeyboardArrowDownIcon style={{marginRight: "24px", color: "#6B6880"}} />
                            <AddToPhotosIcon style={{marginRight: "24px", color: "#6B6880"}} />
                            <DeleteOutlineIcon style={{marginRight: "24px", color: "#6B6880"}} />
                            <AddPhotoAlternateOutlinedIcon style={{color: "#6B6880"}} />
                        </MDBox> */}
                    </MDBox>
                    <div style={{width: `${width * zoom}px`, height: `${height * zoom}px`, overflow: "hidden", position: "relative", zIndex: 2 }}>
                        <iframe
                            frameBorder="0"
                            style={{
                                transform: `scale(${zoom})`,
                                MsTransform: `scale(${zoom})`,
                                OTransform: `scale(${zoom})`,
                                MozTransform: `scale(${zoom})`,
                                WebkitTransform: `scale(${zoom})`,
                                transformOrigin: "0 0",
                                MsTransformOrigin: "0 0",
                                OTransformOrigin: "0 0",
                                WebkitTransformOrigin: "0 0",
                                border: "2px solid #bababa"
                            }}
                            name="somename"
                            title="somename"
                            id="dropframe"
                            src={`${process.env.REACT_APP_BASE_URL}/#/canvas`}
                            width={`${width}px`}
                            height={`${height}px`}
                        />  
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={3}>
               <RightBar
                    addAttribute={addAttribute}
                    dynamicField={dynamicField}
                    setWidth={setWidth}
                    setHeight={setHeight}
                    setZoom={setZoom}
                    setDynamicField={setDynamicField}
                    setBackgroundImage={setBackgroundImage}
                    unsplashSearch={unsplashSearch}
                    setOpen={setOpen}
                    setStyle={setStyle}
                    styled={styled}
                    setStyled={setStyled}
                    setDynamicStyle={setDynamicStyle}
                    setBgcolor={setBgcolor}
                    setColor={setColor}
                    color={color}
                    bgcolor={bgcolor}
                    masterLayouts={masterLayouts}
                    master={master}
                    setMaster={setMaster}
                    name={name}
                    setName={setName}
                    setH={setH}
                /> 
            </Grid>
        </Grid>
    </>
  );
}
