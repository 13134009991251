import Icon from "@mui/material/Icon";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import PanoramaIcon from '@mui/icons-material/Panorama';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FolderIcon from '@mui/icons-material/Folder';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import PollIcon from '@mui/icons-material/Poll';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ArticleIcon from '@mui/icons-material/Article';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { BusinessDetailPageContainer } from './../../views/businessDetails/business-details.container';
import CardMembershipIcon from '@mui/icons-material/CardMembership';

const routes = [
  { type: "title", title: "Master", key: "master" },
  {
    type: "collapse",
    name: "White Label",
    key: "white",
    icon: <ArticleIcon />,
    route: "/white-label",
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Industry",
    key: "Industry",
    icon: <ArticleIcon />,
    route: "/industry",
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Topic",
    key: "topic",
    icon: <CalendarMonthIcon />,
    route: "/topic",
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Subscription",
    key: "Subscription",
    icon: <CardMembershipIcon />,
    route: "/subscription",
    noCollapse: true,
  },
  { type: "title", title: "content", key: "content" },
  {
    type: "collapse",
    name: "All Content",
    key: "all_content",
    icon: <ArticleIcon />,
    route: "/content-table",
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "User Content",
    key: "user_content",
    icon: <ArticleIcon />,
    route: "/user-content",
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Calender",
    key: "calender",
    icon: <CalendarMonthIcon />,
    route: "/calendar",
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "RSS Feed",
  //   key: "rss feed",
  //   icon: <RssFeedIcon />,
  //   // route: "/business-account",
  //   noCollapse: true,
  // },
  { type: "divider", key: "divider-0" },
  { type: "title", title: "Design", key: "design" },
  {
    type: "collapse",
    name: "Master Layouts",
    key: "master-layout",
    icon: <PanoramaIcon />,
    route: "/master-layout",
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "All Themes",
    key: "all themes",
    icon: <PanoramaIcon />,
    route: "/themes",
    noCollapse: true,
  },
  { type: "divider", key: "divider-1" },
  { type: "title", title: "Media", key: "media" },
  {
    type: "collapse",
    name: "All Media",
    key: "all media",
    icon: <FolderIcon />,
    route: "/media",
    noCollapse: true,
  },
  { type: "divider", key: "divider-2" },
  { type: "title", title: "Users", key: "users" },
  {
    type: "collapse",
    name: "All Users",
    key: "all accounts",
    icon: <AccountCircleIcon />,
    route: "/user-account",
    // component: <BusinessAccountPageContainer />,
    noCollapse: true,
  },
  // { type: "divider", key: "divider-3" },
  // { type: "title", title: "Analytics", key: "analytics" },
  // {
  //   type: "collapse",
  //   name: "Overall",
  //   key: "overall",
  //   icon: <PollIcon />,
  //   route: "/overview",
  //   // component: <ChangePasswords />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Facebook",
  //   key: "facebook",
  //   icon: <Icon>facebook</Icon>,
  //   route: "/facebook",
  //   // component: <Overview />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Instagram",
  //   key: "instagram",
  //   icon: <InstagramIcon />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Linkedin",
  //   key: "linkedin",
  //   icon: <LinkedInIcon />,
  //   // route: "/contents/analyics",
  //   // component: <Analytics />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "My Google Business",
  //   key: "my google business",
  //   icon: <StorefrontIcon />,
  //   noCollapse: true,
  // },
];

export default routes;
