export const ADD_PROFILE = "ADD_PROFILE";
export const ADD_PROFILE_FAILURE = "ADD_PROFILE_FAILURE";
export const ADD_PROFILE_SUCCESS = "ADD_PROFILE_SUCCESS";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";

export const DELETE_PROFILE = "DELETE_PROFILE";
export const DELETE_PROFILE_FAILURE = "DELETE_PROFILE_FAILURE";
export const DELETE_PROFILE_SUCCESS = "DELETE_PROFILE_SUCCESS";
