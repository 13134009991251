import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import FormField from "./../../../shared/components/FormField";
import ClearIcon from "@mui/icons-material/Clear";
import MDButton from "../../../shared/components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";

const topHead = {
  fontWeight: "700",
  fontSize: "20px",
  color: "#221C40",
};
const button = {
  background: "linear-gradient(180.67deg, #09032B 2.43%, #312B56 101%)",
  color: "#FFFFFF",
  borderRadius: "8px",
  width: "189px",
};
const boxText = {
  borderRadius: "12px",
  border: "1px solid #DEE1E6",
  width: "128px",
  height: "120px",
};
const buttonBox = {
  display: "flex",
  justifyContent: "center",
  marginTop: "52px",
};
const mdbox = {
  background: "#E6E8EB",
  height: "40px",
  width: "40px",
  borderRadius: "100px",
};
const mdText1 = {
  color: "red",
  marginTop: "-0px",
  marginBottom: "14px",
  fontSize: "12px",
  fontWeight: 400,
};

const X = 5;
const Y = 8;
const A = -4;
const B = -6;

const options = [
  "Image Library",
  "Text Paragraph",
  "Text Field 1",
  "Text Field 2",
  "Text Field 3",
  "Text Field 4",
  "Text Field 5",
  "Text Field 6"
];

export function MasterLayoutDialog(props) {
  const { open, selected, setSelected, onClose, createMasterLayout, updateMasterLayout } = props;
  const [error, setError] = React.useState({});

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox style={{ maxWidth: "274px" }}>
                <MDTypography style={topHead}>
                  {selected.id ? "Update Master Layout" : "Add New Master Layout"}
                </MDTypography>
                <p>Create a new master layout</p>
              </MDBox>
              <MDBox style={mdbox}>
                <ClearIcon
                  onClick={() => onClose()}
                  style={{ margin: "12px 12px", cursor: "pointer" }}
                />
              </MDBox>
            </MDBox>
            <MDBox mb={2} style={{ marginTop: "24px" }}>
              <FormField
                label="Name"
                name="name"
                placeholder="Master Layout Name"
                type="text"
                value={selected.name}
                onChange={e => {
                  setSelected({ ...selected, name: e.target.value });
                  setError({});
                }}
              />
              {error.isTrue && error.type == "name" && (
                <MDTypography style={mdText1}>Name is required.</MDTypography>
              )}
            </MDBox>

            <MDBox mb={2} style={{ marginTop: "24px" }}>
              <FormField
                label="Code"
                name="code"
                placeholder="Master Layout Code"
                type="text"
                value={selected.code}
                onChange={e => {
                  setSelected({ ...selected, code: e.target.value });
                  setError({});
                }}
              />
              {error.isTrue && error.type == "code" && (
                <MDTypography style={mdText1}>Code is required.</MDTypography>
              )}
            </MDBox>

            <MDBox mb={2} style={{ marginTop: "24px" }}>
              <Autocomplete
                options={options}
                multiple
                onChange={(event, newValue) => {
                  setSelected({
                    ...selected,
                    fields: newValue,
                  });
                  setError({});
                }}
                value={selected.fields}
                renderInput={(params) => (
                  <FormField
                  {...params}
                    label="Choose Dynamic Fields"
                    name="fields"
                    placeholder="Select Dynamic Fields"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
              {error.isTrue && error.type == "fields" && (
                <MDTypography style={mdText1}>
                  Please select minimum one field.
                </MDTypography>
              )}
            </MDBox>

            <MDBox style={buttonBox}>
              <MDButton
                style={button}
                fullWidth
                onClick={() => {
                  if (selected.name == "") {
                    setError({ isTrue: true, type: "name" });
                    return;
                  } else if (selected.code == "") {
                    setError({ isTrue: true, type: "code" });
                    return;
                  } else if (selected.fields.length === 0) {
                    setError({ isTrue: true, type: "fields" });
                    return;
                  } else {
                    setError(false);
                    if (selected.id) {
                      updateMasterLayout(selected);
                    } else {
                      createMasterLayout(selected);
                    }
                  }
                }}
              >
                SAVE
              </MDButton>
            </MDBox>
            </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
