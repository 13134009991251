export const CREATE_WHITE = "CREATE_WHITE";
export const CREATE_WHITE_FAILURE = "CREATE_WHITE_FAILURE";
export const CREATE_WHITE_SUCCESS = "CREATE_WHITE_SUCCESS";

export const UPDATE_WHITE = "UPDATE_WHITE";
export const UPDATE_WHITE_FAILURE = "UPDATE_WHITE_FAILURE";
export const UPDATE_WHITE_SUCCESS = "UPDATE_WHITE_SUCCESS";

export const FETCH_WHITE = "FETCH_WHITE";
export const FETCH_WHITE_FAILURE = "FETCH_WHITE_FAILURE";
export const FETCH_WHITE_SUCCESS = "FETCH_WHITE_SUCCESS";

export const DELETE_WHITE = "DELETE_WHITE";
export const DELETE_WHITE_FAILURE = "DELETE_WHITE_FAILURE";
export const DELETE_WHITE_SUCCESS = "DELETE_WHITE_SUCCESS";