import React, { useEffect, useState } from "react";
import DashboardLayout from "./../../shared/components/DashboardLayout";
import DashboardNavbar from "./../../shared/components/DashboardNavbar";
import Loading from './../../shared/Loading';
import { MediaTable } from './component/media-table';
import { DeleteConfirm } from "./component/delete-confirm";

export default function MediaPage(props) {
  const {
    deleteMedia,
    fetchMedia,
    medias,
    isSaved,
    isLoading
  } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState({name: "", status: 1});

  useEffect(() => {
    fetchMedia();
  }, []);

  useEffect(() => {
    if (isSaved) {
      fetchMedia();
      setSelected({image: "", id: ""});
      setOpen(false);
    }
  }, [isSaved]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loading loading={isLoading} />
      <DeleteConfirm
        open={open}
        selected={selected}
        onClose={() => {
          setOpen(false);
          setSelected({image: "", id: ""});
        }}
        deleteMedia={deleteMedia}
      />
      <MediaTable
        selected={selected}
        setSelected={setSelected}
        rows={medias}
        open={open}
        setOpen={setOpen}
      />
    </DashboardLayout>
  );
}
