import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AddIcon from '@mui/icons-material/Add';
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import MDAvatar from "../../../shared/components/MDAvatar";
import MDButton from "../../../shared/components/MDButton";
import breakpoints from "../../../assets/theme/base/breakpoints";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import burceMars from "../../../assets/images/alec.png";

export default function Header({ children }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);


  return (
    <Card
      style={{ backgroundColor: "white" }}
      sx={{
        position: "relative",
        py: 2,
        px: 2,
      }}
    >
      <Grid container>
        <Grid xs={12} item>
          <MDTypography
            style={{
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "140%",
              color: "#344767",
              marginBottom: "24px",
            }}
          >
            <ArrowBackIcon style={{ color: "#000000", marginBottom: "-2px" }} /> Back to list
          </MDTypography>
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="center">
        <Grid item>
          <MDAvatar src={burceMars} alt="profile-image" size="md" shadow="sm" />
        </Grid>
        <Grid item>
          <MDBox height="100%" mt={0.5} lineHeight={1}>
            <MDTypography
              style={{
                fontWeight: "700",
                fontSize: "17px",
                lineHeight: "140%",
                color: "#344767",
              }}
            >
              Alec M. Thompson
            </MDTypography>
            <MDTypography
              style={{
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "140%",
                color: "#09032B",
              }}
              variant="button"
            >
              OWNER
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item sx={{ ml: "auto" }}>
          <MDTypography
            style={{
              fontWeight: "700",
              fontSize: "12px",
              lineHeight: "140%",
              color: "#7B809A",
              textTransform: "uppercase",
            }}
          >
            <ArrowForwardIcon /> SWITCH TO BUSINESS
          </MDTypography>
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="center">
        <Grid item style={{ marginTop: "24px" }}>
          <MDTypography
            style={{
              fontWeight: "700",
              fontSize: "14px",
              lineHeight: "140%",
              color: "#344767",
            }}
          >
            Last Login:{" "}
            <span
              style={{
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "140%",
                color: "#7B809A",
              }}
            >
              31 Jan tomorrow
            </span>
          </MDTypography>
        </Grid>
        <Grid item sx={{ ml: "auto", marginTop: "24px" }}>
          <MDBox
            display="flex"
            justifyContent={{ md: "flex-end" }}
            alignItems="center"
            lineHeight={1}
          >
            <MDTypography
              variant="caption"
              style={{
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "140%",
                color: "#344767",
              }}
            >
              Social:
            </MDTypography>
            <MDBox ml={1}>
              <FacebookOutlinedIcon style={{ marginRight: "12px", marginBottom: "-3px" }} />
              <TwitterIcon style={{ marginRight: "12px", marginBottom: "-3px" }} />
              <InstagramIcon style={{ marginBottom: "-3px" }} />
              <MDButton
                variant="text"
                style={{
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "140%",
                  color: "#09032B",
                }}
              >
                <AddIcon style={{ color: "#09032B", fontWeight: "700", marginTop: "-2px" }} />&nbsp;Add social
              </MDButton>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      {children}
    </Card>
  );
}

Header.defaultProps = {
  children: "",
};

Header.propTypes = {
  children: PropTypes.node,
};
