export const CREATE_TOPIC = "CREATE_TOPIC";
export const CREATE_TOPIC_FAILURE = "CREATE_TOPIC_FAILURE";
export const CREATE_TOPIC_SUCCESS = "CREATE_TOPIC_SUCCESS";

export const UPDATE_TOPIC = "UPDATE_TOPIC";
export const UPDATE_TOPIC_FAILURE = "UPDATE_TOPIC_FAILURE";
export const UPDATE_TOPIC_SUCCESS = "UPDATE_TOPIC_SUCCESS";

export const FETCH_TOPIC = "FETCH_TOPIC";
export const FETCH_TOPIC_FAILURE = "FETCH_TOPIC_FAILURE";
export const FETCH_TOPIC_SUCCESS = "FETCH_TOPIC_SUCCESS";

export const GET_TOPICS = "GET_TOPICS";
export const GET_TOPICS_FAILURE = "GET_TOPICS_FAILURE";
export const GET_TOPICS_SUCCESS = "GET_TOPICS_SUCCESS";

export const DELETE_TOPIC = "DELETE_TOPIC";
export const DELETE_TOPIC_FAILURE = "DELETE_TOPIC_FAILURE";
export const DELETE_TOPIC_SUCCESS = "DELETE_TOPIC_SUCCESS";