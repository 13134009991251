import React, { useState, useEffect } from "react";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import MDBox from "../../shared/components/MDBox";
import MDTypography from "../../shared/components/MDTypography";
import Grid from "@mui/material/Grid";
import MDAvatar from "../../shared/components/MDAvatar";
import Loading from '../../shared/Loading';
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link, useParams, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import MDButton from "../../shared/components/MDButton";
import breakpoints from "../../assets/theme/base/breakpoints";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import burceMars from "../../assets/images/user-1.png";
import Divider from "@mui/material/Divider";
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";
import Header from "./component/header.component";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { makeStyles } from "@mui/styles";
import masterCardLogo from "../../assets/images/logos/mastercard.png";
import gray from "../../assets/images/gray.png";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import Accounts from "./component/accounts-overview.component";
import ThemeDialog from "./component/theme-dialog";
import TopicsDialog from "./component/topics-dialog";
import ColorDialog from "./component/color-assign-dialog";
import WaterMarkDialog from "./component/waterMark-dialog";
import { preetyDate } from "../../helper";
import SelectPagesDialog from "./component/select-pages-dialog";
import GooglePagesDialog from "./component/google-business-dialog";
import { useInitFbSDK } from "./../../hooks/facebook-hooks";

import { createApi } from 'unsplash-js';
import { ChromePicker } from 'react-color';
import Card from "@mui/material/Card";

import { Dialog, Typography } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import BookTwoToneIcon from "@mui/icons-material/BookTwoTone";
import choooseImage from "./../../assets/images/chooose.png";
import { DeleteConfirm } from "./component/delete-confirm";

const useStyles = makeStyles({
  profileText: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#7B809A",
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  hexText: {
    color: "#344767",
    fontSize: "14px",
    fontWeight: "500",
  },
});

const mdbox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}
const mdbutton = {
  background: "#F4F4F4",
  color: "#344767",
  fontWeight: "400",
  fontSize: "18px",
  textTransform: "capitalize",
  padding: "11px 20px",
  marginRight: "16px",
  borderRadius: "8px",
}
const mdtext = {
  fontWeight: "700",
  fontSize: "16px",
  color: " #1B172E",
}
const text = {
  fontWeight: "400",
  fontSize: "12px",
  color: "#7B809A",
}
const topHead = {
  fontWeight: "700",
  color: "#344767",
}
const topText = {
  fontWeight: "400",
  fontSize: "14px",
  color: "#7B809A",
}
const socialIcon = {
  marginRight: "8px",
  color: "#AEAEAE",
  fontSize: "17px",
}
const twitterIcon = {
  marginRight: "18px",
  color: "#AEAEAE",
  fontSize: "17px",
}
const contentText = {
  fontWeight: "400",
  fontSize: "12px",
  color: " #58585E",
  marginTop: "8px",
}
const paraText = {
  fontSize: "11px",
  color: "#7B809A",
  fontWeight: "400",
  marginTop: "3px",
}
const imageText = {
  width: "20px",
  height: "20px",
  marginRight: "8px",
}
const mdboxText = {
  marginLeft: "auto",
  color: "#AEAEAE",
  fontSize: "17px",
  float: "right",
}
const divider = {
  borderBottom: "1px solid #D5D5D5",
  width: "100%",
  marginBottom: "18px",
  marginTop: "18px",
}
const dividerText = {
  borderBottom: "1px solid #E8E8E8",
  width: "55%",
  marginTop: "12px",
  marginBottom: "12px",
}
const headText = {
  fontWeight: "700",
  color: "#344767",
  fontSize: "14px",
}
const typoText = {
  fontWeight: "700",
  fontSize: "14px",
  color: "#344767",
  display: "flex",
}
const teamText = {
  fontWeight: "700",
  fontSize: "17px",
  color: "#344767",
}
const teamparaText = {
  fontWeight: "500",
  fontSize: "12px",
  marginTop: "3px",
}
const postText = {
  fontWeight: "400",
  fontSize: "12px",
  color: "#AEAEAE",
}
const iconText = {
  fontWeight: "700",
  fontSize: "12px",
  color: "#7B809A",
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <MDBox sx={{ p: 3 }}>{children}</MDBox>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function a11yPropss(index) {
  return {
    id: `post-tab-${index}`,
    "aria-controls": `post-tabpanel-${index}`,
  };
}

export function BusinessDetailPage(props) {
  const classes = useStyles();
  const {
    addProfile,
    updateProfile,
    deleteProfile,
    isSaved,
    updateAccount,
    teams,
    fetchTeam,
    deleteTeam,
    teamDeleted,
    getContentByAccount,
    contentByAccount,
    fetchTheme,
    themes,
    user,
    fetchUserById,
    isLoading
  } = props;
  const { userId, accountId } = useParams();
  const emptyProfile = {
    AccountId: accountId,
    type: "",
    name: "",
    email: "",
    pageId: "",
    refreshToken: "",
    accessToken: "",
    picture: "",
    custom: JSON.stringify({}),
    cta: "",
    hashtags: "",
    firstComment: 0,
    status: 1,
  }
  const isFbSDKInitialized = useInitFbSDK();
  const [fbUserAccessToken, setFbUserAccessToken] = useState();
  const [fbUserId, setFbUserId] = useState();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabsOrientation1, setTabsOrientation1] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [tabValue1, setTabValue1] = useState(0);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [openD, setOpenD] = useState(false);
  const [openC, setOpenC] = useState(false);
  const [openE, setOpenE] = useState(false);

  const [facebookPages, setFacebookPages] = useState([]);
  const [instaPages, setInstaPages] = useState([]);

  const [openF, setOpenF] = useState(false);
  const [type, setType] = useState(false);
  const [openG, setOpenG] = useState(false);
  const [selected, setSelected] = useState(emptyProfile);
  const [errors, setErrors] = useState({});
  const [state, setState] = useState(false);
  
  const [color, setColor] = React.useState("");
  const [colorType, setColorType] = React.useState("");
  const [collections, setCollections] = React.useState([]);
  const [collection, setCollection] = React.useState(user && Array.isArray(user.accounts) && user.accounts[0].collection != null && JSON.parse(user.accounts[0].collection));
  const [themeS, setThemeS] = useState("");
  const [selectedTheme, setSelectedTheme] = useState(user && Array.isArray(user.accounts) && user.accounts[0].ThemeId);
  const [position, setPosition] = useState({position: "leftTop", scale: 10, opacity: 1});

  const positionCss = {
    leftTop: { position: "relative", left: "15px", top: "15px" },
    centerTop: { position: "relative", left: "50%", top: "15px", transform: "translate(-50%, 0)" },
    rightTop: { position: "relative", left: "100%", top: "15px", transform: "translate(-105%, 0)"},
    leftCenter: { position: "relative", left: "15px", top: "50%", transform: "translate(0, -50%)"},
    rightcenter: { position: "relative", left: "100%", top: "50%", transform: "translate(-105%, -50%)" },
    bottomLeft: { position: "relative", left: "15px", top: "100%", transform: "translate(0, -105%)" },
    bottomCenter: { position: "relative", left: "50%", top: "100%", transform: "translate(-50%, -105%)" },
    bottomRight: { position: "realtive", left: "100%", top: "100%", transform: "translate(-105%, -105%)" },
    center: { position: "relative", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }
  }

  const handleClickOpen = () => {
    setOpen2(true);
  };
  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClick = () => {
    setState(true);
  };
  const handleClose = () => {
    setState(false);
  };

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    function handleTabsOrientation1() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation1("vertical")
        : setTabsOrientation1("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    handleTabsOrientation1();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const handleSetTabValue1 = (event, newValue) => setTabValue1(newValue);

  useEffect(() => {
    fetchTheme();
    fetchUserById(userId, accountId);
    fetchTeam(accountId);
    getContentByAccount(accountId);
  }, []);

  useEffect(() => {
    if(teamDeleted) {
      fetchTeam(accountId);
    }
  }, [teamDeleted]);

  const showFacebookPages = (data) => {
    setOpenF(true);
    setType("fb");
    setFacebookPages(data);
  }

  const getFacebookPages = (accessToken, userId) => {
    window.FB.api(
      `${userId}/accounts?fields=picture,name,pageId,access_token&access_token=${accessToken}`,
      async (data) => {
        showFacebookPages(data.data)
      }
    )
  }

  const getInstaPages = async (accessToken, userId) => {
    window.FB.api(
      `${userId}/accounts?fields=access_token,picture,instagram_business_account{id,ig_id,name,username}&access_token=${accessToken}`,
      async (data) => {
        setInstaPages(data.data.filter(item => item.instagram_business_account));
        setOpenF(true);
        setType("insta");
      }
    )
  }

  const submitHandle = () => {
    setErrors(validate(selected));
  };
  const validate = (selected) => {
    let errorss = {};
    let isError = false;
    if (!selected.id && !selected.pageId) {
      isError = true;
      errorss.pageId = "Please select page to continue.";
    }
    if (!selected.cta) {
      isError = true;
      errorss.cta = "Profile Call to action is required";
    }
    if (!selected.hashtags) {
      isError = true;
      errorss.hashtags = "Hashtags are required.";
    }
    if (!isError) {
      if (!selected.id) {
        addProfile(selected);
      } else {
        updateProfile({ id: selected.id, cta: selected.cta, hashtags: selected.hashtags, firstComment: selected.firstComment });
      }
    }
    return errorss;
  };

  const api = createApi({
    accessKey: "r-_TgsNnHJJMn0xnfdCD0ZO1vqQM79WpS6_bq-D1ZzQ"
  });
  
  useEffect(() => {
    api.search
      .getCollections({ query: "abstract", page: 1, perPage: 15})
      .then(result => {
        setCollections(result);
      })
      .catch(() => {
      });
  }, []);

  useEffect(() => {
    if (isSaved) {
      fetchUserById(userId, accountId);
        setOpen(false);
        setOpen1(false);
        setOpen2(false);
    }
  }, [isSaved]);

  const onImageChange = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    )
    updateAccount({ logo: event.target.files[0] }, user && Array.isArray(user.accounts) && user.accounts[0].id);
  };

  function getSelectedTheme () {
    let x = themes.filter(y => y.id == selectedTheme);
    return x.length > 0 ? setThemeS(x[0]) : setThemeS({});
  }

  useEffect(() => {
      if (Array.isArray(themes)) {
        getSelectedTheme();
      }
  }, [themes]);

  const handleClose1 = () => {
    setOpen1(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loading loading={isLoading} />
      <DeleteConfirm
        deleteTeam={deleteTeam}
        open={deleteOpen}
        selected={selected}
        onClose={() => {
          setSelected({});
          setDeleteOpen(false);
        }}
      />
      <SelectPagesDialog
        open={openF}
        setOpen={setOpenF}
        onClose={() => {
          setOpenF(false);
          setSelected(emptyProfile);
        }}
        submitHandle={submitHandle}
        facebookPages={facebookPages}
        instaPages={instaPages}
        type={type}
        selected={selected}
        setSelected={setSelected}
        errors={errors}
        setErrors={setErrors}
      />
      <GooglePagesDialog open={openG} setOpen={setOpenG} submitHandle={submitHandle} />
      <ThemeDialog open={open} setOpen={setOpen} />
      <TopicsDialog open={openD} setOpen={setOpenD} />
      <ColorDialog open={openC} setOpen={setOpenC} />
      <WaterMarkDialog open={openE} setOpen={setOpenE} />
      <Grid container spacing={2}>
        <Grid xs={12} lg={8} item>
          <Header user={user}>
            <Grid item xs={12}>
              <AppBar style={{ marginTop: "47px" }} position="static">
                <MDBox>
                  <Tabs
                    style={{ background: "#F5F5F5" }}
                    orientation={tabsOrientation}
                    value={tabValue}
                    onChange={handleSetTabValue}
                  >
                    <Tab label="Business and Social" {...a11yProps(0)} />
                    <Tab label="Branding and Themes" {...a11yProps(1)} />
                    <Tab label="View Team" {...a11yProps(2)} />
                    <Tab label="History" {...a11yProps(3)} />
                  </Tabs>
                </MDBox>
              </AppBar>
            </Grid>
            <TabPanel value={tabValue} index={0}>
              <Grid container>
                <Grid item xs={12} md={6} lg={6} pt={3}>
                  <MDBox>
                    <MDTypography style={typoText}
                      sx={{marginBottom: "14px"}} >
                      Owner Name:
                      <MDAvatar
                        src={user && user.dp}
                        alt="profile-image"
                        size="xs"
                        shadow="sm"
                        style={{ marginLeft: "11px" }}
                      />
                      <span className={classes.profileText}
                        style={{marginLeft: "11px"}}>
                        {user && `${user.firstName} ${user.lastName}` }
                      </span>
                    </MDTypography>
                    <MDTypography style={typoText}
                      sx={{ marginBottom: "10px" }} >
                      Social:{" "}
                      <FacebookRoundedIcon
                        style={{
                          marginRight: "12px",
                          marginLeft: "11px",
                          height: "18px",
                        }}
                      />
                      <InstagramIcon style={{ height: "18px" }} />
                      <MDButton
                        variant="text"
                        style={{
                          fontWeight: "700",
                          fontSize: "14px",
                          color: "#09032B",
                          marginTop: "-11px",
                          marginLeft: "-10px",
                        }}
                      >
                        <Icon style={{ color: "#09032B", fontWeight: "700" }}>
                          add
                        </Icon>
                        &nbsp;Add social
                      </MDButton>
                    </MDTypography>
                    <MDTypography style={typoText}
                      sx={{marginBottom: "14px"}} >
                      Created Date:{" "}
                      <span className={classes.profileText}
                        style={{marginLeft: "11px"}}>
                        {user && user.accounts && Array.isArray(user.accounts) && preetyDate(user.accounts[0].createdAt)}
                      </span>
                    </MDTypography>
                    <MDTypography style={typoText}
                      sx={{marginBottom: "14px"}} >
                      Mobile:{" "}
                      <span className={classes.profileText}
                        style={{marginLeft: "11px"}}>
                        {user && user.accounts && Array.isArray(user.accounts) && user.accounts[0].phone}
                      </span>
                    </MDTypography>
                    <MDTypography style={typoText}
                      sx={{marginBottom: "14px"}} >
                      Email:{" "}
                      <span className={classes.profileText}
                        style={{marginLeft: "11px"}}>
                        {user && user.accounts && Array.isArray(user.accounts) && user.accounts[0].email}
                      </span>
                    </MDTypography>
                    <MDTypography style={typoText}
                      sx={{marginBottom: "14px"}} >
                      Location: 
                      <span className={classes.profileText} style={{marginLeft: "11px"}}>
                      {user && user.accounts && Array.isArray(user.accounts) && user.accounts[0].location}
                      </span>
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid
                    sx={{
                      "& .css-4jxqa8-MuiGrid-root": {
                        maxWidth: "85%",
                      },
                    }}
                  >
                    <Grid xs={12} md={6} item>
                      <MDBox pt={1} px={1}>
                        <MDTypography variant="h6" fontWeight="medium">
                          Payment Method
                        </MDTypography>
                      </MDBox>
                      <MDBox p={1} ml="auto">
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <MDBox style={mdbox}
                              borderRadius="lg" p={2}
                              sx={{
                                border: ({
                                  borders: { borderWidth, borderColor },
                                }) => `${borderWidth[1]} solid ${borderColor}`,
                                width: "160%",
                              }}
                            >
                              <MDBox mr={2}
                                component="img"
                                src={masterCardLogo}
                                alt="master card"
                                width="10%"  
                              />
                              <MDTypography variant="body2" fontWeight="medium">
                                ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;7852
                              </MDTypography>
                              <MDBox ml="auto" lineHeight={0} color="grey">
                                <Tooltip title="Edit Card" placement="top">
                                  <Icon
                                    sx={{ cursor: "pointer", color: "#344767" }}
                                    fontSize="small"
                                  >
                                    edit
                                  </Icon>
                                </Tooltip>
                              </MDBox>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox p={1}
                        sx={{
                          "& .css-ofrcno-MuiGrid-root": {
                            width: "calc(100% + 82px)",
                          },
                        }}
                      >
                        <MDBox pt={1} pb={2}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <MDTypography variant="h6" fontWeight="medium">
                            Watermark
                          </MDTypography>
                        </MDBox>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <MDAvatar
                              variant="rounded"
                              src={user && user.accounts && Array.isArray(user.accounts) && user.accounts[0].logo}
                              alt="profile-image"
                              size="xl"
                              shadow="sm"
                            />
                          </Grid>
                          <Grid item>
                            <MDBox height="100%" lineHeight={1}>
                              <MDTypography style={topHead}
                                sx={{fontSize: "14px"}} >
                                {user && user.accounts && Array.isArray(user.accounts) && user.accounts[0].logo}{" "}
                                <ModeEditIcon
                                  onClick={() => setOpenE(true)}
                                  style={{ marginLeft: "37px" }}
                                />
                              </MDTypography>
                              <MDTypography style={topHead}
                                sx={{fontSize: "14px"}} >
                                Position:{" "}
                                <span className={classes.profileText} >
                                  Left
                                </span>
                              </MDTypography>
                            </MDBox>
                          </Grid>
                          <MDBox style={{ marginLeft: "auto" }}>
                            <MDAvatar style={{ width: "109px", height: "98px" }}
                              variant="rounded"
                              src={gray}
                            />
                          </MDBox>
                        </Grid>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Accounts
                  setSelected={setSelected}
                  setType={setType}
                  setOpen={setOpenF}
                  deleteProfile={deleteProfile}
                  profiles={user && Array.isArray(user.accounts) && Array.isArray(user.accounts[0].Profiles) && user.accounts[0].Profiles.length > 0 ? user.accounts[0].Profiles: []}
                />
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
            <Grid item sm={12}>
              <Card
                style={{
                  background: "#FFFFFF",
                  padding: "24px 24px",
                }}
              >
                <MDBox style={{display: "flex"}}>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#344767",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      Business Colors
                    </MDTypography>
                    <MDTypography
                      style={{
                        color: "#848195",
                        fontSize: "14px",
                        fontWeight: "400",
                        marginTop: "6px"
                      }}
                    >
                      Here you can setup and manage your integration settings.
                    </MDTypography>
                  </MDBox>
                  <MDBox style={{marginLeft: "auto"}}>
                  </MDBox>
                </MDBox>
                <MDBox style={{ display: "flex", marginTop: "32px" }}>
                  <MDBox
                    onClick={() => {
                                setColorType("accentColor");
                                setColor(user && Array.isArray(user.accounts) && user.accounts[0].accentColor);
                            }}>
                    <MDBox
                      style={{
                        height: "80px",
                        width: "80px",
                        borderRadius: "8px",
                        background: colorType == "accentColor" ? color : user && Array.isArray(user.accounts) && user.accounts[0].accentColor,
                      }}
                    />
                    <MDTypography
                      style={{
                        color: "#09032B",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Accent
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    onClick={() => {
                      setColorType("darkColor");
                      setColor(user && Array.isArray(user.accounts) && user.accounts[0].darkColor);    
                  }}
                  style={{ marginLeft: "19px" }}>
                    <MDBox
                      style={{
                        height: "80px",
                        width: "80px",
                        borderRadius: "8px",
                        background: colorType == "darkColor" ? color : user && Array.isArray(user.accounts) && user.accounts[0].darkColor,
                      }}
                    />
                    <MDTypography
                      style={{
                        color: "#09032B",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Dark
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    onClick={() => {
                      setColorType("lightColor");
                      setColor(user && Array.isArray(user.accounts) && user.accounts[0].lightColor);    
                    }} 
                  style={{ marginLeft: "19px" }}>
                    <MDBox
                      style={{
                        height: "80px",
                        width: "80px",
                        borderRadius: "8px",
                        background: colorType == "lightColor" ? color : user && Array.isArray(user.accounts) && user.accounts[0].lightColor,
                      }}
                    />
                    <MDTypography
                      style={{
                        color: "#09032B",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Light
                    </MDTypography>
                  </MDBox>
                </MDBox>
                { colorType != "" && (
                        <div style={{ position: 'relative', zIndex: 2 }}>
                            <div
                                style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
                                onClick={ () => {
                                    if (colorType === "accentColor") {
                                        updateAccount({ accentColor : color }, accountId);
                                    } else if (colorType === "darkColor") {
                                        updateAccount({ darkColor : color }, accountId);
                                    } else if (colorType === "lightColor") {
                                        updateAccount({ lightColor : color }, accountId);
                                    } else {
                                    }
                                    setColor("");
                                    setColorType("");
                                }}
                            />
                            <ChromePicker color={ color } onChange={e => setColor(e.hex) } />
                        </div> )}
                <MDBox style={{display: "flex", marginTop: "90px"}}>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#344767",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      Business Theme
                    </MDTypography>
                    <MDTypography
                      style={{
                        color: "#848195",
                        fontSize: "14px",
                        fontWeight: "400",
                        marginTop: "6px"
                      }}
                    >
                      Select your theme
                    </MDTypography>
                  </MDBox>
                  <MDBox style={{marginLeft: "auto"}}>
                    <MDButton
                      onClick={handleClickOpen1}
                      variant="contained"
                      style={{
                        background:
                          "linear-gradient(180deg, #3E3D45 0%, #202020 100%)",
                        color: "#FFFFFF",
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                        textTransform: "capitalize"
                      }}
                    >
                      Change Theme
                    </MDButton>
                  </MDBox>
                </MDBox>
                <MDBox style={{ marginTop: "20px" }}>
                  <MDAvatar
                    variant="square"
                    src={themeS.htmlData}
                    style={{
                      width: "288px",
                      height: "303px",
                      border: "1px solid #000",
                      borderRadius: "8px",
                    }}
                  />
                  <MDTypography
                    style={{
                      color: "#09032B",
                      fontSize: "16px",
                      fontWeight: "500",
                      marginTop: "12px",
                    }}
                  >
                    {themeS.name}
                  </MDTypography>
                  <MDBox style={{display: "flex", marginTop: "10px"}}>
                    {themeS && Array.isArray(themeS.tags) && themeS.tags.map(x => {
                      return (
                        <MDTypography
                          style={{
                            color: "#4CAF50",
                            fontSize: "12px",
                            fontWeight: "700",
                            background: "rgba(76, 175, 80, 0.2)",
                            borderRadius: "8px",
                            padding: "4px 8px",
                            height: "25px",
                            marginRight: "4px"

                          }}
                        >
                          Technology
                        </MDTypography>
                      )
                    })}
                  </MDBox>
                </MDBox>
                <MDBox style={{display: "flex", marginTop: "48px"}}>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#344767",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      Image Collection
                    </MDTypography>
                    <MDTypography
                      style={{
                        color: "#848195",
                        fontSize: "14px",
                        fontWeight: "400",
                        marginTop: "6px"
                      }}
                    >
                      Here you can setup and manage your integration settings.
                    </MDTypography>
                  </MDBox>
                  <MDBox style={{marginLeft: "auto"}}>
                    <MDButton
                      onClick={handleClickOpen}
                      variant="contained"
                      style={{
                        background:
                          "linear-gradient(180deg, #3E3D45 0%, #202020 100%)",
                        color: "#FFFFFF",
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                        textTransform: "capitalize"
                      }}
                    >
                      Change Collection
                    </MDButton>
                  </MDBox>
                </MDBox>
                <MDAvatar
                  variant="square"
                  src={collection && collection.cover_photo.urls.small}
                  style={{ width: "358px", height: "357px", marginTop: "36px" }}
                />
                <MDBox style={{ display: "flex", marginTop: "12px", maxWidth: "358px" }}>
                  <MDTypography
                    style={{
                      fontWeight: "600",
                      fontSize: "15px",
                      color: " #534F6B",
                    }}
                  >
                    {collection && collection.title}
                  </MDTypography>
                  <MDBox style={{marginLeft: "auto"}}>
                    <MDTypography style={{fontWeight: "700", fontSize: "12px", color: "#09032B"}}>View All
                      <ArrowForwardIcon style={{color: "#09032B", marginBottom: "-2px"}} />
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <MDBox>
                <MDTypography style={topHead}
                  sx={{fontSize: "20px"}} >
                  Team Members
                </MDTypography>
                <MDTypography style={topText} >
                  Here you can setup and manage your integration setting.
                </MDTypography>
              </MDBox>
              <Divider style={{ background: "#E9EAED", opacity: "0.7" }} />
              {teams && Array.isArray(teams) && teams.map(x => {
              return (
                <>
                  <Grid container style={{ padding: "8px 20px" }}>
                    <Grid item xs={12} md={6}>
                      <MDBox display="flex" alignItems="center">
                        <MDAvatar src={x.dp} alt="User DP" />
                        <MDBox ml={2}>
                          <MDTypography
                            style={{
                              fontWeight: "700",
                              fontSize: "17px",
                              color: "#344767",
                            }}
                          >
                            {x.firstName} {x.lastName}
                          </MDTypography>
                          <MDTypography
                            style={{
                              fontWeight: "500",
                              fontSize: "12px",
                              color: "#F4233D",
                              marginTop: "3px",
                            }}
                          >
                            {x.accounts && Array.isArray(x.accounts) && x.accounts[0].Account_User.RoleId == 1 ? "OWNER" : "Member"}
                          </MDTypography>
                          <MDTypography
                            style={{
                              fontWeight: "700",
                              fontSize: "14px",
                              color: "#344767",
                              marginTop: "6px",
                            }}
                          >
                            Last Login:{" "}
                            <span
                              style={{
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "#7B809A",
                              }}
                            >
                              {preetyDate(x.lastLogin)}
                            </span>
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDBox
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <MDBox>
                          <MDTypography
                            style={{
                              fontWeight: "700",
                              fontSize: "17px",
                              color: "#344767",
                            }}
                          >
                            0
                          </MDTypography>
                          <MDTypography
                            style={{
                              fontWeight: "400",
                              fontSize: "12px",
                              color: "#AEAEAE",
                            }}
                          >
                            Posts this month
                          </MDTypography>
                        </MDBox>
                        <MDBox>
                          <MDTypography
                            style={{
                              fontWeight: "700",
                              fontSize: "17px",
                              color: "#344767",
                            }}
                          >
                            0
                          </MDTypography>
                          <MDTypography
                            style={{
                              fontWeight: "400",
                              fontSize: "12px",
                              color: "#AEAEAE",
                            }}
                          >
                            Total posts
                          </MDTypography>
                        </MDBox>
                        <MDBox>
                          {x.accounts && Array.isArray(x.accounts) && x.accounts[0].Account_User.RoleId != 1 && (
                            <IconButton
                              onClick={() => {
                                setSelected(x.accounts[0].Account_User);
                                setDeleteOpen(true);
                              }}>
                              <DeleteForeverOutlinedIcon sx={{color: "red"}} />
                            </IconButton>
                          )}
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                  <Divider style={{ background: "#E9EAED", opacity: "0.7" }} />
                </>
              )
            })}
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              {/* <MDBox>
                <MDBox style={{ display: "flex", alignItems: "center" }}>
                  <MDTypography
                    style={{ marginRight: "19px", marginBottom: "13px" }}
                  >
                    Today
                  </MDTypography>
                  <div
                    style={{
                      marginTop: "-8px",
                      borderBottom: "1px solid #E8E8E8",
                      width: "45%",
                    }}
                  />
                </MDBox>

                <Grid container>
                  <Grid item style={{ marginRight: "10px" }}>
                    <MDAvatar
                      src={burceMars}
                      alt="profile-image"
                      size="md"
                      shadow="sm"
                    />
                  </Grid>
                  <Grid item>
                    <MDBox lineHeight={1}>
                      <MDTypography style={headText}
                        sx={{marginBottom: "3px"}} >
                        &quot;Name Here&quot;&nbsp;
                        <span style={{ color: "#7B809A", fontWeight: "400" }}>
                          Created a post waiting for approval
                        </span>
                      </MDTypography>
                      <MDTypography style={text} >
                        22 DEC 7:20 PM
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
                <div style={dividerText} />
                <Grid container>
                  <Grid item style={{ marginRight: "10px" }}>
                    <MDAvatar
                      src={burceMars}
                      alt="profile-image"
                      size="md"
                      shadow="sm"
                    />
                  </Grid>
                  <Grid item>
                    <MDBox lineHeight={1}>
                      <MDTypography style={headText}
                        sx={{marginBottom: "3px"}} >
                        &quot;Name Here&quot;&nbsp;
                        <span style={{ color: "#7B809A", fontWeight: "400" }}>
                          Created a post waiting for approval
                        </span>
                      </MDTypography>
                      <MDTypography style={text} >
                        22 DEC 7:20 PM
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
                <div style={dividerText} />
                <Grid container>
                  <Grid item style={{ marginRight: "10px" }}>
                    <MDAvatar
                      src={burceMars}
                      alt="profile-image"
                      size="md"
                      shadow="sm"
                    />
                  </Grid>
                  <Grid item>
                    <MDBox lineHeight={1}>
                      <MDTypography style={headText}
                        sx={{marginBottom: "3px"}} >
                        &quot;Name Here&quot;&nbsp;
                        <span style={{ color: "#7B809A", fontWeight: "400" }}>
                          Created a post waiting for approval
                        </span>
                      </MDTypography>
                      <MDTypography style={text} >
                        22 DEC 7:20 PM
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
                <div style={dividerText} />
                <Grid container>
                  <Grid item style={{ marginRight: "10px" }}>
                    <MDAvatar
                      src={burceMars}
                      alt="profile-image"
                      size="md"
                      shadow="sm"
                    />
                  </Grid>
                  <Grid item>
                    <MDBox lineHeight={1}>
                      <MDTypography style={headText} 
                        sx={{marginBottom: "3px"}}>
                        &quot;Name Here&quot;&nbsp;
                        <span style={{ color: "#7B809A", fontWeight: "400" }}>
                          Created a post waiting for approval
                        </span>
                      </MDTypography>
                      <MDTypography style={text} >
                        22 DEC 7:20 PM
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
                <div style={dividerText} />
                <Grid container>
                  <Grid item style={{ marginRight: "10px" }}>
                    <MDAvatar
                      src={burceMars}
                      alt="profile-image"
                      size="md"
                      shadow="sm"
                    />
                  </Grid>
                  <Grid item>
                    <MDBox lineHeight={1}>
                      <MDTypography style={headText}
                        sx={{marginBottom: "3px"}} >
                        &quot;Name Here&quot;&nbsp;
                        <span style={{ color: "#7B809A", fontWeight: "400" }}>
                          Created a post waiting for approval
                        </span>
                      </MDTypography>
                      <MDTypography style={text} >
                        22 DEC 7:20 PM
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
                <div style={dividerText} />
                <Grid container>
                  <Grid item style={{ marginRight: "10px" }}>
                    <MDAvatar
                      src={burceMars}
                      alt="profile-image"
                      size="md"
                      shadow="sm"
                    />
                  </Grid>
                  <Grid item>
                    <MDBox lineHeight={1}>
                      <MDTypography style={headText}
                        sx={{marginBottom: "3px"}} >
                        &quot;Name Here&quot;&nbsp;
                        <span style={{ color: "#7B809A", fontWeight: "400" }}>
                          Created a post waiting for approval
                        </span>
                      </MDTypography>
                      <MDTypography style={text} >
                        22 DEC 7:20 PM
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
                <MDBox
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "15px",
                  }}
                >
                  <MDTypography style={{ marginRight: "9px" }}>
                    12 Jan 2022
                  </MDTypography>
                  <div
                    style={{
                      borderBottom: "1px solid #E8E8E8",
                      width: "38%",
                      marginTop: "12px",
                      marginBottom: "12px",
                    }}
                  />
                </MDBox>

                <Grid container>
                  <Grid item style={{ marginRight: "10px" }}>
                    <MDAvatar
                      src={burceMars}
                      alt="profile-image"
                      size="md"
                      shadow="sm"
                    />
                  </Grid>
                  <Grid item>
                    <MDBox lineHeight={1}>
                      <MDTypography style={headText}
                        sx={{marginBottom: "3px"}} >
                        &quot;Name Here&quot;&nbsp;
                        <span style={{ color: "#7B809A", fontWeight: "400" }}>
                          Created a post waiting for approval
                        </span>
                      </MDTypography>
                      <MDTypography style={text} >
                        22 DEC 7:20 PM
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
                <div style={dividerText} />
                <Grid container>
                  <Grid item style={{ marginRight: "10px" }}>
                    <MDAvatar
                      src={burceMars}
                      alt="profile-image"
                      size="md"
                      shadow="sm"
                    />
                  </Grid>
                  <Grid item>
                    <MDBox lineHeight={1}>
                      <MDTypography style={headText}
                        sx={{marginBottom: "3px"}} >
                        &quot;Name Here&quot;&nbsp;
                        <span style={{ color: "#7B809A", fontWeight: "400" }}>
                          Created a post waiting for approval
                        </span>
                      </MDTypography>
                      <MDTypography style={text} >
                        22 DEC 7:20 PM
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
                <div style={dividerText} />
                <Grid container>
                  <Grid item style={{ marginRight: "10px" }}>
                    <MDAvatar
                      src={burceMars}
                      alt="profile-image"
                      size="md"
                      shadow="sm"
                    />
                  </Grid>
                  <Grid item>
                    <MDBox lineHeight={1}>
                      <MDTypography style={headText}
                        sx={{marginBottom: "3px"}} >
                        &quot;Name Here&quot;&nbsp;
                        <span style={{ color: "#7B809A", fontWeight: "400" }}>
                          Created a post waiting for approval
                        </span>
                      </MDTypography>
                      <MDTypography style={text} >
                        22 DEC 7:20 PM
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
                <div style={dividerText} />
                <Grid container>
                  <Grid item style={{ marginRight: "10px" }}>
                    <MDAvatar
                      src={burceMars}
                      alt="profile-image"
                      size="md"
                      shadow="sm"
                    />
                  </Grid>
                  <Grid item>
                    <MDBox lineHeight={1}>
                      <MDTypography style={headText}
                        sx={{marginBottom: "3px"}} >
                        &quot;Name Here&quot;&nbsp;
                        <span style={{ color: "#7B809A", fontWeight: "400" }}>
                          Created a post waiting for approval
                        </span>
                      </MDTypography>
                      <MDTypography style={text} >
                        22 DEC 7:20 PM
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
                <div style={dividerText} />
                <Grid container>
                  <Grid item style={{ marginRight: "10px" }}>
                    <MDAvatar
                      src={burceMars}
                      alt="profile-image"
                      size="md"
                      shadow="sm"
                    />
                  </Grid>
                  <Grid item>
                    <MDBox lineHeight={1}>
                      <MDTypography style={headText}
                        sx={{marginBottom: "3px"}} >
                        &quot;Name Here&quot;&nbsp;
                        <span style={{ color: "#7B809A", fontWeight: "400" }}>
                          Created a post waiting for approval
                        </span>
                      </MDTypography>
                      <MDTypography style={text} >
                        22 DEC 7:20 PM
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
                <div style={dividerText} />
                <Grid container>
                  <Grid item style={{ marginRight: "10px" }}>
                    <MDAvatar
                      src={burceMars}
                      alt="profile-image"
                      size="md"
                      shadow="sm"
                    />
                  </Grid>
                  <Grid item>
                    <MDBox lineHeight={1}>
                      <MDTypography style={headText}
                        sx={{marginBottom: "3px"}} >
                        &quot;Name Here&quot;&nbsp;
                        <span style={{ color: "#7B809A", fontWeight: "400" }}>
                          Created a post waiting for approval
                        </span>
                      </MDTypography>
                      <MDTypography style={text} >
                        22 DEC 7:20 PM
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
                <div style={dividerText} />
              </MDBox> */}
            </TabPanel>
          </Header>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid item>
            <MDBox style={{ borderRadius: "12px", background: "white" }}>
              <MDBox style={{ display: "flex" }}>
                <MDTypography style={topHead}
                  sx={{ fontSize: "16px", padding: "14px" }} >
                  Account and post tracking
                </MDTypography>
                <MDBox sx={{ ml: "auto" }}>
                  <MDButton variant="text" style={topText}
                    component={Link}
                    to="/content-table"
                    sx={{ padding: "14px", textTransform: "capitalize" }}>
                    View All
                  </MDButton>
                </MDBox>
              </MDBox>
              <MDBox style={{ padding: "0 14px 14px 12px" }}>
                <Grid item xs={12}>
                  <AppBar position="static">
                    <Tabs
                      style={{
                        background: "#F5F5F5",
                        border: "1px solid #E9E7E7",
                        borderRadius: "8px",
                      }}
                      orientation={tabsOrientation1}
                      value={tabValue1}
                      onChange={handleSetTabValue1}
                    >
                      <Tab
                        {...a11yPropss(0)}
                        style={{ padding: "6px 20px", marginRight: "10px" }}
                        label="Successfull"
                      />
                      <Tab
                        {...a11yPropss(1)}
                        style={{ padding: "6px 20px", marginRight: "10px" }}
                        label="Undelivered"
                      />
                      <Tab {...a11yPropss(2)} style={{ padding: "6px 20px" }} label="Pending" />
                    </Tabs>
                  </AppBar>
                </Grid>
              </MDBox>
              <TabPanel value={tabValue1} index={0}>
                {contentByAccount && Array.isArray(contentByAccount.published) && contentByAccount.published.map(x => {
                  return (
                  <>
                    <div style={divider} />
                    <MDBox style={{ padding: "0 14px 14px 12px" }}>
                      <Grid container>
                        <Grid item xs={12} md={4}>
                          <img src="" width="100%" />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <MDBox style={{ marginLeft: "8px" }}>
                            <MDBox style={{ display: "flex" }}>
                              <MDBox style={{color: "#AEAEAE", fontSize: "17px"}} >
                                <FacebookRoundedIcon style={socialIcon} />
                                <InstagramIcon style={socialIcon} />
                              </MDBox>
                              <MDBox>
                                <MDTypography style={paraText} >
                                  31 Jan 12.30 AM
                                </MDTypography>
                              </MDBox>
                              <MDBox style={mdboxText} >
                                <MDBox style={{ marginLeft: "auto" }}>
                                  <MoreVertIcon style={{ color: "#7B809A" }} />
                                </MDBox>
                              </MDBox>
                            </MDBox>
                            <MDBox style={{ display: "flex", marginTop: "5px" }}>
                              <Stack direction="row">
                                <MDTypography style={text} >
                                  Approved by:
                                </MDTypography>
                                <MDTypography sx={{ fontSize: "12px", color: "#344767" }}
                                  variant="body1"
                                  fontWeight="medium"
                                >
                                  &nbsp;&quot;Name here&quot;
                                </MDTypography>
                              </Stack>
                            </MDBox>
                            <MDBox style={{ display: "flex", marginTop: "8px" }}>
                              <MDAvatar style={imageText}
                                src={burceMars}
                                alt="profile-image"
                                size="sm"
                              />
                              <MDTypography style={text} >
                                &nbsp;Posted by
                              </MDTypography>
                              <MDTypography style={{ fontSize: "12px", fontWeight: "400" }}
                                variant="body1"
                              >
                                &nbsp;&quot;Name here&quot;
                              </MDTypography>
                            </MDBox>
                            <MDTypography style={contentText} >
                              Reference site about Loreum Ipsum, giving inform on its
                              origins, as well as a random Lipsum genera...
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </>
                  )})}
              </TabPanel>
              <TabPanel value={tabValue1} index={1}>
              {contentByAccount && Array.isArray(contentByAccount.failed) && contentByAccount.failed.map(x => {
                  return (
                  <>
                    <div style={divider} />
                    <MDBox style={{ padding: "0 14px 14px 12px" }}>
                      <Grid container>
                        <Grid item xs={12} md={4}>
                          <img src="" width="100%" />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <MDBox style={{ marginLeft: "8px" }}>
                            <MDBox style={{ display: "flex" }}>
                              <MDBox style={{color: "#AEAEAE", fontSize: "17px"}} >
                                <FacebookRoundedIcon style={socialIcon} />
                                <InstagramIcon style={socialIcon} />
                              </MDBox>
                              <MDBox>
                                <MDTypography style={paraText} >
                                  31 Jan 12.30 AM
                                </MDTypography>
                              </MDBox>
                              <MDBox style={mdboxText} >
                                <MDBox style={{ marginLeft: "auto" }}>
                                  <MoreVertIcon style={{ color: "#7B809A" }} />
                                </MDBox>
                              </MDBox>
                            </MDBox>
                            <MDBox style={{ display: "flex", marginTop: "5px" }}>
                              <Stack direction="row">
                                <MDTypography style={text} >
                                  Approved by:
                                </MDTypography>
                                <MDTypography sx={{ fontSize: "12px", color: "#344767" }}
                                  variant="body1"
                                  fontWeight="medium"
                                >
                                  &nbsp;&quot;Name here&quot;
                                </MDTypography>
                              </Stack>
                            </MDBox>
                            <MDBox style={{ display: "flex", marginTop: "8px" }}>
                              <MDAvatar style={imageText}
                                src={burceMars}
                                alt="profile-image"
                                size="sm"
                              />
                              <MDTypography style={text} >
                                &nbsp;Posted by
                              </MDTypography>
                              <MDTypography style={{ fontSize: "12px", fontWeight: "400" }}
                                variant="body1"
                              >
                                &nbsp;&quot;Name here&quot;
                              </MDTypography>
                            </MDBox>
                            <MDTypography style={contentText} >
                              Reference site about Loreum Ipsum, giving inform on its
                              origins, as well as a random Lipsum genera...
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </>
                  )})}
              </TabPanel>
              <TabPanel value={tabValue1} index={2}>
                {contentByAccount && Array.isArray(contentByAccount.draft) && contentByAccount.draft.map(x => {
                  return (
                  <>
                    <div style={divider} />
                    <MDBox style={{ padding: "0 14px 14px 12px" }}>
                      <Grid container>
                        <Grid item xs={12} md={4}>
                          <img src={x.images != null && JSON.parse(x.images) && Array.isArray(JSON.parse(x.images)) && JSON.parse(x.images)[0].image } width="100%" />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <MDBox style={{ marginLeft: "8px" }}>
                            <MDBox style={{ display: "flex" }}>
                              <MDBox style={{color: "#AEAEAE", fontSize: "17px"}} >
                                <FacebookRoundedIcon style={socialIcon} />
                                <InstagramIcon style={socialIcon} />
                              </MDBox>
                              <MDBox>
                                <MDTypography style={paraText} >
                                  {Array.isArray(x.Schedules) && x.Schedules.length > 0 ? preetyDate(x.Schedules[0].scTime) : "-"}
                                </MDTypography>
                              </MDBox>
                              <MDBox style={mdboxText} >
                                <MDBox style={{ marginLeft: "auto" }}>
                                  
                                  {/* <MoreVertIcon style={{ color: "#7B809A" }} /> */}
                                </MDBox>
                              </MDBox>
                            </MDBox>
                            <MDBox style={{ display: "flex", marginTop: "5px" }}>
                              <Stack direction="row">
                                <MDTypography style={text} >
                                  Approved by:
                                </MDTypography>
                                <MDTypography sx={{ fontSize: "12px", color: "#344767" }}
                                  variant="body1"
                                  fontWeight="medium"
                                >
                                  &nbsp;&quot;{x.approvedBy}&quot;
                                </MDTypography>
                              </Stack>
                            </MDBox>
                            <MDBox style={{ display: "flex", marginTop: "8px" }}>
                              <MDAvatar style={imageText}
                                src={burceMars}
                                alt="profile-image"
                                size="sm"
                              />
                              <MDTypography style={text} >
                                &nbsp;Created by
                              </MDTypography>
                              <MDTypography style={{ fontSize: "12px", fontWeight: "400" }}
                                variant="body1"
                              >
                                &nbsp;&quot;{x.createdBy}&quot;
                              </MDTypography>
                            </MDBox>
                            <MDTypography style={contentText} >
                              {JSON.parse(x.content).all}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </>
                  )})}
              </TabPanel>
            </MDBox>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open2}
        onClose={() => setOpen2(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <MDBox
          style={{
            display: "grid",
            textAlign: "left",
            padding: "32px 12px",
          }}
        >
          <Grid container>
            <Grid item xs={10}>
              <DialogTitle id="responsive-dialog-title">
                See and save our vast image library
              </DialogTitle>
              <MDTypography
                style={{
                  color: "#7B809A",
                  fontSize: "14px",
                  fontWeight: "600",
                  marginLeft: "18px",
                  marginTop: "-16px",
                }}
              >
                Here you can setup and manage your integration settings.
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDBox
                onClick={() => setOpen2(false)}
                style={{
                  background: "#E6E8EB",
                  height: "40px",
                  width: "40px",
                  borderRadius: "100px",
                  float: "right",
                  marginTop: "24px",
                  marginRight: "24px",
                }}
              >
                <ClearIcon style={{ margin: "10px 10px" }} />
              </MDBox>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "5px" }}>
            {collections && !collections.errors && collections.response && collections.response.results.map(x => {
              return (
                <Grid
                        item
                        xs={12}
                        md={4}
                        onClick={() => {
                            setCollection(x);
                        }}>
                    <img src={ x.cover_photo.urls.small } style={{ width: "100%", height: "300px", objectFit:"cover" }} width="100%" />
                    {collection != null && collection.id == x.id && <img style={{ position: "relative", left: "calc(50% - 32px)", top: "-200px" }} src={choooseImage} />}
                    <Grid item>
                        <MDBox style={collection != null && collection.id == x.id ? { display: "flex", marginTop: "-64px" } : { display: "flex" }}>
                        <MDTypography
                            style={{
                            fontWeight: "600",
                            fontSize: "15px",
                            color: " #534F6B",
                            }}
                        >
                            {x.title}
                        </MDTypography>
                        <MDBox sx={{ ml: "auto", display: 'flex' }}>
                            <Typography sx={{ fontSize: '12px', fontWeight: 700 }}>View All</Typography>
                            <BookTwoToneIcon style={{ color: "#7B809A" }} />
                        </MDBox>
                        </MDBox>
                    </Grid>
                    </Grid>
              )
            })}
          </Grid>
          
          <MDBox style={{ marginTop: "15px" }}>
            <MDButton
              onClick={() => {
                updateAccount({ collection : JSON.stringify(collection) }, accountId);
                handleClose();
            }}
              variant="contained"
              style={{
                background: "linear-gradient(180deg, #3E3D45 0%, #202020 100%)",
                color: "#fff",
                minWidth: "295px",
              }}
            >
              Save and continue
            </MDButton>
          </MDBox>
        </MDBox>
      </Dialog>
      <Dialog
            fullWidth
            open={open1}
            onClose={handleClose1}
            aria-labelledby="responsive-dialog-title"
        >
            <MDBox
            style={{
                display: "grid",
                textAlign: "left",
                padding: "32px 12px",
            }}
            >
            <Grid container>
                <Grid item sm={6} md={6} lg={6}>
                <DialogTitle id="responsive-dialog-title">
                    {"Select Your Theme"}
                </DialogTitle>
                <MDTypography
                    style={{
                    color: "#7B809A",
                    fontSize: "14px",
                    fontWeight: "600",
                    marginLeft: "18px",
                    marginTop: "-16px",
                    }}
                >
                    Here you can setup and manage your integration settings.
                </MDTypography>
                </Grid>
                <Grid item sm={6} md={6} lg={6}>
                <MDBox
                    onClick={handleClose1}
                    style={{
                    background: "#E6E8EB",
                    height: "40px",
                    width: "40px",
                    borderRadius: "100px",
                    float: "right",
                    marginTop: "24px",
                    marginRight: "24px",
                    }}
                >
                    <ClearIcon style={{ margin: "10px 10px" }} />
                </MDBox>
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "15px" }}>
                {themes && Array.isArray(themes) && themes.map(x => {
                    return (
                        <Grid item xs={12} md={4}>
                            <MDBox style={{ }} onClick={() => setSelectedTheme(x.id)}>
                                {selectedTheme != null && selectedTheme == x.id && <img style={{ position: "relative", left: "calc(50% - 32px)", top: "32px", zIndex: 1 }} src={choooseImage} />}
                                <img
                                    width="100%"
                                    src={x.Layouts && Array.isArray(x.Layouts) && x.Layouts.length > 0 ? x.htmlData : ""}
                                    style={
                                        selectedTheme != null && selectedTheme == x.id ? {
                                        border: "1px solid #000",
                                        borderRadius: "8px",
                                        marginTop: "-73px"
                                    } : {
                                        border: "1px solid #000",
                                        borderRadius: "8px",
                                    }}
                                />
                            </MDBox>
                                <MDTypography
                                style={{
                                    color: "#09032B",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    marginTop: "5px",
                                }}
                                >
                                {x.name}
                                </MDTypography>
                        </Grid>
                    )
                })}
            </Grid>
            <MDBox style={{ marginTop: "15px" }}>
                <MDButton
                variant="contained"
                style={{
                    background: "linear-gradient(180deg, #3E3D45 0%, #202020 100%)",
                    color: "#fff",
                    minWidth: "295px",
                }}
                onClick={() => {
                    updateAccount({ ThemeId: selectedTheme }, accountId);
                    handleClose();
                }}
                >
                Save and continue
                </MDButton>
            </MDBox>
            </MDBox>
        </Dialog>
    </DashboardLayout>
  );
}
