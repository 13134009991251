import * as actionType from "./themeActionType";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    bgImg: null,
    themes: [],
    themesAuto: [],
    themeCreated: {},
    layout: {},
    completeTheme: {}
}

export const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.UPLOAD_BG:
        return {
            ...state,
            bgImg: null,
            isError: false,
            isLoading: true,
        };
        case actionType.UPLOAD_BG_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.UPLOAD_BG_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                bgImg: action.payload ? action.payload.data.image : null,
            };
        case actionType.GET_LAYOUT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                layout: {}
            };
        case actionType.GET_LAYOUT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                layout: {}
            };
        case actionType.GET_LAYOUT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                layout: action.payload ? action.payload.data : {},
            };
        case actionType.SAVE_LAYOUT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
            case actionType.SAVE_LAYOUT_FAILURE:
                return {
                    ...state,
                    isError: true,
                    isLoading: false,
                    isSaved: false
                };
            case actionType.SAVE_LAYOUT_SUCCESS:
                return {
                    ...state,
                    isError: false,
                    isLoading: false,
                    isSaved: true
                };
                case actionType.FETCH_THEME:
                    return {
                        ...state,
                        isError: false,
                        isLoading: true,
                    };
                case actionType.FETCH_THEME_FAILURE:
                    return {
                        ...state,
                        isError: true,
                        isLoading: false,
                    };
                case actionType.FETCH_THEME_SUCCESS:
                    return {
                        ...state,
                        isError: false,
                        isLoading: false,
                        themes: action.payload ? action.payload.data.rows : [],
                    };
                case actionType.FETCH_COMPLETE_THEME:
                    return {
                        ...state,
                        isError: false,
                        isLoading: true,
                        completeTheme: {}
                    };
                case actionType.FETCH_COMPLETE_THEME_FAILURE:
                    return {
                        ...state,
                        isError: true,
                        isLoading: false,
                        completeTheme: {}
                    };
                case actionType.FETCH_COMPLETE_THEME_SUCCESS:
                    return {
                        ...state,
                        isError: false,
                        isLoading: false,
                        completeTheme: action.payload ? action.payload.data : {},
                    };
                    case actionType.FETCH_THEME_FOR_AUTOCOMPLETE:
                    return {
                        ...state,
                        isError: false,
                        isLoading: true,
                    };
                case actionType.FETCH_THEME_FOR_AUTOCOMPLETE_FAILURE:
                    return {
                        ...state,
                        isError: true,
                        isLoading: false,
                    };
                case actionType.FETCH_THEME_FOR_AUTOCOMPLETE_SUCCESS:
                    return {
                        ...state,
                        isError: false,
                        isLoading: false,
                        themesAuto: action.payload ? action.payload.data : [],
                    };
                    case actionType.CREATE_THEME:
                    return {
                        ...state,
                        isError: false,
                        isLoading: true,
                        isSaved: false,
                        themeCreated: {}
                    };
                    case actionType.CREATE_THEME_FAILURE:
                        return {
                            ...state,
                            isError: true,
                            isLoading: false,
                            isSaved: false,
                            themeCreated: {}
                        };
                    case actionType.CREATE_THEME_SUCCESS:
                        return {
                            ...state,
                            isError: false,
                            isLoading: false,
                            isSaved: true,
                            themeCreated: action.payload.data
                        };
                        case actionType.UPDATE_THEME:
                            return {
                                ...state,
                                isError: false,
                                isLoading: true,
                                isSaved: false
                            };
                            case actionType.UPDATE_THEME_FAILURE:
                                return {
                                    ...state,
                                    isError: true,
                                    isLoading: false,
                                    isSaved: false
                                };
                            case actionType.UPDATE_THEME_SUCCESS:
                                return {
                                    ...state,
                                    isError: false,
                                    isLoading: false,
                                    isSaved: true
                                };
                                case actionType.DELETE_THEME:
                                    return {
                                        ...state,
                                        isError: false,
                                        isLoading: true,
                                        isSaved: false
                                    };
                                    case actionType.DELETE_THEME_FAILURE:
                                        return {
                                            ...state,
                                            isError: true,
                                            isLoading: false,
                                            isSaved: false
                                        };
                                    case actionType.DELETE_THEME_SUCCESS:
                                        return {
                                            ...state,
                                            isError: false,
                                            isLoading: false,
                                            isSaved: true
                                        };
        default: 
            return state
    }
}