import * as actionTypes from "./adminActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const adminLogin = (data) => {
      return (dispatch) => {
        dispatch(adminLoginInit());
        axios.post(`${baseUrl}/admin/authenticate`, data)
        .then((response)=>{
            sessionStorage.setItem("token",response.data.data.token);
            sessionStorage.setItem("admin", JSON.stringify(response.data.data));
            dispatch(adminLoginSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error); 
            dispatch(adminLoginFailure());
        })
    };
};

export const adminLoginInit = () => ({
    type: actionTypes.ADMIN_LOGIN
});

export const adminLoginFailure = data => ({
    type: actionTypes.ADMIN_LOGIN_FAILURE
});

export const adminLoginSuccess = data => ({
    type: actionTypes.ADMIN_LOGIN_SUCCESS,
    payload: { data }
});

export const authenticateUser = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } };
    return (dispatch) => {
      dispatch(authenticateUserInit());
      axios.post(`${baseUrl}/user/authenticate-by-admin`, data, config)
      .then((response)=>{
          if(response.data.message === "Success"){
              dispatch(authenticateUserSuccess(response.data.data));
          } else{
              dispatch(authenticateUserFailure());
          }
      })
      .catch(function (error) {
          dispatch(authenticateUserFailure());
      })
  };
};

export const authenticateUserInit = () => ({
    type: actionTypes.AUTHENTICATE_USER
});

export const authenticateUserFailure = data => ({
    type: actionTypes.AUTHENTICATE_USER_FAILURE
});

export const authenticateUserSuccess = data => ({
    type: actionTypes.AUTHENTICATE_USER_SUCCESS,
    payload: { data }
});