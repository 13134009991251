import SubscriptionPage from './subscription-billing.component';
import { connect } from 'react-redux';
import { addSubscription, updateSubscription, fetchSubscriptions } from './../../api/subscription/subscriptionAction';

const mapDispatchToProps = {
    addSubscription,
    updateSubscription,
    fetchSubscriptions
}

const mapStateToProps = state => ({
    subscriptions: state.subscriptionPage.subscriptions,
    isSaved: state.subscriptionPage.isSaved,
    isLoading: state.subscriptionPage.isLoading,
});

export const SubscriptionPageContainer = connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);