import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import MDButton from "../../../shared/components/MDButton";
import FormField from "../../../shared/components/FormField";
import ChangePasswordRequestDialog from "./change-password-request-dialog";
import ClearIcon from "@mui/icons-material/Clear";

const topHead = {
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "140%",
  color: "#344767",
}
const topText = {
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "140%",
  color: "#344767",
}
const mdbox = {
  background: "#E6E8EB",
  height: "40px",
  width: "40px",
  borderRadius: "100px",
}
const button = {
  background: "#09032B",
  color: "#FFFFFF",
  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  width: "355px",
  fontWeight: "700",
  fontSize: "12px"
}
const box = {
  marginTop: "40px",
  justifyContent: "center",
  alignIitems: "center",
  display: "flex",
}

export default function ChangePasswordDialog(props) {
  // const classes = useStyles();
  const { open, setOpen } = props;
  const [openC, setOpenC] = React.useState(false);
  const mdText2 = {
    color: "red",
    fontSize: "12px",
    fontWeight: 400,
  };
  const [values, setValues] = React.useState({
    password: "",
    verifypassword: "",
  });

  const [errors, setErrors] = React.useState({});

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const submitHandle = (event) => {
    event.preventDefault();
    setErrors(validate(values));
   
  };
  const validate = (values) => {
    let errorss = {};
    let isError = false;
    if (!values.password) {
      isError = true;
      errorss.password = "Password is required";
    }
    if (values.password !== values.verifypassword) {
        isError = true;
        errorss.verifypassword = "Password and Verify Password does not match.";
      }
    if (!values.verifypassword) {
        isError = true;
        errorss.verifypassword = "Verify Password is required";
      }
    if (!isError) {
      setOpenC(true);
    }
    return errorss;
  };

  return (
    <div>
    <ChangePasswordRequestDialog open={openC} setOpen={setOpenC} />
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox>
                <MDTypography style={topHead} >
                  Change Password
                </MDTypography>
                <MDTypography style={topText} >
                  Set a strong password to keep your account safe.
                </MDTypography>
              </MDBox>
              <MDBox style={mdbox} >
                <ClearIcon  onClick={() => setOpen(false)}
                  style={{ margin: "12px 12px" }} 
                />
              </MDBox>
            </MDBox>
            <Grid container spacing={3} style={{marginTop: "15px"}}>
              <Grid item xs={12}>
                <FormField
                  label="Change password"
                  type="password"
                  name="password"
                  placeholder="*&nbsp;&nbsp;*&nbsp;&nbsp;*&nbsp;&nbsp;*&nbsp;&nbsp;*&nbsp;&nbsp;*&nbsp;&nbsp;"
                  value={values.password}
                  onChange={handleChange}
                />
                {errors.password && (
                  <MDTypography style={mdText2}>{errors.password}</MDTypography>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormField
                  label="Verify password"
                  type="password"
                  name="verifypassword"
                  placeholder="*&nbsp;&nbsp;*&nbsp;&nbsp;*&nbsp;&nbsp;*&nbsp;&nbsp;*&nbsp;&nbsp;*&nbsp;&nbsp;"
                  value={values.verifypassword}
                  onChange={handleChange}
                />
                {errors.verifypassword && (
                  <MDTypography style={mdText2}>{errors.verifypassword}</MDTypography>
                )}
              </Grid>
            </Grid>
            <MDBox style={box} >
              <MDButton style={button} fullWidth
                onClick={submitHandle} >
                CHANGE PASSWORD
              </MDButton>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
