export const CREATE_MASTER_CONTENT = "CREATE_MASTER_CONTENT";
export const CREATE_MASTER_CONTENT_FAILURE = "CREATE_MASTER_CONTENT_FAILURE";
export const CREATE_MASTER_CONTENT_SUCCESS = "CREATE_MASTER_CONTENT_SUCCESS";

export const UPDATE_MASTER_CONTENT = "UPDATE_MASTER_CONTENT";
export const UPDATE_MASTER_CONTENT_FAILURE = "UPDATE_MASTER_CONTENT_FAILURE";
export const UPDATE_MASTER_CONTENT_SUCCESS = "UPDATE_MASTER_CONTENT_SUCCESS";

export const FETCH_MASTER_CONTENTS = "FETCH_MASTER_CONTENTS";
export const FETCH_MASTER_CONTENTS_FAILURE = "FETCH_MASTER_CONTENTS_FAILURE";
export const FETCH_MASTER_CONTENTS_SUCCESS = "FETCH_MASTER_CONTENTS_SUCCESS";

export const DELETE_MASTER_CONTENT = "DELETE_MASTER_CONTENT";
export const DELETE_MASTER_CONTENT_FAILURE = "DELETE_MASTER_CONTENT_FAILURE";
export const DELETE_MASTER_CONTENT_SUCCESS = "DELETE_MASTER_CONTENT_SUCCESS";
