import React, { useEffect } from 'react';
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";
import Loading from '../../shared/Loading';
import DataTable from "../../shared/components/DataTable";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Card from "./../businessAccount/component/card";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Stack from "@mui/material/Stack";
import MDBox from "./../../shared/components/MDBox";
import MDTypography from "./../../shared/components/MDTypography";
import ProductCell from "./component/product-cell.component";
import Wordpress from "../../assets/images/businessdetails/Wordpress.png";
import owner1 from "../../assets/images/owners/owner1.png";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

const freesubs = {
  backgroundColor: "#CDFFDB",
  borderRadius: "6px",
  padding: "5px 5px",
  color: "#858585",
};
const preplan = {
  backgroundColor: "#FFD8DD",
  borderRadius: "6px",
  padding: "5px 10px",
  color: "#858585",
};
const standerdplan = {
  backgroundColor: "#F7FFC7",
  borderRadius: "6px",
  padding: "5px 10px",
  color: "#858585",
};
const freeplan = {
  backgroundColor: "#CDFFDB",
  borderRadius: "6px",
  padding: "5px 5px",
  color: "#858585",
};

const viewdetails = {
  fontWeight: "600",
  fontSize: "14px",
};

const doticon = {
  width: "16px",
  height: "16px",
  marginBottom: "-3px",
};
const iconstyle = {
  width: "20px",
  height: "20px",
  color: "#7B809A",
};
const menuText = {
  fontWeight: "400", 
  fontSize: "14px", 
  color: "#534F6B"
}
const divider = {
  borderBottom: "1px solid #E9EAED",
  width: "100%",
}
const itemText = {
  fontWeight: "700", 
  fontSize: "14px", 
  color: "#344767"
}

export function BusinessAccountPage(props) {
  const { users, fetchAllUsers, isLoading } = props;

  const [data, setData] = React.useState([]);

  const columns = [
      { Header: "BUSINESS DETAILS", accessor: "BUSINESSDETAILS", width: "108px" },
      { Header: "SOCIAL MEDIA", accessor: "SOCIALMEDIA", width: "108px" },
      { Header: "OWNER", accessor: "OWNER", width: "232px" },
      { Header: "SUBSCRIPTION", accessor: "SUBSCRIPTION", width: "160px" },
      { Header: "CREATED DATE", accessor: "CREATEDDATE", width: "130px" },
      { Header: "USERS", accessor: "USERS", width: "106px" },
      { Header: "ACTIONS", accessor: "ACTIONS", width: "203px" },
    ];

  useEffect(() => {
    fetchAllUsers({});
  }, []);

  useEffect(async () => {
    if (users) {
      const filteredData = await Promise.all(users.map(x => {
        return {
          BUSINESSDETAILS: <ProductCell image={x.dp ? x.dp : Wordpress} name={x.firstName} />,
          SOCIALMEDIA: (
            <Stack direction="row" spacing={1}>
              <FacebookIcon style={iconstyle} />
              <InstagramIcon style={iconstyle} />
              <LinkedInIcon style={iconstyle} />
            </Stack>
          ),
          OWNER: <ProductCell image={x.dp ? x.dp : owner1} name={`${x.firstName} ${x.lastName}`} />,
          SUBSCRIPTION: (
            <MDBox width="8rem">
              <MDTypography variant="overline" style={freesubs}>
                Free Subscriptions
              </MDTypography>
            </MDBox>
          ),
          CREATEDDATE: "Today",
          USERS: <Card members={[x.dp ? x.dp : owner1]} />,
          ACTIONS: (
            <>
              <MDTypography variant="overline" style={viewdetails} component={Link} to="/business-details">
                View Details 
              </MDTypography>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <MoreVertIcon {...bindTrigger(popupState)}
                      style={doticon}
                    />
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem style={itemText} >Select a action</MenuItem>
                      <div style={divider} />
                      <MenuItem onClick={popupState.close} style={menuText}>
                        Change Password
                      </MenuItem>
                      <MenuItem onClick={popupState.close} style={menuText}>
                        Upgrade Subscripition
                      </MenuItem>
                      <MenuItem onClick={popupState.close} style={menuText}>
                        Archive Account
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </>
          ),
        }
      }));

      

      setData(filteredData);
    }
  }, [users]);

  return (
    <DashboardLayout>
      <Loading loading={isLoading} />
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
        </MDBox>
        {data && (
          <DataTable table={{columns: columns, rows: data}} canSearch />
        )}
      </MDBox>
    </DashboardLayout>
  );
}
