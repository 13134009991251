import { useState } from 'react';
import MDBox from "../../shared/components/MDBox";
import FormField from "../../shared/components/FormField";
import Grid from "@mui/material/Grid";
import MDTypography from "../../shared/components/MDTypography";
import MDButton from "./../../shared/components/MDButton";
import BorderLeftOutlinedIcon from '@mui/icons-material/BorderLeftOutlined';
import BorderVerticalOutlinedIcon from '@mui/icons-material/BorderVerticalOutlined';
import BorderHorizontalOutlinedIcon from '@mui/icons-material/BorderHorizontalOutlined';
import BorderRightOutlinedIcon from '@mui/icons-material/BorderRightOutlined';
import BorderTopOutlinedIcon from '@mui/icons-material/BorderTopOutlined';
import BorderBottomOutlinedIcon from '@mui/icons-material/BorderBottomOutlined';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import LineWeightOutlinedIcon from '@mui/icons-material/LineWeightOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { ColorPicker, createColor } from 'material-ui-color';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from "@mui/styles";
import HeightIcon from '@mui/icons-material/Height';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SubjectIcon from '@mui/icons-material/Subject';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import LockIcon from '@mui/icons-material/Lock';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const mdButton = {
    textTransform: "capitalize",
    color: "#344767",
    border: "1px solid #344767",
    borderRadius: "8px",
    fontWeight: 700,
    fontSize: "12px"
}

const useStyles = makeStyles({
    radioButton: {
        "& .css-z8dsix-MuiButtonBase-root-MuiRadio-root.Mui-checked .MuiSvgIcon-root": {
            borderColor: "#1B1C29",
        },
        "& .css-z8dsix-MuiButtonBase-root-MuiRadio-root:after": {
            backgroundImage: "linear-gradient(195deg, #1B1C29, #1B1C29)"
        },
    },
    platformName: {
        fontSize: "14px!important",
    },
    platformSize: {
        fontSize: "14px!important"
    }
  });

  const colorClasses = ["colorAccent", "colorDark", "colorLight"];
  const borderClasses = ["borderAccent", "borderDark", "borderLight"];
  const bgClasses = ["bgAccent", "bgDark", "bgLight"];
  const alignmentClasses = ["hvcenter", "hcenter", "hleft", "hright", "vcenter", "vtop", "vbottom"];
  const fontList = [
    { name: 'Abril Fatface', value: 'Abril Fatface' },
    { name: 'Arial', value: 'Arial' },
    { name: 'Alegreya', value: 'Alegreya'},
    { name: 'Alegreya Sans', value: 'Alegreya Sans'},
    { name: 'Archivo Narrow', value: 'Archivo Narrow'},
    { name: 'BioRhyme', value: 'BioRhyme'},
    { name: 'Bebas Neue', value: 'Bebas Neue' },
    { name: 'Caveat Brush', value: 'Caveat Brush' },
    { name: 'Cardo', value: 'Cardo'},
    { name: 'Comic Sans MS', value: 'Comic Sans MS' },
    { name: 'Courier New', value: 'Courier New' },
    { name: 'Chivo', value: 'Chivo'},
    { name: 'Cormorant', value: 'Cormorant'},
    { name: 'Crushed', value: 'Crushed' },
    { name: 'Dancing Script', value: 'Dancing Script' },
    { name: 'DM Sans', value: 'DM Sans'},
    { name: 'Eczar', value: 'Eczar'},
    { name: 'Elsie', value: 'Elsie' },
    { name: 'Fira Sans', value: 'Fira Sans'},
    { name: 'Georgia', value: 'Georgia' },
    { name: 'Helvetica', value: 'Helvetica' },
    { name: 'IBM Plex Sans', value: 'IBM Plex Sans'},
    { name: 'Inconsolata', value: 'Inconsolata'},
    { name: 'Inknut Antiqua', value: 'Inknut Antiqua'},
    { name: 'Inter', value: 'Inter'},
    { name: 'Karla', value: 'Karla'},
    { name: 'Lato', value: 'Lato' },
    { name: 'Libre Baskerville', value: 'Libre Baskerville'},
    { name: 'Libre Franklin', value: 'Libre Franklin'},
    { name: 'Lobster', value: 'Lobster' },
    { name: 'Lora', value: 'Lora'},
    { name: 'Manrope', value: 'Manrope'},
    { name: 'Merriweather', value: 'Merriweather'},
    { name: 'Monoton', value: 'Monoton' },
    { name: 'Montserrat', value: 'Montserrat'},
    { name: 'Neuton', value: 'Neuton'},
    { name: 'Open Sans', value: 'Open Sans'},
    { name: 'Passions Conflict', value: 'Passions Conflict' },
    { name: 'Playfair Display', value: 'Playfair Display'},
    { name: 'Poppins', value: 'Poppins'},
    { name: 'Proza Libre', value: 'Proza Libre'},
    { name: 'PT Sans', value: 'PT Sans'},
    { name: 'PT Serif', value: 'PT Serif'},
    { name: 'Raleway', value: 'Raleway'},
    { name: 'Roboto', value: 'Roboto' },
    { name: 'Roboto Slab', value: 'Roboto Slab'},
    { name: 'Rubik', value: 'Rubik'},
    { name: 'Sacramento', value: 'Sacramento' },
    { name: 'Source Sans Pro', value: 'Source Sans Pro'},
    { name: 'Source Serif Pro', value: 'Source Serif Pro'},
    { name: 'Space Grotesk', value: 'Space Grotesk'},
    { name: 'Space Mono', value: 'Space Mono'},
    { name: 'Spectral', value: 'Spectral'},
    { name: 'Syne', value: 'Syne'},
    { name: 'Time New Roman', value: 'Time New Roman' },
    { name: 'Trebuchet MS', value: 'Trebuchet MS' },
    { name: 'Work Sans', value: 'Work Sans'},
    { name: 'Zilla Slab Highlight', value: 'Zilla Slab Highlight' }
  ];
export function RightBar(props) {
    const classes = useStyles();
    const [shadow, setShadow] = useState(false);
    const {
        master,
        setMaster,
        name,
        setName,
        masterLayouts,
        setBackgroundImage,
        unsplashSearch,
        setOpen,
        setStyle,
        setDynamicStyle,
        width,
        height,
        setWidth,
        setHeight,
        styled,
        setStyled,
        setZoom,
        setH,
        addAttribute
    } = props;
    return (
        <MDBox style={{ width: 'auto', height: "auto", padding: '16px', background: "#FFFFFF", borderRadius: "12px", boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)"}} >
            {(!styled.wrapperClass || (styled.wrapperClass && styled.wrapperClass.length === 0)) && (
                <>
                <MDTypography style={{fontWeight: "500", fontSize: "16px", color: "#221C40", marginBottom: "24px"}}>Layout</MDTypography>
                <MDBox style={{marginBottom: "24px"}}>
                    <FormField style={{marginTop: "16px", marginBottom: "16px"}}
                        label="Layout Name"
                        placeholder="Layout Name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        inputProps={{ type: "text" }}
                    />
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="demo-simple-select-standard-label">Master Layout</InputLabel>
                        <Select
                            label="Master Layout"
                            value={master}
                            onChange={e => {
                                if(master != "") {
                                    if (!window.confirm("Changing Master Layout will destroy all your data.\nDo you still want to change Master Layout?") == true) {
                                        return; 
                                    }
                                }
                                setH([]);
                                setMaster(e.target.value);
                            }}
                        >
                            {masterLayouts && Array.isArray(masterLayouts) && masterLayouts.map(x => {
                                return (
                                    <MenuItem value={x.id}>{x.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </MDBox>
                </>
            )}
            <MDTypography style={{fontWeight: "500", fontSize: "16px", color: "#221C40", marginBottom: "24px"}}>Property</MDTypography>
            {(Array.isArray(styled.innerClass) && (styled.innerClass.indexOf("imageItem") >= 0)) && (
                <>
                    <MDBox>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Dynamic Content</InputLabel>
                            <Select
                                label="Dynamic Content"
                                value={styled.dynamicContent}
                                onChange={e => setStyled({ ...styled, dynamicContent: e.target.value})}
                            >
                                <MenuItem value="">None</MenuItem>
                                <MenuItem value="logo">Logo</MenuItem>
                            </Select>
                        </FormControl>
                    </MDBox>
                </>
            )}
            {(Array.isArray(styled.innerClass) && (styled.innerClass.indexOf("textItem") >= 0) || (styled.innerClass && styled.innerClass.indexOf("businessItem")) >= 0) && (
                <>
                    <MDBox>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Dynamic Content</InputLabel>
                            <Select
                                label="Dynamic Content"
                                value={styled.dynamicContent}
                                onChange={e => setStyled({ ...styled, dynamicContent: e.target.value})}
                            >
                                <MenuItem value="">None</MenuItem>
                                <MenuItem value="textOnImage">Text On Image</MenuItem>
                                <MenuItem value="email">Business Email</MenuItem>
                                <MenuItem value="phone">Business Phone</MenuItem>
                                <MenuItem value="location">Business Address</MenuItem>
                            </Select>
                        </FormControl>
                        <FormField style={{marginTop: "16px", marginBottom: "16px"}}
                            label="Content"
                            placeholder="Content"
                            value={styled.dataContent}
                            multiline
                            rows={4}
                            onChange={e => setStyled({...styled, dataContent: e.target.value})}
                            inputProps={{ type: "text" }}
                        />
                    </MDBox>
                    <MDBox sx={{ my: "20px" }}>
                        <MDTypography style={{fontWeight: "500", fontSize: "16px", color: "#221C40"}}>Auto resizing mode</MDTypography>
                        <MDBox sx={{ display: "flex", my: "15px" }}>
                            <IconButton
                                style={styled.isAuto == "true" ? { backgroundColor: "#123456", color: "#fff" } : {}}
                                onClick={() => {
                                    setStyled({ ...styled, isAuto: styled.isAuto == "true" ? "false" : "true" })
                                    addAttribute({ key: "autoresize", value: true });
                                }}
                                >
                                <HeightIcon
                                    sx={{ transform: "rotate(90deg)", fontSize: "28px" }}
                                />
                            </IconButton>
                        </MDBox>
                    </MDBox>
                    <MDBox sx={{ my: "20px" }}>
                        <MDTypography style={{fontWeight: "500", fontSize: "16px", color: "#221C40"}}>Text</MDTypography>
                        <MDBox sx={{ display: "flex", my: "15px" }}>
                        <Grid container spacing={2}>
                                <Grid item xs={6}>
                                <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-label">Font</InputLabel>
                                <Select
                                    label="Font"
                                    value={styled.innerStyle && styled.innerStyle['font-family']}
                                    onChange={e => {
                                        setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "font-family": e.target.value
                                            }
                                        });
                                    }}
                                >   
                                {fontList && fontList.map((x, ind) => {
                                    return (
                                        <MenuItem key={ind} value={x.value}>{x.name}</MenuItem>
                                    )
                                })}
                                </Select>
                            </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                <Input
                                    placeholder="Font Size"
                                    value={styled.innerStyle && styled.innerStyle['font-size'] && styled.innerStyle['font-size'] && styled.innerStyle['font-size'] != "px" ? parseInt(styled.innerStyle['font-size']) : ""}
                                    onChange={(e) => {
                                        addAttribute({ key: "autoresize", value: true });
                                        setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "font-size": e.target.value + "px"
                                            }
                                        });
                                    }}
                                    fullWidth
                                />
                                </Grid>
                            </Grid>
                            </MDBox>
                            <MDBox>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <IconButton
                                        onClick={(e) => {
                                            setStyled({
                                                ...styled,
                                                innerStyle: {
                                                    ...styled.innerStyle,
                                                    "text-transform": "uppercase"
                                                }
                                            });
                                        }}
                                    ><span style={{ fontSize: "13px", fontWeight: "bold", color: "#000" }}>TT</span></IconButton>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton 
                                        onClick={(e) => {
                                            setStyled({
                                                ...styled,
                                                innerStyle: {
                                                    ...styled.innerStyle,
                                                    "text-transform": "capitalize"
                                                }
                                            });
                                        }}><span style={{ fontSize: "13px", fontWeight: "bold", color: "#000" }}>Tt</span></IconButton>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton onClick={(e) => {
                                        setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "text-transform": "lowercase"
                                            }
                                        });
                                    }}><span style={{ fontSize: "13px", fontWeight: "bold", color: "#000" }}>tt</span></IconButton>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton onClick={(e) => {
                                        setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "text-transform": "none"
                                            }
                                        });
                                    }}>
                                        <CancelOutlinedIcon sx={{ fontSize: "13px" }}/>
                                        </IconButton>
                                </Grid>
                            </Grid>
                            </MDBox>
                            <MDBox>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            variant="standard"
                                            fullWidth
                                            placeholder="Line Height"
                                            InputProps={{
                                                startAdornment: (
                                                    <FormatLineSpacingIcon sx={{ marginRight: "10px" }} />
                                                ),
                                            }}
                                            value={styled.innerStyle && styled.innerStyle['line-height']}
                                            onChange={(e) => {
                                                setStyled({
                                                    ...styled,
                                                    innerStyle: {
                                                        ...styled.innerStyle,
                                                        "line-height": e.target.value
                                                    }
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        placeholder="Letter Spacing"
                                        InputProps={{
                                            startAdornment: (
                                                <SpaceBarIcon sx={{ marginRight: "10px" }} />
                                            ),
                                        }}
                                        value={styled.innerStyle && styled.innerStyle['letter-spacing'] && styled.innerStyle['letter-spacing'] && styled.innerStyle['letter-spacing'] != "px" ? parseInt(styled.innerStyle['letter-spacing']) : ""}
                                        onChange={(e) => {
                                            setStyled({
                                                ...styled,
                                                innerStyle: {
                                                    ...styled.innerStyle,
                                                    "letter-spacing": e.target.value + "px"
                                                }
                                            });
                                        }}
                                    />
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <MDBox>
                                <Grid container spacing={2} sx={{my: 2}}>
                                    <Grid item xs={12}>
                                        <MDTypography style={{fontWeight: "500", fontSize: "16px", color: "#221C40"}}>Padding</MDTypography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            placeholder="Padding Top"
                                            value={styled.innerStyle && styled.innerStyle['padding-top'] && styled.innerStyle['padding-top'] && styled.innerStyle['padding-top'] != "px" ? parseInt(styled.innerStyle['padding-top']) : ""}
                                            onChange={(e) => {
                                                setStyled({
                                                    ...styled,
                                                    innerStyle: {
                                                        ...styled.innerStyle,
                                                        "padding-top": e.target.value + "px"
                                                    }
                                                });
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            placeholder="Padding Bottom"
                                            value={styled.innerStyle && styled.innerStyle['padding-bottom'] && styled.innerStyle['padding-bottom'] && styled.innerStyle['padding-bottom'] != "px" ? parseInt(styled.innerStyle['padding-bottom']) : ""}
                                            onChange={(e) => {
                                                setStyled({
                                                    ...styled,
                                                    innerStyle: {
                                                        ...styled.innerStyle,
                                                        "padding-bottom": e.target.value + "px"
                                                    }
                                                });
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            placeholder="Padding Left"
                                            value={styled.innerStyle && styled.innerStyle['padding-left'] && styled.innerStyle['padding-left'] && styled.innerStyle['padding-left'] != "px" ? parseInt(styled.innerStyle['padding-left']) : ""}
                                            onChange={(e) => {
                                                setStyled({
                                                    ...styled,
                                                    innerStyle: {
                                                        ...styled.innerStyle,
                                                        "padding-left": e.target.value + "px"
                                                    }
                                                });
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            placeholder="Padding Right"
                                            value={styled.innerStyle && styled.innerStyle['padding-right'] && styled.innerStyle['padding-right'] && styled.innerStyle['padding-right'] != "px" ? parseInt(styled.innerStyle['padding-right']) : ""}
                                            onChange={(e) => {
                                                setStyled({
                                                    ...styled,
                                                    innerStyle: {
                                                        ...styled.innerStyle,
                                                        "padding-right": e.target.value + "px"
                                                    }
                                                });
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <MDBox sx={{ display: "flex", mt: "24px" }}> 
                    
                    <FormControlLabel
                        control={
                            <Radio 
                                checked={styled.innerStyle && styled.innerStyle['font-weight'] == "bold" ? true : false}
                                onChange={(e) => {
                                    if (styled.innerStyle && styled.innerStyle['font-weight'] == "bold"){
                                        setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "font-weight": "none"
                                            }
                                        });
                                    } else {
                                        setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "font-weight": "bold"
                                            }
                                        });
                                    }
                                }}
                            />
                        }
                        label="Bold"
                        className={classes.radioButton}
                    />
                    <FormControlLabel
                        control={<Radio
                            checked={styled.innerStyle && styled.innerStyle['font-weight'] == 400 ? true : false}
                                onChange={(e) => {
                                    if (styled.innerStyle && styled.innerStyle['font-weight'] == 400){
                                        setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "font-weight": "none"
                                            }
                                        });
                                    } else {
                                        setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "font-weight": 400
                                            }
                                        });
                                    }
                                }}
                        />}
                        label="Normal"
                        className={classes.radioButton}
                    />
                    <FormControlLabel
                        onClick={e => setStyle({ "text-decoration-line": "underline" }) }
                        control={<Radio
                            checked={styled.innerStyle && styled.innerStyle['text-decoration-line'] == "underline" ? true : false}
                                onClick={(e) => {
                                    if (styled.innerStyle && styled.innerStyle['text-decoration-line'] == "underline"){
                                        setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "text-decoration-line": "none"
                                            }
                                        });
                                    } else {
                                        setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "text-decoration-line": "underline"
                                            }
                                        });
                                    }
                                }}
                        />}
                        label="Underline"
                        className={classes.radioButton}
                    />
                    <FormControlLabel
                        control={<Radio
                            checked={styled.innerStyle && styled.innerStyle['font-style'] == "italic" ? true : false}
                            onClick={(e) => {
                                if (styled.innerStyle && styled.innerStyle['font-style'] == "italic"){
                                    setStyled({
                                        ...styled,
                                        innerStyle: {
                                            ...styled.innerStyle,
                                            "font-style": "none"
                                        }
                                    });
                                } else {
                                    setStyled({
                                        ...styled,
                                        innerStyle: {
                                            ...styled.innerStyle,
                                            "font-style": "italic"
                                        }
                                    });
                                }
                            }}
                        />}
                        label="Italic"
                        className={classes.radioButton}
                    />
            </MDBox>
                            
                            <MDBox sx={{ display: "flex", marginTop: "20px" }}>
                                <IconButton 
                                        sx={{ mr: "15px" }}
                                        onClick={() => setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "text-align": "right"
                                            }
                                        })}
                                    >
                                    <FormatAlignRightIcon />
                                </IconButton>
                                <IconButton 
                                        sx={{ mr: "15px" }}
                                        onClick={() => setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "text-align": "center"
                                            }
                                        })}
                                    >
                                    <FormatAlignCenterIcon />
                                </IconButton>
                                <IconButton 
                                        sx={{ mr: "15px" }}
                                        onClick={() => setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "text-align": "justify"
                                            }
                                        })}>
                                    <FormatAlignJustifyIcon />
                                </IconButton>
                                <IconButton sx={{ mr: "15px" }}
                                        onClick={() => setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "text-align": "left"
                                            }
                                        })}>
                                    <FormatAlignLeftIcon />
                                </IconButton>
                            </MDBox>
                    </MDBox>
                </>
            )}
            {styled.innerClass && (styled.innerClass.indexOf("textItem") === -1 && styled.innerClass.indexOf("businessItem") === -1) && (
                <MDBox style={{marginBottom: "24px"}}>
                    <MDTypography style={{fontWeight: "500", fontSize: "16px", color: "#221C40"}}>Image</MDTypography>
                    <FormField style={{marginTop: "16px"}}
                        label="Image from URL"
                        defaultValue={styled.innerStyle && styled.innerStyle["background-image"] && styled.innerStyle["background-image"].replace('url("').replace(')"')}
                        onChange={e => setStyled({
                            ...styled,
                            innerStyle: {
                                ...styled.innerStyle,
                                "background-image": "url(" + e.target.value + ")"
                            }
                        })}
                        placeholder="https://unsplash.com/photos/y0Zlus5aCYI..."
                        inputProps={{ type: "text" }}
                    />
                    <MDBox style={{display: "flex", marginTop: "20px"}}>
                        
                            <label htmlFor="header-image" variant="outlined" style={{ fontSize: "12px", fontWeight: 700, border: "1px solid #5a5a5a", borderRadius: "10px", padding: "10px 20px", marginRight: "10px", cursor: "pointer" }} >
                                Browse
                            </label>
                        <input
                            accept="image/*"
                            id="header-image"
                            type="file"
                            onChange={setBackgroundImage}
                            style={{ display: "none" }}
                        />
                        <MDButton
                            variant="outlined"
                            style={mdButton}
                            onClick={() => {
                                unsplashSearch("Abstract");
                                setOpen(true);
                            }}
                        >
                            Media Library
                        </MDButton>
                    </MDBox>
                    <RadioGroup style={{marginTop: "24px"}} sx={{
                        "& .css-knlq9b-MuiFormControlLabel-root .MuiFormControlLabel-label": {
                            color: "#5E6D84",
                            fontWeight: 400,
                            fontSize: "14px",
                        },
                    }}
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="fill"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel
                            value="fill"
                            control={<Radio
                                onChange={() => {
                                    setStyled({
                                        ...styled,
                                        innerStyle: {
                                            ...styled.innerStyle,
                                            "background-size": "initial"
                                        }
                                    });
                                }}
                                checked={styled.innerStyle && styled.innerStyle['background-size'] == "initial" ? true : false}
                            />}
                            label="Fill"
                            className={classes.radioButton}
                        />
                        <FormControlLabel
                            value="cover"
                            control={<Radio
                                onChange={() => {
                                    setStyled({
                                        ...styled,
                                        innerStyle: {
                                            ...styled.innerStyle,
                                            "background-size": "cover"
                                        }
                                    });
                                }}
                                checked={styled.innerStyle && styled.innerStyle['background-size'] == "cover" ? true : false}
                                />}
                            label="Cover"
                            className={classes.radioButton}
                        />
                        <FormControlLabel
                            value="contain"
                            control={<Radio
                                onChange={() => {
                                    setStyled({
                                        ...styled,
                                        innerStyle: {
                                            ...styled.innerStyle,
                                            "background-size": "contain"
                                        }
                                    });
                                }}
                                checked={styled.innerStyle && styled.innerStyle['background-size'] == "contain" ? true : false}
                            />}
                            label="Contain"
                            className={classes.radioButton}
                        />
                    </RadioGroup>
                </MDBox>
            )}
            {styled.wrapperClass && styled.wrapperClass.length !== 0 && (
                <>
                    <MDTypography style={{fontWeight: "500", fontSize: "14px", color: "#221C40"}}>Alignment</MDTypography>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <IconButton
                                onClick={() => {
                                    setStyled({
                                        ...styled,
                                        wrapperClass: styled.wrapperClass.filter(x => alignmentClasses.indexOf(x) === -1).concat(["hleft"])
                                    });
                                }}
                           >
                            <BorderLeftOutlinedIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton
                                onClick={() => {
                                    setStyled({
                                        ...styled,
                                        wrapperClass: styled.wrapperClass.filter(x => alignmentClasses.indexOf(x) === -1).concat(["hcenter"])
                                    });
                                }}
                           >
                            <BorderVerticalOutlinedIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton
                                onClick={() => {
                                    setStyled({
                                        ...styled,
                                        wrapperClass: styled.wrapperClass.filter(x => alignmentClasses.indexOf(x) === -1).concat(["vcenter"])
                                    });
                                }}
                           >
                            <BorderHorizontalOutlinedIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton
                            onClick={() => {
                                setStyled({
                                    ...styled,
                                    wrapperClass: styled.wrapperClass.filter(x => alignmentClasses.indexOf(x) === -1).concat(["hright"])
                                });
                            }}
                           >
                            <BorderRightOutlinedIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton
                            onClick={() => {
                                setStyled({
                                    ...styled,
                                    wrapperClass: styled.wrapperClass.filter(x => alignmentClasses.indexOf(x) === -1).concat(["vtop"])
                                });
                            }}
                           >
                            <BorderTopOutlinedIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={2}>
                        <IconButton
                            onClick={() => {
                                setStyled({
                                    ...styled,
                                    wrapperClass: styled.wrapperClass.filter(x => alignmentClasses.indexOf(x) === -1).concat(["vbottom"])
                                });
                            }}
                           >
                            <BorderBottomOutlinedIcon />
                        </IconButton>
                        </Grid>
                    </Grid>
                    <MDBox style={{display: "flex", marginTop: "20px"}}>    
                        <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#5E6D84", marginRight: "29px"}}>Position</MDTypography>
                        <Input
                            placeholder="120"
                            style={{maxWidth: "88px"}}
                            value={styled.wrapperStyle && styled.wrapperStyle['top'] && styled.wrapperStyle['top'] && styled.wrapperStyle['top'] != "px" ? parseInt(styled.wrapperStyle['top']) : ""}
                            onChange={(e) => {
                                setStyled({
                                    ...styled,
                                    wrapperStyle: {
                                        ...styled.wrapperStyle,
                                        "top": e.target.value + "px"
                                    }
                                });
                            }}
                        />
                        <MDTypography style={{fontWeight: "400", fontSize: "12px", marginLeft: "-10px", color: "#CECDD5", marginTop: "5px"}}>X</MDTypography>
                        <Input
                            placeholder="120"
                            style={{maxWidth: "88px", marginLeft: "16px"}}
                            value={styled.wrapperStyle && styled.wrapperStyle['left'] && styled.wrapperStyle['left'] && styled.wrapperStyle['left'] != "px" ? parseInt(styled.wrapperStyle['left']) : ""}
                            onChange={(e) => {
                                setStyled({
                                    ...styled,
                                    wrapperStyle: {
                                        ...styled.wrapperStyle,
                                        "left": e.target.value + "px"
                                    }
                                });
                            }}
                        />
                        <MDTypography style={{fontWeight: "400", fontSize: "12px", marginLeft: "-10px", color: "#CECDD5", marginTop: "5px"}}>X</MDTypography>
                    </MDBox>
                    <MDBox style={{display: "flex", marginTop: "20px"}}>    
                        <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#5E6D84", marginRight: "55px"}}>Size</MDTypography>
                        <Input
                            placeholder="120"
                            style={{maxWidth: "88px"}}
                            value={styled.innerStyle && styled.innerStyle['width'] && styled.innerStyle['width'] && styled.innerStyle['width'] != "px" ? parseInt(styled.innerStyle['width']) : ""}
                            onChange={(e) => {
                                setStyled({
                                    ...styled,
                                    innerStyle: {
                                        ...styled.innerStyle,
                                        "width": e.target.value + "px"
                                    }
                                });
                            }}
                        />
                        <MDTypography style={{fontWeight: "400", fontSize: "12px", marginLeft: "-10px", color: "#CECDD5", marginTop: "5px"}}>X</MDTypography>
                            <IconButton onClick={() => {
                                setStyled({ ...styled, resizable: styled.resizable == "true" ? "false" : "true" })
                                addAttribute({ key: "resizable", value: true });
                            }}>
                                {styled.resizable == "false" ? (
                                    <LockIcon
                                        style={{marginLeft: "16px", color: "#191919"}}
                                    />
                                ) : (
                                    <LockOpenIcon
                                        style={{marginLeft: "16px", color: "#191919"}}
                                    />
                                )}
                            </IconButton>
                        <Input
                            placeholder="705"
                            style={{maxWidth: "88px", marginLeft: "16px"}}
                            value={styled.innerStyle && styled.innerStyle['height'] && styled.innerStyle['height'] && styled.innerStyle['height'] != "px" ? parseInt(styled.innerStyle['height']) : ""}
                            onChange={(e) => {
                                setStyled({
                                    ...styled,
                                    innerStyle: {
                                        ...styled.innerStyle,
                                        "height": e.target.value + "px"
                                    }
                                });
                            }}
                        />
                        <MDTypography style={{fontWeight: "400", fontSize: "12px", marginLeft: "-10px", color: "#CECDD5", marginTop: "5px"}}>Y</MDTypography>
                    </MDBox>
                    <MDBox style={{marginTop: "48px"}}>
                        {/* <MDTypography style={{fontWeight: "500", fontSize: "14px", color: "#221C40", marginBottom: "16px"}}>Transform</MDTypography>  
                        <MDBox style={{display: "flex"}}>    
                            <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#5E6D84", marginRight: "28px"}}>Rotation</MDTypography>
                            <Input placeholder="0°" style={{maxWidth: "99px"}} />
                        </MDBox> */}
                        <MDBox style={{display: "flex", marginTop: "20px"}}>    
                            <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#5E6D84", marginRight: "28px"}}>Opacity</MDTypography>
                            <Input
                                value={parseInt(styled.wrapperStyle.opacity * 100)}
                                onChange={e => setStyled({
                                    ...styled,
                                    wrapperStyle: {
                                        ...styled.wrapperStyle,
                                        opacity: parseFloat(e.target.value / 100)
                                    }
                                })}
                                placeholder="10%" style={{maxWidth: "99px"}} type="number" min="" max="100"/>
                            <MDTypography style={{fontWeight: "400", marginLeft: "-10px", color: "#CECDD5"}}>%</MDTypography>
                        </MDBox>
                    </MDBox>
                </>
            )}
            <MDBox style={{marginTop: "48px"}}>
                <MDTypography style={{fontWeight: "500", fontSize: "14px", color: "#221C40", marginBottom: "16px"}}>Background</MDTypography>
                <MDBox style={{display: "flex"}}>
                    <ColorPicker
                        value={styled.innerStyle && styled.innerStyle['background-color'] ? styled.innerStyle['background-color'] : createColor("transparent")}
                        onChange={(e) => {
                            setStyled({
                                ...styled,
                                innerStyle: {
                                    ...styled.innerStyle,
                                    "background-color": "#" + e.hex
                                }
                            });
                        }}
                    />
                </MDBox>
                <MDBox>
                    <RadioGroup
                        style={{marginTop: "24px"}}
                        sx={{
                            "& .css-knlq9b-MuiFormControlLabel-root .MuiFormControlLabel-label": {
                                color: "#5E6D84",
                                fontWeight: 400,
                                fontSize: "14px",
                            },
                        }}
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="fill"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel
                            control={<Radio
                                onChange={() => {
                                    delete styled.innerStyle["background-color"];
                                    setStyled({
                                        ...styled,
                                        innerClass: styled.innerClass && styled.innerClass.indexOf("bgAccent") > 0 ? styled.innerClass.filter(x => x != "bgAccent") : styled.innerClass.filter(x => bgClasses.indexOf(x) === -1).concat(["bgAccent"])
                                    });
                                }}
                                checked={styled.innerClass && styled.innerClass.indexOf("bgAccent") > 0 ? true : false}
                            />}
                            label="Accent"
                            className={classes.radioButton}
                        />
                        <FormControlLabel
                            control={<Radio
                                onChange={() => {
                                    delete styled.innerStyle["background-color"];
                                    setStyled({
                                        ...styled,
                                        innerClass: styled.innerClass && styled.innerClass.indexOf("bgDark") > 0 ? styled.innerClass.filter(x => x != "bgDark") : styled.innerClass.filter(x => bgClasses.indexOf(x) === -1).concat(["bgDark"])
                                    });
                                }}
                                checked={styled.innerClass && styled.innerClass.indexOf("bgDark") > 0 ? true : false}
                                />}
                            label="Dark"
                            className={classes.radioButton}
                        />
                        <FormControlLabel
                            control={<Radio
                                onChange={() => {
                                    delete styled.innerStyle["background-color"];
                                    setStyled({
                                        ...styled,
                                        innerClass: styled.innerClass && styled.innerClass.indexOf("bgLight") > 0 ? styled.innerClass.filter(x => x != "bgLight") : styled.innerClass.filter(x => bgClasses.indexOf(x) === -1).concat(["bgLight"])
                                    });
                                }}
                                checked={styled.innerClass && styled.innerClass.indexOf("bgLight") > 0 ? true : false}
                                />}
                            label="Light"
                            className={classes.radioButton}
                        />
                    </RadioGroup>
                </MDBox>
            </MDBox>
            {styled.innerClass && (styled.innerClass.indexOf("textItem") >= 0 || styled.innerClass.indexOf("businessItem") >= 0) && (
                <MDBox style={{marginTop: "48px"}}>
                    <MDTypography style={{fontWeight: "500", fontSize: "14px", color: "#221C40", marginBottom: "16px"}}>Color</MDTypography>
                    <MDBox style={{display: "flex"}}>
                        <ColorPicker
                            value={styled.innerStyle && styled.innerStyle['color'] ? styled.innerStyle['color'] : createColor("transparent")}
                            onChange={(e) => {
                                setStyled({
                                    ...styled,
                                    innerStyle: {
                                        ...styled.innerStyle,
                                        "color": "#" + e.hex
                                    }
                                });
                            }}
                        />
                    </MDBox>
                    <MDBox>
                        <RadioGroup
                            style={{marginTop: "24px"}}
                            sx={{
                                "& .css-knlq9b-MuiFormControlLabel-root .MuiFormControlLabel-label": {
                                    color: "#5E6D84",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                },
                            }}
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="fill"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel
                                control={<Radio
                                    onChange={() => {
                                        delete styled.innerStyle["color"];
                                        setStyled({
                                            ...styled,
                                            innerClass: styled.innerClass && styled.innerClass.indexOf("colorAccent") > 0 ? styled.innerClass.filter(x => x != "colorAccent") : styled.innerClass.filter(x => colorClasses.indexOf(x) === -1).concat(["colorAccent"])
                                        });
                                    }}
                                    checked={styled.innerClass && styled.innerClass.indexOf("colorAccent") > 0 ? true : false}
                                />}
                                label="Accent"
                                className={classes.radioButton}
                            />
                            <FormControlLabel
                                control={<Radio
                                    onChange={() => {
                                        delete styled.innerStyle["color"];
                                        setStyled({
                                            ...styled,
                                            innerClass: styled.innerClass && styled.innerClass.indexOf("colorDark") > 0 ? styled.innerClass.filter(x => x != "colorDark") : styled.innerClass.filter(x => colorClasses.indexOf(x) === -1).concat(["colorDark"])
                                        });
                                    }}
                                    checked={styled.innerClass && styled.innerClass.indexOf("colorDark") > 0 ? true : false}
                                    />}
                                label="Dark"
                                className={classes.radioButton}
                            />
                            <FormControlLabel
                                control={<Radio
                                    onChange={() => {
                                        delete styled.innerStyle["color"];
                                        setStyled({
                                            ...styled,
                                            innerClass: styled.innerClass && styled.innerClass.indexOf("colorLight") > 0 ? styled.innerClass.filter(x => x != "colorLight") : styled.innerClass.filter(x => colorClasses.indexOf(x) === -1).concat(["colorLight"])
                                        });
                                    }}
                                    checked={styled.innerClass && styled.innerClass.indexOf("colorLight") > 0 ? true : false}
                                    />}
                                label="Light"
                                className={classes.radioButton}
                            />
                        </RadioGroup>
                    </MDBox>
                </MDBox>
            )}
            {styled.innerClass && (styled.innerClass.indexOf("textItem") === -1 && styled.innerClass.indexOf("businessItem") === -1) && (
            <MDBox style={{marginTop: "48px"}}>
                <MDTypography style={{fontWeight: "500", fontSize: "14px", color: "#221C40", marginBottom: "16px"}}>
                    Border
                </MDTypography>
                <MDBox style={{display: "flex"}}>
                    <ColorPicker
                        value={styled.innerStyle && styled.innerStyle['border-color'] ? styled.innerStyle['border-color'] : styled.innerStyle['border-top-color'] ? styled.innerStyle['border-top-color'] : createColor("transparent")}
                        onChange={(e) => {
                            setStyled({
                                ...styled,
                                innerStyle: {
                                    ...styled.innerStyle,
                                    "border-color": "#" + e.hex
                                }
                            });
                        }}
                    />
                    <Input
                        style={{ maxWidth: "64px", marginRight: "12px", marginLeft: "5px" }}
                        placeholder="0%"
                        value={styled.innerStyle && styled.innerStyle["border-radius"]}
                        onChange={(e) => {
                            setStyled({
                                ...styled,
                                innerStyle: {
                                    ...styled.innerStyle,
                                    "border-radius": e.target.value
                                }
                            });
                        }}
                    /> 
                    <LineWeightOutlinedIcon style={{marginRight: "8px", color: "#1B1C29", marginTop: "8px"}} />
                    <Input
                        defaultValue={styled.innerStyle && styled.innerStyle["border-bottom-width"]}
                        onChange={(e) => {
                            setStyled({
                                ...styled,
                                innerStyle: {
                                    ...styled.innerStyle,
                                    "border-width": e.target.value
                                }
                            });
                        }}
                        placeholder="1"
                        style={{maxWidth: "40px"}}
                    /> 
                </MDBox>
                <MDBox>
                        <RadioGroup
                            style={{marginTop: "24px"}}
                            sx={{
                                "& .css-knlq9b-MuiFormControlLabel-root .MuiFormControlLabel-label": {
                                    color: "#5E6D84",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                },
                            }}
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="fill"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel
                                control={<Radio
                                    onChange={() => {
                                        delete styled.innerStyle["border-top-color"];
                                        delete styled.innerStyle["border-bottom-color"];
                                        delete styled.innerStyle["border-left-color"];
                                        delete styled.innerStyle["border-right-color"];
                                        setStyled({
                                            ...styled,
                                            innerClass: styled.innerClass && styled.innerClass.indexOf("borderAccent") > 0 ? styled.innerClass.filter(x => x != "borderAccent") : styled.innerClass.filter(x => borderClasses.indexOf(x) === -1).concat(["borderAccent"])
                                        });
                                    }}
                                    checked={styled.innerClass && styled.innerClass.indexOf("borderAccent") > 0 ? true : false}
                                />}
                                label="Accent"
                                className={classes.radioButton}
                            />
                            <FormControlLabel
                                control={<Radio
                                    onChange={() => {
                                        delete styled.innerStyle["border-top-color"];
                                        delete styled.innerStyle["border-bottom-color"];
                                        delete styled.innerStyle["border-left-color"];
                                        delete styled.innerStyle["border-right-color"];
                                        setStyled({
                                            ...styled,
                                            innerClass: styled.innerClass && styled.innerClass.indexOf("borderDark") > 0 ? styled.innerClass.filter(x => x != "borderDark") : styled.innerClass.filter(x => borderClasses.indexOf(x) === -1).concat(["borderDark"])
                                        });
                                    }}
                                    checked={styled.innerClass && styled.innerClass.indexOf("borderDark") > 0 ? true : false}
                                    />}
                                label="Dark"
                                className={classes.radioButton}
                            />
                            <FormControlLabel
                                control={<Radio
                                    onChange={() => {
                                        delete styled.innerStyle["border-top-color"];
                                        delete styled.innerStyle["border-bottom-color"];
                                        delete styled.innerStyle["border-left-color"];
                                        delete styled.innerStyle["border-right-color"];
                                        setStyled({
                                            ...styled,
                                            innerClass: styled.innerClass && styled.innerClass.indexOf("borderLight") > 0 ? styled.innerClass.filter(x => x != "borderLight") : styled.innerClass.filter(x => borderClasses.indexOf(x) === -1).concat(["borderLight"])
                                        });
                                    }}
                                    checked={styled.innerClass && styled.innerClass.indexOf("borderLight") > 0 ? true : false}
                                    />}
                                label="Light"
                                className={classes.radioButton}
                            />
                        </RadioGroup>
                    </MDBox>
            </MDBox>
            )}
            {styled.innerClass && (styled.innerClass.indexOf("textItem") === -1 && styled.innerClass.indexOf("businessItem") === -1) && (
            <MDBox style={{marginTop: "48px"}}>
                <MDBox>
                    <MDTypography style={{fontWeight: "500", fontSize: "14px", color: "#221C40", marginBottom: "16px"}}>
                        Shadow
                    </MDTypography>    
                    <FormControlLabel
                        control={<Checkbox
                            onClick={() => {
                                setShadow(!shadow);
                            }}
                            checked={shadow}
                        />}
                        label="Shadow"
                        className={classes.radioButton}
                    />
                    {shadow && (
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    placeholder="Letter Spacing"
                                    InputProps={{
                                        startAdornment: (
                                            <MDTypography sx={{ fontSize: "13px", marginRight: "10px" }} >X</MDTypography>
                                        ),
                                    }}
                                    value={styled.innerStyle && styled.innerStyle['box-shadow']}
                                    onChange={(e) => {
                                        setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "box-shadow": "10px 10px 5px #aaaaaa"
                                            }
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                            <TextField
                                    variant="standard"
                                    fullWidth
                                    placeholder="Letter Spacing"
                                    InputProps={{
                                        startAdornment: (
                                            <MDTypography sx={{ fontSize: "13px", marginRight: "10px" }} >Y</MDTypography>
                                        ),
                                    }}
                                    value={styled.innerStyle && styled.innerStyle['box-shadow']}
                                    onChange={(e) => {
                                        setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "box-shadow": "10px 10px 5px #aaaaaa"
                                            }
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    placeholder="Letter Spacing"
                                    InputProps={{
                                        startAdornment: (
                                            <MDTypography sx={{ fontSize: "13px", marginRight: "10px" }} >Blur</MDTypography>
                                        ),
                                    }}
                                    value={styled.innerStyle && styled.innerStyle['box-shadow']}
                                    onChange={(e) => {
                                        setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "box-shadow": "10px 10px 5px #aaaaaa"
                                            }
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    placeholder="Letter Spacing"
                                    InputProps={{
                                        startAdornment: (
                                            <MDTypography sx={{ fontSize: "13px", marginRight: "10px" }} >Spread</MDTypography>
                                        ),
                                    }}
                                    value={styled.innerStyle && styled.innerStyle['box-shadow']}
                                    onChange={(e) => {
                                        setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "box-shadow": "10px 10px 5px #aaaaaa"
                                            }
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    placeholder="Letter Spacing"
                                    InputProps={{
                                        startAdornment: (
                                            <MDTypography sx={{ fontSize: "13px", marginRight: "10px" }} ></MDTypography>
                                        ),
                                    }}
                                    value={styled.innerStyle && styled.innerStyle['box-shadow']}
                                    onChange={(e) => {
                                        setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "box-shadow": "10px 10px 5px #aaaaaa"
                                            }
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    placeholder="Letter Spacing"
                                    InputProps={{
                                        startAdornment: (
                                            <MDTypography sx={{ fontSize: "13px", marginRight: "10px" }} ></MDTypography>
                                        ),
                                    }}
                                    value={styled.innerStyle && styled.innerStyle['box-shadow']}
                                    onChange={(e) => {
                                        setStyled({
                                            ...styled,
                                            innerStyle: {
                                                ...styled.innerStyle,
                                                "box-shadow": "10px 10px 5px #aaaaaa"
                                            }
                                        });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                </MDBox>
            </MDBox>
            )}
            {(!styled.wrapperClass || (styled.wrapperClass && styled.wrapperClass.length === 0)) && (
                <>
                <MDTypography style={{fontWeight: "500", fontSize: "16px", color: "#221C40", marginTop: "24px", marginBottom: "15px"}}>Presets</MDTypography>
                    <MDBox
                        onClick={() => {
                            setWidth(1200);
                            setHeight(1200);
                            setZoom(.3);
                        }}
                        sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}
                    >
                        <MDTypography className={classes.platformName}>Facebook squre</MDTypography>
                        <MDTypography className={classes.platformSize}>1200 x 1200</MDTypography>
                    </MDBox>
                    <MDBox
                        onClick={() => {
                            setWidth(1200);
                            setHeight(630);
                            setZoom(.4);
                        }}
                        sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}
                    >
                        <MDTypography className={classes.platformName}>Facebook landscape</MDTypography>
                        <MDTypography className={classes.platformSize}>1200 x 630</MDTypography>
                    </MDBox>
                    <MDBox
                        onClick={() => {
                            setWidth(1080);
                            setHeight(1920);
                            setZoom(.5);
                        }}
                        sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}
                    >
                        <MDTypography className={classes.platformName}>Facebook story</MDTypography>
                        <MDTypography className={classes.platformSize}>1080 x 1920</MDTypography>
                    </MDBox>
                    <MDBox
                        onClick={() => {
                            setWidth(1080);
                            setHeight(1080);
                            setZoom(.5);
                        }}
                        sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}
                    >
                        <MDTypography className={classes.platformName}>Instagram square</MDTypography>
                        <MDTypography className={classes.platformSize}>1080 x 1080</MDTypography>
                    </MDBox>
                    <MDBox
                        onClick={() => {
                            setWidth(1080);
                            setHeight(1350);
                            setZoom(.3);
                        }}
                        sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}
                    >
                        <MDTypography className={classes.platformName}>Instagram portrait</MDTypography>
                        <MDTypography className={classes.platformSize}>1080 x 1350</MDTypography>
                    </MDBox>
                    <MDBox
                        onClick={() => {
                            setWidth(1080);
                            setHeight(566);
                            setZoom(.5);
                        }}
                        sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}
                    >
                        <MDTypography className={classes.platformName}>Instagram landscape</MDTypography>
                        <MDTypography className={classes.platformSize}>1080 x 566</MDTypography>
                    </MDBox>
                    <MDBox
                        onClick={() => {
                            setWidth(1080);
                            setHeight(1920);
                            setZoom(.5);
                        }}
                        sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}
                    >
                        <MDTypography className={classes.platformName}>Instagram story</MDTypography>
                        <MDTypography className={classes.platformSize}>1080 x 1920</MDTypography>
                    </MDBox>
                    <MDBox
                        onClick={() => {
                            setWidth(1080);
                            setHeight(1920);
                            setZoom(.5);
                        }}
                        sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}
                    >
                        <MDTypography className={classes.platformName}>Instagram reel</MDTypography>
                        <MDTypography className={classes.platformSize}>1080 x 1920</MDTypography>
                    </MDBox>
                    <MDBox
                        onClick={() => {
                            setWidth(1200);
                            setHeight(628);
                            setZoom(.5);
                        }}
                        sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}
                    >
                        <MDTypography className={classes.platformName}>Linkedin feed</MDTypography>
                        <MDTypography className={classes.platformSize}>1200 x 628</MDTypography>
                    </MDBox>
                    <MDBox
                        onClick={() => {
                            setWidth(1200);
                            setHeight(900);
                            setZoom(.5);
                        }}
                        sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}
                    >
                        <MDTypography className={classes.platformName}>GMB feed</MDTypography>
                        <MDTypography className={classes.platformSize}>1200 x 900</MDTypography>
                    </MDBox>
                </>
            )}
        </MDBox>
    )
}