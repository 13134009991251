import { useEffect } from "react";
import MDBox from "../../shared/components/MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "../../shared/components/MDTypography";
import socialImage from "../../assets/images/social-image.png";
import MDButton from "./../../shared/components/MDButton";
import Paper from '@mui/material/Paper';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import BorderLeftOutlinedIcon from '@mui/icons-material/BorderLeftOutlined';
import BorderVerticalOutlinedIcon from '@mui/icons-material/BorderVerticalOutlined';
import BorderHorizontalOutlinedIcon from '@mui/icons-material/BorderHorizontalOutlined';
import BorderRightOutlinedIcon from '@mui/icons-material/BorderRightOutlined';
import BorderTopOutlinedIcon from '@mui/icons-material/BorderTopOutlined';
import BorderBottomOutlinedIcon from '@mui/icons-material/BorderBottomOutlined';
import Input from '@mui/material/Input';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import LineWeightOutlinedIcon from '@mui/icons-material/LineWeightOutlined';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { blocks } from './block';
const appUrl = process.env.REACT_APP_BASE_URL;

export function TooltipPage(props) {
    const { uploadBG, bgImg, isLoading } = props; 

    document.body.addEventListener('dragstart', handleDragStart);
    useEffect(() => {
        document.body.addEventListener('dragend', handleDrop);
        return () => {
            document.body.removeEventListener('dragend', handleDrop);
        }
      }, []);

    
    

function handleDragStart(ev) {
    let obj = ev.target;
    if (!obj.closest('.draggable')) return;
    if(obj.classList.contains('draggable')){
      obj = obj.firstElementChild;
    }

    const data = {
      type: "drag",
      data: {}
    }
    document.getElementById("dropframe").contentWindow.postMessage(JSON.stringify(data), appUrl);
  }
  function handleDrop(ev) {
      console.log(ev.pageX);
      console.log(ev.pageY);
      console.log(ev.target.getBoundingClientRect());
      var $body = document.getElementsByTagName("body");
      var offsetLeft = ev.pageX - $body[0].scrollLeft;
      var offsetTop = ev.pageY - $body[0].scrollTop;

      console.log("Offset: ", offsetLeft, " - ", offsetTop);

        const data = {
            type: "drop",
            data: {
              mouse:{
                  x: 20,
                  y: 20,
              },
              htmlPayload: ev.target.id,
              timestamp: Date.now()
            }
        }
        console.log("Drop AT index: ", data);
    document.getElementById("dropframe").contentWindow.postMessage(JSON.stringify(data), appUrl);
  }


  function setStyle(x){
    const data = {
      type: "css_wrapper",
      data: x
    }
    document.getElementById("dropframe").contentWindow.postMessage(JSON.stringify(data), appUrl);
  }

  function remove_element(){
    const data = {
      type: "remove_element",
      data: {}
    }
    document.getElementById("dropframe").contentWindow.postMessage(JSON.stringify(data), appUrl);
}

function get_html(){
    const data = {
      type: "get_html",
      data: {}
    }
    document.getElementById("dropframe").contentWindow.postMessage(JSON.stringify(data), appUrl);
}

useEffect(() => {
    if (bgImg) {
        const data = { 
            type: "background_image",
            data: { image: bgImg }
        }
        document.getElementById("dropframe").contentWindow.postMessage(JSON.stringify(data), appUrl);
    }
}, [bgImg])

const setBackgroundImage = (event) => {
    if (
        event &&
        event.target &&
        event.target.files &&
        event.target.files.length > 0
        ) {
            uploadBG({ image: event.target.files[0] });
        }
  }

    const ariaLabel = { 'aria-label': 'description' };
  return (
    <Grid container spacing={2}>
        <Grid item xs={12} style={{display: "flex", background: "#FFFFFF", alignItems: "center"}}>
            <ArrowBackIosOutlinedIcon style={{marginRight: "16px"}} />
            <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#534F6B"}}>Master Layout:&nbsp;
                <span style={{fontWeight: "500", fontSize: "12px", color: "#534F6B"}}>Master Layout Name</span>
            </MDTypography>
            <HelpOutlineOutlinedIcon style={{color: "#CECDD5"}} />
            <img src={socialImage} alt="social iamge" style={{marginLeft: "51px"}} />
            <MDBox style={{display: "flex", marginLeft: "42px"}}>    
                <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#5E6D84", marginRight: "16px"}}>Canvas Size :</MDTypography>
                <Input placeholder="1200" inputProps={ariaLabel} style={{maxWidth: "85px"}} />
                <MDTypography style={{fontWeight: "400", fontSize: "14px", marginLeft: "-10px", color: "#CECDD5", marginTop: "5px"}}>W</MDTypography>
                <LockOpenIcon style={{marginLeft: "12px", color: "#191919", marginTop: "4px"}} />
                <Input placeholder="1200" inputProps={ariaLabel} style={{maxWidth: "85px", marginLeft: "12px"}} />
                <MDTypography style={{fontWeight: "400", fontSize: "14px", marginLeft: "-10px", color: "#CECDD5", marginTop: "5px"}}>H</MDTypography>
            </MDBox>
            <MDBox style={{display: "flex", marginLeft: "60px"}}>
                <UndoOutlinedIcon style={{marginRight: "13px", color: "#1B1C29"}} />
                <RedoOutlinedIcon style={{color: "#B5B3BF"}} />
            </MDBox>
            <MDButton
                style={{
                background: "linear-gradient(180.67deg, #09032B 2.43%, #312B56 101%)",
                boxShadow: "0px 2px 6px rgba(41, 43, 63, 0.36)",
                color: "#FFFFFF",
                fontWeight: 700,
                fontSize: "12px",
                textTransform: "inherit",
                marginLeft: "32px"
                }}
                onClick={() => get_html()}
            >
                Save and Publish
            </MDButton>
        </Grid>
        <Grid item xs={12} md={2}>
            <Paper elevation={3} style={{ width: 'auto', height: 903, padding: '16px'}} >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                        {Array.isArray(blocks) && blocks.map((x) => {
                            return (
                                <div key={x.id} className="draggable" style={{marginTop: "32px"}} draggable >
                                    <img src={`/images/${x.logo}`} style={{ margin: "auto 12px" }} id={x.id} />
                                    <p style={{fontWeight: "400", fontSize: "12px", color: '#6B6880', margin: "auto 12px"}}>{x.label}</p>
                                </div>
                            )
                        })}
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <MDTypography style={{fontWeight: "500", fontSize: "16px", color: "#221C40"}}>
                            Components
                        </MDTypography>
                        <MDBox style={{display: "flex", marginTop: "24px"}}>
                            <TitleOutlinedIcon style={{color: "#B5B3BF", marginRight: "12px"}} />
                            <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#221C40"}}>Title 1</MDTypography>
                        </MDBox>
                        <MDBox style={{display: "flex", marginTop: "20px"}}>
                            <TitleOutlinedIcon style={{color: "#B5B3BF", marginRight: "12px"}} />
                            <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#221C40"}}>Title 2</MDTypography>
                        </MDBox>
                        <MDBox style={{display: "flex", marginTop: "20px"}}>
                            <TitleOutlinedIcon style={{color: "#B5B3BF", marginRight: "12px"}} />
                            <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#221C40"}}>Discription</MDTypography>
                        </MDBox>
                        <MDBox style={{display: "flex", marginTop: "20px"}}>
                            <CropSquareIcon style={{color: "#B5B3BF", marginRight: "12px"}} />
                            <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#221C40"}}>Rectangle 1</MDTypography>
                        </MDBox>
                        <MDBox style={{display: "flex", marginTop: "20px"}}>
                            <CropSquareIcon style={{color: "#B5B3BF", marginRight: "12px"}} />
                            <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#221C40"}}>Rectangle 2</MDTypography>
                        </MDBox>
                        <MDBox style={{display: "flex", marginTop: "20px"}}>
                            <CropSquareIcon style={{color: "#B5B3BF", marginRight: "12px"}} />
                            <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#221C40"}}>Rectangle 3</MDTypography>
                        </MDBox>
                        <MDBox style={{display: "flex", marginTop: "20px"}}>
                            <ImageOutlinedIcon style={{color: "#B5B3BF", marginRight: "12px"}} />
                            <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#221C40"}}>Image</MDTypography>
                        </MDBox>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
        <Grid item xs={12} md={8} style={{textAlign: "center", marginTop: "100px"}}>
            <MDBox style={{display: "flex", margin: "auto 164px"}}>
                <MDTypography style={{fontWeight: "700", fontSize: "16px", color: "#1B1C29"}}>Canvas1 </MDTypography>
                <MDBox style={{marginLeft: "auto"}}>
                    <KeyboardArrowUpIcon style={{marginRight: "24px", color: "#B5B3BF"}} />
                    <KeyboardArrowDownIcon style={{marginRight: "24px", color: "#6B6880"}} />
                    <AddToPhotosIcon style={{marginRight: "24px", color: "#6B6880"}} />
                    <DeleteOutlineIcon onClick={() => remove_element()} style={{marginRight: "24px", color: "#6B6880", cursor: "pointer"}} />
                    <label htmlFor="header-image">
                        <AddPhotoAlternateOutlinedIcon style={{color: "#6B6880", cursor: "pointer"}} />
                    </label>
                    <input
                        accept="image/*"
                        id="header-image"
                        type="file"
                        onChange={setBackgroundImage}
                        style={{ display: "none" }}
                    />
                </MDBox>
            </MDBox>
            <iframe name="somename" title="somename" id="dropframe" src={`${process.env.REACT_APP_BASE_URL}/#/canvas`} width="600px" height="600px"/>
            {/* <img src={burceMars} alt="canvas" /> */}
        </Grid>
        <Grid item xs={12} md={2}>
            <Paper elevation={3} style={{ width: 'auto', height: "auto", padding: '16px'}} >
                <MDTypography style={{fontWeight: "500", fontSize: "16px", color: "#221C40", marginBottom: "24px"}}>Property</MDTypography>
                <MDTypography style={{fontWeight: "500", fontSize: "14px", color: "#221C40"}}>Alignment</MDTypography>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <BorderLeftOutlinedIcon onClick={() => setStyle({ left: 0 })} />
                    </Grid>
                    <Grid item xs={2}>
                        <BorderVerticalOutlinedIcon onClick={() => setStyle({ left: '50%', transform: "translate(-50%, 0)" })}/>
                    </Grid>
                    <Grid item xs={2}>
                        <BorderHorizontalOutlinedIcon onClick={() => setStyle({ top: "50%", transform: "translate(0, -50%)" })}/>
                    </Grid>
                    <Grid item xs={2}>
                        <BorderRightOutlinedIcon />
                    </Grid>
                    <Grid item xs={2}>
                        <BorderTopOutlinedIcon />
                    </Grid>
                    <Grid item xs={2}>
                        <BorderBottomOutlinedIcon />
                    </Grid>
                </Grid>

                <MDTypography style={{fontWeight: "500", fontSize: "14px", color: "#221C40", marginTop: "10px"}}>Background</MDTypography>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <BorderLeftOutlinedIcon />
                    </Grid>
                    <Grid item xs={2}>
                        <BorderVerticalOutlinedIcon />
                    </Grid>
                    <Grid item xs={2}>
                        <BorderHorizontalOutlinedIcon />
                    </Grid>
                    <Grid item xs={2}>
                        <BorderRightOutlinedIcon />
                    </Grid>
                    <Grid item xs={2}>
                        <BorderTopOutlinedIcon />
                    </Grid>
                    <Grid item xs={2}>
                        <BorderBottomOutlinedIcon />
                    </Grid>
                </Grid>
                
                
            </Paper>
        </Grid>
    </Grid>
  );
}
