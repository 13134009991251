import * as actionTypes from "./topicActionType";
import axios from 'axios';
import { checkError } from './../error';
const baseUrl = process.env.REACT_APP_API_URL;

export const createTopic = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(createTopicInit());
        axios.post(`${baseUrl}/topic/store`, data, config)
        .then((response)=>{
            dispatch(createTopicSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createTopicFailure());
        })
    };
};

export const createTopicInit = () => ({
    type: actionTypes.CREATE_TOPIC
});

export const createTopicFailure = data => ({
    type: actionTypes.CREATE_TOPIC_FAILURE
});

export const createTopicSuccess = data => ({
    type: actionTypes.CREATE_TOPIC_SUCCESS,
    payload: { data }
});

export const updateTopic = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(updateTopicInit());
        axios.put(`${baseUrl}/topic/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateTopicSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateTopicFailure());
        })
    };
};

export const updateTopicInit = () => ({
    type: actionTypes.UPDATE_TOPIC
});

export const updateTopicFailure = data => ({
    type: actionTypes.UPDATE_TOPIC_FAILURE
});

export const updateTopicSuccess = data => ({
    type: actionTypes.UPDATE_TOPIC_SUCCESS,
    payload: { data }
});

export const deleteTopic = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(deleteTopicInit());
        axios.delete(`${baseUrl}/topic/${id}`, config)
        .then((response)=>{
            dispatch(deleteTopicSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteTopicFailure());
        })
    };
};

export const deleteTopicInit = () => ({
    type: actionTypes.DELETE_TOPIC
});

export const deleteTopicFailure = data => ({
    type: actionTypes.DELETE_TOPIC_FAILURE
});

export const deleteTopicSuccess = data => ({
    type: actionTypes.DELETE_TOPIC_SUCCESS,
    payload: { data }
});

export const fetchTopic = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(fetchTopicInit());
        axios.get(`${baseUrl}/topic`, config)
        .then((response)=>{
            dispatch(fetchTopicSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchTopicFailure());
        })
    };
};

export const fetchTopicInit = () => ({
    type: actionTypes.FETCH_TOPIC
});

export const fetchTopicFailure = data => ({
    type: actionTypes.FETCH_TOPIC_FAILURE
});

export const fetchTopicSuccess = data => ({
    type: actionTypes.FETCH_TOPIC_SUCCESS,
    payload: { data }
});

export const getTopics = (ids) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
    const params = new FormData();
      return (dispatch) => {
        dispatch(getTopicsInit());
        axios.post(`${baseUrl}/topic/options`, { ids }, config)
        .then((response)=>{
            dispatch(getTopicsSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getTopicsFailure());
        })
    };
};

export const getTopicsInit = () => ({
    type: actionTypes.GET_TOPICS
});

export const getTopicsFailure = data => ({
    type: actionTypes.GET_TOPICS_FAILURE
});

export const getTopicsSuccess = data => ({
    type: actionTypes.GET_TOPICS_SUCCESS,
    payload: { data }
});