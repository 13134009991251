import React, { useEffect, useState } from "react";
import DashboardLayout from "./../../shared/components/DashboardLayout";
import DashboardNavbar from "./../../shared/components/DashboardNavbar";
import Loading from './../../shared/Loading';
// MUI
import Grid from "@mui/material/Grid";
import MDButton from "./../../shared/components/MDButton";
import MDInput from "./../../shared/components/MDInput";
import { ThemeDialog } from './component/theme-dialog';
import { ThemeTable } from './component/theme-table';
import { useNavigate } from "react-router-dom";

export default function ThemePage(props) {
  const { 
    createTheme, 
    updateTheme,
    deleteTheme,
    fetchTheme,
    themes,
    isSaved,
    isLoading,
    themeCreated
  } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState({name: "", status: 1, premium: 0, tags: [], image: ""});
  
  useEffect(() => {
    fetchTheme();
  }, []);

  useEffect(() => {
    if (isSaved) {
      fetchTheme();
      setSelected({name: "", status: 1, tags: [], image: ""});
      setOpen(false);
    }
  }, [isSaved]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loading loading={isLoading} />
      <Grid container style={{display: "flex", marginTop: "24px"}}>
        <Grid item style={{marginLeft: "auto"}}>
            <MDButton
              onClick={() => {
                setSelected({name: "", status: 1, tags: [], image: ""});
                setOpen(true)
              }}
                sx={{
                  background: "linear-gradient(#09032B, #312B56)",
                  color: "#fff",
                  marginLeft: "auto"
                }}
            >
                CREATE THEME
            </MDButton>
        </Grid>
      </Grid>
      <ThemeTable
        selected={selected}
        setSelected={setSelected}
        rows={themes}
        open={open}
        setOpen={setOpen}
        updateTheme={updateTheme}
      />
      <ThemeDialog
        open={open}
        setOpen={setOpen}
        updateTheme={updateTheme}
        createTheme={createTheme}
        onClose={() => {
          setSelected({name: "", status: 1, tags: [], image: ""});
          setOpen(false);
        }}
        selected={selected}
        setSelected={setSelected}
      />
    </DashboardLayout>
  );
}
