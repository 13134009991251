import PropTypes from "prop-types";
import MDBox from "../../MDBox";
import MDTypography from "../../MDTypography";
import { TimelineProvider } from "../context";

function TimelineList({ title, children }) {
  return (
    <TimelineProvider>
      <MDBox style={{ background: "white" }}>
        <MDBox pt={1}>
          <MDTypography style={{ color: "#344767", fontWeight: "700", fontSize: "16px" }}>
            {title}
          </MDTypography>
        </MDBox>
        <MDBox pt={2}>{children}</MDBox>
      </MDBox>
    </TimelineProvider>
  );
}

TimelineList.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default TimelineList;
