import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import { makeStyles } from "@mui/styles";
import MDButton from "../../../shared/components/MDButton";
import ringImage from "../../../assets/images/ring.png";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearIcon from "@mui/icons-material/Clear";

const topHead = {
  fontWeight: "700",
  fontSize: "20px",
  marginBottom: "4px",
  color: "#344767",
}
const topText = {
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "140%",
  color: "#344767",
}
const button = {
  background: "#09032B",
  color: "#FFFFFF",
  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  width: "185px",
}
const boxText = { 
  display: "flex",
  justifyContent: "center",
}
const mdbox = {
  background: "#E6E8EB",
  height: "40px",
  width: "40px",
  borderRadius: "100px",
}

export default function EmailVerifyDialog(props) {
  // const classes = useStyles();
  const { open, setOpen } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent>
            <MDBox p={2}>
                <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
                  <MDBox>
                      <MDTypography style={topHead} >
                      Change Password Request
                      </MDTypography>
                      <MDTypography style={topText} >
                      Confirmation of password reset link sent
                      </MDTypography>
                  </MDBox>
                  <MDBox style={mdbox} >
                    <ClearIcon  onClick={() => setOpen(false)}
                      style={{ margin: "12px 12px" }} 
                    />
                  </MDBox>
                </MDBox>
                <MDBox style={boxText}
                    sx={{ marginTop: "31px" }} >
                    <img src={ringImage} alt="Lock logo" />
                </MDBox>
                <MDBox style={boxText}
                    sx={{ marginTop: "32px" }} >
                  <MDTypography style={{ fontWeight: "600", fontSize: "14px", color: "#7B809A" }} >
                    Please update users to check their email inbox
                  </MDTypography>
                </MDBox>
                <MDBox style={boxText}
                    sx={{ marginTop: "18px" }} >
                  <MDButton style={button} fullWidth >
                    CHECK MAIL
                    <ArrowForwardIcon style={{marginLeft: "6px"}} />
                  </MDButton>
                </MDBox>
            </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
