import MediaPage from './media.component';
import { connect } from 'react-redux';
import { deleteMedia, fetchMedia } from './../../api/media/mediaAction';

const mapDispatchToProps = {
    deleteMedia,
    fetchMedia
}

const mapStateToProps = state => ({
    isLoading: state.mediaPage.isLoading,
    isSaved: state.mediaPage.isSaved,
    medias: state.mediaPage.medias,
});

export const MediaPageContainer = connect(mapStateToProps, mapDispatchToProps)(MediaPage);