import { useState, useEffect } from "react";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import MDBox from "../../shared/components/MDBox";
import MDTypography from "../../shared/components/MDTypography";
import Loading from '../../shared/Loading';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import MDAvatar from "../../shared/components/MDAvatar";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import MDButton from "../../shared/components/MDButton";
import FormField from "../../shared/components/FormField";
import breakpoints from "../../assets/theme/base/breakpoints";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import burceMars from "../../assets/images/alec.png";
import TimelineList from "../../shared/components/Timeline/TimelineList";
import TimelineItem from "../../shared/components/Timeline/TimelineItem";
import timelineData from "./component/timelineData.component";
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";
import ProductImages from "./component/product-images.component";
import Header from "./component/header.component";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  formcontrol: {
    '& .css-xyabx4-MuiButtonBase-root-MuiCheckbox-root.Mui-checked .MuiSvgIcon-root':
      {
        backgroundColor: "#09032B",
        backgroundImage: "none",
      },
  },
  listText: {
    '& .css-knlq9b-MuiFormControlLabel-root .MuiFormControlLabel-label ':
      {
        color: "#7B809A",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "140%",
      },
  },
});

const topHead = {
  fontWeight: "700",
  color: "#344767",
}
const topText = {
  fontWeight: "400",
  fontSize: "14px",
  color: "#7B809A",
}
const socialIcon = {
  marginRight: "8px",
  color: "#AEAEAE",
  fontSize: "17px",
}
const twitterIcon = {
  marginRight: "18px",
  color: "#AEAEAE",
  fontSize: "17px",
}
const contentText = {
  fontWeight: "400",
  fontSize: "12px",
  color: " #58585E",
  marginTop: "8px",
}
const paraText = {
  fontSize: "11px",
  color: "#7B809A",
  fontWeight: "400",
  marginTop: "3px",
}
const imageText = {
  width: "20px",
  height: "20px",
  marginRight: "8px",
}
const mdboxText = {
  marginLeft: "auto",
  color: "#AEAEAE",
  fontSize: "17px",
  float: "right",
}
const divider = {
  borderBottom: "1px solid #D5D5D5",
  width: "100%",
  marginBottom: "18px",
  marginTop: "18px",
}
const text = {
  fontWeight: "400",
  fontSize: "12px",
  color: "#7B809A",
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <MDBox sx={{ p: 3 }}>{children}</MDBox>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function ContentPage(props) {
  const classes = useStyles();
  const { fetchReviews, isLoading, isError, reviews } = props;
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [tabsOrientation1, setTabsOrientation1] = useState("horizontal");
  const [tabValue1, setTabValue1] = useState(0);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    function handleTabsOrientation1() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation1("vertical")
        : setTabsOrientation1("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();
    handleTabsOrientation1();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const handleSetTabValue1 = (event, newValue) => setTabValue1(newValue);

  const renderTimelineItems = timelineData.map(
    ({ color, icon, title, dateTime, description, badges, lastItem }) => (
      <TimelineItem
        key={title + color}
        color={color}
        icon={icon}
        title={title}
        dateTime={dateTime}
        description={description}
        badges={badges}
        lastItem={lastItem}
      />
    )
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <Loading loading={isLoading} /> */}
      <Grid container spacing={2}>
        <Grid xs={12} lg={8} item>
          <Header>
            <Grid item xs={12} md={8}>
              <AppBar style={{ marginTop: "36px" }} position="static">
                <MDBox>
                  <Tabs
                    style={{background: "#F5F5F5", border: "1px solid #E9E7E7",
                    borderRadius: "8px"}}
                    orientation={tabsOrientation}
                    value={tabValue}
                    onChange={handleSetTabValue}
                  >
                    <Tab label="User Data" {...a11yProps(0)} />
                    <Tab label="Post History" {...a11yProps(1)} />
                    <Tab label="Activities" {...a11yProps(2)} />
                    <Tab label="View Team" {...a11yProps(3)} />
                  </Tabs>
                </MDBox>
              </AppBar>
            </Grid>
            <TabPanel value={tabValue} index={0}>
              <MDBox mt={5} mb={3}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item>
                    <MDAvatar
                      src={burceMars}
                      alt="profile-image"
                      size="xl"
                      shadow="sm"
                    />
                  </Grid>
                  <Grid item>
                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                      <MDButton
                        size="medium"
                        style={{
                          background: "#09032B",
                          color: "#FFFFFF",
                          marginBottom: "8px",
                        }}
                      >
                        Upload Avatar 
                      </MDButton>
                      <PersonAddAltIcon style={{float: "right"}} />
                      <MDTypography style={topText} >
                        Upload Png Or JPG, Dimention 1:1 Ratio
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox mt={1} mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6}>
                    <MDBox mb={1.5} mt={1.5}>
                      <FormField style={{ marginBottom: "21px" }}
                        label="Full Name"
                        placeholder="Alec M. Thompson"
                        inputProps={{ type: "text" }}
                      />
                      <FormField style={{ marginBottom: "21px" }}
                        label="Cell no"
                        placeholder="+8801719441978"
                        inputProps={{ type: "number" }}
                      />
                      <FormField style={{ marginBottom: "21px" }}
                        label="Email Address"
                        placeholder="Alec@gmail.com"
                        inputProps={{ type: "email" }}
                      />
                      <FormField style={{ marginBottom: "21px" }}
                        label="Company Name"
                        placeholder="Business Name"
                        inputProps={{ type: "text" }}
                      />
                      <FormField style={{ marginBottom: "21px" }}
                        label="Job Title"
                        placeholder="Design Lead"
                        inputProps={{ type: "text" }}
                      />
                      <FormField
                        label="Account type"
                        placeholder="Owner"
                        inputProps={{ type: "text" }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <MDBox mb={2}>
                      <MDTypography ml={3} style={topHead}
                        sx={{ fontSize: "14px" }} >
                        Permissions
                      </MDTypography>
                      <List className={classes.listText} >
                        <FormGroup style={{ marginLeft: "38px" }}>
                          <FormControlLabel className={classes.formcontrol}
                            control={<Checkbox defaultChecked />}
                            label="Can Create post"
                          />
                          <FormControlLabel className={classes.formcontrol}
                            control={<Checkbox />}
                            label="Approve Post"
                          />
                          <FormControlLabel className={classes.formcontrol}
                            control={<Checkbox />}
                            label="Reply Posts"
                          />
                          <FormControlLabel className={classes.formcontrol}
                            control={<Checkbox />}
                            label="Can edit other post before approve"
                          />
                          <FormControlLabel className={classes.formcontrol}
                            control={<Checkbox />}
                            label="Can add new users"
                          />
                        </FormGroup>
                      </List>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <MDBox my={3}>
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <TimelineList title="Post History this month">
                      {renderTimelineItems}
                    </TimelineList>
                  </Grid>
                </Grid>
              </MDBox>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <TimelineList title="Post History this month">
                    {renderTimelineItems}
                  </TimelineList>
                </Grid>
              </Grid>
            </TabPanel>
          </Header>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid item>
            <MDBox style={{ borderRadius: "12px", background: "white" }}>
              <MDBox style={{ display: "flex" }}>
                <MDTypography style={topHead}
                  sx={{ fontSize: "16px", padding: "14px" }} >
                  Account and post tracking
                </MDTypography>
                <MDBox sx={{ ml: "auto" }}>
                  <MDTypography style={topText}
                    sx={{ padding: "14px" }} >
                    Contents
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox style={{ padding: "0 14px 14px 12px" }}>
                <Grid item xs={12}>
                  <AppBar position="static">
                    <Tabs
                      style={{
                        background: "#F5F5F5",
                        border: "1px solid #E9E7E7",
                        borderRadius: "8px",
                      }}
                      orientation={tabsOrientation1}
                      value={tabValue1}
                      onChange={handleSetTabValue1}
                    >
                      <Tab
                        style={{ padding: "6px 20px", marginRight: "10px" }}
                        label="Successfull"
                      />
                      <Tab
                        style={{ padding: "6px 20px", marginRight: "10px" }}
                        label="Undelivered"
                      />
                      <Tab style={{ padding: "6px 20px" }} label="Pending" />
                    </Tabs>
                  </AppBar>
                </Grid>
              </MDBox>
              <MDBox style={{ padding: "0 14px 14px 12px" }}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <ProductImages />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <MDBox style={{ marginLeft: "8px" }}>
                      <MDBox style={{ display: "flex" }}>
                        <MDBox style={{ color: "#AEAEAE", fontSize: "17px" }} >
                          <FacebookRoundedIcon style={socialIcon} />
                          <InstagramIcon style={socialIcon} />
                          <TwitterIcon style={twitterIcon} />
                        </MDBox>
                        <MDBox>
                          <MDTypography style={paraText} >
                            31 Jan 12.30 AM
                          </MDTypography>
                        </MDBox>
                        <MDBox style={mdboxText} >
                          <MDBox style={{ marginLeft: "auto" }}>
                            <MoreVertIcon style={{ color: "#7B809A" }} />
                          </MDBox>
                        </MDBox>
                      </MDBox>
                      <MDBox style={{ display: "flex", marginTop: "5px" }}>
                        <Stack direction="row">
                          <MDTypography style={text} >
                            Approved by:
                          </MDTypography>
                          <MDTypography sx={{ fontSize: "12px", color: "#344767" }}
                            variant="body1"
                            fontWeight="medium"
                          >
                            &nbsp;&quot;Name here&quot;
                          </MDTypography>
                        </Stack>
                      </MDBox>
                      <MDBox style={{ display: "flex", marginTop: "8px" }}>
                        <MDAvatar style={imageText}
                          src={burceMars}
                          alt="profile-image"
                          size="sm"
                        />
                        <MDTypography style={text} >
                          &nbsp;Posted by
                        </MDTypography>
                        <MDTypography style={{ fontSize: "12px", fontWeight: "400" }}
                          variant="body1"
                        >
                          &nbsp;&quot;Name here&quot;
                        </MDTypography>
                      </MDBox>
                      <MDTypography style={contentText} >
                        Reference site about Loreum Ipsum, giving inform on its
                        origins, as well as a random Lipsum genera...
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <div style={divider} />
              <MDBox style={{ padding: "0 14px 14px 12px" }}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <ProductImages />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <MDBox style={{ marginLeft: "8px" }}>
                      <MDBox style={{ display: "flex" }}>
                        <MDBox style={{ color: "#AEAEAE", fontSize: "17px" }} >
                          <FacebookRoundedIcon style={socialIcon} />
                          <InstagramIcon style={socialIcon} />
                          <TwitterIcon style={twitterIcon} />
                        </MDBox>
                        <MDBox>
                          <MDTypography style={paraText} >
                            31 Jan 12.30 AM
                          </MDTypography>
                        </MDBox>
                        <MDBox style={mdboxText} >
                          <MDBox style={{ marginLeft: "auto" }}>
                            <MoreVertIcon style={{ color: "#7B809A" }} />
                          </MDBox>
                        </MDBox>
                      </MDBox>
                      <MDBox style={{ display: "flex", marginTop: "5px" }}>
                        <Stack direction="row">
                          <MDTypography style={text} >
                            Approved by:
                          </MDTypography>
                          <MDTypography sx={{ fontSize: "12px", color: "#344767" }}
                            variant="body1"
                            fontWeight="medium"
                          >
                            &nbsp;&quot;Name here&quot;
                          </MDTypography>
                        </Stack>
                      </MDBox>
                      <MDBox style={{ display: "flex", marginTop: "8px" }}>
                        <MDAvatar style={imageText}
                          src={burceMars}
                          alt="profile-image"
                          size="sm"
                        />
                        <MDTypography style={text} >
                          &nbsp;Posted by
                        </MDTypography>
                        <MDTypography style={{ fontSize: "12px", fontWeight: "400" }}
                          variant="body1"
                        >
                          &nbsp;&quot;Name here&quot;
                        </MDTypography>
                      </MDBox>
                      <MDTypography style={contentText} >
                        Reference site about Loreum Ipsum, giving inform on its
                        origins, as well as a random Lipsum genera...
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <div style={divider} />
              <MDBox style={{ padding: "0 14px 14px 12px" }}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <ProductImages />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <MDBox style={{ marginLeft: "8px" }}>
                      <MDBox style={{ display: "flex" }}>
                        <MDBox style={{ color: "#AEAEAE", fontSize: "17px" }} >
                          <FacebookRoundedIcon style={socialIcon} />
                          <InstagramIcon style={socialIcon} />
                          <TwitterIcon style={twitterIcon} />
                        </MDBox>
                        <MDBox>
                          <MDTypography style={paraText} >
                            31 Jan 12.30 AM
                          </MDTypography>
                        </MDBox>
                        <MDBox style={mdboxText} >
                          <MDBox style={{ marginLeft: "auto" }}>
                            <MoreVertIcon style={{ color: "#7B809A" }} />
                          </MDBox>
                        </MDBox>
                      </MDBox>
                      <MDBox style={{ display: "flex", marginTop: "5px" }}>
                        <Stack direction="row">
                          <MDTypography style={text} >
                            Approved by:
                          </MDTypography>
                          <MDTypography sx={{ fontSize: "12px", color: "#344767" }}
                            variant="body1"
                            fontWeight="medium"
                          >
                            &nbsp;&quot;Name here&quot;
                          </MDTypography>
                        </Stack>
                      </MDBox>
                      <MDBox style={{ display: "flex", marginTop: "8px" }}>
                        <MDAvatar style={imageText}
                          src={burceMars}
                          alt="profile-image"
                          size="sm"
                        />
                        <MDTypography style={text} >
                          &nbsp;Posted by
                        </MDTypography>
                        <MDTypography style={{ fontSize: "12px", fontWeight: "400" }}
                          variant="body1"
                        >
                          &nbsp;&quot;Name here&quot;
                        </MDTypography>
                      </MDBox>
                      <MDTypography style={contentText} >
                        Reference site about Loreum Ipsum, giving inform on its
                        origins, as well as a random Lipsum genera...
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <div style={divider} />
              <MDBox style={{ padding: "0 14px 14px 12px" }}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <ProductImages />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <MDBox style={{ marginLeft: "8px" }}>
                      <MDBox style={{ display: "flex" }}>
                        <MDBox style={{ color: "#AEAEAE", fontSize: "17px" }} >
                          <FacebookRoundedIcon style={socialIcon} />
                          <InstagramIcon style={socialIcon} />
                          <TwitterIcon style={twitterIcon} />
                        </MDBox>
                        <MDBox>
                          <MDTypography style={paraText} >
                            31 Jan 12.30 AM
                          </MDTypography>
                        </MDBox>
                        <MDBox style={mdboxText} >
                          <MDBox style={{ marginLeft: "auto" }}>
                            <MoreVertIcon style={{ color: "#7B809A" }} />
                          </MDBox>
                        </MDBox>
                      </MDBox>
                      <MDBox style={{ display: "flex", marginTop: "5px" }}>
                        <Stack direction="row">
                          <MDTypography style={text} >
                            Approved by:
                          </MDTypography>
                          <MDTypography sx={{ fontSize: "12px", color: "#344767" }}
                            variant="body1"
                            fontWeight="medium"
                          >
                            &nbsp;&quot;Name here&quot;
                          </MDTypography>
                        </Stack>
                      </MDBox>
                      <MDBox style={{ display: "flex", marginTop: "8px" }}>
                        <MDAvatar style={imageText}
                          src={burceMars}
                          alt="profile-image"
                          size="sm"
                        />
                        <MDTypography style={text} >
                          &nbsp;Posted by
                        </MDTypography>
                        <MDTypography style={{ fontSize: "12px", fontWeight: "400" }}
                          variant="body1"
                        >
                          &nbsp;&quot;Name here&quot;
                        </MDTypography>
                      </MDBox>
                      <MDTypography style={contentText} >
                        Reference site about Loreum Ipsum, giving inform on its
                        origins, as well as a random Lipsum genera...
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <div style={divider} />
              <MDBox style={{ padding: "0 14px 14px 12px" }}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <ProductImages />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <MDBox style={{ marginLeft: "8px" }}>
                      <MDBox style={{ display: "flex" }}>
                        <MDBox style={{color: "#AEAEAE", fontSize: "17px"}} >
                          <FacebookRoundedIcon style={socialIcon} />
                          <InstagramIcon style={socialIcon} />
                          <TwitterIcon style={twitterIcon} />
                        </MDBox>
                        <MDBox>
                          <MDTypography style={paraText} >
                            31 Jan 12.30 AM
                          </MDTypography>
                        </MDBox>
                        <MDBox style={mdboxText} >
                          <MDBox style={{ marginLeft: "auto" }}>
                            <MoreVertIcon style={{ color: "#7B809A" }} />
                          </MDBox>
                        </MDBox>
                      </MDBox>
                      <MDBox style={{ display: "flex", marginTop: "5px" }}>
                        <Stack direction="row">
                          <MDTypography style={text} >
                            Approved by:
                          </MDTypography>
                          <MDTypography sx={{ fontSize: "12px", color: "#344767" }}
                            variant="body1"
                            fontWeight="medium"
                          >
                            &nbsp;&quot;Name here&quot;
                          </MDTypography>
                        </Stack>
                      </MDBox>
                      <MDBox style={{ display: "flex", marginTop: "8px" }}>
                        <MDAvatar style={imageText}
                          src={burceMars}
                          alt="profile-image"
                          size="sm"
                        />
                        <MDTypography style={text} >
                          &nbsp;Posted by
                        </MDTypography>
                        <MDTypography style={{ fontSize: "12px", fontWeight: "400" }}
                          variant="body1"
                        >
                          &nbsp;&quot;Name here&quot;
                        </MDTypography>
                      </MDBox>
                      <MDTypography style={contentText} >
                        Reference site about Loreum Ipsum, giving inform on its
                        origins, as well as a random Lipsum genera...
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <div style={divider} />
              <MDBox style={{ padding: "0 14px 14px 12px" }}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <ProductImages />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <MDBox style={{ marginLeft: "8px" }}>
                      <MDBox style={{ display: "flex" }}>
                        <MDBox style={{color: "#AEAEAE", fontSize: "17px"}} >
                          <FacebookRoundedIcon style={socialIcon} />
                          <InstagramIcon style={socialIcon} />
                          <TwitterIcon style={twitterIcon} />
                        </MDBox>
                        <MDBox>
                          <MDTypography style={paraText} >
                            31 Jan 12.30 AM
                          </MDTypography>
                        </MDBox>
                        <MDBox style={mdboxText} >
                          <MDBox style={{ marginLeft: "auto" }}>
                            <MoreVertIcon style={{ color: "#7B809A" }} />
                          </MDBox>
                        </MDBox>
                      </MDBox>
                      <MDBox style={{ display: "flex", marginTop: "5px" }}>
                        <Stack direction="row">
                          <MDTypography style={text} >
                            Approved by:
                          </MDTypography>
                          <MDTypography sx={{ fontSize: "12px", color: "#344767" }}
                            variant="body1"
                            fontWeight="medium"
                          >
                            &nbsp;&quot;Name here&quot;
                          </MDTypography>
                        </Stack>
                      </MDBox>
                      <MDBox style={{ display: "flex", marginTop: "8px" }}>
                        <MDAvatar style={imageText}
                          src={burceMars}
                          alt="profile-image"
                          size="sm"
                        />
                        <MDTypography style={text} >
                          &nbsp;Posted by
                        </MDTypography>
                        <MDTypography style={{ fontSize: "12px", fontWeight: "400" }}
                          variant="body1"
                        >
                          &nbsp;&quot;Name here&quot;
                        </MDTypography>
                      </MDBox>
                      <MDTypography style={contentText} >
                        Reference site about Loreum Ipsum, giving inform on its
                        origins, as well as a random Lipsum genera...
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}
