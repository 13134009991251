import * as actionTypes from "./scheduleActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;
export const getSchedules = () => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(getSchedulesInit());
        axios.get(`${baseUrl}/schedule`, config)
        .then((response)=>{
            dispatch(getSchedulesSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getSchedulesFailure());
        })
    };
};

export const getSchedulesInit = () => ({
    type: actionTypes.GET_SCHEDULES
});

export const getSchedulesFailure = data => ({
    type: actionTypes.GET_SCHEDULES_FAILURE
});

export const getSchedulesSuccess = data => ({
    type: actionTypes.GET_SCHEDULES_SUCCESS,
    payload: { data }
});

