const defaultLineChartData = {
  labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  datasets: [
    {
      label: "Facebook",
      color: "info",
      data: [50, 100, 200, 190, 400, 350, 500, 450, 700],
    },
  ],
};

export default defaultLineChartData;
