import IndustryPage from './industry.component';
import { connect } from 'react-redux';
import { createIndustry, updateIndustry, deleteIndustry, fetchIndustry } from './../../api/industry/industryAction';

const mapDispatchToProps = {
    createIndustry, 
    updateIndustry,
    deleteIndustry,
    fetchIndustry
}

const mapStateToProps = state => ({
    isLoading: state.industryPage.isLoading,
    isSaved: state.industryPage.isSaved,
    industries: state.industryPage.industries,
});

export const IndustryPageContainer = connect(mapStateToProps, mapDispatchToProps)(IndustryPage);