import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
import MDTypography from "../MDTypography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import MDBox from "../MDBox";
import MDInput from "../MDInput";
import MDPagination from "../MDPagination";
import { styled, useTheme } from "@mui/material/styles";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { Stack, Switch } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import DataTableHeadCell from "../DataTable/DataTableHeadCell";
import FilterListIcon from "@mui/icons-material/FilterList";
import DataTableBodyCell from "../DataTable/DataTableBodyCell";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  formcontrol: {
    '& .css-xyabx4-MuiButtonBase-root-MuiCheckbox-root.Mui-checked .MuiSvgIcon-root': {
      backgroundColor: "#09032B",
      backgroundImage: "none",
    },
  },
  listText: {
    '& .css-knlq9b-MuiFormControlLabel-root .MuiFormControlLabel-label': {
      color: "#534F6B",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "140%",
    },
  },
  iconText: {
    backgroundColor: "#7B809A", 
    color: "#fff", 
    marginRight: "12px"
  },
});

const BATYpo1 = {
  fontFamily: "roboto",
  fontWeight: "400",
  fontSize: "14px",
  marginLeft: "19px",
  color: "#534F6B",
  marginTop: "7px",
};
const BATYpo2 = {
  fontFamily: "roboto",
  fontWeight: "400",
  fontSize: "14px",
  color: "#534F6B",
  marginTop: "7px",
};

const filterbtn = {
  border: "1px solid #7B809A",
  borderRadius: "8px",
  marginLeft: "10px",
  width: "140px",
  color: "#7B809A",
  fontWeight: "400",
  fontSize: "16px",
};

const filtertye = {
  fontFamily: "roboto",
  fontWeight: "700",
  fontSize: "20px",
  color: "#344767",
  marginLeft: "19px",
};
const topHead = {
  color: "#09032B", 
  fontWeight: "700", 
  fontSize: " 20px"
}
const mdText = {
  margin: "26px 0px 14px 0px", 
  fontWeight: "700", 
  fontSize: "14px", 
  color: "#344767"
}

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  noEndBorder,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, globalFilter },
  } = tableInstance;

  const classes = useStyles();
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  const [search, setSearch] = useState(globalFilter);

  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableContainer style={{ backgroundColor: "#ffffff" }}>
      <MDBox p={3} display="flex" ml="auto" justifyContent="space-between">
        <MDTypography style={topHead} variant="body2" >
          Business Accounts
        </MDTypography>
        <MDBox display="flex">
          <MDBox width="173px">
            {canSearch && (
              <MDInput
                placeholder="Search here"
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            )}
          </MDBox>
          <IconButton
            style={filterbtn}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <FilterListIcon className={classes.iconText} />
              Filters
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MDTypography style={filtertye}>Filter Type</MDTypography>
            <Divider />
            <Stack direction="row" spacing={1}>
              <MDTypography style={BATYpo1}>Business account</MDTypography>
              <Switch />
              <MDTypography style={BATYpo2}>User account</MDTypography>
            </Stack>
            <Divider />
            <List className={classes.listText} >
            <FormGroup style={{ marginLeft: "38px" }}>
              <FormControlLabel className={classes.formcontrol}
                control={<Checkbox defaultChecked />}
                label="Subcription end soon"
              />
              <FormControlLabel className={classes.formcontrol}
                control={<Checkbox />}
                label="Subscription close"
              />
              <FormControlLabel className={classes.formcontrol}
                control={<Checkbox />}
                label="Subbscriptio end last month"
              />
              <FormControlLabel className={classes.formcontrol}
                control={<Checkbox />}
                label="One Year deactive"
              />
              <FormControlLabel className={classes.formcontrol}
                control={<Checkbox />}
                label="No Subscription"
              />
              <FormControlLabel className={classes.formcontrol}
                control={<Checkbox />}
                label="Email Verified"
              />
              <FormControlLabel className={classes.formcontrol}
                control={<Checkbox />}
                label="Email not verified"
              />
              <FormControlLabel className={classes.formcontrol}
                control={<Checkbox />}
                label="Payement verified"
              />
              <MDTypography style={mdText} >
                Interest wise short
              </MDTypography>
              <FormControlLabel className={classes.formcontrol}
                control={<Checkbox />}
                label="Technology Company"
              />
              <FormControlLabel className={classes.formcontrol}
                control={<Checkbox />}
                label="Technology Company"
              />
              <FormControlLabel className={classes.formcontrol}
                control={<Checkbox />}
                label="Technology Company"
              />
              <FormControlLabel className={classes.formcontrol}
                control={<Checkbox />}
                label="Technology Company"
              />
              <FormControlLabel className={classes.formcontrol}
                control={<Checkbox />}
                label="Technology Company"
              />
            </FormGroup>
          </List>
          </Menu>
        </MDBox>
      </MDBox>
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <DataTableBodyCell
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;
