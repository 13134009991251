import * as actionType from "./userActionType";

const initialState = {
    isError: false,
    isLoading: false,
    user: {},
    passwordChanged: false,
    isSaved: false,
    teams: [],
    teamDeleted: false,
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.FETCH_ALL_USERS:
        return {
            ...state,
            isError: false,
            isLoading: true,
        };
    case actionType.FETCH_ALL_USERS_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false,
        };
    case actionType.FETCH_TEAM:
        return {
            ...state,
            isError: false,
            isLoading: true,
        };
    case actionType.FETCH_TEAM_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false
        };
    case actionType.FETCH_TEAM_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            teams: action.payload ? action.payload.data : [],
        };    
        case actionType.DELETE_TEAM:
            return {
                ...state,
                isError: false,
                isLoading: true,
                teamDeleted: false
            };
        case actionType.DELETE_TEAM_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.DELETE_TEAM_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                teamDeleted: true
            };
    case actionType.FETCH_ALL_USERS_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            users: action.payload ? action.payload.data.rows : [],
        };
        case actionType.FETCH_USER_BY_ID:
        return {
            ...state,
            isError: false,
            isLoading: true,
        };
        case actionType.FETCH_USER_BY_ID_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.FETCH_USER_BY_ID_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                user: action.payload ? action.payload.data : {},
            };
            case actionType.CHANGE_PASSWORD:
                return {
                    ...state,
                    isError: false,
                    isLoading: true,
                    passwordChanged: false,
                };
                case actionType.CHANGE_PASSWORD_FAILURE:
                    return {
                        ...state,
                        isError: true,
                        isLoading: false,
                        passwordChanged: false,
                    };
                case actionType.CHANGE_PASSWORD_SUCCESS:
                    return {
                        ...state,
                        isError: false,
                        isLoading: false,
                        passwordChanged: true,
                    };
                    case actionType.UPDATE_USER_ACCOUNT:
                        return {
                            ...state,
                            isError: false,
                            isLoading: true,
                            isSaved: false
                        };
                    case actionType.UPDATE_USER_ACCOUNT_FAILURE:
                        return {
                            ...state,
                            isError: true,
                            isSaved: false,
                            isLoading: false
                        };
                    case actionType.UPDATE_USER_ACCOUNT_SUCCESS:
                        return {
                            ...state,
                            isError: false,
                            isLoading: false,
                            isSaved: true
                        };
        default: 
            return state
    }
}