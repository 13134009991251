import { useState } from "react";
// import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import MDAvatar from "./../../shared/components/MDAvatar";
import MDTypography from "./../../shared/components/MDTypography";
import MDBox from "./../../shared/components/MDBox";
import MDButton from "./../../shared/components/MDButton";
// import Footer from "./../../shared/components/Footer";
import FormField from "./../../shared/components/FormField";
import Loading from './../../shared/Loading';
import logo from "./../../assets/images/logo-ct-dark.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";
import { GoogleLogin } from "react-google-login";

const useStyles = makeStyles({
  googleBtn: {
    width: "100%",
    backgroundColor: "#F1F1F1!important",
    color: "#1B172E!important",
    justifyContent: "center",
    fontWeight: "700!important",
    fontSize: "14px!important",
    borderRadius: "8px!important",
    "& div": {
      backgroundColor: "#F1F1F1!important",
    },
  },
});

export default function LoginPage(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { adminLogin, admin, isLoggedIn, isLoading } = props;
  const mdText1 = {
    color: "red",
    marginTop: "-0px",
    marginBottom: "14px",
    fontSize: "12px",
    fontWeight: 400,
  };
  const mdText2 = {
    color: "red",
    marginTop: "-14px",
    marginBottom: "14px",
    fontSize: "12px",
    fontWeight: 400,
  };
  const [values, setValues] = useState({
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const submitHandle = (event) => {
    event.preventDefault();
    setErrors(validate(values));
  };
  const validate = (values) => {
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;
    if (!values.username) {
      isError = true;
      errorss.username = "Email is required";
    } else if (!regex.test(values.username)) {
      isError = true;
      errorss.username = "This is not a valid email format";
    }
    if (!values.password) {
      isError = true;
      errorss.password = "Password is required";
    }
    if (!isError) {
      adminLogin(values);
    }
    return errorss;
  };

  if (isLoggedIn) {
    window.location.href = `${process.env.REACT_APP_BASE_URL}/#/user-account`;
  }

  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const x = 0;
  const y = -11;
  const ortext = {
    transform: `translate(${x}px, ${y}px)`,
    backgroundColor: "white",
    width: "33px",
    margin: "0px auto",
    borderRadius: "25px",
    color: "#344767",
    fontSize: "14px",
  };
  const orborder = {
    borderTop: "1px dashed #E4E4E4",
    textAlign: "center",
    marginTop: "24px",
  };

  return (
    <>
      <Loading loading={isLoading} />
      <Grid style={{ backgroundColor: "#101030f0", minHeight: "100vh" }}>
        <Grid item
          style={{ display: "flex", paddingLeft: "45px", paddingTop: "32px" }}
        >
          <MDAvatar
            src={logo}
            variant="square"
            style={{ width: "13px", height: "22px", marginTop: "5px" }}
          />
          &nbsp;&nbsp;
          <MDTypography style={{ color: "white" }}>Social Broker</MDTypography>
        </Grid>
        <Grid item style={{ display: "flex", justifyContent: "center" }}>
          <MDBox
            style={{
              backgroundColor: "white",
              width: "469px",
              height: "auto",
              padding: "0px 25px",
              borderRadius: "12px",
              marginTop: "149px",
              paddingBottom: "40px"
            }}
            // onSubmit={handleSubmit(onSubmit)}
          >
            <MDBox style={{ textAlign: "center" }}>
              <MDTypography
                style={{
                  color: "#2A3342",
                  fontSize: "25px",
                  fontWeight: "700",
                  marginTop: "40px",
                }}
              >
                Sign in to your account
              </MDTypography>
              <MDTypography
                style={{
                  color: "#556987",
                  fontSize: "16px",
                  fontWeight: "700",
                  marginTop: "5px",
                }}
              >
                Let’s get social
              </MDTypography>
            </MDBox>
            <form>
              <MDBox mb={2} style={{ marginTop: "24px" }}>
                <FormField
                  label="Email"
                  name="username"
                  placeholder="john@email.com"
                  type="email"
                  value={values.username}
                  onChange={handleChange}
                />
                {errors.username && (
                <MDTypography style={mdText1}>{errors.username}</MDTypography>
              )}
              </MDBox>
              <MDBox mb={2} style={{ marginTop: "24px" }}>
                <FormField
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="****************"
                  value={values.password}
                  onChange={handleChange}
                />
              </MDBox>
              {errors.password && (
                <MDTypography style={mdText2}>{errors.password}</MDTypography>
              )}
              <MDBox
                display="block"
                alignItems="center"
                ml={0}
                style={{ marginTop: "24px" }}
              >
                <Grid>
                  <Grid item>
                    <Checkbox />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleSetRememberMe}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Remember me
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <MDButton
                      component={Link}
                      to="/business-details"
                      sx={{
                        color: "#f4233d",
                        fontSize: "12px",
                        "@media (min-width: 320px)": {
                          float: "left",
                          marginTop: "0px",
                        },
                        "@media (min-width: 364px)": {
                          float: "right",
                          marginTop: "-35px",
                        },
                      }}
                    >
                      Forget Password?
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  onClick={submitHandle}
                  style={{ backgroundColor: "#09032B", color: "white" }}
                  size="large"
                  fullWidth
                  type="submit"
                >
                  sign in
                </MDButton>
              </MDBox>
            </form>
          </MDBox>
        </Grid>
      </Grid>
    </>
  );
}
