import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import FormField from "./../../../shared/components/FormField";
import ClearIcon from "@mui/icons-material/Clear";
import MDButton from "../../../shared/components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const topHead = {
  fontWeight: "700",
  fontSize: "20px",
  color: "#221C40",
};
const button = {
  background: "linear-gradient(180.67deg, #09032B 2.43%, #312B56 101%)",
  color: "#FFFFFF",
  borderRadius: "8px",
  width: "189px",
};
const boxText = {
  borderRadius: "12px",
  border: "1px solid #DEE1E6",
  width: "128px",
  height: "120px",
};
const buttonBox = {
  display: "flex",
  justifyContent: "center",
  marginTop: "52px",
};
const mdbox = {
  background: "#E6E8EB",
  height: "40px",
  width: "40px",
  borderRadius: "100px",
};
const mdText1 = {
  color: "red",
  marginTop: "-0px",
  marginBottom: "14px",
  fontSize: "12px",
  fontWeight: 400,
};

const X = 5;
const Y = 8;
const A = -4;
const B = -6;

export function TopicDialog(props) {
  const { open, setOpen, selected, setSelected, onClose, createTopic, updateTopic, industries } = props;
  const [errors, setErrors] = React.useState(false);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox style={{ maxWidth: "274px" }}>
                <MDTypography style={topHead}>
                  {selected.id ? "Update Topic" : "Create Topic"}
                </MDTypography>
              </MDBox>
              <MDBox style={mdbox}>
                <ClearIcon
                  onClick={() => onClose()}
                  style={{ margin: "12px 12px", cursor: "pointer" }}
                />
              </MDBox>
            </MDBox>
            
            <Autocomplete
              options={Array.isArray(industries) ? industries : []}
              value={Array.isArray(industries) ? industries.filter(c => c.id == selected.IndustryId)[0] : ""}
              onChange={(event, newValue) => {
                setSelected({
                  ...selected,
                  IndustryId: newValue.id,
                });
                setErrors(false);
              }}
              renderInput={(params) => (
                <FormField
                {...params}
                  label="Choose Industry"
                  name="IndustryId"
                  placeholder="Select Industry"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            {errors.industries && (
              <MDTypography style={mdText1}>
                {errors.industries}
              </MDTypography>
            )}
            <MDBox mb={2} style={{ marginTop: "24px" }}>
              <FormField
                label="Name"
                name="name"
                placeholder="Topic Name"
                type="text"
                value={selected.name}
                onChange={e => {
                  setSelected({ ...selected, name: e.target.value });
                  setErrors(false);
                }}
              />
              {errors.name && (
                <MDTypography style={mdText1}>Name is required.</MDTypography>
              )}
            </MDBox>

            <MDBox style={buttonBox}>
              <MDButton
                style={button}
                fullWidth
                onClick={() => {
                  if (selected.name == "") {
                    setErrors({ name: "Name is required." });
                    return;
                  } else if (selected.IndustryId == "") {
                    setErrors({ industries: "Industry is required." })
                  } else {
                    setErrors(false);
                    if (selected.id) {
                      updateTopic(selected);
                    } else {
                      createTopic(selected);
                    }
                  }
                }}
              >
                SAVE
              </MDButton>
            </MDBox>
            </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
