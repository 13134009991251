import { useState, useEffect } from 'react';
import Card from "@mui/material/Card";
import TableCell from "@mui/material/TableCell";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import IdCell from "./../../../../shared/components/DataTable/components/IdCell";
import DefaultCell from "./../../../../shared/components/DataTable/components/DefaultCell";
import { Stack } from "@mui/material";
import MDAvatar from "./../../../../shared/components/MDAvatar";
import MDBox from "./../../../../shared/components/MDBox";
import MDTypography from "./../../../../shared/components/MDTypography";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import SendIcon from '@mui/icons-material/Send';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import { preetyDate } from "./../../../../helper";

const tableHeading = {
  fontWeight: 700,
  fontSize: "11px",
  color: "#7B809A"
}

const tablecell = {
  fontWeight: 700,
  fontSize: "12px",
  color: "#7B809A"
}

export default function List(props) {
  const { content, selected, setSelected, setDeleteOpen } = props;

  const checkPr = (x, type) => {
    if (Array.isArray(x)) {
      const y = x.filter(z => z.type == type);
      return y.length > 0;
    }
    return false;
  }

  return (
    <Card style={{ marginTop: "39px" }}>
      <TableContainer component={Paper} sx={{ width: "100%" }}>
        <Table>
            <TableRow>
              <TableCell style={tableHeading}>DATE</TableCell>
              <TableCell style={tableHeading}>CONTENT TYPE</TableCell>
              <TableCell style={tableHeading}>PLATFORMS</TableCell>
              <TableCell style={tableHeading}>STATUS</TableCell>
              <TableCell style={tableHeading}>ACTION</TableCell>
              <TableCell style={tableHeading}>HASHTAGS</TableCell>
              <TableCell style={tableHeading}>CONTENT</TableCell>
            </TableRow>
          <TableBody>
            {content && Array.isArray(content) && content.map(x => {
              return (
                <TableRow>
                  <TableCell style={tablecell}>{x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 ? preetyDate(x.Schedules[0].scTime) : "-"}</TableCell>
                  <TableCell style={tablecell}>
                    {"Social Post"}
                    <img width="100px" src={x.images && Array.isArray(JSON.parse(x.images)) && JSON.parse(x.images).length > 0 && JSON.parse(x.images)[0].image} />
                  </TableCell>
                  <TableCell>
                  {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && checkPr(JSON.parse(x.Schedules[0].ProfileIds), "insta") && (
                    <InstagramIcon
                      style={{
                        borderRadius: "4px",
                        margin: "2px",
                        width: "21px",
                        height: "21px",
                        color: "#6B6880"
                      }}
                    />
                  )}
                  {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && checkPr(JSON.parse(x.Schedules[0].ProfileIds), "facebook") && (
                    <FacebookRoundedIcon
                      style={{
                        borderRadius: "4px",
                        margin: "2px",
                        width: "21px",
                        height: "21px",
                        color: "#6B6880"
                      }}  
                    />
                  )}
                  {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && checkPr(JSON.parse(x.Schedules[0].ProfileIds), "linkedin") && (
                    <LinkedInIcon
                      style={{
                        borderRadius: "4px",
                        margin: "2px",
                        width: "21px",
                        height: "21px",
                        color: "#6B6880"
                      }}
                    />
                  )}
                  {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && checkPr(JSON.parse(x.Schedules[0].ProfileIds), "gmb") && (
                    <StorefrontRoundedIcon
                      style={{
                        borderRadius: "4px",
                        width: "21px",
                        height: "21px",
                        color: "#6B6880"
                      }}
                    />
                  )}
                  </TableCell>
                  <TableCell style={tablecell}>
                    {x.status == 3 ? <Chip size="small" label="Ready to review"/> : x.status == 1 ? <Chip size="small" label="Draft" /> : x.status == 0 ? <Chip size="small" label="Disable" /> : "Approved"}
                  </TableCell>
                  <TableCell style={tablecell}>
                    <IconButton
                      onClick={() => {
                        setSelected({
                          id: x.id,
                          AccountId: x.AccountId,
                          ProfileIds: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && JSON.parse(x.Schedules[0].ProfileIds),
                          content: JSON.parse(x.content),
                          layouts: JSON.parse(x.layouts),
                          images: x.images,
                          hashtags: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && JSON.parse(x.Schedules[0].hashtags),
                          scDate: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].scDate,
                          scTime: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].scTime,
                          status: x.status,
                          scheduledId: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].id
                        });
                        setDeleteOpen(true);
                      }}
                    >
                    <DeleteOutlineIcon fontSize="small" />
                  </IconButton>
                  </TableCell>
                  <TableCell style={tablecell}>{x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && JSON.parse(x.Schedules[0].hashtags).all}</TableCell>
                  <TableCell style={tablecell} width="20%">
                    <p style={{display: "-webkit-box", WebkitLineClamp: 3, WebkitBoxOrient: "vertical", overflow: "hidden",}}>
                      {JSON.parse(x.content).all}
                    </p>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
