import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import { makeStyles } from "@mui/styles";
import MDButton from "../../../shared/components/MDButton";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ClearIcon from "@mui/icons-material/Clear";
import NoteAltIcon from "@mui/icons-material/NoteAlt";

const topHead = {
  fontWeight: "700",
  fontSize: "20px",
  color: "#221C40",
};
const button = {
  background: "linear-gradient(180.67deg, #09032B 2.43%, #312B56 101%)",
  color: "#FFFFFF",
  borderRadius: "8px",
  width: "189px",
};
const boxText = {
  borderRadius: "12px",
  border: "1px solid #DEE1E6",
  width: "128px",
  height: "120px",
};
const buttonBox = {
  display: "flex",
  justifyContent: "center",
  marginTop: "52px",
};
const mdbox = {
  background: "#E6E8EB",
  height: "40px",
  width: "40px",
  borderRadius: "100px",
};
const iconBox = {
  height: "30px",
  width: "30px",
  border: "1px solid #FFFFFF",
  borderRadius: "100px",
  background: "#F0F2F5",
}
const iconText = {
  width: "13px",
  height: "13px",
  margin: "7px 7px",
}
const text = {
  fontWeight: "500",
  fontSize: "12px",
  color: "#3A3555",
}

const X = 5;
const Y = 8;
const A = -4;
const B = -6;

export default function ContentDialog(props) {
  // const classes = useStyles();
  const { open, setOpen } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox style={{ maxWidth: "274px" }}>
                <MDTypography style={topHead}>
                  What type of content would you like to create?
                </MDTypography>
              </MDBox>
              <MDBox style={mdbox}>
                <ClearIcon
                  onClick={() => setOpen(false)}
                  style={{ margin: "12px 12px" }}
                />
              </MDBox>
            </MDBox>
            <MDBox style={buttonBox}>
              <MDBox style={boxText} sx={{ marginRight: "20px" }}>
                <MDBox style={{ display: "grid", marginTop: "23px" }}>
                  <MDBox style={{ display: "flex", justifyContent: "center" }}>
                    <MDBox style={iconBox}
                      sx={{ transform: `translate(${X}px, ${Y}px)` }} >
                      <FacebookOutlinedIcon style={iconText}
                        sx={{ color: "#1877F2" }} />
                    </MDBox>
                    <MDBox style={iconBox} >
                      <InstagramIcon style={iconText}
                        sx={{ color: "red" }} />
                    </MDBox>
                  </MDBox>
                  <MDBox style={{ display: "flex", justifyContent: "center" }}>
                    <MDBox style={iconBox} >
                      <LinkedInIcon
                        style={{ width: "13px", height: "13px", color: "#2686B9", margin: "7px 7px", }}
                      />
                    </MDBox>
                    <MDBox style={iconBox}
                      sx={{ transform: `translate(${A}px, ${B}px)` }} >
                      <StorefrontIcon style={iconText}
                        sx={{ color: "#709BE0" }} />
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography style={text}
                    sx={{ marginTop: "6px" }} >
                    Social Media Post
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox style={boxText}>
                <MDBox style={{ display: "flex", justifyContent: "center" }}>
                  <NoteAltIcon
                    style={{
                      width: "43px",
                      height: "43px",
                      color: "#36D6E7",
                      marginTop: "29px",
                    }}
                  />
                </MDBox>
                <MDBox style={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography style={text}
                    sx={{ marginTop: "16px" }} >
                    Blog Article
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox style={buttonBox}>
              <MDButton style={button} fullWidth>
                GET STARTED
              </MDButton>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
