import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import FormField from "../../../shared/components/FormField";
import MDTypography from "../../../shared/components/MDTypography";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import MDButton from "../../../shared/components/MDButton";
import paypalLogo from "../../../assets/images/logos/paypal.png";
import stripeLogo from "../../../assets/images/logos/stripe.png";
import ClearIcon from "@mui/icons-material/Clear";

const topHead = {
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "140%",
  color: "#344767",
}
const topText = {
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "140%",
  color: "#7B809A",
  marginTop: "4px"
}

export default function PaymentDetailsDialog(props) {
  // const classes = useStyles();
  const { open, setOpen } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox>
                  <MDTypography style={topHead} >
                  Update Payment Details
                  </MDTypography>
                  <MDTypography style={topText} >
                    Update your payment details from here
                  </MDTypography>
              </MDBox>
              <MDBox
                style={{
                  background: "#E6E8EB",
                  height: "40px",
                  width: "40px",
                  borderRadius: "100px",
                }}
              >
                <ClearIcon  onClick={() => setOpen(false)}
                  style={{ margin: "12px 12px" }} 
                />
              </MDBox>
            </MDBox>
            <Grid container spacing={2} style={{marginTop: "23px"}}>
              <Grid item xs={12}>
                <MDBox
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                    sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                >
                  <MDBox
                      component="img"
                      src={paypalLogo}
                      alt="paypal card"
                      width="10%"
                      mr={2}
                  />
                  <MDTypography variant="h6" fontWeight="medium">
                      Paypal Account
                  </MDTypography>
                  <MDBox ml="auto" lineHeight={0}>
                      <MDButton variant="text" style={{ color: "#09032B" }}>
                          Change
                      </MDButton>
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDBox
                    component="img"
                    src={stripeLogo}
                    alt="master card"
                    width="10%"
                    mr={2}
                  />
                  <MDTypography variant="h6" fontWeight="medium">
                    Stripe Account
                  </MDTypography>
                  <MDBox ml="auto">
                    <MDButton variant="text" style={{ color: "#09032B" }}>
                      Change
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDTypography 
              pl={3}  
              style={{fontWeight: "700", fontSize: "16px", color: "#344767", marginTop: "30px"}}>
              Previous Card Details
          </MDTypography>
          <MDBox component="form" pb={3} px={3} mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormField
                  label="Enter Card Number *"
                  placeholder="1155 111 2222"
                  inputProps={{ type: "cardnumber" }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  label="Card Holder Name *"
                  placeholder="Name of the card holder"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormField
                  label="CVV"
                  placeholder="CVV Number"
                  inputProps={{ type: "number" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormField
                  label="Expry date"
                  placeholder="DD / YYYY"
                  inputProps={{ type: "date" }}
                />
              </Grid>
            </Grid>
            <MDBox
              style={{
                marginTop: "39px",
                justifyContent: "center",
                alignIitems: "center",
                display: "flex",
              }}
            >
              <MDButton
                style={{
                  background: "#09032B",
                  color: "#FFFFFF",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                  borderRadius: "8px",
                  width: "355px",
                }}
                fullWidth
              >
                UPDATE DETAILS
              </MDButton>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
