import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import FormField from "./../../../shared/components/FormField";
import ClearIcon from "@mui/icons-material/Clear";
import MDButton from "../../../shared/components/MDButton";
import { createWhite, updateWhite } from "../../../api/white/whiteAction";

const topHead = {
  fontWeight: "700",
  fontSize: "20px",
  color: "#221C40",
};
const button = {
  background: "linear-gradient(180.67deg, #09032B 2.43%, #312B56 101%)",
  color: "#FFFFFF",
  borderRadius: "8px",
  width: "189px",
};
const boxText = {
  borderRadius: "12px",
  border: "1px solid #DEE1E6",
  width: "128px",
  height: "120px",
};
const buttonBox = {
  display: "flex",
  justifyContent: "center",
  marginTop: "52px",
};
const mdbox = {
  background: "#E6E8EB",
  height: "40px",
  width: "40px",
  borderRadius: "100px",
};
const mdText1 = {
  color: "red",
  marginTop: "-0px",
  marginBottom: "14px",
  fontSize: "12px",
  fontWeight: 400,
};

const X = 5;
const Y = 8;
const A = -4;
const B = -6;

export function WhiteDialog(props) {
  const { open, setOpen, selected, setSelected, onClose, createWhite, updateWhite } = props;
  const [error, setError] = React.useState(false);
  const onImageChange = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    )
    setSelected({ ...selected, logo: event.target.files[0] });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox style={{ maxWidth: "274px" }}>
                <MDTypography style={topHead}>
                  {selected.id ? "Update White" : "Create White"}
                </MDTypography>
              </MDBox>
              <MDBox style={mdbox}>
                <ClearIcon
                  onClick={() => onClose()}
                  style={{ margin: "12px 12px", cursor: "pointer" }}
                />
              </MDBox>
            </MDBox>
            <MDBox mb={2} style={{ marginTop: "24px" }}>
              <FormField
                label="First Name"
                name="firstName"
                placeholder="First Name"
                type="text"
                value={selected.firstName}
                onChange={e => {
                  setSelected({ ...selected, firstName: e.target.value });
                  setError(false);
                }}
              />
              {error != "" && (
                <MDTypography style={mdText1}>First Name is required.</MDTypography>
              )}
            </MDBox>
            <MDBox mb={2} style={{ marginTop: "24px" }}>
              <FormField
                label="Last Name"
                name="lastName"
                placeholder="Last Name"
                type="text"
                value={selected.lastName}
                onChange={e => {
                  setSelected({ ...selected, lastName: e.target.value });
                  setError(false);
                }}
              />
              {error != "" && (
                <MDTypography style={mdText1}>Last Name is required.</MDTypography>
              )}
            </MDBox>
            <MDBox mb={2} style={{ marginTop: "24px" }}>
              <FormField
                label="Email"
                name="email"
                placeholder="Email"
                type="text"
                value={selected.email}
                onChange={e => {
                  setSelected({ ...selected, email: e.target.value });
                  setError(false);
                }}
              />
              {error != "" && (
                <MDTypography style={mdText1}>Email is required.</MDTypography>
              )}
            </MDBox>
            <MDBox mb={2} style={{ marginTop: "24px" }}>
              <FormField
                label="Phone Number"
                name="phoneNo"
                placeholder="Business Name"
                type="text"
                value={selected.phoneNo}
                onChange={e => {
                  setSelected({ ...selected, phoneNo: e.target.value });
                  setError(false);
                }}
              />
              {error != "" && (
                <MDTypography style={mdText1}>Phone No is required.</MDTypography>
              )}
            </MDBox>
            {!selected.id && (
              <MDBox mb={2} style={{ marginTop: "24px" }}>
                <FormField
                  label="Password"
                  name="password"
                  placeholder="Password"
                  type="text"
                  value={selected.password}
                  onChange={e => {
                    setSelected({ ...selected, password: e.target.value });
                    setError(false);
                  }}
                />
                {error != "" && (
                  <MDTypography style={mdText1}>Password is required.</MDTypography>
                )}
              </MDBox>
            )}
            <MDBox mb={2} style={{ marginTop: "24px" }}>
              <FormField
                label="Business Name"
                name="businessName"
                placeholder="Business Name"
                type="text"
                value={selected.businessName}
                onChange={e => {
                  setSelected({ ...selected, businessName: e.target.value });
                  setError(false);
                }}
              />
              {error != "" && (
                <MDTypography style={mdText1}>Business Name is required.</MDTypography>
              )}
            </MDBox>
            <MDBox mb={2} style={{ marginTop: "24px" }}>
              <FormField
                label="Business Slug"
                name="businessSlug"
                placeholder="Business Slug"
                type="text"
                value={selected.businessSlug}
                onChange={e => {
                  setSelected({ ...selected, businessSlug: e.target.value });
                  setError(false);
                }}
              />
              {error != "" && (
                <MDTypography style={mdText1}>Business Slug is required.</MDTypography>
              )}
            </MDBox>
            <MDBox mb={2} style={{ marginTop: "24px" }}>
              <FormField
                label="Primary Color"
                name="primaryColor"
                placeholder="Primary Color"
                type="text"
                value={selected.primaryColor}
                onChange={e => {
                  setSelected({ ...selected, primaryColor: e.target.value });
                  setError(false);
                }}
              />
              {error != "" && (
                <MDTypography style={mdText1}>Primary Color is required.</MDTypography>
              )}
            </MDBox>
            <MDBox mb={2} style={{ marginTop: "24px" }}>
              <FormField
                label="Secondary Color"
                name="secondaryColor"
                placeholder="Secondary Color"
                type="text"
                value={selected.secondaryColor}
                onChange={e => {
                  setSelected({ ...selected, secondaryColor: e.target.value });
                  setError(false);
                }}
              />
              {error != "" && (
                <MDTypography style={mdText1}>Secondary Color is required.</MDTypography>
              )}
            </MDBox>
            <MDBox mb={2} style={{ marginTop: "24px" }}>
              <FormField
                label="Sidebar Color"
                name="navColor"
                placeholder="Sidebar Color"
                type="text"
                value={selected.navColor}
                onChange={e => {
                  setSelected({ ...selected, navColor: e.target.value });
                  setError(false);
                }}
              />
              {error != "" && (
                <MDTypography style={mdText1}>Sidebar Color is required.</MDTypography>
              )}
            </MDBox>
            <MDBox component="label" htmlFor="image-id" sx={{ p: "10px" }}>
              <div style={{ width: "100%", height: "100px", display: "flex", justifyContent: "center", alignItems: "center",  border: "2px solid #eaeaea", borderRadius: "20px" }}>
                {selected && selected.id && typeof selected.logo === "string" ? <img src={selected.logo} height="100px" /> : selected.logo != "" && selected.logo != null ? <img src={URL.createObjectURL(selected.logo)} height="100px" /> : <strong>Upload Logo</strong>}
              </div>
              <input style={{ display: "none" }} type="file" id="image-id" onChange={onImageChange} />
            </MDBox>

            <MDBox style={buttonBox}>
              <MDButton
                style={button}
                fullWidth
                onClick={() => {
                  if (selected.name == "") {
                    setError(true);
                    return;
                  } else {
                    setError(false);
                    if (selected.id) {
                      updateWhite(selected);
                    } else {
                      createWhite(selected);
                    }
                  }
                }}
              >
                SAVE
              </MDButton>
            </MDBox>
            </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
