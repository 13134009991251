import * as actionTypes from "./mediaActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;


export const deleteMedia = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(deleteMediaInit());
        axios.delete(`${baseUrl}/media/${id}`, config)
        .then((response)=>{
            dispatch(deleteMediaSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteMediaFailure());
        })
    };
};

export const deleteMediaInit = () => ({
    type: actionTypes.DELETE_MEDIA
});

export const deleteMediaFailure = data => ({
    type: actionTypes.DELETE_MEDIA_FAILURE
});

export const deleteMediaSuccess = data => ({
    type: actionTypes.DELETE_MEDIA_SUCCESS,
    payload: { data }
});

export const fetchMedia = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(fetchMediaInit());
        axios.get(`${baseUrl}/media`, config)
        .then((response)=>{
            dispatch(fetchMediaSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchMediaFailure());
        })
    };
};

export const fetchMediaInit = () => ({
    type: actionTypes.FETCH_MEDIA
});

export const fetchMediaFailure = data => ({
    type: actionTypes.FETCH_MEDIA_FAILURE
});

export const fetchMediaSuccess = data => ({
    type: actionTypes.FETCH_MEDIA_SUCCESS,
    payload: { data }
});
