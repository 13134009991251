import * as actionTypes from "./subscriptionActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const addSubscription = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(addSubscriptionInit());
        axios.post(`${baseUrl}/package/store`, data, config)
        .then((response)=>{
            dispatch(addSubscriptionSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(addSubscriptionFailure());
        })
    };
};

export const addSubscriptionInit = () => ({
    type: actionTypes.CREATE_SUBSCRIPTION
});

export const addSubscriptionFailure = data => ({
    type: actionTypes.CREATE_SUBSCRIPTION_FAILURE
});

export const addSubscriptionSuccess = data => ({
    type: actionTypes.CREATE_SUBSCRIPTION_SUCCESS,
    payload: { data }
});

export const updateSubscription = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(updateSubscriptionInit());
        axios.put(`${baseUrl}/package/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateSubscriptionSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateSubscriptionFailure());
        })
    };
};

export const updateSubscriptionInit = () => ({
    type: actionTypes.UPDATE_SUBSCRIPTION
});

export const updateSubscriptionFailure = data => ({
    type: actionTypes.UPDATE_SUBSCRIPTION_FAILURE
});

export const updateSubscriptionSuccess = data => ({
    type: actionTypes.UPDATE_SUBSCRIPTION_SUCCESS,
    payload: { data }
});

export const deleteSubscription = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(deleteSubscriptionInit());
        axios.delete(`${baseUrl}/package/${id}`, config)
        .then((response)=>{
            dispatch(deleteSubscriptionSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteSubscriptionFailure());
        })
    };
};

export const deleteSubscriptionInit = () => ({
    type: actionTypes.DELETE_SUBSCRIPTION
});

export const deleteSubscriptionFailure = data => ({
    type: actionTypes.DELETE_SUBSCRIPTION_FAILURE
});

export const deleteSubscriptionSuccess = data => ({
    type: actionTypes.DELETE_SUBSCRIPTION_SUCCESS,
    payload: { data }
});

export const fetchSubscriptions = () => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(fetchSubscriptionsInit());
        axios.get(`${baseUrl}/package`, config)
        .then((response)=>{
            dispatch(fetchSubscriptionsSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchSubscriptionsFailure());
        })
    };
};

export const fetchSubscriptionsInit = () => ({
    type: actionTypes.FETCH_SUBSCRIPTION
});

export const fetchSubscriptionsFailure = data => ({
    type: actionTypes.FETCH_SUBSCRIPTION_FAILURE
});

export const fetchSubscriptionsSuccess = data => ({
    type: actionTypes.FETCH_SUBSCRIPTION_SUCCESS,
    payload: { data }
});
