import AllContentPage from './allcontant.component';
import { connect } from 'react-redux';
import { getContent, deleteContent } from './../../api/content/contentAction';

const mapDispatchToProps = {
    getContent,
    deleteContent
}

const mapStateToProps = state => ({
    isLoading: state.contentPage.isLoading,
    isError: state.contentPage.isError,
    content: state.contentPage.content,
    isSaved: state.contentPage.isSaved,
});

export const AllContentPageContainer = connect(mapStateToProps, mapDispatchToProps)(AllContentPage);