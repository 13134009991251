import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import { makeStyles } from "@mui/styles";
import MDButton from "../../../shared/components/MDButton";
import lockImage from "../../../assets/images/lock.png";
import EmailVerifyDialog from "./email-verify-dialog";
import ClearIcon from "@mui/icons-material/Clear";

const topHead = {
  fontWeight: "700",
  fontSize: "20px",
  marginBottom: "4px",
  color: "#344767",
}
const topText = {
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "140%",
  color: "#344767",
}
const button = {
  background: "#09032B",
  color: "#FFFFFF",
  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  width: "355px",
}
const box = {
  display: "flex",
  justifyContent: "center",
}
const mdbox = {
  background: "#E6E8EB",
  height: "40px",
  width: "40px",
  borderRadius: "100px",
}

export default function ChangePasswordRequestDialog(props) {
  // const classes = useStyles();
  const { open, setOpen } = props;
  const [openC, setOpenC] = React.useState(false);

  return (
    <div>
      <EmailVerifyDialog open={openC} setOpen={setOpenC} />
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox>
                <MDTypography style={topHead} >
                  Change Password Request
                </MDTypography>
                <MDTypography style={topText} >
                  Send a link so that we can verify its you.
                </MDTypography>
              </MDBox>
              <MDBox style={mdbox} >
                <ClearIcon  onClick={() => setOpen(false)}
                  style={{ margin: "12px 12px" }} 
                />
              </MDBox>
            </MDBox>
            <MDBox style={box}
              sx={{ marginTop: "31px" }} >
              <img src={lockImage} alt="Lock logo" />
            </MDBox>
            <MDBox style={box}
              sx={{ marginTop: "32px" }} >
                <MDTypography style={{ fontWeight: "600", fontSize: "14px", color: "#7B809A" }} >
                  Send a reset password link to the customer email.
                </MDTypography>
            </MDBox>
            <MDBox style={box}
              sx={{ marginTop: "52px" }} >
                <MDButton style={button}
                  onClick={() => setOpenC(true)}
                  fullWidth >
                  SEND RESET LINK
                </MDButton>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
