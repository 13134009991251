import React, { useEffect, useState } from "react";
import DashboardLayout from "./../../shared/components/DashboardLayout";
import DashboardNavbar from "./../../shared/components/DashboardNavbar";
import Loading from './../../shared/Loading';
// MUI
import Grid from "@mui/material/Grid";
import MDButton from "./../../shared/components/MDButton";
import MDInput from "./../../shared/components/MDInput";
import { WhiteDialog } from './component/white-dialog';
import { WhiteTable } from './component/white-table';

export default function WhitePage(props) {
  const { 
    createWhite, 
    updateWhite,
    deleteWhite,
    fetchWhite,
    whites,
    isSaved,
    isLoading
  } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState({
    firstName: "",
    lastName: "",
    businessName: "",
    primaryColor: "",
    secondaryColor: "",
    navColor: "",
    email: "",
    phoneNo: "",
    password: "",
    logo: "",
    status: 1
  });

  useEffect(() => {
    fetchWhite();
  }, []);

  useEffect(() => {
    if (isSaved) {
      fetchWhite();
      setSelected({
        firstName: "",
        lastName: "",
        businessName: "",
        primaryColor: "",
        secondaryColor: "",
        navColor: "",
        email: "",
        phoneNo: "",
        password: "",
        logo: "",
        status: 1
      });
      setOpen(false);
    }
  }, [isSaved]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loading loading={isLoading} />
      <Grid container style={{display: "flex", marginTop: "24px"}}>
        <Grid item style={{marginLeft: "auto"}}>
            <MDButton
              onClick={() => {
                setSelected({
                  firstName: "",
                  lastName: "",
                  businessName: "",
                  primaryColor: "",
                  secondaryColor: "",
                  navColor: "",
                  email: "",
                  phoneNo: "",
                  password: "",
                  logo: "",
                  status: 1
                });
                setOpen(true)
              }}
                sx={{
                  background: "linear-gradient(#09032B, #312B56)",
                  color: "#fff",
                  marginLeft: "auto"
                }}
            >
                CREATE ADMIN / WL
            </MDButton>
        </Grid>
      </Grid>
      <WhiteTable
        selected={selected}
        setSelected={setSelected}
        rows={whites}
        open={open}
        setOpen={setOpen}
        updateWhite={updateWhite}
      />
      <WhiteDialog
        open={open}
        setOpen={setOpen}
        updateWhite={updateWhite}
        createWhite={createWhite}
        onClose={() => {
          setSelected({});
          setOpen(false);
        }}
        selected={selected}
        setSelected={setSelected}
      />
    </DashboardLayout>
  );
}
