import React, { useMemo, useState, useEffect } from "react";
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";
import Loading from './../../shared/Loading';
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import Grid from "@mui/material/Grid";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import { preetyTime } from "../../helper";
import { AttractionsOutlined } from "@mui/icons-material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const button = {
    textTransform: "capitalize",
    background: "#FFFFFF",
    border: "1px solid #CECDD5",
    color: "#7B809A",
    borderRadius: "8px",
  };
const mdBox = {
    background: "#FFFFFF",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "12px",
    width: "auto",
  };
const iconText = {
    color: "#000",
    width: "15px",
    height: "15px",
    margin: "2px 6px",
  };
const topText = {
    fontWeight: "400",
    fontSize: "14px",
    color: "#344767",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  };
const box = {
    backgroundColor: "#F0F2F5",
    height: "28px",
    width: "28px",
    borderRadius: "6px",
  };
const cardText = {
    width: "100%",
    margin: 0,
    boxShadow: "inset 0px 12px 40px rgba(0, 0, 0, 0.2)",
    objectFit: "cover",
    objectPosition: "center",
    height: "auto",
    borderRadius: "12px 12px 0px 0px"
  };

export default function SchedulePage(props) {
  const {
    getSchedules,
    schedules,
    isLoading
  } = props;

  const [events, setEvents] = useState([]);
  useEffect(async () => {
    if (Array.isArray(schedules) && schedules.length > 0) {
      const z = await Promise.all(schedules.map(async(x) => {
        console.log("X:: ", x);
        const profiles = await JSON.parse(x.ProfileIds).map(x => x.type);
        return {
          id: x.id,
          title: JSON.parse(x.Content.content).all,
          date: new Date(x.scTime),
          extendedProps: {
            image: JSON.parse(x.Content.images)[0].image,
            profiles: profiles
          }
        }
      }));
      setEvents(z);
    }
  }, [schedules]);

  useEffect(() => {
      getSchedules();
  }, []);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loading loading={isLoading} />
      <Grid container spacing={2} style={{marginTop: "25px"}}>
          <Grid item xs={12}>
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              events={events}
              eventContent={renderEventContent}
              eventDurationEditable={false}
              rerenderDelay={10}
            />
          </Grid>
      </Grid>
    </DashboardLayout>
  );
}


function renderEventContent(eventInfo) {
  return (
      <div style={eventInfo.event.title ? { width: "100%", padding: "1px 3px" } : {display: "none"}}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ width: "100%" }}>
            {eventInfo.event.extendedProps.profiles && eventInfo.event.extendedProps.profiles.indexOf("insta") !== -1 && <img src="/instagram_white.svg" width="10px" height="10px"/>}
            {eventInfo.event.extendedProps.profiles && eventInfo.event.extendedProps.profiles.indexOf("facebook") !== -1 && <FacebookRoundedIcon sx={{ width: "10px", height: "10px", color: "#fff" }} />}
            {eventInfo.event.extendedProps.profiles && eventInfo.event.extendedProps.profiles.indexOf("linkedin") !== -1 && <LinkedInIcon sx={{ width: "10px", height: "10px", color: "#fff" }} />}
            {eventInfo.event.extendedProps.profiles && eventInfo.event.extendedProps.profiles.indexOf("gmb") !== -1 && <StorefrontRoundedIcon sx={{ width: "10px", height: "10px", color: "#fff" }} />}
          </div>
          <span style={{ fontSize: "8px", color: "#fff", lineHeight: "14px" }}>{eventInfo.timeText}</span>
        </div>
        <img src={eventInfo.event.extendedProps.image} style={{ width: "100%", height: "51px", objectFit: "cover", border: "1px solid transparent", borderRadius: "4px" }}/>
        <div style={{ paddingBottom: "3px" }}>
          <p style={{ fontSize: "10px", color: "#fff", lineHeight: "14px", display: "-webkit-box", WebkitLineClamp: 1, WebkitBoxOrient: "vertical", overflow: "hidden", wordBreak: "break-all" }}>
            {eventInfo.event.title}
          </p>
        </div>
      </div>
  )
}
