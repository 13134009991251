import TopicPage from './topic.component';
import { connect } from 'react-redux';
import { createTopic, updateTopic, deleteTopic, fetchTopic } from './../../api/topic/topicAction';
import { fetchIndustryForAutoComplete } from './../../api/industry/industryAction';

const mapDispatchToProps = {
    createTopic, 
    updateTopic,
    deleteTopic,
    fetchTopic,
    fetchIndustryForAutoComplete
}

const mapStateToProps = state => ({
    isLoading: state.topicPage.isLoading,
    isSaved: state.topicPage.isSaved,
    topics: state.topicPage.topics,
    industriesAuto: state.industryPage.industriesAuto,
});

export const TopicPageContainer = connect(mapStateToProps, mapDispatchToProps)(TopicPage);