import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDTypography from "../../../shared/components/MDTypography";
import MDAvatar from "../../../shared/components/MDAvatar";
import breakpoints from "../../../assets/theme/base/breakpoints";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Stack from "@mui/material/Stack";
import burceMars from "../../../assets/images/bruce-mars.jpg";
import MDBox from "../../../shared/components/MDBox";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from '@mui/material/IconButton';
import Divider from "@mui/material/Divider";
import ChangePasswordDialog from "./change-password-dialog";
import PaymentDetailsDialog from "./payment-details-dialog";
import { Link } from 'react-router-dom';


const standerdplan = {
  backgroundColor: "#4CAE67",
  borderRadius: "12.5px",
  color: "#FFFFFF",
  width: "122px",
  height: "25px",
  paddingTop: "3px",
  fontSize: "12px",
  marginTop: "10px",
};
const menuText = {
  fontWeight: "400", 
  fontSize: "14px", 
  color: "#534F6B"
}

export default function Header(props) {
  const { user, children } = props;
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [openC, setOpenC] = useState(false);
  const [openP, setOpenP] = useState(false);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item>
      <ChangePasswordDialog open={openC} setOpen={setOpenC} />
      <PaymentDetailsDialog open={openP} setOpen={setOpenP} />
      <Card
        style={{ backgroundColor: "white" }}
        sx={{
          position: "relative",
          py: 2,
          px: 2,
        }}
      >
        <Grid style={{ display: "flex" }}>
          <Grid style={{ marginBottom: "22px" }} component={Link} to="/user-account">
            <MDTypography style={{ fontWeight: "700", fontSize: "16px", color: "#344767" }}>
              <ArrowBackIcon style={{ color: "#000000", marginBottom: "-2px" }} /> Back to list
            </MDTypography>
          </Grid>
          <Grid style={{ marginLeft: "auto" }}>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon style={{ color: "#7B809A" }} />
          </IconButton>
            <Menu
              sx={{
                "& .css-hm2aps-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper": {
                  boxShadow: "0px 20px 40px 3px rgba(0, 0, 0, 0.13)",
                  borderRadius: "12px",
                }
              }}
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={open}
              onClose={handleClose}
              keepMounted
            >
              <MenuItem style={{fontWeight: "700", fontSize: "14px", color: "#344767"}}>Select a action</MenuItem>
              <div
                style={{
                  borderBottom: "1px solid #E9EAED",
                  width: "100%",
                  marginBottom: "16px",
                  marginTop: "10px",
                }}
              />
              <MenuItem style={menuText}
                onClick={handleClose}>Edit Business Details</MenuItem>
              <MenuItem style={menuText}
                onClick={() => setOpenP(true)}>Edit Payment Details</MenuItem>
              <MenuItem style={menuText}
                onClick={handleClose}>Edit Subscription Details</MenuItem>
              <MenuItem style={menuText}
                onClick={() => setOpenC(true)}>Change Password</MenuItem>
              <MenuItem style={menuText}
                onClick={handleClose}>Archive Account</MenuItem>
            </Menu>
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar variant="rounded" src={user && user.Accounts && Array.isArray(user.Accounts) && user.Accounts[0].logo} alt="profile-image" size="xl" shadow="sm" />
          </Grid>
          <Grid item>
            <MDBox height="100%" lineHeight={1}>
              <MDTypography
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  lineHeight: "140%",
                  color: "#344767",
                }}
              >
                {user && user.Accounts && Array.isArray(user.Accounts) && user.Accounts[0].name}
                <PersonAddAltIcon style={{ marginLeft: "18px" }} />
              </MDTypography>
              <MDTypography
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "140%",
                  color: "#344767",
                }}
              >
                {user &&
                  user.Accounts &&
                  Array.isArray(user.Accounts) &&
                  user.Accounts.length > 0 &&
                  user.Accounts[0].industries &&
                  Array.isArray(user.Accounts[0].industries) &&
                  user.Accounts[0].industries.map(x => x.name + ", ")
                }
              </MDTypography>
            </MDBox>
          </Grid>
          <MDBox style={{ marginLeft: "auto", display: "flex" }}>
            <MDBox style={{ marginRight: "25px" }}>
              <MDBox lineHeight={1} style={{ textAlign: "center" }}>
                <MDTypography style={{ fontWeight: "400", fontSize: "14px", color: "#7B809A" }}>
                  Ongoing subscription
                </MDTypography>
                <MDTypography style={standerdplan}>PREMIUM</MDTypography>
              </MDBox>
            </MDBox>
            <MDBox>
              <Stack direction="row">
                <MDTypography style={{ color: "#414141", fontWeight: "700", fontSize: "18px" }}>
                  $
                </MDTypography>
                <MDTypography style={{ color: "#414141" }} variant="h1">
                  89
                </MDTypography>
                <MDTypography
                  style={{
                    marginTop: "25px",
                    color: "#414141",
                    fontWeight: "700",
                    fontSize: "18px",
                  }}
                  variant="body3"
                >
                  /mo
                </MDTypography>
              </Stack>
            </MDBox>
          </MDBox>
        </Grid>
        {children}
      </Card>
    </Grid>
  );
}

Header.defaultProps = {
  children: "",
};

Header.propTypes = {
  children: PropTypes.node,
};
