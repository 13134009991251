import React, { useEffect } from 'react';
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";
import Loading from './../../shared/Loading';
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Card from "./../businessAccount/component/card";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Stack from "@mui/material/Stack";
import MDBox from "./../../shared/components/MDBox";
import MDTypography from "./../../shared/components/MDTypography";
import ProductCell from "./component/product-cell.component";
import Wordpress from "../../assets/images/businessdetails/Wordpress.png";
import owner1 from "../../assets/images/owners/owner1.png";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { preetyDate } from "./../../helper"

const freesubs = {
  backgroundColor: "#CDFFDB",
  borderRadius: "6px",
  padding: "5px 5px",
  color: "#858585",
};
const tableHeading = {
  fontWeight: 700,
  fontSize: "11px",
  color: "#7B809A"
}
const tablecell = {
  fontWeight: 700,
  fontSize: "14px",
  color: "#09032B"
}
const viewdetails = {
  fontWeight: "600",
  fontSize: "14px",
};
const doticon = {
  width: "16px",
  height: "16px",
  marginBottom: "-3px",
};
const iconstyle = {
  width: "20px",
  height: "20px",
  color: "#7B809A",
};
const menuText = {
  fontWeight: "400", 
  fontSize: "14px", 
  color: "#534F6B"
}
const divider = {
  borderBottom: "1px solid #E9EAED",
  width: "100%",
}
const itemText = {
  fontWeight: "700", 
  fontSize: "14px", 
  color: "#344767"
}

function Row(props) {
  const { row, authenticateUser, setRt } = props;
  const [open, setOpen] = React.useState(false);

  const checkAccount = (data, type) => {
    if (data && data.Profiles && Array.isArray(data.Profiles)) {
      const y = data.Profiles.filter(x => x.type == type);
      if (y.length > 0) {
        return true;
      }
      return false;
    }
    return false;
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          <ProductCell image={row.dp ? row.dp : Wordpress} name={row.firstName + " " + row.lastName} />
        </TableCell>
        <TableCell align="center" style={tablecell}>{row.email}</TableCell>
        <TableCell align="center" style={tablecell}>{row.phoneNo}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Accounts
              </Typography>
              <Table size="small" aria-label="accounts">
                  <TableRow>
                    <TableCell align="center" style={tableHeading}>BUSINESS DETAILS</TableCell>
                    <TableCell align="center" style={tableHeading}>SOCIAL PROFILES</TableCell>
                    <TableCell align="center" style={tableHeading}>OWNER</TableCell>
                    <TableCell align="center" style={tableHeading}>SUBSCRIPTION</TableCell>
                    <TableCell align="center" style={tableHeading}>DATE CREATED</TableCell>
                    <TableCell align="center" style={tableHeading}>USERS</TableCell>
                    <TableCell align="center" style={tableHeading}>ACTIONS</TableCell>
                  </TableRow>
                <TableBody>
                  {row.accounts && Array.isArray(row.accounts) && row.accounts.map((account) => (
                    <TableRow key={account.id}>
                      <TableCell component="th" scope="row">
                        <ProductCell image={account.logo ? account.logo : Wordpress} name={account.name} />
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          {checkAccount(account, "fb") && <FacebookIcon style={iconstyle} />}
                          {checkAccount(account, "insta") && <InstagramIcon style={iconstyle} />}
                          {checkAccount(account, "linkedin") && <LinkedInIcon style={iconstyle} />}
                        </Stack>
                      </TableCell>
                      <TableCell align="right">
                        <ProductCell image={row.dp ? row.dp : owner1} name={`${row.firstName} ${row.lastName}`} />
                      </TableCell>
                      <TableCell align="right">
                        <MDBox width="8rem">
                          <MDTypography variant="overline" style={freesubs}>
                            Free Subscriptions
                          </MDTypography>
                        </MDBox>
                      </TableCell>
                      <TableCell>{preetyDate(account.createdAt)}</TableCell>
                      <TableCell><Card members={[row.dp ? row.dp : owner1]} /></TableCell>
                      <TableCell>
                        <MDTypography variant="overline" style={viewdetails} onClick={() => {setRt(true); authenticateUser({ email: row.email })}}>
                          View Details 
                        </MDTypography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export function UserAccountPage(props) {
  const { users, fetchAllUsers, isLoading, authenticateUser, userAuthenticate } = props;
  const [rt, setRt] = React.useState(false);

  useEffect(() => {
    fetchAllUsers({});
  }, []);

  useEffect(() => {
    if(userAuthenticate && userAuthenticate.token && rt){
      setRt(false);
      let a= document.createElement('a');
      a.target= '_blank';
      a.href= `https://app.socialbroker.app/#/auth-user/${userAuthenticate.token}`;
      a.click();
    }
  }, [userAuthenticate]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loading loading={isLoading} />
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
        </MDBox>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                  <TableRow>
                    <TableCell style={tableHeading}>Owner</TableCell>
                    <TableCell align="center" style={tableHeading}>Email</TableCell>
                    <TableCell align="center" style={tableHeading}>Phone Number</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                <TableBody>
                  {users && users.map((row) => (
                    <Row key={row.id} row={row} authenticateUser={authenticateUser} setRt={setRt}/>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
      </MDBox>
    </DashboardLayout>
  );
}
