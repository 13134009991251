import { BusinessDetailPage } from './business-details.component';
import { connect } from 'react-redux';
import {  fetchUserById, updateAccount, fetchTeam, deleteTeam } from './../../api/user/userAction';
import { addProfile, updateProfile, deleteProfile } from './../../api/profile/profileAction';
import { getContentByAccount } from './../../api/content/contentAction';
import { fetchTheme } from './../../api/theme/themeAction';

const mapDispatchToProps = {
    fetchUserById,
    addProfile,
    updateProfile,
    deleteProfile,
    updateAccount,
    fetchTeam,
    deleteTeam,
    getContentByAccount,
    fetchTheme
}

const mapStateToProps = state => ({
    user: state.userPage.user,
    teams: state.userPage.teams,
    themes: state.themePage.themes,
    teamDeleted: state.userPage.teamDeleted,
    contentByAccount: state.contentPage.contentByAccount,
    isLoading: state.userPage.isLoading || state.profilePage.isLoading,
    isSaved: state.profilePage.isSaved || state.userPage.isSaved
});

export const BusinessDetailPageContainer = connect(mapStateToProps, mapDispatchToProps)(BusinessDetailPage);