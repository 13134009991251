import SchedulePage from './schedule.component';
import { connect } from 'react-redux';
import { getSchedules } from './../../api/schedule/scheduleAction';

const mapDispatchToProps = {
    getSchedules
}

const mapStateToProps = state => ({
    isLoading: state.schedulePage.isLoading,
    isError: state.schedulePage.isError,
    schedules: state.schedulePage.schedules
});

export const SchedulePageContainer = connect(mapStateToProps, mapDispatchToProps)(SchedulePage);