import * as actionType from "./profileActionType";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    profile: []
}

export const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADD_PROFILE:
        return {
            ...state,
            isError: false,
            isLoading: true,
            isSaved: false
        };
    case actionType.ADD_PROFILE_FAILURE:
        return {
            ...state,
            isError: true,
            isSaved: false,
            isLoading: false
        };
    case actionType.ADD_PROFILE_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            isSaved: true
        };
        case actionType.UPDATE_PROFILE:
        return {
            ...state,
            isError: false,
            isLoading: true,
            isSaved: false
        };
        case actionType.UPDATE_PROFILE_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false
            };
        case actionType.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true,
            };
        case actionType.DELETE_PROFILE:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
            case actionType.DELETE_PROFILE_FAILURE:
                return {
                    ...state,
                    isError: true,
                    isLoading: false,
                    isSaved: false
                };
            case actionType.DELETE_PROFILE_SUCCESS:
                return {
                    ...state,
                    isError: false,
                    isLoading: false,
                    isSaved: true,
                };
        default: 
            return state
    }
}