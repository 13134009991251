import { useState } from "react";
import ImgsViewer from "react-images-viewer";
import Stack from "@mui/material/Stack";
import MDBox from "../../../shared/components/MDBox";
import poster1 from "../../../assets/images/poster1.png";
import poster2 from "../../../assets/images/poster2.png";

export default function ProductImages() {
  const [currentImage, setCurrentImage] = useState(poster1);
  const [imgsViewer, setImgsViewer] = useState(false);
  const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);

  const handleSetCurrentImage = ({ currentTarget }) => {
    setCurrentImage(currentTarget.src);
    setImgsViewerCurrent(Number(currentTarget.id));
  };

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
  const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);

  return (
    <MDBox style={{ width: "116px" }}>
      <ImgsViewer
        imgs={[
          { src: poster1 },
          { src: poster2 },
          { src: poster1 },
          { src: poster1 },
          { src: poster1 },
        ]}
        isOpen={imgsViewer}
        onClose={closeImgsViewer}
        currImg={imgsViewerCurrent}
        onClickPrev={imgsViewerPrev}
        onClickNext={imgsViewerNext}
        backdropCloseable
      />

      <MDBox
        component="img"
        src={poster1}
        alt="Product Image"
        shadow="lg"
        borderRadius="lg"
        width="100%"
        onClick={openImgsViewer}
      />
      <MDBox>
        <Stack direction="row" spacing={0.7}>
          <MDBox
            style={{ width: "26px" }}
            component="img"
            id={0}
            src={currentImage}
            alt="small image 1"
            borderRadius="lg"
            shadow="md"
            sx={{ cursor: "pointer", objectFit: "cover" }}
            onClick={handleSetCurrentImage}
          />
          <MDBox
            component="img"
            style={{ width: "26px" }}
            id={1}
            src={poster2}
            alt="small image 2"
            borderRadius="lg"
            shadow="md"
            sx={{ cursor: "pointer", objectFit: "cover" }}
            onClick={handleSetCurrentImage}
          />
          <MDBox
            component="img"
            style={{ width: "26px" }}
            id={2}
            src={poster1}
            alt="small image 3"
            borderRadius="lg"
            shadow="md"
            sx={{ cursor: "pointer", objectFit: "cover" }}
            onClick={handleSetCurrentImage}
          />
          <MDBox
            component="img"
            style={{ width: "26px" }}
            id={3}
            src={poster1}
            alt="small image 4"
            borderRadius="lg"
            shadow="md"
            sx={{ cursor: "pointer", objectFit: "cover" }}
            onClick={handleSetCurrentImage}
          />
        </Stack>
      </MDBox>
    </MDBox>
  );
}
