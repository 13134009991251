import * as actionType from "./masterContentActionType";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    masterContents: []
}

export const masterContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.FETCH_MASTER_CONTENTS:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.FETCH_MASTER_CONTENTS_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.FETCH_MASTER_CONTENTS_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                masterContents: action.payload ? action.payload.data.rows : [],
            };
        case actionType.CREATE_MASTER_CONTENT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_MASTER_CONTENT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false
            };
        case actionType.CREATE_MASTER_CONTENT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.UPDATE_MASTER_CONTENT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.UPDATE_MASTER_CONTENT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false
            };
        case actionType.UPDATE_MASTER_CONTENT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.DELETE_MASTER_CONTENT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.DELETE_MASTER_CONTENT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false
            };
        case actionType.DELETE_MASTER_CONTENT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        default: 
            return state
    }
}