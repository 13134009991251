import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../shared/components/DashboardLayout";
import DashboardNavbar from "./../../shared/components/DashboardNavbar";
// mui
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import Icon from "@mui/material/Icon";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
// md
import MDBox from "./../../shared/components/MDBox";
import MDButton from "./../../shared/components/MDButton";
// icons
import WindowIcon from "@mui/icons-material/Window";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
// list/grid
import GridData from "./component/grid/index";
import List from "./component/list/index";
import Loading from './../../shared/Loading';
import { DeleteConfirm } from './component/delete-confirm';

const button = {
  textTransform: "capitalize",
  background: "#FFFFFF",
  border: "1px solid #CECDD5",
  color: "#7B809A",
  borderRadius: "8px",
};

export default function AllContentPage(props) {
  const {
    content,
    isLoading,
    getContent,
    isSaved,
    deleteContent
  } = props;
  const [alignment, setAlignment] = React.useState("left");const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const emptyContent = {
    ProfileIds: [],
    content: {all: "", facebook: "", insta: "", linkedin: "", gmb: ""},
    layouts: "",
    images: undefined,
    tags: [],
    hashtags: {
      all: "",
      facebook: "",
      insta: "",
      linkedin: "",
      gmb: "",
    },
    scTime: null,
    status: 1
  }
  // menu
  const [menu, setMenu] = React.useState(null);
  const [search, setSearch] = React.useState({});

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const [menu1, setMenu1] = React.useState(null);

  const openMenu1 = (event) => setMenu1(event.currentTarget);
  const closeMenu1 = () => setMenu1(null);
  const [loading, setLoading] = useState(false);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          setSearch({ ...search, type: null })
          closeMenu();
        }}
        
      >
        All Posts <Checkbox checked={!search.type} sx={{ ml: "auto" }}/>
      </MenuItem>
      <Divider />
      <MenuItem 
        onClick={() => {
          setSearch({ ...search, type: "Social Posts" })
          closeMenu();
        }}
        
      >
        Social Posts <Checkbox checked={search.type == "Social Posts"} sx={{ ml: "auto" }}/></MenuItem>
      <MenuItem onClick={() => {
          setSearch({ ...search, type: "Video Posts" })
          closeMenu();
        }}
        >Video Posts <Checkbox checked={search.type == "Video Posts"} sx={{ ml: "auto" }}/></MenuItem>
      <MenuItem onClick={() => {
          setSearch({ ...search, type: "RSS Feed" })
          closeMenu();
        }}
        >RSS Feed <Checkbox checked={search.type == "RSS Feed"} sx={{ ml: "auto" }}/></MenuItem>
      <MenuItem onClick={() => {
          setSearch({ ...search, type: "Blog posts" })
          closeMenu();
        }}
        >Blog posts <Checkbox checked={search.type == "Blog posts"} sx={{ ml: "auto" }}/></MenuItem>
    </Menu>
  );
  const renderMenu1 = (
    <Menu
      anchorEl={menu1}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu1)}
      onClose={closeMenu1}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          setSearch({ ...search, status: null })
          closeMenu1();
        }}
        checked={!search.status}
        >Select All <Checkbox sx={{ ml: "auto" }}/></MenuItem>
      <Divider />
      <MenuItem
      onClick={() => {
        setSearch({ ...search, status: 4 })
        closeMenu1();
      }}
        >Scheduled <Checkbox checked={search.status == 4} sx={{ ml: "auto" }}/></MenuItem>
      <MenuItem
      onClick={() => {
          setSearch({ ...search, status: 2 })
          closeMenu1();
        }}
        >Published <Checkbox checked={search.status == 2} sx={{ ml: "auto" }}/></MenuItem>
      <MenuItem 
      onClick={() => {
          setSearch({ ...search, status: 5 })
          closeMenu1();
        }}
        >Failed <Checkbox checked={search.status == 5} sx={{ ml: "auto" }}/></MenuItem>
      <MenuItem 
      onClick={() => {
          setSearch({ ...search, status: 1 })
          closeMenu1();
        }}
        >Draft <Checkbox checked={search.status == 1} sx={{ ml: "auto" }}/></MenuItem>
      <Divider />
      <MenuItem 
      onClick={() => {
          setSearch({ ...search, status: 6 })
          closeMenu1();
        }}
        >Ready For Review <Checkbox checked={search.status == 6} sx={{ ml: "auto" }}/></MenuItem>
    </Menu>
  );
  // menu
  const [isGrid, setIsGrid] = React.useState(false);
  const [isList, setIsList] = React.useState(true);
  const [selected, setSelected] = React.useState(emptyContent);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleGrid = () => {
    setIsGrid(true);
    setIsList(false);
  }
  const handleList = () => {
    setIsGrid(false);
    setIsList(true);
  }

  useEffect(() => {
    getContent();
  }, []);

  useEffect(() => {
    if(search){
      getContent();
    }
  }, [search]);

  useEffect(() => {
    if (isSaved) {
      getContent();
      setSelected(emptyContent);
      setDeleteOpen(false);
    }
  }, [isSaved]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loading loading={isLoading || loading} />
      <DeleteConfirm
        deleteContent={deleteContent}
        open={deleteOpen}
        selected={selected}
        onClose={() => {
          setSelected(emptyContent);
          setDeleteOpen(false);
        }}
      />
      <Grid style={{ display: "flex", marginTop: "27px" }}>
        <Grid item>
          <MDButton
            style={button}
            variant={menu ? "contained" : "outlined"}
            onClick={openMenu}
          >
            All Posts&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu}
        </Grid>
        <Grid style={{ marginLeft: "auto" }}>
          <MDButton
            style={button}
            sx={{ marginLeft: "16px" }}
            variant={menu1 ? "contained" : "outlined"}
            onClick={openMenu1}
          >
            All Topics&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu1}
          <MDButton
            style={button}
            sx={{ marginLeft: "16px" }}
            variant={menu1 ? "contained" : "outlined"}
            onClick={openMenu1}
          >
            Ready for Review&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu1}
          <ToggleButtonGroup
            sx={{
              marginLeft: "16px",
              background: "#E6E6EA",
              borderRadius: "12px",
              padding: "6px 8px",
              "& .css-1c48ueu-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                {
                  color: "#fff !important",
                  backgroundColor: "#09032B !important",
                  
                },
              "& .css-1c48ueu-MuiButtonBase-root-MuiToggleButton-root": {
                border: "none",
              },
            }}
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <ToggleButton onClick={handleGrid}
              style={{
                borderRadius: "8px",
                width: "30px",
                height: "30px",
                marginRight: "8px",
              }} value="left" aria-label="left aligned">
              <WindowIcon />
            </ToggleButton>
            <ToggleButton onClick={handleList}
              style={{
                borderRadius: "8px",
                width: "30px",
                height: "30px",
              }} value="right" aria-label="right aligned">
              <FormatListBulletedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <MDBox style={{ marginTop: "24px" }}>
        {isGrid ? <GridData setDeleteOpen={setDeleteOpen} content={content} selected={selected} setSelected={setSelected}/> : null }
        {isList ?  <List setDeleteOpen={setDeleteOpen} content={content} selected={selected} setSelected={setSelected}/> : null }
      </MDBox>
    </DashboardLayout>
  );
}
