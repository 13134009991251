import { LayoutPage } from './layout.component';
import { connect } from 'react-redux';
import { uploadBG, getLayout, fetchTheme } from './../../api/theme/themeAction';
import { fetchMasterLayouts } from "./../../api/master-layout/masterLayoutAction"

const mapDispatchToProps = {
    uploadBG,
    getLayout,
    fetchMasterLayouts,
    fetchTheme
}

const mapStateToProps = state => ({
    bgImg: state.themePage.bgImg,
    isLoading: state.themePage.isLoading,
    themes: state.themePage.themes,
    layout: state.themePage.layout,
    masterLayouts: state.masterLayoutPage.masterLayouts
});

export const LayoutPageContainer = connect(mapStateToProps, mapDispatchToProps)(LayoutPage);