import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import MDButton from "../../../shared/components/MDButton";
import Icon from "@mui/material/Icon";
import FormField from "../../../shared/components/FormField";
import ClearIcon from "@mui/icons-material/Clear";

const topHead = {
  fontWeight: "700",
  fontSize: "20px",
  color: "#09032B",
  marginBottom: "4px",
}
const topText = {
  fontWeight: "600", 
  fontSize: "14px", 
  color: "#7B809A"
}
const mdbox = {
  background: "#E6E8EB",
  height: "40px",
  width: "40px",
  borderRadius: "100px",
}
const button = {
  background: "#C7CCD0",
  color: "#FFFFFF",
  borderRadius: "8px",
  marginTop: "-46px",
  float: "right",
  fontWeight: "700",
  fontSize: "12px",
}
const box = {
  height: "138px",
  width: "223px",
  margin: "37px auto",
  backgroundColor: "#E8E8E8",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}

export default function ColorDialog(props) {
  // const classes = useStyles();
  const { open, setOpen } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox>
                <MDTypography style={topHead} >
                  Update Primary Color
                </MDTypography>
                <MDTypography style={topText} >
                  Change type
                </MDTypography>
              </MDBox>
              <MDBox style={mdbox} >
                <ClearIcon  onClick={() => setOpen(false)}
                  style={{ margin: "12px 12px" }} 
                />
              </MDBox>
            </MDBox>
            <MDBox component="form" px={3} mt={2}>
              <Grid container>
                <Grid item xs={12}>
                  <FormField label="Hex Color" placeholder="# Enter code" />
                  <MDButton style={button} >
                    Generate
                  </MDButton>
                </Grid>
              </Grid>

              <MDBox style={box} >
                <MDTypography
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#7B809A",
                  }}
                >
                  Waiting...
                </MDTypography>
              </MDBox>
              <MDTypography
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#7B809A",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Color generated, All updates saved
              </MDTypography>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
