import PropTypes from "prop-types";
import drake from "./../../../../../assets/images/drake.jpg";
import MDAvatar from "./../../../MDAvatar";
import MDBox from "./../../../MDBox";
import MDTypography from "./../../../MDTypography";

function IdCell({ id, checked }) {
  return (
    <MDBox display="flex" alignItems="center">
      <MDAvatar
        src={drake}
        variant="square"
        style={{ width: "139px", height: "124px", borderRadius: "6px", border: "1px solid #E8E8E8" }}
      />
      <MDBox sx={{marginLeft: "18px"}}>
        <MDTypography variant="caption" fontWeight="medium" color="text">
          {id}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Setting default value for the props of IdCell
IdCell.defaultProps = {
  checked: false,
};

// Typechecking props for the IdCell
IdCell.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
};

export default IdCell;
