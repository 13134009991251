import { UserAccountPage } from './user-account.component';
import { connect } from 'react-redux';
import { fetchAllUsers } from './../../api/user/userAction';
import { authenticateUser } from './../../api/admin/adminAction';

const mapDispatchToProps = {
    fetchAllUsers,
    authenticateUser
}

const mapStateToProps = state => ({
    isLoggedIn: state.adminPage.isLoggedIn,
    admin: state.adminPage.loggedAdmin,
    isLoading: state.userPage.isLoading,
    users: state.userPage.users,
    userAuthenticate: state.adminPage.userAuthenticate,
});

export const UserAccountPageContainer = connect(mapStateToProps, mapDispatchToProps)(UserAccountPage);