import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import FormField from "./../../../shared/components/FormField";
import ClearIcon from "@mui/icons-material/Clear";
import MDButton from "../../../shared/components/MDButton";
import { useRef, useState } from "react";
import { Cancel, Tag } from "@mui/icons-material";
import { FormControl, Stack, TextField, Typography } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  form: {
    '& .MuiInputBase-adornedStart': {
      flexWrap: "wrap"
    }
  }
});

const topHead = {
  fontWeight: "700",
  fontSize: "20px",
  color: "#221C40",
};
const button = {
  background: "linear-gradient(180.67deg, #09032B 2.43%, #312B56 101%)",
  color: "#FFFFFF",
  borderRadius: "8px",
  width: "189px",
};
const boxText = {
  borderRadius: "12px",
  border: "1px solid #DEE1E6",
  width: "128px",
  height: "120px",
};
const buttonBox = {
  display: "flex",
  justifyContent: "center",
  marginTop: "52px",
};
const mdbox = {
  background: "#E6E8EB",
  height: "40px",
  width: "40px",
  borderRadius: "100px",
};
const mdText1 = {
  color: "red",
  marginTop: "-0px",
  marginBottom: "14px",
  fontSize: "12px",
  fontWeight: 400,
};

const X = 5;
const Y = 8;
const A = -4;
const B = -6;

const Tags = ({ data, handleDelete }) => {
  return (
    <MDBox
      sx={{
        background: "#283240",
        height: "100%",
        display: "flex",
        padding: "5px 10px",
        margin: "0.2rem",
        justifyContent: "center",
        alignContent: "center",
        color: "#ffffff",
        borderRadius: "10px"
      }}
    >
      <Stack direction='row' gap={1}>
        <Typography sx={{ fontSize: "12px" }}>{data}</Typography>
        <Cancel
          sx={{ cursor: "pointer" }}
          onClick={() => {
            handleDelete(data);
          }}
        />
      </Stack>
    </MDBox>
  );
};

export function ThemeDialog(props) {
  const classes = useStyles();
  const { open, setOpen, selected, setSelected, onClose, createTheme, updateTheme } = props;
  const [error, setError] = React.useState(false);

  const tagRef = useRef();

  const onImageChange = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    )
    setSelected({ ...selected, image: event.target.files[0] });
  };

  const handleDelete = (value) => {
    const newtags = selected.tags.filter((val) => val !== value);
    setSelected({...selected, tags: newtags});
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    setSelected({...selected, tags: [...selected.tags, tagRef.current.value]});
    tagRef.current.value = "";
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox style={{ maxWidth: "274px" }}>
                <MDTypography style={topHead}>
                  {selected.id ? "Update Theme" : "Create Theme"}
                </MDTypography>
              </MDBox>
              <MDBox style={mdbox}>
                <ClearIcon
                  onClick={() => onClose()}
                  style={{ margin: "12px 12px", cursor: "pointer" }}
                />
              </MDBox>
            </MDBox>
            <MDBox mb={2} style={{ marginTop: "24px" }}>
              <FormField
                label="Name"
                name="name"
                placeholder="Theme Name"
                type="text"
                value={selected.name}
                onChange={e => {
                  setSelected({ ...selected, name: e.target.value });
                  setError(false);
                }}
              />
              {error != "" && (
                <MDTypography style={mdText1}>Name is required.</MDTypography>
              )}
            </MDBox>

            <MDBox>
              <form className={classes.form} onSubmit={e => handleOnSubmit(e)}>
                <TextField
                  inputRef={tagRef}
                  fullWidth
                  variant='standard'
                  size='small'
                  sx={{ margin: "1rem 0" }}
                  margin='none'
                  placeholder={selected && selected.tags && selected.tags.length < 30 ? "Enter tags" : ""}
                  InputProps={{
                    startAdornment: (
                      <React.Fragment>
                        {selected && selected.tags && selected.tags.map((data, index) => {
                          return (
                            <Tags data={data} handleDelete={handleDelete} key={index} />
                          );
                        })}
                      </React.Fragment>
                    ),
                  }}
                />
              </form>
            </MDBox>

            <MDBox component="label" htmlFor="image-id" sx={{ p: "10px" }}>
              <div style={{ width: "100%", height: "100px", display: "flex", justifyContent: "center", alignItems: "center",  border: "2px solid #eaeaea", borderRadius: "20px" }}>
                {selected && selected.id && typeof selected.image === "string" ? <img src={selected.image} height="100px" /> : selected.image != "" && selected.image != null ? <img src={URL.createObjectURL(selected.image)} height="100px" /> : <strong>Upload Image</strong>}
              </div>
              <input style={{ display: "none" }} type="file" id="image-id" onChange={onImageChange} />
            </MDBox>

            <MDBox>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={selected.premium === 1} onChange={() => setSelected({ ...selected, premium: selected.premium === 1 ? 0 : 1 })} />} label="Is premium theme?" />
            </FormGroup>
            </MDBox>

            <MDBox style={buttonBox}>
              <MDButton
                style={button}
                fullWidth
                onClick={() => {
                  if (selected.name == "") {
                    setError(true);
                    return;
                  } else {
                    setError(false);
                    if (selected.id) {
                      updateTheme({...selected, tags: JSON.stringify(selected.tags)});
                    } else {
                      createTheme({...selected, tags: JSON.stringify(selected.tags)});
                    }
                  }
                }}
              >
                SAVE
              </MDButton>
            </MDBox>
            </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
