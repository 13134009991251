import React, { useEffect, useRef } from "react";
import DashboardLayout from "./../../shared/components/DashboardLayout";
import DashboardNavbar from "./../../shared/components/DashboardNavbar";
import Loading from './../../shared/Loading';
import { Cancel } from "@mui/icons-material";
// MUI
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
// import MDTypography from "./../../shared/components/MDTypography";
import MDInput from "./../../shared/components/MDInput";
import MDButton from "./../../shared/components/MDButton";
// Icons
import WindowIcon from "@mui/icons-material/Window";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import MDBox from "../../shared/components/MDBox";
import ClearIcon from "@mui/icons-material/Clear";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FormField from "./../../shared/components/FormField";
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import MasterLayoutDialoge from "./component/master-layout-dialoge";
import { preetyDate } from "./../../helper";
import EditIcon from '@mui/icons-material/Edit';
import { DeleteConfirm } from "./component/delete-confirm";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  form: {
    '& .MuiInputBase-adornedStart': {
      flexWrap: "wrap"
    }
  }
});

const button = {
  textTransform: "capitalize", 
  background: "#FFFFFF",
  border: "1px solid #CECDD5", 
  color: "#7B809A", 
  borderRadius: "8px",
  padding: 0
}
const divider = {
  borderBottom: "1px solid #E6E6EA",
  width: "100%",
}

const tableHeading = {
  fontWeight: 700,
  fontSize: "11px",
  color: "#7B809A"
}

const tablecell = {
  fontWeight: 700,
  fontSize: "12px",
  color: "#7B809A"
}

const Tags = ({ data, handleDelete }) => {
  return (
    <MDBox
      sx={{
        background: "#283240",
        height: "100%",
        display: "flex",
        padding: "5px 10px",
        margin: "0.2rem",
        justifyContent: "center",
        alignContent: "center",
        color: "#ffffff",
        borderRadius: "10px"
      }}
    >
      <Stack direction='row' gap={1}>
        <Typography sx={{ fontSize: "12px" }}>{data}</Typography>
        <Cancel
          sx={{ cursor: "pointer" }}
          onClick={() => {
            handleDelete(data);
          }}
        />
      </Stack>
    </MDBox>
  );
};

export default function ContentTablePage(props) {
  const classes = useStyles();
  const { 
    getTopics,
    fetchIndustryForAutoComplete,
    industries,
    topics,
    fetchTheme,
    fetchCompleteTheme,
    themes,
    completeTheme,
    fetchMasterLayouts,
    masterLayouts,
    loggedAdmin,
    createMasterContent,
    updateMasterContent,
    deleteMasterContent,
    fetchMasterContents,
    masterContents,
    isSaved,
    uploadBG,
    bgImg,
    isLoading
   } = props
  const emptyMasterContent = {
    content: "",
    MasterLayoutId: "",
    consumer: "",
    imageKeywords: "",
    defaultDate: null,
    region: "",
    imageType: "",
    industries: [],
    topics: [],
    hashtags: "",
    preferences: "",
    expiryDate: null,
    createdUser: loggedAdmin.firstName + " " + loggedAdmin.lastName,
    status: ""
  }

  const [alignment, setAlignment] = React.useState("right");
  const [selected, setSelected] = React.useState(emptyMasterContent);
  const [selectedTheme, setSelectedTheme] = React.useState("");
  const [selectedLayout, setSelectedLayout] = React.useState({});
  const [dynamicFields, setDynamicFields] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [openD, setOpenD] = React.useState(false);
  const [openL, setOpenL] = React.useState(false);
  const [openC, setOpenC] = React.useState(false);
  const [dimention, setDimention] = React.useState({width: 1200, height: 1200, zoom: .08});

  const tagRef = useRef();

  const handleDelete = (value) => {
    const newtags = selected.hashtags.filter((val) => val !== value);
    setSelected({...selected, hashtags: newtags});
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    let val = "";
    if (tagRef.current.value.trim() != "") {
      if (/^#/i.test(tagRef.current.value)) {
        val = tagRef.current.value;
      } else {
        val = "#" + tagRef.current.value;
      }
      setSelected({...selected, hashtags: [...selected.hashtags, val]});
    }
    tagRef.current.value = "";
  };

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  // toggleButton

  // menu
  const [menu, setMenu] = React.useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const [menu1, setMenu1] = React.useState(null);
  const [menu2, setMenu2] = React.useState(null);
  const [search, setSearch] = React.useState({});
  const [layoutShow, setLayoutShow] = React.useState(false);

  const openMenu1 = (event) => setMenu1(event.currentTarget);
  const openMenu2 = (event) => setMenu2(event.currentTarget);
  const closeMenu1 = () => setMenu1(null);
  const closeMenu2 = () => setMenu2(null);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          setSearch({
            ...search,
            type: null
          });
          closeMenu();
        }}>
        All Posts <Checkbox checked={!search.type} sx={{ ml: "auto" }} />
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={() => {
          setSearch({
            ...search,
            type: "Social Posts"
          });
          closeMenu();
        }}>
        Social Posts <Checkbox checked={search.type == "Social Posts"} sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem
        onClick={() => {
          setSearch({
            ...search,
            type: "RSS Feed"
          });
          closeMenu();
        }}>
        RSS Feed <Checkbox checked={search.type == "RSS Feed"} sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem
        onClick={() => {
          setSearch({
            ...search,
            type: "Blog Posts"
          });
          closeMenu();
        }}>
        Blog posts <Checkbox checked={search.type == "Blog Posts"} sx={{ ml: "auto" }} />
      </MenuItem>
    </Menu>
  );
  const renderMenu1 = (
    <Menu
      anchorEl={menu1}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu1)}
      onClose={closeMenu1}
      keepMounted
    >
      <MenuItem onClick={() => {
        setSearch({
          ...search,
          status: null
        });
        closeMenu1();
      }}>
        Select All <Checkbox checked={!search.status} sx={{ ml: "auto" }} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => {
        setSearch({
          ...search,
          status: 1
        });
        closeMenu1();
      }}>
        Draft <Checkbox checked={search.status == 1} sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={() => {
        setSearch({
          ...search,
          status: 3
        });
        closeMenu1();
      }}>
        Ready to review <Checkbox checked={search.status == 3} sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={() => {
        setSearch({
          ...search,
          status: 2
        });
        closeMenu1();
      }}>
        Disable <Checkbox checked={search.status == 2} sx={{ ml: "auto" }} />
      </MenuItem>
    </Menu>
  );
  const contentType = (
    <Menu
      anchorEl={menu2}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu2)}
      onClose={closeMenu2}
      keepMounted
    >
      <MenuItem onClick={() => {
        setSearch({
          ...search,
          preferences: null
        });
        closeMenu2();
      }}>
        Select All <Checkbox checked={!search.preferences} sx={{ ml: "auto" }} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => {
        setSearch({
          ...search,
          preferences: "Timely"
        });
        closeMenu2();
      }}>
        Timely <Checkbox checked={search.preferences == "Timely"} sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={() => {
        setSearch({
          ...search,
          preferences: "Evergreen"
        });
        closeMenu2();
      }}>
        Evergreen <Checkbox checked={search.preferences == "Evergreen"} sx={{ ml: "auto" }} />
      </MenuItem>
    </Menu>
  );
  // menu

  useEffect(() => {
    fetchMasterContents();
    fetchIndustryForAutoComplete();
    fetchTheme();
    fetchMasterLayouts();
  }, []);

  useEffect(() => {
    fetchMasterContents(search);
  }, [search]);

  useEffect(() => {
    if (themes && Array.isArray(themes) && themes.length > 0){
      fetchCompleteTheme({ id: themes[0].id });
    }
  }, [themes]);

  function selectTheme(id) {
    fetchCompleteTheme({ id });
    setLayoutShow(false);
    // let x = {};
    // if(themes && Array.isArray(themes) && themes.length > 0) {
    //   let y = themes.filter(z => z.id == id);
    //   x = y.length > 0 ? y[0] : {}
    // }
    // if(x.Layouts && Array.isArray(x.Layouts)) {
    //   const xy = x.Layouts.filter(y => y.MasterLayoutId == selected.MasterLayoutId);
    //   if (xy.length > 0){
    //     setSelectedLayout(xy[0]);
    //     setTimeout(() => setLayoutShow(true), 100);
    //   }
    // }
    // setSelectedTheme(x);
  }

  function selectLayout() {
    setLayoutShow(false);
    if (selectedTheme && selectedTheme.id && selected.MasterLayoutId) {
      if(selectedTheme.Layouts && Array.isArray(selectedTheme.Layouts)) {
        const x = selectedTheme.Layouts.filter(y => y.MasterLayoutId == selected.MasterLayoutId);
        if (x.length > 0){
          setSelectedLayout(x[0]);
          setTimeout(() => setLayoutShow(true), 100);
          console.log("SelectedLayout", x[0]);
        }
      }
    }
  }

  useEffect(() => {
    if(completeTheme && completeTheme.id) {
      setSelectedTheme(completeTheme);
      if(completeTheme.Layouts && Array.isArray(completeTheme.Layouts) && selected.MasterLayoutId) {
          const xy = completeTheme.Layouts.filter(y => y.MasterLayoutId == selected.MasterLayoutId);
          if (xy.length > 0){
            setSelectedLayout(xy[0]);
            setTimeout(() => setLayoutShow(true), 100);
          }
        }
    }
  }, [completeTheme]);

  useEffect(() => {
    selectLayout();
  }, [selected.MasterLayoutId]);

  function setDynamics(x, v) {
    let obj = {};
    obj[x] = v;
    setDynamicFields({ ...dynamicFields, ...obj });
  }

  const fetchTopics = async (x) => {
    const z = await Promise.all(x.map(y => y.id))
    getTopics(z);
  }

  const onImageChange = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      uploadBG({ image: event.target.files[0] });
    }
    
  };

  function validate(data) {
    if(selected.id) {
      updateMasterContent({ ...data, defaultDate: data.defaultDate.ts ? new Date(data.defaultDate.ts) : data.defaultDate, expiryDate: data.expiryDate.ts ? new Date(data.expiryDate.ts) : data.expiryDate, industries: JSON.stringify(data.industries), topics: JSON.stringify(data.topics), hashtags: JSON.stringify(data.hashtags) })
    } else {
      createMasterContent({ ...data, defaultDate: data.defaultDate.ts ? new Date(data.defaultDate.ts) : data.defaultDate, expiryDate: data.expiryDate.ts ? new Date(data.expiryDate.ts) : data.expiryDate, industries: JSON.stringify(data.industries), topics: JSON.stringify(data.topics), hashtags: JSON.stringify(data.hashtags) });
    }
  }

  useEffect(() => {
    if(isSaved) {
      fetchMasterContents();
      setOpenC(false);
      setSelected(emptyMasterContent);
      setDynamicFields({});
      setOpenD(false);
    }
  }, [isSaved]);

  useEffect(() => {
    if(bgImg) {
      setDynamicFields({ ...dynamicFields, "Image Library": bgImg });
    }
  }, [bgImg]);

  async function setValues(x) {
    const a = await Promise.all(x.masterIndustries.map(b => { 
      return { id: b.id, label: b.name }
    }));
    const z = await Promise.all(a.map(y => y.id));
    getTopics(z);
    const c = await Promise.all(x.masterTopics.map(d => {
      return { id: d.id, label: d.name }
    }));
    setSelected({...x, status: [{ id: 1, label: "Draft"}, { id: 2, label: "Disabled" }, { id: 3, label: "Ready to review" } ].filter(d => d.id == x.status)[0], defaultDate: new Date(x.defaultDate), expiryDate: new Date(x.expiryDate), industries: a, topics: c, dynamicFields: JSON.parse(x.dynamicFields)});
    setDynamicFields(JSON.parse(x.dynamicFields));
  }

  function loadIframe(event, id, fields) {
    let css = `
    .colorAccent {
      color: #FB8C00
    }
    .colorDark {
        color: #1B1C29
    }
    .colorLight {
        color: #DCDDE1
    }
    .bgAccent {
        background-color: #FB8C00
    }
    .bgDark {
        background-color: #1B1C29
    }
    .bgLight {
        background-color: #DCDDE1
    }
    .resizable .resizers .resizer{
      display: none;
    }
    .resizable .resizers .delete-icon {
      display: none;
    }
    .delete-icon {
      display: none;
    }  `;
          const { contentWindow } = event.target;
          const main = contentWindow.document.body;
          const selectedOne = JSON.parse(selectedLayout.data)[0];
          let m = selectedOne.width > selectedOne.height ? selectedOne.width : selectedOne.height;
          let z = (100 / (m / 100) / 100);
          setDimention({ width: selectedOne.width, zoom: z, height: selectedOne.height });
            main.innerHTML = `${selectedOne.html}`;
            let head = contentWindow.document.head || contentWindow.document.getElementsByTagName('head')[0],
            style = contentWindow.document.createElement('style');
            head.appendChild(style);
            style.type = 'text/css';
            if (style.styleSheet){
                style.styleSheet.cssText = css;
            } else {
                style.appendChild(document.createTextNode(css));
            }
            if(fields){
                for (var key in fields) {
                    if (fields.hasOwnProperty(key) && key != "Image Library") {
                      if(fields[key] != ""){
                        let a = contentWindow.document.querySelector('[name="' + key + '"] .textItem').innerHTML = fields[key];
                      }
                    }
                    if (fields.hasOwnProperty(key) && key == "Image Library") {
                        if(fields["Image Library"]){
                            const imageEle = contentWindow.document.querySelector('[name="' + key + '"] .imageItem');
                            if(imageEle) {
                                imageEle.style["background-image"] = "url(" + fields["Image Library"] + ")";
                                imageEle.style["background-position"] = "center";
                                imageEle.style["background-repeat"] = "no-repeat";
                                imageEle.style["background-size"] = "cover";
                            }
                        }
                    }
                }
            }
  }

  useEffect(() => {
    if(dynamicFields) {
      const data = {
        type: "setup",
        data: {
          dynamicFields
        }
      }
      if(document.getElementById("selected-layout")) {
        setTimeout(() => document.getElementById("selected-layout").contentWindow.postMessage(JSON.stringify(data), process.env.REACT_APP_BASE_URL), 100);
      }
    }
  }, [dynamicFields]);

  return (
    <DashboardLayout>
      <Loading loading={isLoading} />
      <DeleteConfirm
        open={openD}
        selected={selected}
        onClose={() => {
          setOpenD(false);
          setSelected(emptyMasterContent);
        }}
        deleteContent={deleteMasterContent}
      />
      <DashboardNavbar />
      <MasterLayoutDialoge
        selected={selected}
        setSelected={setSelected}
        open={openL}
        setOpen={setOpenL}
        masterLayouts={masterLayouts}
        setDynamicFields={setDynamicFields}
      />
      <Grid container style={{display: "flex", marginTop: "24px"}}>
        <Grid item xs={3}>
            <MDInput
            style={{
            borderRadius: "8px", background: "#FFFFFF"}}
            fullWidth
            label="Search"
            inputProps={{ type: "text", autoComplete: "" }}
            />
        </Grid>
        <Grid item style={{marginLeft: "auto"}}>
            <MDButton
                sx={{
                background: "linear-gradient(#09032B, #312B56)",
                color: "#fff",
                marginLeft: "auto"
                }}
                onClick={() => {
                  setOpenC(true);
                }}
            >
                CREATE MASTER CONTENT
            </MDButton>
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ display: "flex", marginTop: "20px" }} >
        <Grid item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <MDButton
                fullWidth
                style={button}
                variant={menu ? "contained" : "outlined"}
                onClick={openMenu}
              >
                Post Type&nbsp;
                <Icon>keyboard_arrow_down</Icon>
              </MDButton>
              {renderMenu}
            </Grid>
            <Grid item xs={3}>
              <MDButton
                fullWidth
                style={button}
                variant={menu1 ? "contained" : "outlined"}
                onClick={openMenu1}
              >
                Status&nbsp;
                <Icon>keyboard_arrow_down</Icon>
              </MDButton>
              {renderMenu1}
            </Grid>
            <Grid item xs={3}>
              <MDButton
                fullWidth
                style={button}
                variant={menu2 ? "contained" : "outlined"}
                onClick={openMenu2}
              >
                Content Type&nbsp;
                <Icon>keyboard_arrow_down</Icon>
              </MDButton>
              {contentType}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs={3}>
              <ToggleButtonGroup
                sx={{
                  background: "#E6E6EA",
                  borderRadius: "12px",
                  padding: "6px 8px",
                  "& .css-1c48ueu-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                    {
                      color: "#fff !important",
                      backgroundColor: "#09032B !important",
                      
                    },
                  "& .css-1c48ueu-MuiButtonBase-root-MuiToggleButton-root": {
                    border: "none",
                  },
                }}
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
              >
                <ToggleButton
                  style={{
                    borderRadius: "8px",
                    width: "30px",
                    height: "30px",
                    marginRight: "8px",
                  }} value="left" aria-label="left aligned">
                  <WindowIcon />
                </ToggleButton>
                <ToggleButton
                  style={{
                    borderRadius: "8px",
                    width: "30px",
                    height: "30px",
                  }} value="right" aria-label="right aligned">
                  <FormatListBulletedIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>            
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ my: 2 }}>
        <Grid item xs={openC ? 6 : 12}>
          <TableContainer component={Paper} sx={{ width: "100%" }}>
            <Table>
                <TableRow>
                  <TableCell style={tableHeading}>DEFAULT REVIEW DATE</TableCell>
                  <TableCell style={tableHeading}>CAPTION</TableCell>
                  <TableCell style={tableHeading}>CONTENT TYPE</TableCell>
                  <TableCell style={tableHeading}>STATUS</TableCell>
                  <TableCell style={tableHeading}>ACTION</TableCell>
                  <TableCell style={tableHeading}>INDUSTRIES</TableCell>
                  <TableCell style={tableHeading}>TOPICS</TableCell>
                </TableRow>
              <TableBody>
                {masterContents && Array.isArray(masterContents) && masterContents.map(x => {
                  return (
                    <TableRow>
                      <TableCell style={tablecell}>{preetyDate(x.defaultDate)}</TableCell>
                      <TableCell style={tablecell}>{x.content}</TableCell>
                      <TableCell style={tablecell}>{x.preferences}</TableCell>
                      <TableCell style={tablecell}>
                        {x.status == 3 ? <Chip size="small" label="Ready to review"/> : x.status == 1 ? <Chip size="small" label="Draft" /> : <Chip size="small" label="Disable" />}
                      </TableCell>
                      <TableCell style={tablecell}>
                      <IconButton
                        onClick={() => {
                          setValues({...x, hashtags: JSON.parse(x.hashtags)});
                          setOpenC(true);
                        }}
                      >
                        <EditIcon fontSize="small"/>
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setSelected(x);
                          setOpenD(true);
                        }}
                      >
                        <DeleteOutlineIcon fontSize="small" />
                      </IconButton>
                      </TableCell>
                      <TableCell style={tablecell}>
                        {x.masterIndustries && Array.isArray(x.masterIndustries) && x.masterIndustries.map(x => {
                          return <Chip size="small" label={x.name} sx={{ mr: 1 }} />
                        })}
                      </TableCell>
                      <TableCell style={tablecell}>
                        {x.masterTopics && Array.isArray(x.masterTopics) && x.masterTopics.map(x => {
                          return <Chip size="small" label={x.name} sx={{ mr: 1 }} />
                        })}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {openC && (
          <Grid item xs={6}>
            <Paper sx={{ padding: "32px" }}>
              <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", pb: "32px" }}>
                <Typography sx={{ color: "#344767", fontWeight: 700, fontSize: "27px" }}>Create New Master Content</Typography>
                <ClearIcon
                    onClick={() => {
                      setSelected(emptyMasterContent);
                      setDynamicFields({});
                      setOpenC(false);
                    }}
                    sx={{ cursor: "pointer" }}
                />
              </MDBox>
              <MDBox>
                <textarea
                  style={{ padding: "10px", height: "200px", width: "100%" }}
                  placeholder="Lorem ipsum is..."
                  value={selected.content}
                  onChange={e => setSelected({
                    ...selected,
                    content: e.target.value
                  })}
                ></textarea>
              </MDBox>
              <MDBox sx={{ border: "1px solid #E6E6EA", padding: "26px 16px", mt: 1 }}>
                  <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <MDBox sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ fontWeight: 500, fontSize: "14px", color: "#7B809A" }}>Layouts previewing as: </Typography>
                      <FormControl variant="standard" sx={{ ml: 1, minWidth: 120 }}>
                        {/* <InputLabel id="demo-simple-select-standard-label">Theme Name</InputLabel> */}
                        <Select
                          value={selectedTheme && selectedTheme.id}
                          onChange={e => selectTheme(e.target.value)}
                          placeholder="Theme Name"
                          label="Theme Name"
                        >
                          {themes && Array.isArray(themes) && themes.map(x => {
                            return <MenuItem value={x.id}>{x.name}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </MDBox>
                    <MDBox>
                      <MDBox onClick={() => setOpenL(true)} sx={{ cursor: "pointer", padding: "2px 10px", border: "2px dashed #CECDD5", borderRadius: "15x" }}>
                        <AddIcon sx={{ color: "#534F6B" }} />
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  {layoutShow && selectedTheme && selectedTheme.id && selectedLayout.MasterLayoutId && selectedLayout.MasterLayoutId != "" ? (
                    <Grid container spacing={1} sx={{ mt: 1 }}>
                      <Grid item xs={4}>
                        <MDBox>
                          <div style={{ width: "100px", height: "100px", overflow: "hidden" }}>
                            <iframe
                                frameBorder="0"
                                src={`${process.env.REACT_APP_BASE_URL}/#/layout-frame`}
                                width={dimention.width}
                                height={dimention.height}
                                id="selected-layout"
                                style={{
                                    transform: `scale(${dimention.zoom})`,
                                    MsTransform: `scale(${dimention.zoom})`,
                                    OTransform: `scale(${dimention.zoom})`,
                                    MozTransform: `scale(${dimention.zoom})`,
                                    WebkitTransform: `scale(${dimention.zoom})`,
                                    transformOrigin: "0 0",
                                    MsTransformOrigin: "0 0",
                                    OTransformOrigin: "0 0",
                                    WebkitTransformOrigin: "0 0"
                                }}
                                onLoad={(event) => {
                                    loadIframe(event, selectedLayout.MasterLayoutId, dynamicFields);
                                }}
                            />
                          </div>
                          <MDBox onClick={() => setSelected({ ...selected, MasterLayoutId: "" })} sx={{ cursor: "pointer", mt: "5px", display: "flex", alignItems: "center" }}>
                            <DeleteOutlineIcon sx={{ color: "#E71D36" }} />
                            <span style={{ color: "#E71D36", fontSize: "13px" }}>Remove Layout</span>
                          </MDBox>
                        </MDBox>
                      </Grid>
                      <Grid item xs={8}>
                        <MDBox>
                          {Object.keys(dynamicFields).map(function(x) {
                            if(["Text Field 1", "Text Field 2", "Text Field 3", "Text Field 4", "Text Field 5", "Text Field 6", "Text Paragraph"].indexOf(x) !== -1) {
                              return (
                                <FormField
                                  type="text"
                                  placeholder={x}
                                  value={dynamicFields[x]}
                                  onChange={e => setDynamics(x, e.target.value)}
                                />
                              )
                            } else {
                              return (
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <MDButton htmlFor="selected-img" component="label">
                                      <input type="file" style={{ display: "none" }} id="selected-img" onChange={onImageChange} />
                                      Upload Image
                                    </MDButton>
                                  </Grid>
                                  <Grid item xs={6}>
                                    { dynamicFields[x] ? <img src={dynamicFields[x]} width="50px" /> : "" }
                                  </Grid>
                                </Grid>
                              )
                            }
                          })}
                        </MDBox>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={1} sx={{ mt: 1 }}>
                      <Grid item xs={4}>
                        <img src="/images/no-image.png" width="100px"/>
                      </Grid>
                      <Grid item xs={8}>
                        <MDBox>
                          {Object.keys(dynamicFields).map(function(x) {
                            if(["Text Field 1", "Text Field 2", "Text Field 3", "Text Field 4", "Text Field 5", "Text Field 6", "Text Paragraph"].indexOf(x) !== -1) {
                              return (
                                <FormField
                                  type="text"
                                  placeholder={x}
                                  value={dynamicFields[x]}
                                  onChange={e => setDynamics(x, e.target.value)}
                                />
                              )
                            } else {
                              return (
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <MDButton htmlFor="selected-img" component="label">
                                      <input type="file" style={{ display: "none" }} id="selected-img" onChange={onImageChange} />
                                      Upload Image
                                    </MDButton>
                                  </Grid>
                                  <Grid item xs={6}>
                                    { dynamicFields[x] ? <img src={dynamicFields[x]} width="50px" /> : "" }
                                  </Grid>
                                </Grid>
                              )
                            }
                          })}
                        </MDBox>
                      </Grid>
                    </Grid>
                  )}
              </MDBox>
              <MDBox sx={{ mt: "20px" }}>
                <FormField
                  type="text"
                  label="Image alt text"
                  placeholder="Image alt text"
                  value={selected.imageKeywords}
                  onChange={e => setSelected({
                    ...selected,
                    imageKeywords: e.target.value
                  })}
                />
              </MDBox>
              <MDBox sx={{ mt: "20px" }}>
              <Autocomplete
                  options={["User defined unsplash collection", "By Image Keyword", "Dynamic by user"]}
                  value={selected.imageType}
                  onChange={(e, val) => {
                    setSelected({
                      ...selected,
                      imageType: val
                    });
                  }}
                  renderInput={(params) => (
                    <FormField
                    {...params}
                      label="Image Type"
                      name="imageType"
                      placeholder="Image Type"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </MDBox>
              <MDBox sx={{ mt: "20px" }}>
                <DateTimePicker
                  label="Default Date For Content"
                  value={selected.defaultDate}
                  onChange={(newValue) => {
                    setSelected({ ...selected, defaultDate: newValue });
                  }}
                  renderInput={(params) => <TextField fullWidth variant="standard" {...params} />}
                />
              </MDBox>
              <MDBox sx={{ mt: "20px" }}>
              <Autocomplete
                  options={["Australia"]}
                  value={selected.region}
                  onChange={(e, val) => setSelected({
                    ...selected,
                    region: val
                  })}
                  renderInput={(params) => (
                    <FormField
                    {...params}
                      label="Region"
                      name="region"
                      placeholder="Select Region"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </MDBox>
              <MDBox sx={{ mt: "20px" }}>
                <Autocomplete
                  multiple
                  options={Array.isArray(industries) ? industries : []}
                  value={Array.isArray(industries) && industries.length > 0 && Array.isArray(selected.industries) ? selected.industries: []}
                  onChange={(event, newValue) => {
                    setSelected({
                      ...selected,
                      industries: newValue,
                    });
                    fetchTopics(newValue);
                    setErrors(false);
                  }}
                  renderInput={(params) => (
                    <FormField
                    {...params}
                      label="Choose Industry"
                      name="IndustryId"
                      placeholder="Select Industry"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </MDBox>
              <MDBox sx={{ mt: "20px" }}>
                <Autocomplete
                  multiple
                  options={Array.isArray(topics) ? topics : []}
                  value={Array.isArray(topics) && topics.length > 0 && Array.isArray(selected.topics) ? selected.topics : []}
                  onChange={(event, newValue) => {
                    setSelected({
                      ...selected,
                      topics: newValue,
                    });
                    setErrors(false);
                  }}
                  renderInput={(params) => (
                    <FormField
                    {...params}
                      label="Choose Topics"
                      name="TopicId"
                      placeholder="Select Topic"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </MDBox>
              <MDBox sx={{ mt: "20px" }}>
              <form className={classes.form} onSubmit={e => handleOnSubmit(e)}>
                <TextField
                  inputRef={tagRef}
                  fullWidth
                  label="Hashtags"
                  variant='standard'
                  size='small'
                  sx={{ margin: "1rem 0" }}
                  margin='none'
                  placeholder={selected && selected.hashtags && selected.hashtags.length < 30 ? "Enter tags" : ""}
                  InputProps={{
                    startAdornment: (
                      <React.Fragment>
                        {selected && Array.isArray(selected.hashtags) && selected.hashtags.map((data, index) => {
                          return (
                            <Tags data={data} handleDelete={handleDelete} key={index} />
                          );
                        })}
                      </React.Fragment>
                    ),
                  }}
                />
              </form>
              </MDBox>
              <MDBox sx={{ mt: "20px" }}>
              <Autocomplete
                  options={["Timely", "Evergreen"]}
                  value={selected.preferences}
                  onChange={(event, val) => {
                    let date = new Date();
                    setSelected({
                      ...selected,
                      preferences: val,
                      expiryDate: val === "Evergreen" ? new Date(date.setMonth(date.getMonth() + 6)) : null
                    });
                  }}
                  renderInput={(params) => (
                    <FormField
                    {...params}
                      label="Which one you prefer"
                      name="Preferences"
                      placeholder="Which one you prefer"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </MDBox>
              {selected.preferences == "Timely" && (
                <MDBox sx={{ mt: "20px" }}>
                  <DateTimePicker
                    label="Select a expiry date"
                    value={selected.expiryDate}
                    onChange={(newValue) => {
                      setSelected({ ...selected, expiryDate: newValue });
                    }}
                    renderInput={(params) => <TextField fullWidth variant="standard" {...params} />}
                  />
                </MDBox>
              )}
              <MDBox sx={{ mt: "20px" }}>
                <FormField
                  type="text"
                  label="User that was created by"
                  placeholder="User that was created by"
                  value={selected.createdUser}
                  // onChange={handleChange}
                />
              </MDBox>
              <MDBox sx={{ mt: "20px" }}>
              <Autocomplete
                  options={["All Consumer"]}
                  value={selected.consumer}
                  onChange={(e, val) => {
                    setSelected({
                      ...selected,
                      consumer: val
                    });
                  }}
                  renderInput={(params) => (
                    <FormField
                    {...params}
                      label="Consumer"
                      name="consumer"
                      placeholder="Consumer"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </MDBox>
              <MDBox sx={{ mt: "20px" }}>
              <Autocomplete
                  options={[{ id: 1, label: "Draft"}, { id: 0, label: "Disabled" }, { id: 3, label: "Ready to review" } ]}
                  value={selected.status}
                  onChange={(e, val) => {
                    setSelected({
                      ...selected,
                      status: val
                    });
                  }}
                  renderInput={(params) => (
                    <FormField
                    {...params}
                      label="Status"
                      name="status"
                      placeholder="Status"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </MDBox>
              <MDBox sx={{ mt: "20px" }}>
                <MDButton
                  sx={{
                    background: "linear-gradient(#09032B, #312B56)",
                    color: "#fff",
                    marginLeft: "auto"
                  }}
                  onClick={() => validate({ ...selected, dynamicFields: JSON.stringify(dynamicFields), status: selected.status.id })}
                >
                  {selected.id ? "UPDATE " : "CREATE "} MASTER CONTENT
                </MDButton>
              </MDBox>
            </Paper>
          </Grid>
        )}
      </Grid>

    </DashboardLayout>
  );
}
