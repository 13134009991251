import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from '@material-ui/styles';
import MDButton from "../../../shared/components/MDButton";
import ClearIcon from "@mui/icons-material/Clear";
import MDAvatar from "../../../shared/components/MDAvatar";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import Switch from "@mui/material/Switch";
import FormField from "./../../../shared/components/FormField";

const useStyles = makeStyles({
    listText: {
      '& .css-knlq9b-MuiFormControlLabel-root .MuiFormControlLabel-label ':
        {
          color: "#344767",
          fontWeight: "500",
          fontSize: "12px",
        },
    },
  });

const topHead = {
    fontWeight: "700",
    fontSize: "20px",
    color: "#344767",
    }
const topText = {
    fontWeight: "500",
    fontSize: "14px",
    marginTop: "3px",
    color: "#7B809A",
}
const mdbox = {
    background: "#E6E8EB",
    height: "40px",
    width: "40px",
    borderRadius: "100px",
}
const box = {
    marginTop: "49px",
}
const text = {
    fontWeight: "600", 
    fontSize: "15px", 
    color: "#344767", 
    marginBottom: "25px", 
    marginTop: "32px"
}
const imageText = {
    fontWeight: "500", 
    fontSize: "18px", 
    color: "#344767"
}
const button = {
    background: "#09032B",
    width: "192px",
    height: "50px",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
    color: "#FFFFFF",
    borderRadius: "8px",
    textTransform: "capitalize",
}

export function DeleteConfirm(props) {
  const classes = useStyles();
  const { open, selected, onClose, deleteContent } = props;
  const mdText1 = {
    color: "red",
    marginTop: "-0px",
    marginBottom: "14px",
    fontSize: "12px",
    fontWeight: 400,
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
              <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox>
                <MDTypography style={topHead} >
                  Delete Box
                </MDTypography>
                <MDTypography style={topText} >
                  Do you really want to delete this content?
                </MDTypography>
              </MDBox>
              <MDBox>
                <ClearIcon  onClick={() => onClose()}
                  style={{ width: "32px", height: "32px" }} 
                />
              </MDBox>
            </MDBox>
            <MDBox style={box} >
                <MDButton style={button} variant="contained" onClick={() => deleteContent(selected.id, selected.scheduledId)}>
                    Delete
                </MDButton>
                <MDButton onClick={() => onClose()}>
                    Cancel
                </MDButton>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
