import React, { useEffect, useState } from "react";
import DashboardLayout from "./../../shared/components/DashboardLayout";
import DashboardNavbar from "./../../shared/components/DashboardNavbar";
import Loading from './../../shared/Loading';
// MUI
import Grid from "@mui/material/Grid";
import MDButton from "./../../shared/components/MDButton";
import MDInput from "./../../shared/components/MDInput";
import { TopicDialog } from './component/topic-dialog';
import { TopicTable } from './component/topic-table';

export default function TopicPage(props) {
  const { 
    createTopic, 
    updateTopic,
    deleteTopic,
    fetchTopic,
    topics,
    isSaved,
    isLoading,
    industriesAuto,
    fetchIndustryForAutoComplete
  } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState({name: "", status: 1});

  useEffect(() => {
    fetchIndustryForAutoComplete();
    fetchTopic();
  }, []);

  useEffect(() => {
    if (isSaved) {
      fetchTopic();
      setSelected({name: "", IndustryId: "", status: 1});
      setOpen(false);
    }
  }, [isSaved]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loading loading={isLoading} />
      <Grid container style={{display: "flex", marginTop: "24px"}}>
        <Grid item style={{marginLeft: "auto"}}>
            <MDButton
              onClick={() => {
                setSelected({name: "", IndustryId: "", status: 1});
                setOpen(true)
              }}
                sx={{
                  background: "linear-gradient(#09032B, #312B56)",
                  color: "#fff",
                  marginLeft: "auto"
                }}
            >
                CREATE TOPIC
            </MDButton>
        </Grid>
      </Grid>
      <TopicTable
        selected={selected}
        setSelected={setSelected}
        rows={topics}
        open={open}
        setOpen={setOpen}
        updateTopic={updateTopic}
      />
      <TopicDialog
        open={open}
        setOpen={setOpen}
        updateTopic={updateTopic}
        createTopic={createTopic}
        onClose={() => {
          setSelected({name: "", IndustryId: "", status: 1});
          setOpen(false);
        }}
        selected={selected}
        setSelected={setSelected}
        industries={industriesAuto}
      />
    </DashboardLayout>
  );
}
