import { TooltipPage } from './tooltip.component';
import { connect } from 'react-redux';
import { uploadBG } from './../../api/theme/themeAction';

const mapDispatchToProps = {
    uploadBG
}

const mapStateToProps = state => ({
    bgImg: state.themePage.bgImg,
    isLoading: state.themePage.isLoading,
});

export const TooltipPageContainer = connect(mapStateToProps, mapDispatchToProps)(TooltipPage);