import ContentTablePage from './contentTable.component';
import { connect } from 'react-redux';
import{ getTopics } from "./../../api/topic/topicAction";
import { fetchIndustryForAutoComplete } from "./../../api/industry/industryAction";
import { fetchTheme, fetchCompleteTheme, uploadBG } from "./../../api/theme/themeAction";
import { fetchMasterLayouts } from "./../../api/master-layout/masterLayoutAction";
import { createMasterContent, updateMasterContent, deleteMasterContent, fetchMasterContents } from "./../../api/master-content/masterContentAction";

const mapDispatchToProps = {
    getTopics,
    fetchIndustryForAutoComplete,
    fetchTheme,
    fetchMasterLayouts,
    createMasterContent,
    updateMasterContent,
    deleteMasterContent,
    fetchMasterContents,
    uploadBG,
    fetchCompleteTheme
}

const mapStateToProps = state => ({
    loggedAdmin: state.adminPage.loggedAdmin,
    industries: state.industryPage.industriesAuto,
    topics: state.topicPage.topicOptions,
    completeTheme: state.themePage.completeTheme,
    themes: state.themePage.themes,
    bgImg: state.themePage.bgImg,
    masterLayouts: state.masterLayoutPage.masterLayouts,
    masterContents: state.masterContentPage.masterContents,
    isSaved: state.masterContentPage.isSaved,
    isLoading: state.masterContentPage.isLoading || state.topicPage.isLoading || state.industryPage.isLoading
});

export const ContentTablePageContainer = connect(mapStateToProps, mapDispatchToProps)(ContentTablePage);