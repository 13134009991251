import { BusinessAccountPage } from './business-account.component';
import { connect } from 'react-redux';
import { fetchAllUsers } from './../../api/user/userAction';

const mapDispatchToProps = {
    fetchAllUsers
}

const mapStateToProps = state => ({
    isLoggedIn: state.adminPage.isLoggedIn,
    admin: state.adminPage.loggedAdmin,
    isLoading: state.userPage.isLoading,
    users: state.userPage.users
});

export const BusinessAccountPageContainer = connect(mapStateToProps, mapDispatchToProps)(BusinessAccountPage);