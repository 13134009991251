import * as actionType from "./mediaActionType";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    medias: []
}

export const mediaReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.FETCH_MEDIA:
        return {
            ...state,
            isError: false,
            isLoading: true,
        };
        case actionType.FETCH_MEDIA_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.FETCH_MEDIA_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                medias: action.payload ? action.payload.data.rows : [],
            };
        case actionType.DELETE_MEDIA:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.DELETE_MEDIA_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false
            };
        case actionType.DELETE_MEDIA_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        default: 
            return state
    }
}