import * as actionType from "./contentActionType";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    content: [],
    contentByAccount: []
}

export const contentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.DELETE_CONTENT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false,
            };
        case actionType.DELETE_CONTENT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false,
            };
        case actionType.DELETE_CONTENT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.GET_CONTENT:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_CONTENT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.GET_CONTENT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                content: action.payload ? action.payload.data.rows : [],
            };
        case actionType.GET_CONTENT_BY_ACCOUNT:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_CONTENT_BY_ACCOUNT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.GET_CONTENT_BY_ACCOUNT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                contentByAccount: action.payload ? action.payload.data : [],
            };
        default: 
            return state
    }
}