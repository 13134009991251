function timelineItem(theme) {
  const { borders } = theme;

  const { borderWidth, borderColor } = borders;

  return {
    "&:after": {
      content: "''",
      position: "absolute",
      top: "27px",
      left: "5px",
      height: "35px",
      opacity: 1,
      borderRight: `${borderWidth[2]} solid ${borderColor}`,
    },
  };
}

export default timelineItem;
