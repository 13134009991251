import ThemePage from './theme.component';
import { connect } from 'react-redux';
import { createTheme, updateTheme, deleteTheme, fetchTheme } from '../../api/theme/themeAction';

const mapDispatchToProps = {
    createTheme, 
    updateTheme,
    deleteTheme,
    fetchTheme
}

const mapStateToProps = state => ({
    isLoading: state.themePage.isLoading,
    isSaved: state.themePage.isSaved,
    themes: state.themePage.themes,
    themeCreated: state.themePage.themeCreated
});

export const ThemePageContainer = connect(mapStateToProps, mapDispatchToProps)(ThemePage);