export const blocks = [
    {
        "id": "circle",
        "label": "Cicle",
        "logo": "circle.png",
        "html": "<div class='circleItem innerItem' style='width: 200px; height: 200px;border-radius: 50%; border: 1px solid #5a5a5a'></div>",
        "scrollable": true,
        "resizeable": true
    },
    {
        "id": "rectangle",
        "label": "Rectangle",
        "logo": "ractangle.png",
        "html": "<div class='ractangleItem innerItem' style='width: 200px; height: 200px; border-radius: 20px; border: 1px solid #5a5a5a'></div>",
        "scrollable": true,
        "resizeable": true
    },
    {
        "id": "image",
        "label": "Image",
        "logo": "image.png",
        "html": "<img src='/images/image.png' width: '100px' height: '100px'/>",
        "scrollable": true,
        "resizeable": true
    },
    {
        "id": "text",
        "label": "Text",
        "logo": "text.png",
        "html": "<div class='textItem innerItem' style='text-align: center; overflow: hidden; minWidth: 50px; minHeight: 50px; width: 100%; height: 100%; font-size: 100%;'>Something will be here</div>",
        "scrollable": true,
        "resizeable": true
    }
]