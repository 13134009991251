import * as actionType from "./adminActionType";

const initialState = {
    isError: false,
    isLoading: false,
    isLoggedIn: sessionStorage.getItem('admin') !== null ? true : false,
    loggedAdmin: JSON.parse(sessionStorage.getItem('admin')) || {},
    userAuthenticate: {}
}

export const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADMIN_LOGIN:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isLoggedIn: false
            };
        case actionType.ADMIN_LOGIN_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isLoggedIn: false
            };
        case actionType.ADMIN_LOGIN_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isLoggedIn: true,
                loggedAdmin: action.payload ? action.payload.data : {},
            };
        case actionType.AUTHENTICATE_USER:
            return {
                ...state,
                isError: false,
                isLoading: true,
                userAuthenticate: {}
            };
        case actionType.AUTHENTICATE_USER_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.AUTHENTICATE_USER_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                userAuthenticate: action.payload ? action.payload.data : {},
            };
        default: 
            return state
    }
}