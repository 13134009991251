import { useState } from "react";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import MDButton from "../../../shared/components/MDButton";
import { Grid, Avatar } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from '@mui/icons-material/Delete';

const hashtagText = {
  color: "#221C40", 
  fontSize: "14px", 
  fontWeight: "400", 
  marginRight: "15px"
}

export default function Accounts(props) {
  const { profiles, deleteProfile, setSelected, setType, setOpen } = props;

  return (
    <>
      <MDBox pt={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Social Connections</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          Here you can setup and manage your integration settings.
        </MDTypography>
      </MDBox>
      <MDBox pt={2} pb={3}>
        { profiles.map((x) => {
                    return (
                      <>
                      <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <MDBox display="flex" alignItems="center">
                        <MDBox>
                          <Avatar src={x.picture} alt={x.name} />
                        </MDBox>
                        <MDBox ml={2}>
                          <MDTypography
                            style={{
                              color: "#344767",
                              fontSize: "20px",
                              fontWeight: "700",
                            }}
                          >
                            {x.type == "fb" ? "Facebook" : (x.type == "insta" ? "Instagram" : ( x.type == "gmb" ? "Google" : "Linkedin" ) )}
                            &nbsp;({x.name})
                          </MDTypography>
                          <MDBox display="flex" alignItems="flex-end">
                            <MDTypography
                              variant="button"
                              style={{
                                color: "#7B809A",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              Show less{" "}
                              <KeyboardArrowUpIcon style={{ marginBottom: "-3px" }} />
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                      <MDBox
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        width={{ xs: "100%", sm: "auto" }}
                        mt={{ xs: 1, sm: 0 }}
                      >
                        <MDBox lineHeight={0} mx={2}>
                          <MDTypography
                            variant="button"
                            style={{
                              color: "#534F6B",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Enabled
                          </MDTypography>
                        </MDBox>
                        <MDBox mr={1}>
                          <Switch
                            sx={{
                              "& .css-t7u51m-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                                {
                                  backgroundColor: "#534F6B !important",
                                },
                            }}
                            checked={true}
                          />
                        </MDBox>
                        <MDBox>
                          <DeleteIcon
                            onClick={() => deleteProfile(x.id)}
                            style={{ marginBottom: "-3px", color: "#191919", cursor: "pointer" }} />
                        </MDBox>
                      </MDBox>
                    </MDBox>
                    <MDBox ml={2} pl={6} pt={2} lineHeight={1}>
                      <MDBox
                        style={{ backgroundColor: "#F5F5F5" }}
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                        my={1}
                        py={1}
                        pl={{ xs: 1, sm: 2 }}
                        pr={1}
                      >
                        <Grid container>
                          <Grid item sm={12} md={3} style={{ textAlign: "Left" }}>
                            <MDTypography
                              variant="button"
                              fontWeight="medium"
                              color="text"
                            >
                              Post CTA
                            </MDTypography>
                          </Grid>
                          <Grid item sm={12} md={7} style={{ textAlign: "Left" }}>
                            <MDTypography
                              variant="button"
                              style={{
                                color: "#7B809A",
                                fontSize: "14px",
                                display: "flex",
                              }}
                            >
                              {x.cta}
                            </MDTypography>
                          </Grid>
                          <Grid item sm={12} md={2}>
                            <MDButton 
                              onClick={() => {
                                setSelected(x);
                                setType(x.type);
                                setOpen(true);
                              }}
                              variant="text" style={{ color: "#344767", float: "right", fontWeight: "700", fontSize: "16px", textTransform: "capitalize",
                              textDecoration: "underline" }}>
                              Edit
                            </MDButton>
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox
                        style={{ backgroundColor: "#F5F5F5" }}
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                        my={1}
                        py={1}
                        pl={{ xs: 1, sm: 2 }}
                        pr={1}
                      >
                        <Grid container style={{display: "flex", alignItems: "center"}}>
                          <Grid item sm={12} md={3} style={{ textAlign: "Left" }}>
                            <MDTypography
                              variant="button"
                              fontWeight="medium"
                              color="text"
                            >
                              Hashtags
                            </MDTypography>
                          </Grid>
                          <Grid item sm={12} md={7} style={{ textAlign: "Left", display: "flex" }}>
                            
                            <MDTypography
                              style={{
                                color: "#221C40",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              {x.hashtags}
                            </MDTypography>
                         
                          </Grid>
                          <Grid item sm={12} md={2}>
                            <MDButton
                              onClick={() => {
                                setSelected(x);
                                setType(x.type);
                                setOpen(true);
                              }}
                              variant="text" style={{ color: "#344767", float: "right", fontWeight: "700", fontSize: "16px", textTransform: "capitalize",
                              textDecoration: "underline" }}>
                              Edit
                            </MDButton>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </MDBox>
                    <Divider style={{ background: "#D4D4D4" }} />
                    </>
                    )
                  })}
      </MDBox>
    </>
  );
}
