import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import Chip from '@mui/material/Chip';
import { visuallyHidden } from '@mui/utils';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useNavigate } from "react-router-dom";
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const tableHeading = {
  fontWeight: 700,
  fontSize: "11px",
  color: "#7B809A"
}

const tablecell = {
  fontWeight: 700,
  fontSize: "14px",
  color: "#09032B"
}

const openInNewTab = (url) => {
  var c = document.createElement("a");
  c.href = url;
  // c.target = "_blank";
  c.click();
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
      <TableRow>
        <TableCell></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            style={tableHeading}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell style={tableHeading}>Tags</TableCell>
        <TableCell style={tableHeading}>Status</TableCell>
        <TableCell></TableCell>
      </TableRow>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
};


function Row(props) {
  const navigate = useNavigate();
  const { row, setSelected, updateTheme, setUpdateOpen } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        key={row.id}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          style={tablecell}
          component="th"
          scope="row"
          padding="none"
        >
          {row.name}
        </TableCell>
        <TableCell>
            {row.tags && Array.isArray(JSON.parse(row.tags)) && JSON.parse(row.tags).map(x => {
              return <Chip label={x} sx={{ mr: 1 }} />
            })}
        </TableCell>
        <TableCell>
        <Switch
          checked={row.status == 1}
          onChange={() => updateTheme({ ...row, status: row.status == 1 ? 0 : 1})}
        />
        </TableCell>
        <TableCell>
        <Tooltip title="Edit" placement="top">
          <Icon
            onClick={() => {
              setSelected({...row, image: row.htmlData, tags: row.tags && Array.isArray(JSON.parse(row.tags)) ? JSON.parse(row.tags) : []});
              setUpdateOpen(true);
            }}
            sx={{ cursor: "pointer", color: "#344767" }}
            fontSize="small"
          >
            edit
          </Icon>
        </Tooltip>
        <Tooltip title="Create Layout" placement="top">
          <AddPhotoAlternateIcon
            onClick={() => {
              openInNewTab(`${process.env.REACT_APP_BASE_URL}/#/layout/${row.id}`);
            }}
            sx={{ cursor: "pointer", color: "#344767" }}
            fontSize="small"
          />
        </Tooltip>
        <Tooltip title="Delete" placement="top">
          <Icon
            onClick={() => window.confirm("Do you really want to delete this theme?") ? updateTheme({ ...row, isDeleted: 1 }) : false }
            sx={{ cursor: "pointer", color: "#344767" }}
            fontSize="small"
          >
            delete
          </Icon>
        </Tooltip>
        </TableCell>
      </TableRow>

      <TableRow>
      <TableCell  style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
              Layouts
            </Typography>
            <Table size="small" aria-label="purchases">
                <TableRow>
                  <TableCell style={{ tableHeading }}>Name</TableCell>
                  <TableCell style={{ tableHeading }}>Image</TableCell>
                  <TableCell style={{ tableHeading }}>Dynamic Field</TableCell>
                  <TableCell style={{ tableHeading }}>Action</TableCell>
                </TableRow>
              {row.Layouts && Array.isArray(row.Layouts) && row.Layouts.map(x => {
                return (
                  <TableRow key={x.id}>
                    <TableCell>{x.name}</TableCell>
                    <TableCell component="th" scope="row">
                      <img src={x.image} width="80px" />
                    </TableCell>
                    <TableCell>
                      {x.MasterLayout && JSON.parse(x.MasterLayout.fields).map(c => {
                        return <Chip label={c} sx={{ mr: 1 }}/>
                      })}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Edit" placement="top">
                        <Icon
                          onClick={() => openInNewTab(`${process.env.REACT_APP_BASE_URL}/#/layout/${row.id}/${x.id}`)}
                          sx={{ cursor: "pointer", color: "#344767" }}
                          fontSize="small"
                        >
                          edit
                        </Icon>
                      </Tooltip>

                      <Tooltip title="Delete" placement="top">
                        <Icon
                          sx={{ cursor: "pointer", color: "#344767" }}
                          fontSize="small"
                        >
                          delete
                        </Icon>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })}
            </Table>
          </Box>
        </Collapse>
      </TableCell>
      </TableRow>
    </>
  )
}

export function ThemeTable(props) {
  const { selected, setSelected, open, setOpen, rows, updateTheme } = props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, mt: 2 }}>
        <Typography sx={{ p: 2, fontWeight: 700, fontSize: "20px", color: "#09032B" }}>Theme</Typography>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <Row setUpdateOpen={setOpen} key={row.id} row={row} updateTheme={updateTheme} setSelected={setSelected} />
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}