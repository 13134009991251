import React, { useEffect } from 'react';
import { blocks } from './block';
import './canvas.css';
import axios from 'axios';
const appUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = process.env.REACT_APP_API_URL;

export function CanvasPage() {
  const accent = "#FB8C00";
  const dark = "#1B1C29";
  const light = "#DCDDE1";
  
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
      window.addEventListener("message", receiveMessage, false);
      return () => {
          window.removeEventListener("message", receiveMessage, false);
      }
    }, []);

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown);
    return () => {
        document.removeEventListener('mousedown', onMouseDown);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('dragstart', ondragStart);
    return () => {
        document.removeEventListener('dragstart', ondragStart);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("click", selectElement);
    return () => {
      window.removeEventListener("click", selectElement);
    }
  }, []);
  /* Helper Function */
  const support = (function () {
      if (!window.DOMParser) return false;
      const parser = new DOMParser();
      try {
          parser.parseFromString('x', 'text/html');
      } catch(err) {
          return false;
      }
      return true;
  })();

  const getById = (id) => {
      const block = blocks.filter(x => x.id == id);
      return block[0];
  }

  const textToHTML= function (data, html, block={}, name=data.htmlPayload) {
      // check for DOMParser support
      if (support) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, 'text/html');

          const wrapper = document.createElement('div');
          wrapper.setAttribute("id", data.timestamp);
          wrapper.setAttribute("name", name);
          wrapper.setAttribute("data-name", name);
          wrapper.setAttribute("data-content", name);
          wrapper.setAttribute("data-dynamic-field", "");
          const resizersWrapper = document.createElement('div');
          resizersWrapper.classList.add("resizers");
          const deleteDiv = document.createElement('div');
          deleteDiv.classList.add("delete-icon");
          deleteDiv.innerHTML = `X`;
          deleteDiv.addEventListener("click", remove, false);
          const resizerDivTL = document.createElement('div');
          resizerDivTL.classList.add("resizer", "top-left");
          const resizerDivBL = document.createElement('div');
          resizerDivBL.classList.add("resizer", "bottom-left");
          const resizerDivTR = document.createElement('div');
          resizerDivTR.classList.add("resizer", "top-right");
          const resizerDivBR = document.createElement('div');
          resizerDivBR.classList.add("resizer", "bottom-right");
          resizersWrapper.innerHTML = doc.body.innerHTML;
          const isClass = resizersWrapper.querySelector('.textItem');
          if (isClass) {
            isClass.append(name);
          }
          resizersWrapper.append(deleteDiv);
          resizersWrapper.append(resizerDivTL);
          resizersWrapper.append(resizerDivTR);
          resizersWrapper.append(resizerDivBL);
          resizersWrapper.append(resizerDivBR);

          wrapper.append(resizersWrapper);

          wrapper.classList.add("wrapper");
          wrapper.style.top = data.mouse.y + "px";
          wrapper.style.left = data.mouse.x + "px";
          wrapper.style.position = 'absolute';
          return wrapper;
      }
      // Otherwise, create div and append HTML
      let dom = document.createElement('div');
      dom.classList.add("wrapper");
      dom.innerHTML = html;
      return dom;
  };
  
  /* End Helper Function */
  function receiveMessage (ev) {
      if (["webpackClose", "webpackWarnings", "webpackInvalid"].indexOf(ev.data.type) === -1) {
          const canvas = document.getElementById("canvas");
          const data = JSON.parse(ev.data);
          if (data.type === "drag") {
              /*
              * @TODO Show grid lines
              */
          } else if (data.type === "drop") {
              const x = data.data;
              if (x && x.timestamp) {
                  const block = getById(x.htmlPayload);
                  const wrapper = textToHTML(x, block.html, block, x.name);
                  canvas.prepend(wrapper);
              }
          } else if (data.type === "innerStyle" || data.type === "wrapperStyle") {
              setStyle(data.data, data.type);
          } else if (data.type === "remove_element") {
            remove();
          } else if (data.type === "set_attr") {
            setAttr(data.data);
          } else if (data.type === "background_image") {
            setBackground(data.data);
          }/* else if (data.type === "get_html") {
            getHTML(data.data);
          } */ else if (data.type === "update") {
            updateCanvas(data.data);
          } else if (data.type === "remove_all") {
            emptyCanvas();
          } else if(data.type === "update_layers") {
            update_layers(data.data);
          } else if(data.type === "update_dynamic_Field") {
            update_dynamic_Field(data.data);
          }
      }
  }

  function setAttr(attr) {
    const ele = document.getElementsByClassName("selected");
    if (ele[0].getAttribute(attr.key) && ele[0].getAttribute(attr.key) == "true") {
      ele[0].setAttribute(attr.key, false);
    } else {
      ele[0].setAttribute(attr.key, true);
    }
    return;
  }

  function css(element, style) {
    for (const property in style) {
      if(isNaN(property) && style[property]){
        element.style[property] = style[property];
      }
    }
  }

  function setStyle(data, type){
    const mainEle = document.querySelector("#main.selected");
    const ele = document.querySelectorAll(".selected .innerItem");
    if (ele.length > 0) {
        const element = document.querySelector(".selected");
        element.removeAttribute("style");
        ele[0].removeAttribute("style");
        element.setAttribute("data-dynamic-field", data.dynamicContent);
        element.setAttribute("data-content", data.dataContent);
        css(ele[0], data.innerStyle);
        css(element, data.wrapperStyle);
        ele[0].removeAttribute("class");
        ele[0].classList.add(...data.innerClass);
        const textItem = element.querySelector(".textItem");
        if(textItem){
          textItem.innerHTML = data.dataContent.replace(/\n/g, "<br />");
        }
        element.removeAttribute("class");
        element.classList.add(...data.wrapperClass);
    } else {
        mainEle.removeAttribute("style");
        css(mainEle, data.innerStyle);
        mainEle.removeAttribute("class");
        mainEle.classList.add(...data.innerClass);
    }
  }
  const isOverflown = ({ clientHeight, scrollHeight }) => scrollHeight > clientHeight;

  const resizeText = ({ element, elements, minSize = 10, maxSize = 512, step = 1, unit = 'px' }) => {
    (elements || [element]).forEach(el => {
      if(el.parentNode.parentNode.getAttribute("autoresize") == "true") {
        let i = minSize
        let overflow = false
        const parent = el
  
        while (!overflow && i < maxSize) {
            el.style.fontSize = `${i}${unit}`
            overflow = isOverflown(parent, el)
  
          if (!overflow) i += step
        }
  
        // revert to last state where no overflow happened
        el.style.fontSize = `${i - step}${unit}`
      }
    })
  }

  function setBackground (img) {
    const mainEle = document.getElementById("main");
    const ele = document.querySelectorAll(".selected .innerItem");
    
    if (ele.length > 0) {
      ele[0].style["background-image"] = `url(${img.image})`;
      ele[0].style["background-position"] = "center";
      ele[0].style["background-repeat"] = "no-repeat";
      ele[0].style["background-size"] = "cover";
    } else {
      mainEle.style["background-image"] = "url(" + img.image + ")";
      mainEle.style["background-position"] = "center";
      mainEle.style["background-repeat"] = "no-repeat";
      mainEle.style["background-size"] = "cover";
    }
  }

  async function selectElement (ev) {
    const element = ev.target;
    const parentNode = element.parentNode;
    let targetNode = null;
    if(element.getElementById !== "main") {
      targetNode = parentNode.closest('.wrapper');
    }
    const previousSelected = document.querySelector(".selected");
    if (previousSelected) {
      previousSelected.classList.remove("selected");
      previousSelected.classList.remove("resizable");
    }
    if (targetNode) {
      const wrapperClass = targetNode.classList;
      wrapperClass.add("selected");
      wrapperClass.add("resizable");
      let wrapperStyle = targetNode.style;
      const innerTarget= targetNode.querySelector(".innerItem");
      let wrapperObj = {};
      Object.keys(wrapperStyle).forEach(function(key, index) {
        wrapperObj[wrapperStyle[key]] = wrapperStyle[wrapperStyle[key]];
      });
      let innerStyle = "";
      if(innerTarget){
        innerStyle = innerTarget.style;
      }
      let innerObj = {};
      Object.keys(innerStyle).forEach(function(key, index) {
        innerObj[innerStyle[key]] = innerStyle[innerStyle[key]];
      });
      // Object.keys(innerStyle).forEach(function(key, index) {
      //   innerObj[innerStyle[key]] = innerStyle[innerStyle[key]];
      // });
      const innerTargetClass = innerTarget.classList;
      let innerClass = await Promise.all(Object.keys(innerTargetClass)
      .map(function(key) {
          return innerTargetClass[key];
      }));
      let wrapperClassObj = await Promise.all(Object.keys(wrapperClass)
      .map(function(key) {
          return wrapperClass[key];
      }));
      const data = {
        type: "style_elements",
        data: {
          wrapperStyle: wrapperObj,
          wrapperClass: wrapperClassObj,
          innerClass,
          innerStyle: innerObj,
          dynamicContent: targetNode.getAttribute("data-dynamic-field"),
          dataContent: targetNode.getAttribute("data-content"),
          isAuto: targetNode.getAttribute("autoresize"),
          resizable: targetNode.getAttribute("resizable")
        }
      }
      window.parent.postMessage(JSON.stringify(data), appUrl);
    } else {
      const mainTarget = document.querySelector("#main");
      let innerObj = {};
      let innerStyle = "";
      if(mainTarget){
        innerStyle = mainTarget.style;
      }
      Object.keys(innerStyle).forEach(function(key, index) {
        innerObj[innerStyle[key]] = innerStyle[innerStyle[key]];
      });
      const innerTargetClass = mainTarget.classList;
      innerTargetClass.add("selected");
      let innerClass = await Promise.all(Object.keys(innerTargetClass)
      .map(function(key) {
          return innerTargetClass[key];
      }));
      const data = {
        type: "style_elements",
        data: {
          wrapperStyle: {},
          wrapperClass: [],
          innerClass,
          innerStyle: innerObj,
          isAuto: "false",
          resizable: "false"
        }
      }
      window.parent.postMessage(JSON.stringify(data), appUrl);
    }
  }

  function onMouseDown (ev) {
    if (ev.target.classList.contains("resizer")) {
      console.log(ev.target.parentNode.parentNode);
      if(ev.target.parentNode.parentNode.getAttribute("resizable") && ev.target.parentNode.parentNode.getAttribute("resizable") == "false") {
        alert("Unlock resize button.");
        return;
      }
        makeResizableDiv();
      // }
    } else {
        moveAllOver(ev);
    }
    if(ev.target.parentNode.querySelector(".textItem")) {
      const ele = document.getElementsByClassName("selected");
      resizeText({element: ev.target.parentNode.querySelector(".textItem")});
    }
  }

  function ondragStart (ev) {
    return false;
  }

  function makeResizableDiv(div = ".selected") {
    const element = document.querySelector(div);
    const resizers = document.querySelectorAll(div + ' .resizer')
    const innerEle = element.querySelector(".innerItem");
    const minimum_size = 20;
    let original_width = 0;
    let original_height = 0;
    let original_x = 0;
    let original_y = 0;
    let original_mouse_x = 0;
    let original_mouse_y = 0;
      for (let i = 0;i < resizers.length; i++) {
        const currentResizer = resizers[i];
        currentResizer.addEventListener('mousedown', function(e) {
          e.preventDefault()
          original_width = parseFloat(getComputedStyle(element, null).getPropertyValue('width').replace('px', ''));
          original_height = parseFloat(getComputedStyle(element, null).getPropertyValue('height').replace('px', ''));
          original_x = element.getBoundingClientRect().left;
          original_y = element.getBoundingClientRect().top;
          original_mouse_x = e.pageX;
          original_mouse_y = e.pageY;
          window.addEventListener('mousemove', resize);
          window.addEventListener('mouseup', stopResize);
  
          function stopResize() {
            window.removeEventListener('mousemove', resize)
          }
        })
        
        function resize(e) {
          if (currentResizer.classList.contains('bottom-right')) {
            const width = original_width + (e.pageX - original_mouse_x);
            const height = original_height + (e.pageY - original_mouse_y)
            if (width > minimum_size) {
              innerEle.style.width = (width) + 'px';
              element.style.width = width + 'px';
            }
            if (height > minimum_size) {
              innerEle.style.height = (height) + 'px';
              element.style.height = height + 'px'
            }
          }
          else if (currentResizer.classList.contains('bottom-left')) {
            const width = original_width - (e.pageX - original_mouse_x)
            const height = original_height + (e.pageY - original_mouse_y)
            if (height > minimum_size) {
              innerEle.style.height = (height) + 'px';
              element.style.height = height + 'px'
            }
            if (width > minimum_size) {
              innerEle.style.width = (width) + 'px';
              element.style.width = width + 'px'
              element.style.left = original_x + (e.pageX - original_mouse_x) + 'px'
            }
          }
          else if (currentResizer.classList.contains('top-right')) {
            const width = original_width + (e.pageX - original_mouse_x)
            const height = original_height - (e.pageY - original_mouse_y)
            if (width > minimum_size) {
              innerEle.style.width = (width) + 'px';
              element.style.width = width + 'px'
            }
            if (height > minimum_size) {
              innerEle.style.height = (height) + 'px';
              element.style.height = height + 'px'
              element.style.top = original_y + (e.pageY - original_mouse_y) + 'px'
            }
          }
          else {
            const width = original_width - (e.pageX - original_mouse_x)
            const height = original_height - (e.pageY - original_mouse_y)
            if (width > minimum_size) {
              innerEle.style.width = (width) + 'px';
              element.style.width = width + 'px'
              element.style.left = original_x + (e.pageX - original_mouse_x) + 'px'
            }
            if (height > minimum_size) {
              innerEle.style.height = (height) + 'px';
              element.style.height = height + 'px'
              element.style.top = original_y + (e.pageY - original_mouse_y) + 'px'
            }
          }
        }
      }
  }

  function moveAllOver(ev) {
      ev.target.onmousedown = function(event) {
          const parentNode = ev.target.parentNode;
          if (!parentNode.closest('.wrapper')) return;
          if (ev.target.id != "main") {
              const targetWrapper = parentNode.closest('.wrapper');
              let shiftX = event.clientX - targetWrapper.getBoundingClientRect().left;
              let shiftY = event.clientY - targetWrapper.getBoundingClientRect().top;
            
              targetWrapper.style.position = 'absolute';
              // targetWrapper.style.zIndex = 1;
              // document.body.append(targetWrapper);
            
              moveAt(event.pageX, event.pageY);
            
              // moves the ev.target at (pageX, pageY) coordinates
              // taking initial shifts into account
              function moveAt(pageX, pageY) {
                targetWrapper.classList.remove("vbottom", "vtop", "vcenter", "hright", "hleft", "hcenter", "hvcenter");
                targetWrapper.style.left = pageX - shiftX + 'px';
                targetWrapper.style.top = pageY - shiftY + 'px';
              }
            
              function onMouseMove(event) {
                moveAt(event.pageX, event.pageY);
              }
            
              // move the ev.target on mousemove
              window.addEventListener('mousemove', onMouseMove);
              window.addEventListener('mouseup', stopMoving);
            
              function stopMoving() {
                window.removeEventListener('mousemove', onMouseMove)
              }
              // drop the ev.target, remove unneeded handlers
              // ev.target.onmouseup = function() {
              //   document.removeEventListener('mousemove', onMouseMove);
              //   // ev.target.onmouseup = null;
              // };
          }
        }; 
  }

  function remove (e) {
    e.preventDefault();
    const ele = document.getElementsByClassName("selected");
    console.log(ele[0]);
    ele[0].remove();
    return;
  }

  // function getHTML (data) {
  //   const html = document.getElementById('canvas').innerHTML;
  //   const layoutId = data.layoutId;
  //   if (layoutId) {
  //     setSelected({ id: layoutId, data: JSON.stringify(html), name: data.name, MasterLayoutId: data.MasterLayoutId })
  //     setOpen(true);
  //     // updateLayout(selected);
  //   } else {
  //     setSelected({ ThemeId: data.id, data: JSON.stringify(html), name: data.name, MasterLayoutId: data.MasterLayoutId, status: 1 });
  //     setOpen(true);
  //     // saveLayout(selected);
  //   }
  // }

  function updateCanvas (data) {
    const d = JSON.parse(data.htmlPayload);
    document.getElementById('canvas').innerHTML = d;
  }

  function emptyCanvas() {
    document.getElementById('canvas').innerHTML = '<div id="main" name="" data-name="" style="width: 100%; height: 100vh"></div>';
  }
  
  function update_layers({ ele1, ele2 }) {
    let child_nodes = document.getElementById("canvas").childNodes;
    let nodeA = child_nodes[ele1];
    let nodeB = child_nodes[ele2];
    swap(nodeA, nodeB);
  }

  const swap = function (nodeA, nodeB) {
    const parentA = nodeA.parentNode;
    const siblingA = nodeB;
    nodeB.parentNode.insertBefore(nodeA, nodeB);
    parentA.insertBefore(nodeB, siblingA);
  };

  function update_dynamic_Field(data) {
    const wrapper = document.getElementsByClassName('selected')[0];
  }

  return (
    <>
      <div id="canvas">
          <div id="main" name="" data-name="" style={{ width: '100%', height: "100vh" }}></div>
      </div>
          <div class="grid" name="" data-name=""></div>
    </>
  )
}
