export const UPLOAD_BG = "UPLOAD_BG";
export const UPLOAD_BG_FAILURE = "UPLOAD_BG_FAILURE";
export const UPLOAD_BG_SUCCESS = "UPLOAD_BG_SUCCESS";

export const SAVE_LAYOUT = "SAVE_LAYOUT";
export const SAVE_LAYOUT_FAILURE = "SAVE_LAYOUT_FAILURE";
export const SAVE_LAYOUT_SUCCESS = "SAVE_LAYOUT_SUCCESS";

export const CREATE_THEME = "CREATE_THEME";
export const CREATE_THEME_FAILURE = "CREATE_THEME_FAILURE";
export const CREATE_THEME_SUCCESS = "CREATE_THEME_SUCCESS";

export const UPDATE_THEME = "UPDATE_THEME";
export const UPDATE_THEME_FAILURE = "UPDATE_THEME_FAILURE";
export const UPDATE_THEME_SUCCESS = "UPDATE_THEME_SUCCESS";

export const FETCH_THEME = "FETCH_THEME";
export const FETCH_THEME_FAILURE = "FETCH_THEME_FAILURE";
export const FETCH_THEME_SUCCESS = "FETCH_THEME_SUCCESS";

export const FETCH_COMPLETE_THEME = "FETCH_COMPLETE_THEME";
export const FETCH_COMPLETE_THEME_FAILURE = "FETCH_COMPLETE_THEME_FAILURE";
export const FETCH_COMPLETE_THEME_SUCCESS = "FETCH_COMPLETE_THEME_SUCCESS";

export const GET_LAYOUT = "GET_LAYOUT";
export const GET_LAYOUT_FAILURE = "GET_LAYOUT_FAILURE";
export const GET_LAYOUT_SUCCESS = "GET_LAYOUT_SUCCESS";

export const FETCH_THEME_FOR_AUTOCOMPLETE = "FETCH_THEME_FOR_AUTOCOMPLETE";
export const FETCH_THEME_FOR_AUTOCOMPLETE_FAILURE = "FETCH_THEME_FOR_AUTOCOMPLETE_FAILURE";
export const FETCH_THEME_FOR_AUTOCOMPLETE_SUCCESS = "FETCH_THEME_FOR_AUTOCOMPLETE_SUCCESS";

export const DELETE_THEME = "DELETE_THEME";
export const DELETE_THEME_FAILURE = "DELETE_THEME_FAILURE";
export const DELETE_THEME_SUCCESS = "DELETE_THEME_SUCCESS";
