import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from "./shared/protected.route";
import { ContentPageContainer } from './views/content/content.container';
import { BusinessDetailPageContainer } from './views/businessDetails/business-details.container';
import { BusinessAccountPageContainer } from './views/businessAccount/business-account.container';
import { LoginPageContainer } from './views/login/login.container';
import { SignupPageContainer } from './views/signup/signup.container';
import { SubscriptionPageContainer } from './views/subscription/subscription-billing.container';
import { TeamPageContainer } from './views/teamMates/team-mates.container';
import { UserDetailsPageContainer } from './views/userDetails/user-details.container';
import { ContentTablePageContainer } from "./views/allContentTable/contentTable.container";
import { ContentGridTablePageContainer } from "./views/contentTable/content-table.conatiner";
import { ContentListPageContainer } from "./views/contentList/content-list.container";
import { FacebookPageContainer } from './views/facebook/facebook.container';
import { OverviewPageContainer } from './views/overview/overview.container';
import { CuratedPageContainer } from './views/curatedContent/curated-content.container';
import { TooltipPageContainer } from './views/Tooltip/tooltip.container';
import { CanvasPage } from './views/layout/canvas.component';
import { UserAccountPageContainer } from "./views/userAccount/user-account.container";
import { TopicPageContainer } from "./views/topic/topic.container";
import { IndustryPageContainer } from "./views/industry/industry.container";
import { WhitePageContainer } from "./views/white/white.container";
import { MediaPageContainer } from "./views/media/media.container";
import { LayoutPageContainer } from "./views/layout/layout.container";
import { ThemePageContainer } from "./views/theme/theme.container";
import { MasterLayoutPageContainer } from "./views/master-layouts/master-layouts.container";
import { LayoutFrameContainer } from "./views/allContentTable/layoutFrame"; 
import { AllContentPageContainer } from './views/allContent/allcontent.container';
import { SchedulePageContainer } from "./views/schedule/schedule.container";

export const RoutePath = () => (
  <Routes>
      <Route path="/calendar" element={<SchedulePageContainer />} />
      <Route path="/layout-frame" element={<LayoutFrameContainer />} />
      <Route path="/industry" element={<ProtectedRoute><IndustryPageContainer /></ProtectedRoute>} />
      <Route path="/white-label" element={<ProtectedRoute><WhitePageContainer /></ProtectedRoute>} />
      <Route path="/user-content" element={<ProtectedRoute><AllContentPageContainer /></ProtectedRoute>} />
      <Route path="/media" element={<ProtectedRoute><MediaPageContainer /></ProtectedRoute>} />
      <Route path="/topic" element={<ProtectedRoute><TopicPageContainer /></ProtectedRoute>} />
      <Route path="/content" element={<ProtectedRoute><ContentPageContainer /></ProtectedRoute>} /> 
      <Route path="/:userId/business-details/:accountId" element={<ProtectedRoute><BusinessDetailPageContainer /></ProtectedRoute>} />
      <Route path="/business-account" element={<ProtectedRoute><BusinessAccountPageContainer /></ProtectedRoute>} />
      <Route path="/user-account" element={<ProtectedRoute><UserAccountPageContainer /></ProtectedRoute>} />
      <Route path="/login" element={<LoginPageContainer />} />
      <Route path="/subscription" element={<ProtectedRoute><SubscriptionPageContainer /></ProtectedRoute>} />
      <Route path="/team-mates" element={<ProtectedRoute><TeamPageContainer /></ProtectedRoute>} />
      <Route path="/user-details" element={<ProtectedRoute><UserDetailsPageContainer /></ProtectedRoute>} />
      <Route path="/signup" element={<SignupPageContainer />} />
      <Route path="/content-table" element={<ProtectedRoute><ContentTablePageContainer /></ProtectedRoute>} />
      <Route path="/contenttable" element={<ProtectedRoute><ContentGridTablePageContainer /></ProtectedRoute>} />
      <Route path="/content-list" element={<ProtectedRoute><ContentListPageContainer /></ProtectedRoute>} />
      <Route path="/facebook" element={<ProtectedRoute><FacebookPageContainer /></ProtectedRoute>} />
      <Route path="/overview" element={<ProtectedRoute><OverviewPageContainer /></ProtectedRoute>} />
      <Route path="/master-layout" element={<ProtectedRoute><MasterLayoutPageContainer /></ProtectedRoute>} />
      <Route path="/curated" element={<ProtectedRoute><CuratedPageContainer /></ProtectedRoute>} />
      <Route path="/tooltip" element={<ProtectedRoute><TooltipPageContainer /></ProtectedRoute>} />
      <Route path="/layout/:themeId" element={<ProtectedRoute><LayoutPageContainer /></ProtectedRoute>} />
      <Route path="/layout/:themeId/:layoutId" element={<ProtectedRoute><LayoutPageContainer /></ProtectedRoute>} />
      <Route path="/canvas" element={<ProtectedRoute><CanvasPage /></ProtectedRoute>} />
      <Route path="/themes" element={<ProtectedRoute><ThemePageContainer /></ProtectedRoute>} />
      <Route path="/login" element={<LoginPageContainer />} />
      <Route path="/" element={<ProtectedRoute><UserAccountPageContainer /></ProtectedRoute>} />
  </Routes>
);
