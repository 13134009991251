export const CREATE_MASTER_LAYOUT = "CREATE_MASTER_LAYOUT";
export const CREATE_MASTER_LAYOUT_FAILURE = "CREATE_MASTER_LAYOUT_FAILURE";
export const CREATE_MASTER_LAYOUT_SUCCESS = "CREATE_MASTER_LAYOUT_SUCCESS";

export const UPDATE_MASTER_LAYOUT = "UPDATE_MASTER_LAYOUT";
export const UPDATE_MASTER_LAYOUT_FAILURE = "UPDATE_MASTER_LAYOUT_FAILURE";
export const UPDATE_MASTER_LAYOUT_SUCCESS = "UPDATE_MASTER_LAYOUT_SUCCESS";

export const FETCH_MASTER_LAYOUTS = "FETCH_MASTER_LAYOUTS";
export const FETCH_MASTER_LAYOUTS_FAILURE = "FETCH_MASTER_LAYOUTS_FAILURE";
export const FETCH_MASTER_LAYOUTS_SUCCESS = "FETCH_MASTER_LAYOUTS_SUCCESS";

export const DELETE_MASTER_LAYOUT = "DELETE_MASTER_LAYOUT";
export const DELETE_MASTER_LAYOUT_FAILURE = "DELETE_MASTER_LAYOUT_FAILURE";
export const DELETE_MASTER_LAYOUT_SUCCESS = "DELETE_MASTER_LAYOUT_SUCCESS";
