import * as actionTypes from "./masterLayoutActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const createMasterLayout = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(createMasterLayoutInit());
        axios.post(`${baseUrl}/master-layout/store`, data, config)
        .then((response)=>{
            dispatch(createMasterLayoutSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createMasterLayoutFailure());
        })
    };
};

export const createMasterLayoutInit = () => ({
    type: actionTypes.CREATE_MASTER_LAYOUT
});

export const createMasterLayoutFailure = data => ({
    type: actionTypes.CREATE_MASTER_LAYOUT_FAILURE
});

export const createMasterLayoutSuccess = data => ({
    type: actionTypes.CREATE_MASTER_LAYOUT_SUCCESS,
    payload: { data }
});

export const updateMasterLayout = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(updateMasterLayoutInit());
        axios.put(`${baseUrl}/master-layout/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateMasterLayoutSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateMasterLayoutFailure());
        })
    };
};

export const updateMasterLayoutInit = () => ({
    type: actionTypes.UPDATE_MASTER_LAYOUT
});

export const updateMasterLayoutFailure = data => ({
    type: actionTypes.UPDATE_MASTER_LAYOUT_FAILURE
});

export const updateMasterLayoutSuccess = data => ({
    type: actionTypes.UPDATE_MASTER_LAYOUT_SUCCESS,
    payload: { data }
});

export const deleteMasterLayout = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(deleteMasterLayoutInit());
        axios.delete(`${baseUrl}/master-layout/${id}`, config)
        .then((response)=>{
            dispatch(deleteMasterLayoutSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteMasterLayoutFailure());
        })
    };
};

export const deleteMasterLayoutInit = () => ({
    type: actionTypes.DELETE_MASTER_LAYOUT
});

export const deleteMasterLayoutFailure = data => ({
    type: actionTypes.DELETE_MASTER_LAYOUT_FAILURE
});

export const deleteMasterLayoutSuccess = data => ({
    type: actionTypes.DELETE_MASTER_LAYOUT_SUCCESS,
    payload: { data }
});

export const fetchMasterLayouts = () => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }};
    return (dispatch) => {
        dispatch(fetchMasterLayoutsInit());
        axios.get(`${baseUrl}/master-layout`, config)
        .then((response)=>{
            dispatch(fetchMasterLayoutsSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchMasterLayoutsFailure());
        })
    };
};

export const fetchMasterLayoutsInit = () => ({
    type: actionTypes.FETCH_MASTER_LAYOUTS
});

export const fetchMasterLayoutsFailure = data => ({
    type: actionTypes.FETCH_MASTER_LAYOUTS_FAILURE
});

export const fetchMasterLayoutsSuccess = data => ({
    type: actionTypes.FETCH_MASTER_LAYOUTS_SUCCESS,
    payload: { data }
});

