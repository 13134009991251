import { useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "../../shared/components/MDBox";
import MDTypography from "../../shared/components/MDTypography";
import masterCardLogo from "../../assets/images/logos/mastercard.png";
import MDButton from "../../shared/components/MDButton";
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";
import Divider from "@mui/material/Divider";
import { useMaterialUIController } from "./../../context";
import MDAvatar from "../../shared/components/MDAvatar";
import imageprofile from "../../assets/images/image-profile.png";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Grid from "@mui/material/Grid";
import AddIcon from '@mui/icons-material/Add';
import TeamMatesDialog from "./component/team-mates-dialog";

const standerdplan = {
    backgroundColor: "#4CAE67",
    borderRadius: "12.5px",
    color: "#FFFFFF",
    width: "122px",
    height: "25px",
    paddingTop: "3px",
    fontSize: "12px",
    marginTop: "10px",
    textAlign: "center" 
  };

export function TeamPage() {
//   const { fetchReviews, isLoading, isError, reviews } = props;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [open, setOpen] = useState(false);
//   const {starter, premium, enterprise} = prices;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TeamMatesDialog open={open} setOpen={setOpen} />
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
            <Card
                sx={{
                background: "#FFFFFF",
                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                borderRadius: "12px",
                width: "972px",
                }}
            >
                <MDBox style={{ display: "flex", padding: "23px 20px" }}>
                    <MDBox>
                    <MDTypography
                        style={{
                        fontWeight: "700",
                        fontSize: "20px",
                        color: "#344767",
                        }}
                    >
                        Team Members
                    </MDTypography>
                    <MDTypography
                        style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#7B809A",
                        }}
                    >
                        Here you can setup and manage your integration setting.
                    </MDTypography>
                    </MDBox>
                    <MDBox sx={{ ml: "auto" }}>
                    <MDButton
                        onClick={() => setOpen(true)}
                        variant="outlined"
                        style={{ backgroundColor: "#FFFFFF", 
                                color: "#09032B", 
                                textTransform: "capitalize",
                                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                                borderColor: "#09032B" }}
                                size="small"
                    >
                        <AddIcon sx={{color: "#09032B"}} />Add New
                    </MDButton>
                    </MDBox>
                </MDBox>
                <Divider style={{ background: "#E9EAED", opacity: "0.7" }} />
                <Grid container style={{padding: "8px 20px"}}>
                    <Grid item xs={12} md={6}>
                        <MDBox display="flex" alignItems="center">
                            <MDAvatar src={imageprofile} alt="Slack logo" />
                            <MDBox ml={2}>
                                <MDTypography
                                style={{
                                    fontWeight: "700",
                                    fontSize: "17px",
                                    color: "#344767",
                                }}
                                >
                                    Haylie Herwitz
                                </MDTypography>
                                <MDTypography
                                style={{
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    color: "#F4233D",
                                    marginTop: "3px"
                                }}
                                >
                                    OWNER
                                </MDTypography>
                                <MDTypography
                                    style={{
                                    fontWeight: "700",
                                    fontSize: "14px",
                                    color: "#344767",
                                    marginTop: "6px"
                                    }}
                                >
                                    Last Login:{" "}
                                <span
                                    style={{
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: "#7B809A",
                                    }}
                                >
                                    31 Jan tomorrow
                                </span>
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDBox style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <MDBox>
                                <MDTypography
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "17px",
                                        color: "#344767",
                                    }}
                                >
                                    233
                                </MDTypography>
                                <MDTypography
                                style={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    color: "#AEAEAE",
                                }}
                                >
                                    Posts this month
                                </MDTypography>
                            </MDBox>
                            <MDBox>
                                <MDTypography
                                style={{
                                    fontWeight: "700",
                                    fontSize: "17px",
                                    color: "#344767",
                                }}
                                >
                                233
                                </MDTypography>
                                <MDTypography
                                style={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    color: "#AEAEAE",
                                }}
                                >
                                Total posts
                                </MDTypography>
                            </MDBox>
                            <MDBox>
                                <MDTypography
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "12px",
                                        color: "#7B809A",
                                    }}
                                >
                                    <ArrowForwardIcon style={{ marginBottom: "-2px" }} />
                                    TRACK ACCOUNT
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </Grid>
                </Grid>
                <Divider style={{ background: "#E9EAED", opacity: "0.7" }} />
                <Grid container style={{padding: "8px 20px"}}>
                    <Grid item xs={12} md={6}>
                        <MDBox display="flex" alignItems="center">
                            <MDAvatar src={imageprofile} alt="Slack logo" />
                            <MDBox ml={2}>
                                <MDTypography
                                style={{
                                    fontWeight: "700",
                                    fontSize: "17px",
                                    color: "#344767",
                                }}
                                >
                                    Haylie Herwitz
                                </MDTypography>
                                <MDTypography
                                style={{
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    color: "#72D68E",
                                    marginTop: "3px"
                                }}
                                >
                                    USER
                                </MDTypography>
                                <MDTypography
                                    style={{
                                    fontWeight: "700",
                                    fontSize: "14px",
                                    color: "#344767",
                                    marginTop: "6px"
                                    }}
                                >
                                    Last Login:{" "}
                                <span
                                    style={{
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: "#7B809A",
                                    }}
                                >
                                    31 Jan tomorrow
                                </span>
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDBox style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <MDBox>
                                <MDTypography
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "17px",
                                        color: "#344767",
                                    }}
                                >
                                    233
                                </MDTypography>
                                <MDTypography
                                style={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    color: "#AEAEAE",
                                }}
                                >
                                    Posts this month
                                </MDTypography>
                            </MDBox>
                            <MDBox>
                                <MDTypography
                                style={{
                                    fontWeight: "700",
                                    fontSize: "17px",
                                    color: "#344767",
                                }}
                                >
                                233
                                </MDTypography>
                                <MDTypography
                                style={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    color: "#AEAEAE",
                                }}
                                >
                                Total posts
                                </MDTypography>
                            </MDBox>
                            <MDBox>
                                <MDTypography
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "12px",
                                        color: "#7B809A",
                                    }}
                                >
                                    <ArrowForwardIcon style={{ marginBottom: "-2px" }} />
                                    TRACK ACCOUNT
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </Grid>
                </Grid>
                <Divider style={{ background: "#E9EAED", opacity: "0.7" }} />
                <Grid container style={{padding: "8px 20px"}}>
                    <Grid item xs={12} md={6}>
                        <MDBox display="flex" alignItems="center">
                            <MDAvatar src={imageprofile} alt="Slack logo" />
                            <MDBox ml={2}>
                                <MDTypography
                                style={{
                                    fontWeight: "700",
                                    fontSize: "17px",
                                    color: "#344767",
                                }}
                                >
                                    Haylie Herwitz
                                </MDTypography>
                                <MDTypography
                                style={{
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    color: "#72D68E",
                                    marginTop: "3px"
                                }}
                                >
                                    USER
                                </MDTypography>
                                <MDTypography
                                    style={{
                                    fontWeight: "700",
                                    fontSize: "14px",
                                    color: "#344767",
                                    marginTop: "6px"
                                    }}
                                >
                                    Last Login:{" "}
                                <span
                                    style={{
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: "#7B809A",
                                    }}
                                >
                                    31 Jan tomorrow
                                </span>
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDBox style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <MDBox>
                                <MDTypography
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "17px",
                                        color: "#344767",
                                    }}
                                >
                                    233
                                </MDTypography>
                                <MDTypography
                                style={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    color: "#AEAEAE",
                                }}
                                >
                                    Posts this month
                                </MDTypography>
                            </MDBox>
                            <MDBox>
                                <MDTypography
                                style={{
                                    fontWeight: "700",
                                    fontSize: "17px",
                                    color: "#344767",
                                }}
                                >
                                233
                                </MDTypography>
                                <MDTypography
                                style={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    color: "#AEAEAE",
                                }}
                                >
                                Total posts
                                </MDTypography>
                            </MDBox>
                            <MDBox>
                                <MDTypography
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "12px",
                                        color: "#7B809A",
                                    }}
                                >
                                    <ArrowForwardIcon style={{ marginBottom: "-2px" }} />
                                    TRACK ACCOUNT
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </Grid>
                </Grid>
                <Divider style={{ background: "#E9EAED", opacity: "0.7" }} />
                <Grid container style={{padding: "8px 20px"}}>
                    <Grid item xs={12} md={6}>
                        <MDBox display="flex" alignItems="center">
                            <MDAvatar src={imageprofile} alt="Slack logo" />
                            <MDBox ml={2}>
                                <MDTypography
                                style={{
                                    fontWeight: "700",
                                    fontSize: "17px",
                                    color: "#344767",
                                }}
                                >
                                    Haylie Herwitz
                                </MDTypography>
                                <MDTypography
                                style={{
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    color: "#72D68E",
                                    marginTop: "3px"
                                }}
                                >
                                    USER
                                </MDTypography>
                                <MDTypography
                                    style={{
                                    fontWeight: "700",
                                    fontSize: "14px",
                                    color: "#344767",
                                    marginTop: "6px"
                                    }}
                                >
                                    Last Login:{" "}
                                <span
                                    style={{
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: "#7B809A",
                                    }}
                                >
                                    31 Jan tomorrow
                                </span>
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDBox style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <MDBox>
                                <MDTypography
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "17px",
                                        color: "#344767",
                                    }}
                                >
                                    233
                                </MDTypography>
                                <MDTypography
                                style={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    color: "#AEAEAE",
                                }}
                                >
                                    Posts this month
                                </MDTypography>
                            </MDBox>
                            <MDBox>
                                <MDTypography
                                style={{
                                    fontWeight: "700",
                                    fontSize: "17px",
                                    color: "#344767",
                                }}
                                >
                                233
                                </MDTypography>
                                <MDTypography
                                style={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    color: "#AEAEAE",
                                }}
                                >
                                Total posts
                                </MDTypography>
                            </MDBox>
                            <MDBox>
                                <MDTypography
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "12px",
                                        color: "#7B809A",
                                    }}
                                >
                                    <ArrowForwardIcon style={{ marginBottom: "-2px" }} />
                                    TRACK ACCOUNT
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </Grid>
                </Grid>
            </Card>
        </MDBox>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}
