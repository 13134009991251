import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Stack from "@mui/material/Stack";
import MDButton from "../../../shared/components/MDButton";
import Icon from "@mui/material/Icon";
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from "@mui/icons-material/Clear";

const topHead = {
  fontWeight: "700",
  fontSize: "20px",
  color: "#344767",
  marginBottom: "4px",
}
const topText = {
  fontWeight: "600", 
  fontSize: "14px", 
  color: "#7B809A"
}
const mdbox = {
  background: "#E6E8EB",
  height: "40px",
  width: "40px",
  borderRadius: "100px",
}
const mdbutton = {
  background: "#F4F4F4",
  color: "#344767",
  fontWeight: "400",
  fontSize: "18px",
  textTransform: "capitalize",
  padding: "11px",
  borderRadius: "8px"
}
const iconText = {
  color: "#AEAEAE",
  width: "18px",
  height: "18px"
}
const text = {
  fontWeight: "700",
  fontSize: "14px",
  color: "#344767",
}
const button = {
  background: "#09032B",
  color: "#FFFFFF",
  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  width: "355px",
  fontWeight: "700",
  fontSize: "12px"
}

export default function TopicsDialog(props) {
  // const classes = useStyles();
  const { open, setOpen } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox>
                <MDTypography style={topHead} >
                  Update Topics
                </MDTypography>
                <MDTypography style={topText} >
                  Change type
                </MDTypography>
              </MDBox>
              <MDBox style={mdbox} >
                <ClearIcon  onClick={() => setOpen(false)}
                  style={{ margin: "12px 12px" }} 
                />
              </MDBox>
            </MDBox>
            <MDTypography style={text}
              sx={{ marginTop: "24px" }} >
              Choosen Topics
            </MDTypography>
            <Grid container spacing={2} style={{ marginTop: "12px" }}>
              <Grid item xs={12} sm={3}>
                <MDButton style={mdbutton} >
                  E-Commerce &nbsp;
                  <Icon style={{ color: "#AEAEAE" }}>close</Icon>
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={3}>
                <MDButton style={mdbutton}>
                  Real Estate &nbsp;
                  <Icon style={{ color: "#AEAEAE" }}>close</Icon>
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={3}>
                <MDButton style={mdbutton} >
                  Real Estate &nbsp;
                  <Icon style={{ color: "#AEAEAE" }}>close</Icon>
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={3}>
                <MDButton style={mdbutton} >
                  Real Estate &nbsp;
                  <Icon style={{ color: "#AEAEAE" }}>close</Icon>
                </MDButton>
              </Grid>
            </Grid>
            <MDTypography style={text}
              sx={{ marginTop: "32px" }} >
              Suggested Topics
            </MDTypography>
            <Grid container spacing={2} style={{ marginTop: "12px" }}>
              <Grid item xs={12} sm={3}>
                <MDButton style={mdbutton} >
                  E-Commerce &nbsp;
                  <AddIcon style={iconText} />
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={3}>
                <MDButton style={mdbutton} >
                  E-Commerce &nbsp;
                  <AddIcon style={iconText} />
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={3}>
                <MDButton style={mdbutton} >
                  E-Commerce &nbsp;
                  <AddIcon style={iconText} />
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={3}>
                <MDButton style={mdbutton} >
                  E-Commerce &nbsp;
                  <AddIcon style={iconText} />
                </MDButton>
              </Grid>
              <Grid item xs={12} md={3}>
                <MDButton style={mdbutton} >
                  E-Commerce &nbsp;
                  <AddIcon style={iconText} />
                </MDButton>
              </Grid>
              <Grid item xs={12} md={3}>
                <MDButton style={mdbutton} >
                  E-Commerce &nbsp;
                  <AddIcon style={iconText} />
                </MDButton>
              </Grid>
            </Grid>
            <MDBox
              style={{
                marginTop: "39px",
              }}
            >
              <MDButton style={button} fullWidth >
                UPDATE Topics
              </MDButton>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
