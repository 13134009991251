import React from 'react';
import IdCell from "./../components/IdCell";
import DefaultCell from "./../components/DefaultCell";

// Icons
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MDTypography from "../../MDTypography";
import MDBox from "../../MDBox";

const dataTableData = {
  columns: [
    {
      Header: "Post Details",
      accessor: "id",
      Cell: ({ value }) => <IdCell id={value} />,
    },
    {
      Header: "Post Media",
      accessor: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Time & Date",
      accessor: "status",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "product",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "revenue",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],

  rows: [
    {
      id: (
        <MDBox>
          <MDTypography style={{ color: "#09032B", fontSize: "14px", fontWeight: "600" }}>Post Title Goes Here</MDTypography>
          <MDTypography style={{  color: "#534F6B", fontSize: "12px", fontWeight: "400", maxWidth: "292px" }}>
            Reference site about Lorem Ipsum, giving inform on its origins, as well as a random Lipsum genera...
          </MDTypography>
          <MDTypography style={{  color: "#71768D", fontSize: "12px", fontWeight: "400" }}>
            #realestate
          </MDTypography>
          <MDTypography style={{  color: "#71768D", fontSize: "12px", fontWeight: "400" }}>
            #property
          </MDTypography>
          <MDTypography style={{  color: "#71768D", fontSize: "12px", fontWeight: "400" }}>
            #management
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDBox style={{display: "flex"}}>
          <FacebookRoundedIcon style={{ marginRight: "10px", color: "#AEAEAE"}} />
          <InstagramIcon style={{ marginRight: "10px", color: "#AEAEAE"}} />
          <TwitterIcon style={{color: "#AEAEAE" }} />
        </MDBox>
      ),
      status: (
        <MDTypography
          style={{ color: "#7B809A", fontSize: "11px", fontWeight: "400" }}>
          31 Jan 12.30 AM
        </MDTypography>
      ),
      product: (
        <MDTypography
          style={{
            fontWeight: "500",
            fontSize: "11px",
            color: "#09032B",
            padding: "4px 7px 3px",
            background: "#F0F2F5",
            borderRadius: "36px",
            width: "118px",
          }}>Waiting for Approval
        </MDTypography>
      ),
      revenue: (
        <MDBox style={{display: "flex"}}>
          <MDTypography style={{fontWeight: "600", fontSize: "13px", color: "#1B172E", marginRight: "26px"}}>View Post</MDTypography>
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <MoreVertIcon {...bindTrigger(popupState)}
                  style={{ width: "16px", height: "16px", color: "#7B809A" }}
                />
                <Menu {...bindMenu(popupState)}>
                  <MenuItem style={{fontWeight: "700", fontSize: "14px", color: "#344767"}}>Select a Option</MenuItem>
                  <div
                    style={{
                      borderBottom: "1px solid #E9EAED",
                      width: "100%",
                      // marginTop: "10px",
                    }}
                  />
                  <MenuItem onClick={popupState.close}
                    style={{fontWeight: "400", fontSize: "14px", color: "#534F6B"}}>
                    Analytics
                  </MenuItem>
                  <MenuItem onClick={popupState.close}
                    style={{fontWeight: "400", fontSize: "14px", color: "#534F6B"}}>
                    Archive
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </MDBox>
      ),
    },
    {
      id: (
        <MDBox>
          <MDTypography style={{ color: "#09032B", fontSize: "14px", fontWeight: "600" }}>Post Title Goes Here</MDTypography>
          <MDTypography style={{  color: "#534F6B", fontSize: "12px", fontWeight: "400", maxWidth: "292px" }}>
            Reference site about Lorem Ipsum, giving inform on its origins, as well as a random Lipsum genera...
          </MDTypography>
          <MDTypography style={{  color: "#71768D", fontSize: "12px", fontWeight: "400" }}>
            #realestate
          </MDTypography>
          <MDTypography style={{  color: "#71768D", fontSize: "12px", fontWeight: "400" }}>
            #property
          </MDTypography>
          <MDTypography style={{  color: "#71768D", fontSize: "12px", fontWeight: "400" }}>
            #management
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDBox style={{display: "flex"}}>
          <FacebookRoundedIcon style={{ marginRight: "10px", color: "#AEAEAE"}} />
          <InstagramIcon style={{ marginRight: "10px", color: "#AEAEAE"}} />
          <TwitterIcon style={{color: "#AEAEAE" }} />
        </MDBox>
      ),
      status: (
        <MDTypography
          style={{ color: "#7B809A", fontSize: "11px", fontWeight: "400" }}>
          31 Jan 12.30 AM
        </MDTypography>
      ),
      product: (
        <MDTypography
          style={{
            fontWeight: "500",
            fontSize: "11px",
            color: "#09032B",
            padding: "4px 7px 3px",
            background: "#F0F2F5",
            borderRadius: "36px",
            width: "118px",
          }}>Waiting for Approval
        </MDTypography>
      ),
      revenue: (
        <MDBox style={{display: "flex"}}>
          <MDTypography style={{fontWeight: "600", fontSize: "13px", color: "#1B172E", marginRight: "26px"}}>View Post</MDTypography>
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <MoreVertIcon {...bindTrigger(popupState)}
                  style={{ width: "16px", height: "16px", color: "#7B809A" }}
                />
                <Menu {...bindMenu(popupState)}>
                  <MenuItem style={{fontWeight: "700", fontSize: "14px", color: "#344767"}}>Select a Option</MenuItem>
                  <div
                    style={{
                      borderBottom: "1px solid #E9EAED",
                      width: "100%",
                      // marginTop: "10px",
                    }}
                  />
                  <MenuItem onClick={popupState.close}
                    style={{fontWeight: "400", fontSize: "14px", color: "#534F6B"}}>
                    Analytics
                  </MenuItem>
                  <MenuItem onClick={popupState.close}
                    style={{fontWeight: "400", fontSize: "14px", color: "#534F6B"}}>
                    Archive
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </MDBox>
      ),
    },
    {
      id: (
        <MDBox>
          <MDTypography style={{ color: "#09032B", fontSize: "14px", fontWeight: "600" }}>Post Title Goes Here</MDTypography>
          <MDTypography style={{  color: "#534F6B", fontSize: "12px", fontWeight: "400", maxWidth: "292px" }}>
            Reference site about Lorem Ipsum, giving inform on its origins, as well as a random Lipsum genera...
          </MDTypography>
          <MDTypography style={{  color: "#71768D", fontSize: "12px", fontWeight: "400" }}>
            #realestate
          </MDTypography>
          <MDTypography style={{  color: "#71768D", fontSize: "12px", fontWeight: "400" }}>
            #property
          </MDTypography>
          <MDTypography style={{  color: "#71768D", fontSize: "12px", fontWeight: "400" }}>
            #management
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDBox style={{display: "flex"}}>
          <FacebookRoundedIcon style={{ marginRight: "10px", color: "#AEAEAE"}} />
          <InstagramIcon style={{ marginRight: "10px", color: "#AEAEAE"}} />
          <TwitterIcon style={{color: "#AEAEAE" }} />
        </MDBox>
      ),
      status: (
        <MDTypography
          style={{ color: "#7B809A", fontSize: "11px", fontWeight: "400" }}>
          31 Jan 12.30 AM
        </MDTypography>
      ),
      product: (
        <MDTypography
          style={{
            fontWeight: "500",
            fontSize: "11px",
            color: "#09032B",
            padding: "4px 7px 3px",
            background: "#F0F2F5",
            borderRadius: "36px",
            width: "118px",
          }}>Waiting for Approval
        </MDTypography>
      ),
      revenue: (
        <MDBox style={{display: "flex"}}>
          <MDTypography style={{fontWeight: "600", fontSize: "13px", color: "#1B172E", marginRight: "26px"}}>View Post</MDTypography>
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <MoreVertIcon {...bindTrigger(popupState)}
                  style={{ width: "16px", height: "16px", color: "#7B809A" }}
                />
                <Menu {...bindMenu(popupState)}>
                  <MenuItem style={{fontWeight: "700", fontSize: "14px", color: "#344767"}}>Select a Option</MenuItem>
                  <div
                    style={{
                      borderBottom: "1px solid #E9EAED",
                      width: "100%",
                      // marginTop: "10px",
                    }}
                  />
                  <MenuItem onClick={popupState.close}
                    style={{fontWeight: "400", fontSize: "14px", color: "#534F6B"}}>
                    Analytics
                  </MenuItem>
                  <MenuItem onClick={popupState.close}
                    style={{fontWeight: "400", fontSize: "14px", color: "#534F6B"}}>
                    Archive
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </MDBox>
      ),
    },
    {
      id: (
        <MDBox>
          <MDTypography style={{ color: "#09032B", fontSize: "14px", fontWeight: "600" }}>Post Title Goes Here</MDTypography>
          <MDTypography style={{  color: "#534F6B", fontSize: "12px", fontWeight: "400", maxWidth: "292px" }}>
            Reference site about Lorem Ipsum, giving inform on its origins, as well as a random Lipsum genera...
          </MDTypography>
          <MDTypography style={{  color: "#71768D", fontSize: "12px", fontWeight: "400" }}>
            #realestate
          </MDTypography>
          <MDTypography style={{  color: "#71768D", fontSize: "12px", fontWeight: "400" }}>
            #property
          </MDTypography>
          <MDTypography style={{  color: "#71768D", fontSize: "12px", fontWeight: "400" }}>
            #management
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDBox style={{display: "flex"}}>
          <FacebookRoundedIcon style={{ marginRight: "10px", color: "#AEAEAE"}} />
          <InstagramIcon style={{ marginRight: "10px", color: "#AEAEAE"}} />
          <TwitterIcon style={{color: "#AEAEAE" }} />
        </MDBox>
      ),
      status: (
        <MDTypography
          style={{ color: "#7B809A", fontSize: "11px", fontWeight: "400" }}>
          31 Jan 12.30 AM
        </MDTypography>
      ),
      product: (
        <MDTypography
          style={{
            fontWeight: "500",
            fontSize: "11px",
            color: "#09032B",
            padding: "4px 7px 3px",
            background: "#F0F2F5",
            borderRadius: "36px",
            width: "118px",
          }}>Waiting for Approval
        </MDTypography>
      ),
      revenue: (
        <MDBox style={{display: "flex"}}>
          <MDTypography style={{fontWeight: "600", fontSize: "13px", color: "#1B172E", marginRight: "26px"}}>View Post</MDTypography>
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <MoreVertIcon {...bindTrigger(popupState)}
                  style={{ width: "16px", height: "16px", color: "#7B809A" }}
                />
                <Menu {...bindMenu(popupState)}>
                  <MenuItem style={{fontWeight: "700", fontSize: "14px", color: "#344767"}}>Select a Option</MenuItem>
                  <div
                    style={{
                      borderBottom: "1px solid #E9EAED",
                      width: "100%",
                      // marginTop: "10px",
                    }}
                  />
                  <MenuItem onClick={popupState.close}
                    style={{fontWeight: "400", fontSize: "14px", color: "#534F6B"}}>
                    Analytics
                  </MenuItem>
                  <MenuItem onClick={popupState.close}
                    style={{fontWeight: "400", fontSize: "14px", color: "#534F6B"}}>
                    Archive
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </MDBox>
      ),
    },
    {
      id: (
        <MDBox>
          <MDTypography style={{ color: "#09032B", fontSize: "14px", fontWeight: "600" }}>Post Title Goes Here</MDTypography>
          <MDTypography style={{  color: "#534F6B", fontSize: "12px", fontWeight: "400", maxWidth: "292px" }}>
            Reference site about Lorem Ipsum, giving inform on its origins, as well as a random Lipsum genera...
          </MDTypography>
          <MDTypography style={{  color: "#71768D", fontSize: "12px", fontWeight: "400" }}>
            #realestate
          </MDTypography>
          <MDTypography style={{  color: "#71768D", fontSize: "12px", fontWeight: "400" }}>
            #property
          </MDTypography>
          <MDTypography style={{  color: "#71768D", fontSize: "12px", fontWeight: "400" }}>
            #management
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDBox style={{display: "flex"}}>
          <FacebookRoundedIcon style={{ marginRight: "10px", color: "#AEAEAE"}} />
          <InstagramIcon style={{ marginRight: "10px", color: "#AEAEAE"}} />
          <TwitterIcon style={{color: "#AEAEAE" }} />
        </MDBox>
      ),
      status: (
        <MDTypography
          style={{ color: "#7B809A", fontSize: "11px", fontWeight: "400" }}>
          31 Jan 12.30 AM
        </MDTypography>
      ),
      product: (
        <MDTypography
          style={{
            fontWeight: "500",
            fontSize: "11px",
            color: "#09032B",
            padding: "4px 7px 3px",
            background: "#F0F2F5",
            borderRadius: "36px",
            width: "118px",
          }}>Waiting for Approval
        </MDTypography>
      ),
      revenue: (
        <MDBox style={{display: "flex"}}>
          <MDTypography style={{fontWeight: "600", fontSize: "13px", color: "#1B172E", marginRight: "26px"}}>View Post</MDTypography>
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <MoreVertIcon {...bindTrigger(popupState)}
                  style={{ width: "16px", height: "16px", color: "#7B809A" }}
                />
                <Menu {...bindMenu(popupState)}>
                  <MenuItem style={{fontWeight: "700", fontSize: "14px", color: "#344767"}}>Select a Option</MenuItem>
                  <div
                    style={{
                      borderBottom: "1px solid #E9EAED",
                      width: "100%",
                      // marginTop: "10px",
                    }}
                  />
                  <MenuItem onClick={popupState.close}
                    style={{fontWeight: "400", fontSize: "14px", color: "#534F6B"}}>
                    Analytics
                  </MenuItem>
                  <MenuItem onClick={popupState.close}
                    style={{fontWeight: "400", fontSize: "14px", color: "#534F6B"}}>
                    Archive
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </MDBox>
      ),
    },
  ],
};

export default dataTableData;
