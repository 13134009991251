// Material Dashboard 2 PRO React components
import MDBox from "./../../../shared/components/MDBox";
import MDProgress from "./../../../shared/components/MDProgress";

// ProductPage page components
import ProductCell from "./../components/ProductCell";
import ReviewCell from "./../components/ReviewCell";
import DefaultCell from "./../components/DefaultCell";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// Images
import blackChair from "./../../../assets/images/ecommerce/black-chair.jpeg";
import chairPink from "./../../../assets/images/ecommerce/chair-pink.jpeg";
import chairSteel from "./../../../assets/images/ecommerce/chair-steel.jpeg";
import chairWood from "./../../../assets/images/ecommerce/chair-wood.jpeg";
import { Stack } from "@mui/material";
import MDTypography from "../../../shared/components/MDTypography";

const dataTableData = {
  columns: [
    { Header: "", accessor: "product", width: "80%" },
    { Header: "", accessor: "availability", align: "center"},
    { Header: "", accessor: "price" },
    { Header: "", accessor: "id", align: "center" },
  ],

  rows: [
    {
      product: <ProductCell name="Facebook" />,
      availability: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "18px",
              height: "18px",
              color: "#FB8C00",
              marginRight: "5px",
            }}
          />
          <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#7B809A"}}>Scheduled</MDTypography>
        </Stack>
      ),
      price: (
        <Stack direction="row">
          <MDTypography
            style={{ color: "#50B153", fontSize: "12px", fontWeight: "300" }}
          >
            10 <span style={{ color: "#534F6B", fontSize: "12px", fontWeight: "300" }}>Posts</span>
          </MDTypography>
        </Stack>
      ),
      id: <DefaultCell>May 22, 08:00 AM</DefaultCell>,
    },
    {
      product: <ProductCell name="Instagram" />,
      // review: <ReviewCell rating={5} />,
      availability: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "18px",
              height: "18px",
              color: "#FB8C00",
              marginRight: "5px",
            }}
          />
          <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#7B809A"}}>Scheduled</MDTypography>
        </Stack>
      ),
      price: (
        <Stack direction="row">
          <MDTypography
            style={{ color: "#50B153", fontSize: "12px", fontWeight: "300" }}
          >
            10 <span style={{ color: "#534F6B", fontSize: "12px", fontWeight: "300" }}>Posts</span>
          </MDTypography>
        </Stack>
      ),
      id: <DefaultCell>May 22, 08:00 AM</DefaultCell>,
    },
    {
      product: <ProductCell name="Twitter" />,
      // review: <ReviewCell rating={4.5} />,
      availability: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "18px",
              height: "18px",
              color: "#FB8C00",
              marginRight: "5px",
            }}
          />
          <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#7B809A"}}>Scheduled</MDTypography>
        </Stack>
      ),
      price: (
        <Stack direction="row">
          <MDTypography
            style={{ color: "#50B153", fontSize: "12px", fontWeight: "300" }}
          >
            10 <span style={{ color: "#534F6B", fontSize: "12px", fontWeight: "300" }}>Posts</span>
          </MDTypography>
        </Stack>
      ),
      id: <DefaultCell>May 22, 08:00 AM</DefaultCell>,
    },
    {
      product: <ProductCell name="Pinterest" />,
      // review: <ReviewCell rating={4.5} />,
      availability: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "18px",
              height: "18px",
              color: "#FB8C00",
              marginRight: "5px",
            }}
          />
          <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#7B809A"}}>Scheduled</MDTypography>
        </Stack>
      ),
      price: (
        <Stack direction="row">
          <MDTypography
            style={{ color: "#50B153", fontSize: "12px", fontWeight: "300" }}
          >
            10 <span style={{ color: "#534F6B", fontSize: "12px", fontWeight: "300" }}>Posts</span>
          </MDTypography>
        </Stack>
      ),
      id: <DefaultCell>May 22, 08:00 AM</DefaultCell>,
    },
    {
      product: <ProductCell name="Linkedin" />,
      // review: <ReviewCell rating={4.5} />,
      availability: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "18px",
              height: "18px",
              color: "#FB8C00",
              marginRight: "5px",
            }}
          />
          <MDTypography style={{fontWeight: "400", fontSize: "12px", color: "#7B809A"}}>Scheduled</MDTypography>
        </Stack>
      ),
      price: (
        <Stack direction="row">
          <MDTypography
            style={{ color: "#50B153", fontSize: "12px", fontWeight: "300" }}
          >
            10 <span style={{ color: "#534F6B", fontSize: "12px", fontWeight: "300" }}>Posts</span>
          </MDTypography>
        </Stack>
      ),
      id: <DefaultCell>May 22, 08:00 AM</DefaultCell>,
    },
  ],
};

export default dataTableData;
