import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import MDTypography from "../../../shared/components/MDTypography";
import MDButton from "../../../shared/components/MDButton";
import MDBox from "../../../shared/components/MDBox";
import MDAvatar from "../../../shared/components/MDAvatar";
import themeImage from "../../../assets/images/topics1.png";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import ClearIcon from "@mui/icons-material/Clear";

const topHead = {
  fontWeight: "700",
  fontSize: "20px",
  color: "#344767",
  marginBottom: "4px",
}
const topText = {
  fontWeight: "600", 
  fontSize: "14px", 
  color: "#7B809A"
}

// const useStyles = makeStyles({
//   closeBtn: {
//     position: "relative",
//     top: "0px",
//     cursor: "pointer",
//     fontSize: "24px",
//     color: "#AEAEAE",
//   },
// });

export default function ThemeDialog(props) {
  // const classes = useStyles();
  const { open, setOpen } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={3}>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox>
                <MDTypography style={topHead} >
                  Update Topics
                </MDTypography>
                <MDTypography style={topText} >
                  Change type
                </MDTypography>
              </MDBox>
              <MDBox
                style={{
                  background: "#E6E8EB",
                  height: "40px",
                  width: "40px",
                  borderRadius: "100px",
                }}
              >
                <ClearIcon  onClick={() => setOpen(false)}
                  style={{ margin: "12px 12px" }} 
                />
              </MDBox>
            </MDBox>
            <MDBox
              style={{
                display: "flex",
                justifyContent: "space-between",
                display: "flex",
                padding: "0px",
                background: "#F0F2F5",
                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                marginLeft: "25px",
                marginRight: "25px",
                marginTop: "25px",
              }}
            >
              <MDBox
                sx={{
                  display: "flex",
                  marginTop: "-9px",
                  marginBottom: "-10px",
                }}
              >
                <MDAvatar
                  variant="rounded"
                  src={themeImage}
                  alt="profile-image"
                  size="xl"
                />
                <MDBox>
                  <MDTypography
                    pt={2}
                    sx={{
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "140%",
                      color: "#09032B",
                      marginLeft: "10px",
                    }}
                  >
                    Theme Name Here
                  </MDTypography>
                  <MDTypography
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#09032B",
                      marginLeft: "10px",
                    }}
                  >
                    Free
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox style={{ marginRight: "10px" }}>
                <span
                  sx={{
                    position: "relative",
                    top: "0px",
                    cursor: "pointer",
                    fontSize: "24px",
                  }}
                >
                  x
                </span>
              </MDBox>
            </MDBox>
            <MDBox p={3}>
              <MDBox style={{ marginBottom: "17px" }}>
                <MDTypography
                  sx={{
                    fontWeight: "700",
                    fontSize: "20px",
                    color: "#344767",
                    marginBottom: "4px",
                  }}
                >
                  Themes
                </MDTypography>
                <MDTypography
                  style={{
                    color: "#7b809a",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Here you can setup and manage your integration settings.
                </MDTypography>
              </MDBox>
              <Grid item xs={12} style={{ marginBottom: "17px" }}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="filled-adornment-amount">
                    Search
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={<SearchIcon />}
                    label="Amount"
                  />
                </FormControl>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <img
                    src={themeImage}
                    alt="Theme logo"
                    style={{ width: "-webkit-fill-available" }}
                  />
                  <MDBox style={{ display: "flex" }}>
                    <MDTypography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: " #344767",
                      }}
                    >
                      Theme name Here
                    </MDTypography>
                    <MDBox sx={{ ml: "auto" }}>
                      <MDTypography
                        color="success"
                        sx={{ fontWeight: 400, fontSize: "14px" }}
                      >
                        Free
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDButton
                    sx={{
                      background: "#09032B",
                      color: "#FFFFFF",
                      border: "1px solid #09032B",
                      borderRadius: "8px",
                      height: "33px",
                    }}
                    fullWidth
                  >
                    Active
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src={themeImage}
                    alt="Theme logo"
                    style={{ width: "-webkit-fill-available" }}
                  />
                  <MDBox style={{ display: "flex" }}>
                    <MDTypography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: " #344767",
                      }}
                    >
                      Theme name Here
                    </MDTypography>
                    <MDBox sx={{ ml: "auto" }}>
                      <MDTypography
                        color="success"
                        sx={{ fontWeight: 400, fontSize: "14px" }}
                      >
                        Free
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDButton
                    sx={{
                      background: "#FFFFFF",
                      color: "#09032B",
                      border: "1px solid #09032B",
                      borderRadius: "8px",
                      height: "33px",
                    }}
                    fullWidth
                  >
                    USE THIS THEME
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src={themeImage}
                    alt="Theme logo"
                    style={{ width: "-webkit-fill-available" }}
                  />
                  <MDBox style={{ display: "flex" }}>
                    <MDTypography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: " #344767",
                      }}
                    >
                      Theme name Here
                    </MDTypography>
                    <MDBox sx={{ ml: "auto" }}>
                      <MDTypography
                        color="success"
                        sx={{ fontWeight: 400, fontSize: "14px" }}
                      >
                        Free
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDButton
                    sx={{
                      background: "#FFFFFF",
                      color: "#09032B",
                      border: "1px solid #09032B",
                      borderRadius: "8px",
                      height: "33px",
                    }}
                    fullWidth
                  >
                    USE THIS THEME
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src={themeImage}
                    alt="Theme logo"
                    style={{ width: "-webkit-fill-available" }}
                  />
                  <MDBox style={{ display: "flex" }}>
                    <MDTypography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: " #344767",
                      }}
                    >
                      Theme name Here
                    </MDTypography>
                    <MDBox sx={{ ml: "auto" }}>
                      <MDTypography
                        color="success"
                        sx={{ fontWeight: 400, fontSize: "14px" }}
                      >
                        Free
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDButton
                    sx={{
                      background: "#FFFFFF",
                      color: "#09032B",
                      border: "1px solid #09032B",
                      borderRadius: "8px",
                      height: "33px",
                    }}
                    fullWidth
                  >
                    USE THIS THEME
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src={themeImage}
                    alt="Theme logo"
                    style={{ width: "-webkit-fill-available" }}
                  />
                  <MDBox style={{ display: "flex" }}>
                    <MDTypography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: " #344767",
                      }}
                    >
                      Theme name Here
                    </MDTypography>
                    <MDBox sx={{ ml: "auto" }}>
                      <MDTypography
                        color="success"
                        sx={{ fontWeight: 400, fontSize: "14px" }}
                      >
                        Free
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDButton
                    sx={{
                      background: "#FFFFFF",
                      color: "#09032B",
                      border: "1px solid #09032B",
                      borderRadius: "8px",
                      height: "33px",
                    }}
                    fullWidth
                  >
                    USE THIS THEME
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src={themeImage}
                    alt="Theme logo"
                    style={{ width: "-webkit-fill-available" }}
                  />
                  <MDBox style={{ display: "flex" }}>
                    <MDTypography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: " #344767",
                      }}
                    >
                      Theme name Here
                    </MDTypography>
                    <MDBox sx={{ ml: "auto" }}>
                      <MDTypography
                        color="success"
                        sx={{ fontWeight: 400, fontSize: "14px" }}
                      >
                        Free
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDButton
                    sx={{
                      background: "#FFFFFF",
                      color: "#09032B",
                      border: "1px solid #09032B",
                      borderRadius: "8px",
                      height: "33px",
                    }}
                    fullWidth
                  >
                    USE THIS THEME
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src={themeImage}
                    alt="Theme logo"
                    style={{ width: "-webkit-fill-available" }}
                  />
                  <MDBox style={{ display: "flex" }}>
                    <MDTypography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: " #344767",
                      }}
                    >
                      Theme name Here
                    </MDTypography>
                    <MDBox sx={{ ml: "auto" }}>
                      <MDTypography
                        color="success"
                        sx={{ fontWeight: 400, fontSize: "14px" }}
                      >
                        Free
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDButton
                    sx={{
                      background: "#FFFFFF",
                      color: "#09032B",
                      border: "1px solid #09032B",
                      borderRadius: "8px",
                      height: "33px",
                    }}
                    fullWidth
                  >
                    USE THIS THEME
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src={themeImage}
                    alt="Theme logo"
                    style={{ width: "-webkit-fill-available" }}
                  />
                  <MDBox style={{ display: "flex" }}>
                    <MDTypography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: " #344767",
                      }}
                    >
                      Theme name Here
                    </MDTypography>
                    <MDBox sx={{ ml: "auto" }}>
                      <MDTypography
                        color="success"
                        sx={{ fontWeight: 400, fontSize: "14px" }}
                      >
                        Free
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDButton
                    sx={{
                      background: "#FFFFFF",
                      color: "#09032B",
                      border: "1px solid #09032B",
                      borderRadius: "8px",
                      height: "33px",
                    }}
                    fullWidth
                  >
                    USE THIS THEME
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src={themeImage}
                    alt="Theme logo"
                    style={{ width: "-webkit-fill-available" }}
                  />
                  <MDBox style={{ display: "flex" }}>
                    <MDTypography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: " #344767",
                      }}
                    >
                      Theme name Here
                    </MDTypography>
                    <MDBox sx={{ ml: "auto" }}>
                      <MDTypography
                        color="success"
                        sx={{ fontWeight: 400, fontSize: "14px" }}
                      >
                        Free
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDButton
                    sx={{
                      background: "#FFFFFF",
                      color: "#09032B",
                      border: "1px solid #09032B",
                      borderRadius: "8px",
                      height: "33px",
                    }}
                    fullWidth
                  >
                    USE THIS THEME
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
