import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import Grid from "@mui/material/Grid";
import MDButton from "../../../shared/components/MDButton";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Chip from "@mui/material/Chip";
import ClearIcon from "@mui/icons-material/Clear";

const topHead = {
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "140%",
  color: "#344767",
}
const topText = {
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "140%",
  color: "#344767",
}
const mdbox = {
  background: "#E6E8EB",
  height: "40px",
  width: "40px",
  borderRadius: "100px",
}
const button = {
  background: "#09032B",
  color: "#FFFFFF",
  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  width: "355px",
  fontWeight: "700",
  fontSize: "12px"
}
const box = {
  marginTop: "40px",
  justifyContent: "center",
  alignIitems: "center",
  display: "flex",
}

const tableHeading = {
    fontWeight: 700,
    fontSize: "11px",
    color: "#7B809A"
  }
  
const tablecell = {
fontWeight: 700,
fontSize: "14px",
color: "#09032B"
}

export default function MasterLayoutDialoge(props) {
  const { open, setOpen, masterLayouts, selected, setSelected, setDynamicFields } = props;
  const mdText2 = {
    color: "red",
    fontSize: "12px",
    fontWeight: 400,
  };

  const onSelect = async (row) => {
    let obj = {}
    const fields = await Promise.all(row.fields && JSON.parse(row.fields).map(x => obj[x] = ""));
    setSelected({
      ...selected,
      MasterLayoutId: row.id
    });
    setDynamicFields(obj);
    setOpen(false);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox>
                <MDTypography style={topHead} >
                  Choose Master Layout
                </MDTypography>
                <MDTypography style={topText} >
                  Please select master layout
                </MDTypography>
              </MDBox>
              <MDBox style={mdbox} >
                <ClearIcon
                  onClick={() => setOpen(false)}
                  style={{ margin: "12px 12px" }} 
                />
              </MDBox>
            </MDBox>
            <Grid container spacing={3} style={{marginTop: "15px"}}>
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                >
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Code</TableCell>
                        <TableCell>Fields</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableBody>
                    {masterLayouts && Array.isArray(masterLayouts) && masterLayouts.map((row, index) => {
                       return (<TableRow
                            hover
                            tabIndex={-1}
                            key={row.id}
                        >
                        <TableCell
                            style={tablecell}
                            component="th"
                            scope="row"
                            padding="none"
                        >
                            {row.code}. {row.name}
                        </TableCell>
                        <TableCell
                            style={tablecell}
                            component="th"
                            scope="row"
                            padding="none"
                        >
                            {row.code}
                        </TableCell>
                        <TableCell
                            style={tablecell}
                            component="th"
                            scope="row"
                            padding="none"
                        >
                            {row.fields && JSON.parse(row.fields).map((x, i) => <Chip sx={{ mr: 1 }} label={x} />)}
                        </TableCell>
                        <TableCell>
                            <MDButton
                                variant="contained"
                                onClick={() => onSelect(row)}>
                                    Select
                            </MDButton>
                        </TableCell>
                        </TableRow>)
                        })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
          </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
