import WhitePage from './white.component';
import { connect } from 'react-redux';
import { createWhite, updateWhite, deleteWhite, fetchWhite } from './../../api/white/whiteAction';

const mapDispatchToProps = {
    createWhite, 
    updateWhite,
    deleteWhite,
    fetchWhite
}

const mapStateToProps = state => ({
    isLoading: state.whitePage.isLoading,
    isSaved: state.whitePage.isSaved,
    whites: state.whitePage.whites,
});

export const WhitePageContainer = connect(mapStateToProps, mapDispatchToProps)(WhitePage);