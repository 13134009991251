import * as actionTypes from "./userActionType";
import axios from 'axios';
import { toast } from 'react-toastify';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;
export const fetchAllUsers = (data) => {
    console.warn(data)
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }, params: data};
    console.warn('config', config)
    return (dispatch) => {
        axios.get(`${baseUrl}/user`, config)
        .then((response)=>{
            dispatch(fetchAllUsersSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchAllUsersFailure());
        })
    };
};

export const fetchAllUsersInit = () => ({
    type: actionTypes.FETCH_ALL_USERS
});

export const fetchAllUsersFailure = data => ({
    type: actionTypes.FETCH_ALL_USERS_FAILURE
});

export const fetchAllUsersSuccess = data => ({
    type: actionTypes.FETCH_ALL_USERS_SUCCESS,
    payload: { data }
});

export const fetchUserById = (userId, accountId) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } };
    return (dispatch) => {
        axios.get(`${baseUrl}/user/by/${userId}/account/${accountId}`, config)
        .then((response)=>{
            dispatch(fetchUserByIdSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchUserByIdFailure());
        })
    };
};

export const fetchUserByIdInit = () => ({
    type: actionTypes.FETCH_USER_BY_ID
});

export const fetchUserByIdFailure = data => ({
    type: actionTypes.FETCH_USER_BY_ID_FAILURE
});

export const fetchUserByIdSuccess = data => ({
    type: actionTypes.FETCH_USER_BY_ID_SUCCESS,
    payload: { data }
});

export const changePassword = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } };
    return (dispatch) => {
        axios.post(`${baseUrl}/user/change-password`, config)
        .then((response)=>{
            dispatch(changePasswordSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(changePasswordFailure());
        })
    };
};

export const changePasswordInit = () => ({
    type: actionTypes.CHANGE_PASSWORD
});

export const changePasswordFailure = data => ({
    type: actionTypes.CHANGE_PASSWORD_FAILURE
});

export const changePasswordSuccess = data => ({
    type: actionTypes.CHANGE_PASSWORD_SUCCESS,
    payload: { data }
});

export const fetchTeam = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(fetchTeamInit());
        axios.get(`${baseUrl}/user/admin/team/${id}`, config)
        .then((response)=> {
            dispatch(fetchTeamSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchTeamFailure());
        })
    };
};

export const fetchTeamInit = () => ({
    type: actionTypes.FETCH_TEAM
});

export const fetchTeamFailure = data => ({
    type: actionTypes.FETCH_TEAM_FAILURE
});

export const fetchTeamSuccess = data => ({
    type: actionTypes.FETCH_TEAM_SUCCESS,
    payload: { data }
});

export const deleteTeam = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(deleteTeamInit());
        axios.post(`${baseUrl}/user/admin/delete-team`, data, config)
        .then((response)=> {
            toast.success("Team member has been removed.", {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch(deleteTeamSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteTeamFailure());
        })
    };
};

export const deleteTeamInit = () => ({
    type: actionTypes.DELETE_TEAM
});

export const deleteTeamFailure = data => ({
    type: actionTypes.DELETE_TEAM_FAILURE
});

export const deleteTeamSuccess = data => ({
    type: actionTypes.DELETE_TEAM_SUCCESS,
    payload: { data }
});

export const updateAccount = (data, id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
    const params = new FormData();
    Object.keys(data).forEach(key => {
        const value = data[key];
        params.append(key, value);
    });
      return (dispatch) => {
        dispatch(updateAccountInit());
        axios.put(`${baseUrl}/account/admin/${id}`, params, config)
        .then((response)=>{
            dispatch(updateAccountSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateAccountFailure());
        })
    };
};


export const updateAccountInit = () => ({
    type: actionTypes.UPDATE_USER_ACCOUNT
});

export const updateAccountFailure = data => ({
    type: actionTypes.UPDATE_USER_ACCOUNT_FAILURE
});

export const updateAccountSuccess = data => ({
    type: actionTypes.UPDATE_USER_ACCOUNT_SUCCESS,
    payload: { data }
});