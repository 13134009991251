import * as actionType from "./subscriptionActionType";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    subscriptions: []
}

export const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.FETCH_SUBSCRIPTION:
        return {
            ...state,
            isError: false,
            isLoading: true,
        };
    case actionType.FETCH_SUBSCRIPTION_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false,
        };
    case actionType.FETCH_SUBSCRIPTION_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            subscriptions: action.payload ? action.payload.data.rows : [],
        };
    case actionType.GET_SUBSCRIPTIONS:
        return {
            ...state,
            isError: false,
            isLoading: true,
        };
    case actionType.GET_SUBSCRIPTIONS_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false,
        };
    case actionType.GET_SUBSCRIPTIONS_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            subscriptionOptions: action.payload ? action.payload.data : [],
        };
        case actionType.CREATE_SUBSCRIPTION:
        return {
            ...state,
            isError: false,
            isLoading: true,
            isSaved: false
        };
        case actionType.CREATE_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false
            };
        case actionType.CREATE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
            case actionType.UPDATE_SUBSCRIPTION:
                return {
                    ...state,
                    isError: false,
                    isLoading: true,
                    isSaved: false
                };
                case actionType.UPDATE_SUBSCRIPTION_FAILURE:
                    return {
                        ...state,
                        isError: true,
                        isLoading: false,
                        isSaved: false
                    };
                case actionType.UPDATE_SUBSCRIPTION_SUCCESS:
                    return {
                        ...state,
                        isError: false,
                        isLoading: false,
                        isSaved: true
                    };
                    case actionType.DELETE_SUBSCRIPTION:
                        return {
                            ...state,
                            isError: false,
                            isLoading: true,
                            isSaved: false
                        };
                        case actionType.DELETE_SUBSCRIPTION_FAILURE:
                            return {
                                ...state,
                                isError: true,
                                isLoading: false,
                                isSaved: false
                            };
                        case actionType.DELETE_SUBSCRIPTION_SUCCESS:
                            return {
                                ...state,
                                isError: false,
                                isLoading: false,
                                isSaved: true
                            };
        default: 
            return state
    }
}