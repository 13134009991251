import * as actionTypes from "./contentActionType";
import axios from 'axios';
import { checkError } from './../error';
const baseUrl = process.env.REACT_APP_API_URL;

export const deleteContent = (contentId, scheduleId) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
    return (dispatch) => {
        dispatch(deleteContentInit());
        axios.delete(`${baseUrl}/content/admin/${contentId}/${scheduleId}`, config)
        .then((response)=>{
            dispatch(deleteContentSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteContentFailure());
        })
    };
};

export const deleteContentInit = () => ({
    type: actionTypes.DELETE_CONTENT
});

export const deleteContentFailure = data => ({
    type: actionTypes.DELETE_CONTENT_FAILURE
});

export const deleteContentSuccess = data => ({
    type: actionTypes.DELETE_CONTENT_SUCCESS,
    payload: { data }
});

export const getContent = (id, params) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }, params: params };
    return (dispatch) => {
        dispatch(getContentInit());
        axios.get(`${baseUrl}/content`, config)
        .then((response)=>{
            dispatch(getContentSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getContentFailure());
        })
    };
};

export const getContentInit = () => ({
    type: actionTypes.GET_CONTENT
});

export const getContentFailure = data => ({
    type: actionTypes.GET_CONTENT_FAILURE
});

export const getContentSuccess = data => ({
    type: actionTypes.GET_CONTENT_SUCCESS,
    payload: { data }
});

export const getContentByAccount = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
    return (dispatch) => {
        dispatch(getContentByAccountInit());
        axios.get(`${baseUrl}/content/admin/account/${id}`, config)
        .then((response)=>{
            dispatch(getContentByAccountSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getContentByAccountFailure());
        })
    };
};

export const getContentByAccountInit = () => ({
    type: actionTypes.GET_CONTENT_BY_ACCOUNT
});

export const getContentByAccountFailure = data => ({
    type: actionTypes.GET_CONTENT_BY_ACCOUNT_FAILURE
});

export const getContentByAccountSuccess = data => ({
    type: actionTypes.GET_CONTENT_BY_ACCOUNT_SUCCESS,
    payload: { data }
});