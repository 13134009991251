import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import MDInput from "../../../shared/components/MDInput";
import FormField from "../../../shared/components/FormField";
import MDTypography from "../../../shared/components/MDTypography";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import MDButton from "../../../shared/components/MDButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ClearIcon from "@mui/icons-material/Clear";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const topHead = {
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "140%",
  color: "#344767",
}

const useStyles = makeStyles({
  closeBtn: {
    position: "relative",
    cursor: "pointer",
    fontSize: "24px",
    color: "#AEAEAE",
  },
  selct: {
    '& .css-rjulde-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select': {
        padding: "0px!important",
    },
    '& .css-ynad2y-MuiSvgIcon-root-MuiSelect-icon': {
        display: "block",
    },
  },
});

export default function SubscriptionDialog(props) {
  const classes = useStyles();
  const { open, onClose, selected, setSelected, addSubscription, updateSubscription } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox>
                  <MDTypography style={topHead} >
                    {selected.id ? "Update" : "Create"} subscription Plan
                  </MDTypography>
              </MDBox>
              <MDBox
                onClick={() => onClose()}
                style={{
                  cursor: "pointer",
                  background: "#E6E8EB",
                  height: "40px",
                  width: "40px",
                  borderRadius: "100px",
                }}
              >
                <ClearIcon
                  style={{ margin: "12px 12px" }} 
                />
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox component="form" pb={3} px={3} mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormField
                  label="Subscription Plan Name"
                  placeholder="Subscription plan name goes here..."
                  type="text"
                  value={selected.name}
                  onChange={e => setSelected({ ...selected, name: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  label="Plan description"
                  placeholder="Plan description goes here..."
                  type="text"
                  value={selected.description}
                  onChange={e => setSelected({ ...selected, description: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  label="Stripe Price ID"
                  placeholder="Stripe Price ID"
                  type="text"
                  value={selected.priceId}
                  onChange={e => setSelected({ ...selected, priceId: e.target.value })}
                />
              </Grid>
              <Grid item xs={6}>
                <FormField
                  label="Plan Price"
                  placeholder="$00.00"
                  type="number"
                  value={selected.price}
                  onChange={e => setSelected({ ...selected, price: e.target.value })}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormField
                  label="Content per month"
                  placeholder="Content per month"
                  type="number"
                  value={selected.contentNumber}
                  onChange={e => setSelected({ ...selected, contentNumber: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormField
                  label="Billing Interval"
                  placeholder="1"
                  type="number"
                  value={selected.timeInterval}
                  onChange={e => setSelected({ ...selected, timeInterval: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  defaultValue="Month"
                  options={["Month", "Year"]}
                  renderInput={(params) => (
                    <FormField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                  value={selected.timeFrame}
                  onChange={e => setSelected({...selected, timeFrame: e.target.textContent})}
                />
              </Grid>
              <Grid item xs={6}>
                <FormField
                  label="Platform Access"
                  placeholder="1"
                  type="number"
                  value={selected.platformAccess}
                  onChange={e => setSelected({ ...selected, platformAccess: e.target.value })}
                />
              </Grid>
              <Grid item xs={6}>
                <FormField
                  label="User Access"
                  placeholder="1"
                  type="number"
                  value={selected.userAccess}
                  onChange={e => setSelected({ ...selected, userAccess: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} display="flex">
                <MDTypography
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#534F6B",
                    marginRight: "60px",
                  }}
                >
                  Access to premium themes and layouts
                </MDTypography>
                <FormControlLabel
                  checked={selected.themeAcess == 1}
                  onChange={e => setSelected({ ...selected, themeAcess: selected.themeAcess === 1 ? 0 : 1 })}
                  sx={{ marginTop: "-8px" }}
                  control={<Switch />}
                />
              </Grid>
              <Grid item xs={12} display="flex">
                <MDTypography
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#534F6B",
                    marginRight: "60px",
                  }}
                >
                  Access to industry content
                </MDTypography>
                <FormControlLabel
                  checked={selected.industryAccess == 1}
                  onChange={e => setSelected({ ...selected, industryAccess: selected.industryAccess === 1 ? 0 : 1 })}
                  sx={{ marginTop: "-8px" }}
                  control={<Switch />}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={8}>
                <MDBox sx={{ display: "flex", marginTop: "36px" }}>
                  <MDTypography
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#534F6B",
                    }}
                  >
                    Publish As
                  </MDTypography>
                  <MDButton
                    size="small"
                    sx={{ mr: "30px", ml: "50px" }}
                    color={selected.publishStatus == 1 ? "dark" : "light"}
                    onClick={() => setSelected({ ...selected, publishStatus: 1 })}
                    >
                      Public
                  </MDButton>
                  <MDButton
                    variant="contained"
                    color={selected.publishStatus == 0 ? "dark" : "light"}
                    size="small"
                    onClick={() => setSelected({ ...selected, publishStatus: 0 })}
                  >
                    Draft
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
            <MDBox sx={{ display: "flex", marginTop: "37px" }}>
              <MDTypography
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "#534F6B",
                  marginRight: "60px",
                }}
              >
                Make It Featured
              </MDTypography>
              <FormControlLabel
                sx={{ marginTop: "-8px" }}
                control={<Switch />}
              />
            </MDBox>
            <MDButton
              style={{
                background: "#09032B",
                color: "#FFFFFF",
                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                borderRadius: "8px",
                width: "150px",
                fontWeight: "700",
                fontSize: "12px",
                marginTop: "56px",
                textTransform: "capitalize"
              }}
              fullWidth
              onClick={() => {
                selected.id ? updateSubscription(selected) : addSubscription(selected);
              }}
              >
                Save
            </MDButton>
            <MDButton
              variant="text"
              style={{
                color: "#6B6880",
                fontWeight: "700",
                fontSize: "12px",
                marginTop: "56px",
                textTransform: "capitalize"
              }}
            >
              Cancel
            </MDButton>
          </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
