import React, { useEffect, useState } from "react";
import DashboardLayout from "./../../shared/components/DashboardLayout";
import DashboardNavbar from "./../../shared/components/DashboardNavbar";
// MUI
import Grid from "@mui/material/Grid";
import MDButton from "./../../shared/components/MDButton";
import MDInput from "./../../shared/components/MDInput";
import Loading from './../../shared/Loading';
import { MasterLayoutDialog } from './component/master-layout-dialog';
import { MasterLayoutTable } from './component/master-layout-table';
import { useNavigate } from "react-router-dom";

export default function MasterLayoutPage(props) {
  const { 
    createMasterLayout, 
    updateMasterLayout,
    deleteMasterLayout,
    fetchMasterLayouts,
    masterLayouts,
    isSaved,
    isLoading
  } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState({name: "", code: "", fields: [], status: 1});
  
  useEffect(() => {
    fetchMasterLayouts();
  }, []);

  useEffect(() => {
    if (isSaved) {
      fetchMasterLayouts();
      setSelected({name: "", code: "", fields: [], status: 1});
      setOpen(false);
    }
  }, [isSaved]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loading loading={isLoading} />
      <Grid container style={{display: "flex", marginTop: "24px"}}>
        <Grid item style={{marginLeft: "auto"}}>
            <MDButton
              onClick={() => {
                setSelected({name: "", code: "", fields: [], status: 1});
                setOpen(true)
              }}
                sx={{
                  background: "linear-gradient(#09032B, #312B56)",
                  color: "#fff",
                  marginLeft: "auto"
                }}
            >
                CREATE MASTER LAYOUT
            </MDButton>
        </Grid>
      </Grid>
      <MasterLayoutTable
        selected={selected}
        setSelected={setSelected}
        rows={masterLayouts}
        open={open}
        setOpen={setOpen}
        updateMasterLayout={updateMasterLayout}
      />
      <MasterLayoutDialog
        open={open}
        setOpen={setOpen}
        updateMasterLayout={updateMasterLayout}
        createMasterLayout={createMasterLayout}
        onClose={() => {
          setSelected({name: "", code: "", fields: [], status: 1});
          setOpen(false);
        }}
        selected={selected}
        setSelected={setSelected}
      />
    </DashboardLayout>
  );
}
